import { Grid, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined'
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined'
import DetailCard from '../../components/Cards/DetailCard'
import { axiosAuth } from '../../util/axios-instance'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import GrupaCard from '../../components/Cards/GrupaCard'
import BackToButton from '../../components/FormsUI/Button/BackToButton'
import SimpleModal from '../../components/Modal/Modal'
import NadredjenaGrupaCard from '../../components/Cards/NadedjenaGrupaCard'
import latToCyr from '../../util/latToCyr'

const useStyles = makeStyles({
    pageContainer: {
        margin: '0px 0',
        textAlign: 'left',
        padding: '0 50px',
    },
    accIcon: {
        fontSize: '3rem',
    },
})

const SingleUser = () => {
    // eslint-disable-next-line
    const [load, setLoad] = useState(false)
    const [user, setUser] = useState({})
    const [grupa, setGrupa] = useState({})
    const { id } = useParams()
    const location = useLocation()
    const history = useHistory()
    const classes = useStyles()

    useEffect(() => {
        if (location?.state?.from !== 'KORISNICI')
            history.push('/app/korisnici/')
    }, [])
    const getUserDetails = async () => {
        try {
            setLoad(true)
            const { data } = await axiosAuth.get(`/api/user/single/${id}`)

            setUser(data.user)
            setGrupa(data.group)
            setLoad(false)
        } catch (err) {
            console.log(err.response)
        }
    }
    useEffect(() => {
        getUserDetails()
    }, [])

    // const {
    //     kratakNaziv,
    //     naziv,
    //     tip: { bla },
    // } = grupa || {}

    return (
        <Grid container spacing={4} className={classes.pageContainer}>
            <BackToButton label="НАЗАД НА ЛИСТУ СВИХ КОРИСНИКА" />
            <Grid container justifyContent="center">
                <PageTitle>Детаљи корисника</PageTitle>
            </Grid>

            {load ? (
                <SimpleModal />
            ) : (
                <>
                    <Grid item xs={12} sm={6} md={4}>
                        <AccountBoxOutlinedIcon
                            color="primary"
                            className={classes.accIcon}
                        />

                        <DetailCard name="ИМЕ" val={user?.ime} />
                        <DetailCard name="ПРЕЗИМЕ" val={user?.prezime} />
                        <DetailCard name="РОЛА" val={latToCyr(user?.rola)} />
                        <DetailCard name="Е-ПОШТА" val={user?.email} />
                        <DetailCard
                            name="СТАТУС"
                            val={latToCyr(user?.status)}
                        />
                        <DetailCard
                            name="ПОТВРДЂЕНА Е-ПОШТА"
                            val={user?.emailPotvrdjen ? 'Да' : 'Не'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <HomeWorkOutlinedIcon
                            color="primary"
                            className={classes.accIcon}
                        />

                        <GrupaCard
                            kn={grupa?.kratakNaziv}
                            naziv={grupa?.naziv}
                            ime="ГРУПА"
                            tip={latToCyr(grupa?.tip)}
                        />
                        {grupa?.nadredjenaGrupa?.naziv ? (
                            <NadredjenaGrupaCard
                                ime="НАДРЕЂЕНА ГРУПА"
                                naziv={grupa.nadredjenaGrupa.naziv}
                            />
                        ) : null}
                    </Grid>
                </>
            )}
        </Grid>
    )
}

export default SingleUser
