import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Register from '../pages/Register/Register'
import Login from '../pages/Login/Login'
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword'
import NotFound from '../pages/NotFound/NotFound'
import RestartPassword from '../pages/RestartPassword/RestartPassword'

function PublicRoutes() {
    return (
        <>
            <Switch>
                <Route path="/" exact>
                    <Login />
                </Route>
                <Route path="/registracija" exact>
                    <Register />
                </Route>
                <Route path="/zaboravljena-lozinka" exact>
                    <ForgotPassword />
                </Route>
                <Route path="/reset-lozninke" exact>
                    <RestartPassword />
                </Route>
                <Route>
                    <NotFound />
                </Route>
            </Switch>
        </>
    )
}

export default PublicRoutes
