import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { Grid, makeStyles, Button } from '@material-ui/core'

import { trigger } from 'swr'

import { axiosAuth } from '../../../util/axios-instance'

const useStyles = makeStyles(() => ({
    closeActionTitle: {
        margin: '1rem',
    },
    cardMargin: {
        margin: 20,
    },
    paper: {
        padding: 20,
        width: 400,
    },
}))

const StepChangeStatus = ({
    setSeverity,
    setSnackbar,

    setError,
    activity,
}) => {
    const [isSubmiting, setIsSubmiting] = useState(false)
    const classes = useStyles()

    const checkFiles = (files) => {
        const demandedFilesNumber = files.filter((file) =>
            file.originalnoIme.includes(`revizija=${activity.returned}`)
        ).length

        return demandedFilesNumber
    }
    const handleStatusChangeSubmit = async () => {
        try {
            setIsSubmiting(true)
            await axiosAuth.post('/api/activity/activity-status/', {
                status: 'Završena',
                activityId: activity._id,
            })

            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно промењен статус активности.',
            })
            setIsSubmiting(false)
            // handleNext()
            trigger(`/api/activity/${activity._id}`)
        } catch (error) {
            setIsSubmiting(false)
            setError(error.response)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Мењање статуса активности није успешно!',
            })
        }
    }
    return (
        <Grid container className={classes.cardMargin}>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleStatusChangeSubmit}
                    disabled={
                        isSubmiting ||
                        (activity.returned === 0 &&
                            activity.fajlovi.length < activity.brojFajlova) ||
                        (activity.returned !== 0 &&
                            checkFiles(activity.fajlovi) < activity.brojFajlova)
                    }
                >
                    Заврши активност
                </Button>
            </Grid>
        </Grid>
    )
}

export default StepChangeStatus

StepChangeStatus.propTypes = {
    setSeverity: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    // handleNext: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    activity: PropTypes.objectOf(PropTypes.any).isRequired,
    // setShowStepLabel: PropTypes.func.isRequired,
}
