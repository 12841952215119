import moment from 'moment'

const daysToDeadline = (dateToday, deadline) => {
    // console.log('Danasnji dan', dateToday, 'Rok za zavrsetak', deadline)
    if (!dateToday || !deadline) return false
    if (
        dateToday === 'Незапочета активност' ||
        deadline === 'Незапочета активност' ||
        deadline === 'клизно'
    )
        return false
    return moment.duration(moment(deadline).diff(moment(dateToday))).asDays()
}

export default daysToDeadline
