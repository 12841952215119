import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Grid, makeStyles, Snackbar, Tooltip } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { DataTable } from 'primereact/datatable'
import { Column } from 'jspdf-autotable'
import BackToButton from '../../../../components/FormsUI/Button/BackToButton'
import { axiosAuth as axios } from '../../../../util/axios-instance'
import SimpleModal from '../../../../components/Modal/Modal'
import PageTitle from '../../../../components/FormsUI/PageTitle/PageTitle'
import AddButton from '../../../../components/FormsUI/Button/AddButton'
import AddLocationsToContract from './AddLocationsToContract'
import { useSnackbar } from '../../../../util/snackbar-hook'
import cyrToLat from '../../../../util/cyrToLat'

const useStyles = makeStyles(() => ({
    tableCell: {
        width: '100%',

        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
    },
}))

const InventoryLocations = () => {
    const classes = useStyles()
    const { id } = useParams()
    const history = useHistory()
    const [load, setLoad] = useState(false)
    const [contract, setContract] = useState(null)
    const [locations, setLocations] = useState([])
    const [allLocations, setAllLocations] = useState([])
    const [addOpen, setAddOpen] = useState(false)
    const [refreshState, setRefreshState] = useState(false)
    const { snackbar, severity, setSeverity, setSnackbar, handleSnackBarOpen } =
        useSnackbar()

    const getContractLocations = async () => {
        try {
            setLoad(true)
            const response = await axios.get(`/api/contract/${id}`)

            const tableData = response.data.contract.lokacije.reduce(
                (acc, currentEl) => {
                    const addElement = {
                        _id: currentEl._id,
                        naziv: currentEl.naziv,
                        prioritet: currentEl.prioritet
                            ? currentEl.prioritet
                            : '',
                        prioritetNaziv: currentEl.prioritet?.naziv,
                        ugovor: currentEl.ugovor,
                        ugovorNaziv: currentEl.ugovor.naziv,
                        cyr: currentEl.naziv,
                        lat: cyrToLat(currentEl.naziv),
                    }
                    acc.push(addElement)
                    return acc
                },
                []
            )

            setContract(response.data.contract)
            setLocations(tableData)
            setLoad(false)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteLocation = async (location) => {
        try {
            setLoad(true)
            setRefreshState(true)
            await axios.patch(`/api/link/unlink/${id}`, {
                lokacija: location,
            })
            setRefreshState(false)
            setLoad(false)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно обрисана локација са уговора',
            })
        } catch (error) {
            console.log(error)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Неуспешно брисање локације са уговора',
            })
        }
    }

    const getAllLocations = async () => {
        try {
            const res = await axios.get('api/link/get/autocomplete/locations')
            setAllLocations(res.data.locations)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllLocations()
    }, [])

    useEffect(() => {
        if (!refreshState) getContractLocations()
    }, [refreshState])

    const onRowClick = (rowData) => {
        if (
            rowData.originalEvent.target.nodeName === 'TD' ||
            rowData.originalEvent.target.nodeName === 'DIV'
        ) {
            history.push(`/app/inventar/lokacija/${rowData.data._id}`, {
                from: { page: 'contracts', contractId: id },
            })
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'obrisi'
        ) {
            deleteLocation(rowData.data._id)
        }
    }

    const actionBodyTemplate = () => (
        <Grid key="edit" container spacing={2}>
            <Grid item xs={12}>
                <Button
                    name="obrisi"
                    color="secondary"
                    fullWidth
                    variant="contained"
                >
                    {' '}
                    Обриши
                </Button>
            </Grid>
        </Grid>
    )

    const cellData = (rowData, cellTooltip) => (
        <Tooltip title={rowData[cellTooltip] ? rowData[cellTooltip] : ''}>
            <div className={classes.tableCell}> {rowData[cellTooltip]}</div>
        </Tooltip>
    )

    return (
        <Grid container style={{ padding: 30 }}>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={4000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {load && <SimpleModal />}
            {addOpen && (
                <AddLocationsToContract
                    locations={allLocations}
                    contractId={id}
                    modalOpen={addOpen}
                    setModal={setAddOpen}
                    setRefresh={setRefreshState}
                    setSeverityHandler={setSeverity}
                    setSnackbarHandler={setSnackbar}
                />
            )}
            <Grid item>
                <BackToButton
                    label="Назад на списак уговора"
                    style={{ marginLeft: 0 }}
                />
            </Grid>
            <Grid item style={{ paddingTop: 8 }}>
                <AddButton
                    label="Додај локације"
                    click={() => setAddOpen(true)}
                />
            </Grid>
            <Grid item xs={12}>
                {contract && (
                    <PageTitle
                        title={`Локације на уговору - ${contract?.naziv}`}
                    />
                )}
            </Grid>
            {/* <Grid item></Grid> */}
            <Grid item>
                <DataTable
                    value={locations}
                    onRowClick={onRowClick}
                    className="blackTable"
                    showGridlines
                    emptyMessage="Уговор нема локација"
                >
                    <Column
                        field="naziv"
                        header="Назив"
                        body={(rowData) => cellData(rowData, 'naziv')}
                    />
                    <Column
                        field="prioritetNaziv"
                        header="Приоритет"
                        body={(rowData) => cellData(rowData, 'prioritetNaziv')}
                    />
                    <Column
                        field="ugovorNaziv"
                        header="Уговор"
                        body={(rowData) => cellData(rowData, 'ugovorNaziv')}
                    />
                    <Column
                        field="opstina"
                        header=""
                        body={actionBodyTemplate}
                        style={{ width: 100 }}
                    />
                </DataTable>
            </Grid>
        </Grid>
    )
}

export default InventoryLocations
