import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Snackbar } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import { axiosInstance } from '../../util/axios-instance'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import BackToButton from '../../components/FormsUI/Button/BackToButton'

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(8),
        maxWidth: 500,
        margin: '0 auto',
    },
    gridAlign: {
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
}))

const Register = () => {
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: 'default',
    })
    const [severity, setSeverity] = useState('info')
    const classes = useStyles()
    const history = useHistory()

    // const ctx = React.useContext(AppContext)
    // ctx.handleAuthHeader(true)

    const INITIAL_FORM_STATE = {
        ime: '',
        prezime: '',
        email: '',
        lozinka: '',
    }

    const FORM_VALIDATION = Yup.object().shape({
        ime: Yup.string().required('унесите име'),
        prezime: Yup.string().required('унесите презиме'),
        email: Yup.string()
            .email('погрешан имејл')
            .required('унесите имејл адресу'),
        lozinka: Yup.string()
            .required('унесите лозинку')
            .min(8, 'лозинка мора имати минимум 8 карактера'),
    })

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const handleRegisterSubmit = async (userInfo, { setErrors, resetForm }) => {
        try {
            await axiosInstance.post(`/api/user/signup`, userInfo)
            resetForm(INITIAL_FORM_STATE)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'На ваш мејл је послат активациони линк.',
            })
            setTimeout(() => {
                history.push('/')
            }, 4000)
        } catch (err) {
            console.log(err)
            let error = 'email'
            if (err.response.data.errors) {
                error =
                    err.response.data.errors[0].includes('Password') &&
                    'password'
            }
            if (err.response.data.errors) {
                error =
                    err.response.data.errors[0].includes('Name') && 'firstName'
            }
            setErrors({
                [error]: error.response,
            })
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Неуспешна регистрација',
            })
        }
    }

    return (
        <Grid container className={classes.gridAlign}>
            <Grid container>
                <BackToButton label="назад на пријаву корисника" />
            </Grid>
            <PageTitle title="регистрација корисника" />
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={4000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Grid item xs={12}>
                <Container maxWidth="xs">
                    <div className={classes.formWrapper}>
                        <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE,
                            }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={handleRegisterSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <PageTitle>Регистрација</PageTitle>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextfieldWrapper
                                                name="ime"
                                                label="Име"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextfieldWrapper
                                                name="prezime"
                                                label="Презиме"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextfieldWrapper
                                                name="email"
                                                label="Имејл"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextfieldWrapper
                                                name="lozinka"
                                                label="Лозинка"
                                                type="password"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ButtonWrapper
                                                disabled={isSubmitting}
                                                type="submit"
                                                variant="contained"
                                                height={48}
                                                fontSize="1rem"
                                            >
                                                Региструј се
                                            </ButtonWrapper>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Container>
            </Grid>
        </Grid>
    )
}

export default Register
