import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper, makeStyles, Typography } from '@material-ui/core'
import { Form, Formik } from 'formik'

import * as Yup from 'yup'

import MultipleFileUploadField from '../upload/MultipleFileUploadField'
import StepChangeStatus from './StepChangeStatus'
import StepChangeControlStatus from '../ControllActivityStepper/StepChangeControlStatus'

const useStyles = makeStyles(() => ({
    cardMargin: {
        margin: 'auto',
    },
    paper: {
        padding: 20,
    },
    fileNumber: {
        marginTop: 25,
    },
}))

const StepUploadFiles = ({
    activity,
    connected,
    setSeverity,
    setSnackbar,

    setError,

    withChanges,
}) => {
    const classess = useStyles()

    const INITIAL_FILE_STATE = {
        files: [],
    }

    const FILE_VALIDATION = Yup.object().shape({
        files: Yup.array().of(
            Yup.object().shape({
                errors: Yup.array().max(0, 'Имате грешку при увозу података'),
            })
        ),
        // .test({
        //     message: `Потребан број фајлова за активност: ${activity.brojFajlova}`,
        //     test: (arr) => arr.length >= activity.brojFajlova,
        // }),
    })
    /* eslint-disable */

    const checkFiles = (files) => {
        const demandedFilesNumber = files.filter((file) =>
            file.originalnoIme.includes(`revizija=${activity.returned}`)
        ).length

        return demandedFilesNumber
    }
    const findSubActivity = (activityData) => {
        if (activityData.kontrolna) {
            const subActivity = connected.find((el) => {
                if (withChanges === 'true') return el.kratakNaziv === 'A3.1'
                if (withChanges === 'false') {
                    const isA3 = el.kratakNaziv === 'A3.3'

                    return isA3
                        ? el.kratakNaziv === 'A3.3'
                        : el.kratakNaziv === 'A7.1'
                }
            })
            return subActivity._id
        }
        return activityData._id
    }

    const filesToUpload = () => {
        if (activity.returned === 0) {
            if (activity.kratakNaziv === 'A5') {
                const taxNumber = activity.podaci.length
                return taxNumber * 2 - activity.fajlovi.length
            }
            const filesDifference =
                activity.brojFajlova * 1 - activity.fajlovi.length

            return filesDifference
        }

        return activity.brojFajlova * 1 - checkFiles(activity.fajlovi)
    }

    return (
        <Paper className={classess.paper}>
            <Grid container className={classess.cardMargin}>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{ ...INITIAL_FILE_STATE }}
                        validationSchema={FILE_VALIDATION}
                        // onSubmit={fileUpload}
                    >
                        {({ isValid, isSubmitting, errors }) => (
                            <Form>
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <MultipleFileUploadField
                                            name="files"
                                            activity={activity}
                                            setSeverity={setSeverity}
                                            setSnackbar={setSnackbar}
                                            setError={setError}
                                        />

                                        <>
                                            {filesToUpload() > 0 && (
                                                <Typography
                                                    color="error"
                                                    className={
                                                        classess.fileNumber
                                                    }
                                                >
                                                    {`Број фајлова неопходан за увоз: ${filesToUpload()}`}
                                                </Typography>
                                            )}
                                            {filesToUpload() <= 0 && (
                                                <Typography
                                                    color="primary"
                                                    className={
                                                        classess.fileNumber
                                                    }
                                                >
                                                    {`Увезли сте довољан број фајлова.`}
                                                </Typography>
                                            )}
                                        </>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                {activity.kontrolna ? (
                    <StepChangeControlStatus
                        activity={activity}
                        setSeverity={setSeverity}
                        setSnackbar={setSnackbar}
                        setError={setError}
                    />
                ) : (
                    <StepChangeStatus
                        activity={activity}
                        setSeverity={setSeverity}
                        setSnackbar={setSnackbar}
                        setError={setError}
                    />
                )}
            </Grid>
        </Paper>
    )
}

export default StepUploadFiles

StepUploadFiles.propTypes = {
    activity: PropTypes.objectOf(PropTypes.any).isRequired,
    connected: PropTypes.arrayOf(PropTypes.any),
    setSeverity: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    // setShowStepLabel: PropTypes.func.isRequired,
    withChanges: PropTypes.string,
}

StepUploadFiles.defaultProps = {
    withChanges: 'false',
    connected: [],
}
