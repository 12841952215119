import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import {
    Container,
    Grid,
    Snackbar,
    Button,
    Collapse,
    Tooltip,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Message } from 'primereact/message'
import { useParams } from 'react-router-dom'
import useSWR, { trigger } from 'swr'
import AddCommentIcon from '@material-ui/icons/AddComment'
import SimpleModal from '../../components/Modal/Modal'
import BackToButton from '../../components/FormsUI/Button/BackToButton'
import BackToButtonHard from '../../components/FormsUI/Button/BackToButtonHard'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import ActivityDetails from './ActivityDetails/ActivityDetails'
import EditActivity from './EditActivity/EditActivity'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import { axiosAuth } from '../../util/axios-instance'
import ActivityFiles from './ActivityDetails/ActivityFiles'
import ActivityComments from './ActivityDetails/ActivityComments'
import BackToButtonNotification from '../../components/FormsUI/Button/BackToButtonNotification'

const useStyles = makeStyles(() => ({
    message: {
        width: '100%',
        fontSize: '1rem',
    },
    icon: {
        fontSize: 40,
        color: '#417AB4',
        marginRight: 10,
    },
    contractTitle: {
        textAlign: 'left',

        margin: '20px 15px',

        fontSize: 25,
        color: '#417AB4',
        display: 'flex',
        alignItems: 'center',
    },
    addCommentSection: {
        marginLeft: 10,
    },
    btn: {
        backgroundColor: '#1E8637',
        color: '#FFF',
        '&:hover': {
            background: '#059D2A',
        },
        marginRight: '1rem',
        textAlign: 'center',
        marginTop: '4rem',
    },
    toComments: {
        marginTop: 10,
    },
    buttonMargin: {
        marginBottom: 20,
    },
    buttonAdd: {
        backgroundColor: '#1E8637',
        color: '#FFF',
        '&:hover': {
            background: '#059D2A',
        },
    },
}))

const ActivityPerLocation = () => {
    // 'Nije započeta', 'U toku', 'Završena'

    const classes = useStyles()
    const userInfo = useSelector((state) => state.myrole)
    const { id } = useParams()
    const myGroupType = userInfo.user.grupa && userInfo.user.grupa.cirilica

    const [activity, setActivity] = useState('')
    const [isResponsibleGroup, setIsResponsibleGroup] = useState(false)
    const [isControlActivity, setIsControlActivity] = useState(false)
    const [isA1, setIsA1] = useState(false)
    const [responsableInvestor, setResponsableInvestor] = useState([])

    const [isInactive, setIsInactive] = useState(false)

    const [isSubmiting, setIsSubmiting] = useState(false)

    const [withFiles, setWithFiles] = useState(false)
    const [connected, setConnected] = useState([])
    const [load, setLoad] = useState(false)
    const [comments, setComments] = useState(false)
    const [error, setError] = useState('')
    const myCommentsRef = useRef(null)
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })

    const buttonStyles = { backgroundColor: '#1E8637', hoverColor: '#059D2A' }
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }
    const INITIAL_FORM_STATE = {
        komentar: '',
    }

    const FORM_VALIDATION = Yup.object().shape({
        komentar: Yup.string()
            .min(1, 'Не можете послати празну поруку.')
            .required('Обавезно поље'),
    })
    const handleComments = () => {
        setComments(!comments)
        setTimeout(() => {
            myCommentsRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
            })
        }, 500)
    }

    const handleActivateActivity = async () => {
        try {
            setIsSubmiting(true)
            await axiosAuth.post('/api/activity/activity-status/', {
                status: 'U toku',
                activityId: activity._id,
            })

            trigger(`/api/activity/${id}`)
            setIsSubmiting(false)
            setIsInactive(false)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно покренута активност.',
            })
        } catch (er) {
            console.log(er.response)
            setIsSubmiting(false)
            const noContract = !activity.lokacija?.ugovor
                ? 'Активности на кластеру који није под уговором се не могу покренути!'
                : `Мењање статуса активности није успешно! ${er.response.data.message}`
            setError(er.response)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: noContract,
            })
        }
    }

    const handleComment = async (comment, { resetForm }) => {
        try {
            await axiosAuth.post(`/api/comment/${id}`, comment)
            resetForm(INITIAL_FORM_STATE)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно послат коментар.',
            })
            trigger(`/api/activity/${id}`)
        } catch (err) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Коментар није послат!',
            })
        }
    }

    const checkActivity = (toCheckActivity) => {
        if (toCheckActivity.kratakNaziv === 'A1') {
            // console.log(toCheckActivity.kratakNaziv, 'a1')
            setIsA1(true)
        }

        if (toCheckActivity.status === 'Nije započeta') {
            // console.log(toCheckActivity.status, 'nije zapoceta')
            setIsInactive(true)
        }
        if (toCheckActivity.brojFajlova > 0) {
            // console.log(toCheckActivity.brojFajlova, 'br fajlova')
            setWithFiles(true)
        }
    }
    // console.log('Aktivnosti - MY PROFILE', userInfo)

    const { data } = useSWR(`/api/activity/${id}`)

    useEffect(() => {
        if (data) {
            setActivity(data.activity)
            setConnected(data?.connected || [])
            setIsControlActivity(data.activity.kontrolna)
            const checkResponsibility = data.activity.odgovoran.some((el) =>
                // console.log('el', el, 'moj tip ', myGroupType)
                myGroupType.toUpperCase().includes(el.toUpperCase())
            )

            const investor = data?.activity?.lokacija?.odgovoran.filter(
                (el) => el.tip === 'Investitor'
            )
            // console.log('investitor je : ', investor[0])

            setResponsableInvestor(investor[0])

            setIsResponsibleGroup(checkResponsibility)
            checkActivity(data.activity)
            // console.log(
            //     'da li je odgovorna grupa?',
            //     checkResponsibility,
            //     'da li je A1?',
            //     isA1,
            //     'da li je aktivna?',
            //     isActive,
            //     'da li zavrsena?',
            //     isFinished,
            //     'da li je neaktivna?',
            //     isInactive,
            //     'sa fajlovima',
            //     withFiles,
            //     'da li je kontrolna?',
            //     isControlActivity
            // )
            setLoad(false)
        }
        if (!data && !error) setLoad(true)
        if (error) setError(error.response)
    }, [data])

    return (
        <>
            {load && <SimpleModal />}

            {activity && (
                <Container>
                    <Grid container>
                        <Snackbar
                            open={snackbar.open}
                            onClose={handleSnackBarOpen}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            autoHideDuration={3500}
                            key="key"
                        >
                            <Alert
                                onClose={handleSnackBarOpen}
                                severity={severity}
                            >
                                {snackbar.message}
                            </Alert>
                        </Snackbar>
                        {userInfo.user.rola === 'Admin' ? (
                            <BackToButton label="Назад на листу свих активности" />
                        ) : (
                            <BackToButtonNotification label="Назад на обавештења" />
                        )}
                        {userInfo.user.rola !== 'Admin' && (
                            <BackToButtonHard
                                label="Назад на кластере"
                                idLokacije={activity?.lokacija?._id}
                            />
                        )}

                        <Grid container justifyContent="center">
                            <PageTitle
                                title={`${activity.kratakNaziv} - ${activity.naziv} `}
                            />
                        </Grid>

                        <Grid container>
                            {activity && activity.status === 'Završena' && (
                                <Grid item xs={12}>
                                    <Message
                                        severity="warn"
                                        text="Активност је завршена. Можете само вршити преглед
                        активности и додати коментар."
                                        className={classes.message}
                                    />
                                </Grid>
                            )}
                            {userInfo.user.rola !== 'Admin' &&activity && activity.status === 'Nije započeta' && (
                                <Grid item xs={12}>
                                    <Message
                                        severity="info"
                                        text="Активност још није започета."
                                        className={classes.message}
                                    />
                                </Grid>
                            )}

                            {userInfo.user.rola === 'Admin' &&
                            (
                                    <Grid item xs={12}>
                                        <Tooltip
                                            title="Промени статус активности на: 'У току'"
                                            placement="top"
                                        >
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={handleActivateActivity}
                                                disabled={isSubmiting}
                                                className={classes.btn}
                                            >
                                                Реактивирај Активност
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                )}

                            <ActivityDetails
                                // isControlActivity={isControlActivity}
                                activity={activity}
                                connected={data?.connected || []}
                            />

                            <Grid
                                container
                                justifyContent="flex-start"
                                className={classes.editSection}
                            >
                                <Grid item xs={3}>
                                    <Collapse
                                        in={
                                            isA1 &&
                                            isInactive &&
                                            userInfo.user.rola ===
                                                'Zaposleni' &&
                                            ((userInfo.user.grupa.tip ===
                                                responsableInvestor?.tip &&
                                                userInfo.user.grupa.naziv ===
                                                    responsableInvestor?.naziv) ||
                                                (userInfo.user.grupa
                                                    .sveVideci &&
                                                    userInfo.user.grupa.tip ===
                                                        'Investitor'))
                                        }
                                    >
                                        <Button
                                            name="pokreniAktivnost"
                                            onClick={handleActivateActivity}
                                            className={classes.btn}
                                            variant="contained"
                                            fullWidth
                                            disabled={isSubmiting}
                                        >
                                            Покрени активности
                                        </Button>
                                    </Collapse>
                                </Grid>
                            </Grid>

                            {isResponsibleGroup &&
                                activity &&
                                (activity.status !== 'Završena' ||
                                    activity.kratakNaziv === 'A3' ||
                                    activity.kratakNaziv === 'A6.0' ||
                                    activity.kratakNaziv === 'A6' ||
                                    activity.kratakNaziv === 'A7') &&
                                userInfo.user.rola !== 'Rukovodilac' && (
                                    <EditActivity
                                        isControlActivity={isControlActivity}
                                        activity={activity}
                                        setSeverity={setSeverity}
                                        setSnackbar={setSnackbar}
                                        setError={setError}
                                        isA1={isA1}
                                        isInactive={isInactive}
                                        setIsInactive={setIsInactive}
                                        withFiles={withFiles}
                                        connected={connected}
                                    />
                                )}

                            <ActivityFiles
                                activity={activity}
                                connected={connected}
                            />

                            <Grid
                                container
                                className={classes.addCommentSection}
                            >
                                <Grid item xs={12} sm={4}>
                                    <Formik
                                        initialValues={{
                                            ...INITIAL_FORM_STATE,
                                        }}
                                        validationSchema={FORM_VALIDATION}
                                        onSubmit={handleComment}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <Grid container spacing={3}>
                                                    <div
                                                        className={
                                                            classes.contractTitle
                                                        }
                                                    >
                                                        <AddCommentIcon
                                                            className={
                                                                classes.icon
                                                            }
                                                        />
                                                        <span>
                                                            Додајте Коментар
                                                        </span>
                                                    </div>

                                                    <Grid item xs={12}>
                                                        <TextfieldWrapper
                                                            name="komentar"
                                                            variant="outlined"
                                                            label="Коментар"
                                                            multiline
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className={
                                                            classes.buttonMargin
                                                        }
                                                    >
                                                        <ButtonWrapper
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                            type="submit"
                                                            variant="contained"
                                                            buttonStyles={
                                                                buttonStyles
                                                            }
                                                        >
                                                            Додај коментар
                                                        </ButtonWrapper>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className={
                                                            classes.buttonMargin
                                                        }
                                                    >
                                                        <Button
                                                            fullWidth
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={
                                                                handleComments
                                                            }
                                                        >
                                                            {!comments
                                                                ? 'Види све коментаре'
                                                                : 'Сакриј коментаре'}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                            <Collapse in={comments}>
                                <ActivityComments activity={activity} />
                                <div
                                    ref={myCommentsRef}
                                    className={classes.toComments}
                                />
                            </Collapse>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </>
    )
}

export default ActivityPerLocation
