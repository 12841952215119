import { useState } from 'react'

// eslint-disable-next-line
export const useSnackbar = () => {
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    return {
        snackbar,
        severity,
        setSeverity,
        setSnackbar,
        handleSnackBarOpen,
    }
}
