export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'

export const USER_GUESTS_LIST_REQUEST = 'USER_GUESTS_LIST_REQUEST'
export const USER_GUESTS_LIST_SUCCESS = 'USER_GUESTS_LIST_SUCCESS'
export const USER_GUESTS_LIST_FAIL = 'USER_GUESTS_LIST_FAIL'

export const MYINFO_REQUEST = 'MYINFO_REQUEST'
export const MYINFO_SUCCESS = 'MYINFO_SUCCESS'
export const MYINFO_FAIL = 'MYINFO_FAIL'

export const USER_ROLE = 'USER_ROLE'
