import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Grid,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { axiosAuth as axios } from '../../../util/axios-instance'
import UserTable from '../../../components/tables/UserTable'
import SimpleModal from '../../../components/Modal/Modal'
// import activities from '../../Activities/activitiesList'
// import latToCyr from '../../../util/latToCyr'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
    createbutton: {
        padding: 8,
        margin: '20px 0px',
    },
    root: {
        margin: 12,
        textAlign: 'left',
    },
    table: {
        margin: 8,
    },
    editButton: {
        backgroundColor: '#059D2A',
        color: 'white',
        '&:hover': {
            background: '#059D2A',
        },
    },
    deleteButton: {
        backgroundColor: '#D86565',
        color: 'white',
        '&:hover': {
            background: '#D86565',
        },
    },
    buttonW: {
        margin: '1rem 0',
    },
}))

const ReportActivitySum = ({ flag }) => {
    const [reportData, setReportdata] = useState([])
    const [headers, setHeaders] = useState([])
    const [resetTable, setResetTable] = useState(false)
    const classes = useStyles()
    const [load, setLoad] = useState(false)
    const [selectedPriority, setSelectedPriority] = useState(flag[0]._id)

    const getSum = async (priori) => {
        try {
            setLoad(true)
            const url = `/api/report/summary/${priori}`
            const { data } = await axios.get(url)
            const allLocationGroups = await axios.get(
                '/api/group/sve-videci/true'
            )

            const allLocationContractors = allLocationGroups.data.groups.filter(
                (el) => el.tip.includes('Izvođač')
            )
            const allLocationContractorsHeaders = allLocationContractors.map(
                (contractor) => ({
                    field: contractor.naziv,
                    header: contractor.naziv,
                })
            )

            const contractorsHeader = data?.izvodjaci?.map((item) => ({
                field: `${item.izvodjac.naziv}`,
                header: `${item.izvodjac.naziv}`,
            }))

            setHeaders([
                {
                    field: 'naziv',
                    header: 'Назив',
                },
                {
                    field: 'count',
                    header: 'Укупно',
                },
                ...contractorsHeader,
                ...allLocationContractorsHeaders,
            ])

            const total = {
                naziv: 'Укупно кластера',
                count: data.totalLocations,
            }

            const contractorDataTotal = data.izvodjaci?.map((item) => ({
                [item.izvodjac.naziv]: item.count,
            }))
            const allLocationContractorTotal = allLocationContractors.map(
                (item) => ({
                    [item.naziv]: data.totalLocations,
                })
            )

            const contractorDataTotaltoObject = Object.assign(
                {},
                ...contractorDataTotal
            )

            const allLocationContractorToObject = Object.assign(
                {},
                ...allLocationContractorTotal
            )

            const dynamicTotalContractorsData = {
                ...total,
                ...contractorDataTotaltoObject,
                ...allLocationContractorToObject,
            }

            const tableData =
                data.completedActivitiesCount &&
                data.completedActivitiesCount.reduce((acc, currentEl) => {
                    // const res = currentEl.izCounter.izvodjac.map((a) => a.naziv)
                    // eslint-disable-next-line
                    const duzinaTrase = () => {
                        if (currentEl.kratakNaziv === 'A1') {
                            return ` (${data.trasa} м)`
                        }
                        if (currentEl.kratakNaziv === 'A5') {
                            return ` (${data.tax || '0'} rsd)`
                        }
                        return ''
                    }

                    const addElement = {
                        naziv: `${currentEl.kratakNaziv} - ${currentEl.naziv}`,
                        count: `${currentEl.count}  ${duzinaTrase()}`,
                    }
                    const singleContractorCount = currentEl?.izvCounter.map(
                        (item) => ({
                            [item.izvodjac.naziv]: item?.count,
                        })
                    )
                    const allLocationContractorCount =
                        allLocationContractors.map((item) => ({
                            [item.naziv]: currentEl.count,
                        }))

                    const allLocationContractorCountToObject = Object.assign(
                        {},
                        ...allLocationContractorCount
                    )

                    const singleContractorCountToObject = Object.assign(
                        {},
                        ...singleContractorCount
                    )

                    const contractorsCount = {
                        ...addElement,
                        ...singleContractorCountToObject,
                        ...allLocationContractorCountToObject,
                    }
                    acc.push(contractorsCount)
                    return acc
                }, [])

            const fullData = [dynamicTotalContractorsData, ...tableData]
            setReportdata(fullData)
            setResetTable(!resetTable)
            setLoad(false)
        } catch (error) {
            // console.log(error)
            setLoad(false)
        }
    }
    // console.log(izvodjac)
    useEffect(() => {
        getSum(selectedPriority)
    }, [selectedPriority])

    // console.log('fulldata', fullData)

    const handleChange = (event) => {
        setSelectedPriority(event.target.value)
    }

    // console.log('Nazivi', Nazivi, 'TableFull', tableFull)
    return (
        <div className={classes.root}>
            <Grid container alignItems="center">
                <Grid item>
                    <Typography variant="body1">
                        Изаберите жељени приоритет
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControl
                        variant="outlined"
                        className={classes.formControl}
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            приоритети
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="outlinedselekt"
                            value={selectedPriority}
                            onChange={handleChange}
                            label="извештаји"
                        >
                            {/* <MenuItem value="">
                                <em>избор приоритета</em>
                            </MenuItem> */}
                            {flag?.map((item) => (
                                <MenuItem value={item._id} key={item._id}>
                                    {item.naziv}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <div className={classes.table}>
                {load && <SimpleModal />}
                {headers.length > 0 && (
                    <UserTable
                        model="report/summary"
                        selectedItem={selectedPriority}
                        title="Сумарни подаци o статусима активности"
                        data={reportData}
                        totalResults={0}
                        page={0}
                        setPage={() => {}}
                        rowsPerPage={10}
                        setRowsPerPage={() => {}}
                        name="reportsum1"
                        onRowClick={() => {}}
                        tableHeaders={headers}
                        resetTable={resetTable}
                        setSearch={() => {}}
                        search={{ eng: '' }}
                        withPagination={false}
                        sortField=""
                        exportFileName="Сумарни-извештај"
                    />
                )}
            </div>
        </div>
    )
}

export default ReportActivitySum

ReportActivitySum.propTypes = {
    flag: PropTypes.arrayOf(PropTypes.any).isRequired,
}
