import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
    messageDiv: {
        height: '30px',
        marginTop: '1rem',
        fontSize: '0.95rem',
    },
}))

const Notification = ({ message }) => {
    const classes = useStyles()

    return (
        <div className={classes.messageDiv}>
            <p>{message}</p>
        </div>
    )
}

export default Notification

Notification.propTypes = {
    message: PropTypes.string.isRequired,
}
