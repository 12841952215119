import React, { useState, useEffect } from 'react'
import { Button, Grid, Typography, Snackbar } from '@material-ui/core'
import { Alert, Autocomplete, createFilterOptions } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import { axiosAuth as axios } from '../../../../util/axios-instance'
import SimpleModal from '../../../../components/Modal/Modal'
import latToCyr from '../../../../util/latToCyr'
import InventoryTable from '../../../../components/tables/InventoryTable'
import { useSnackbar } from '../../../../util/snackbar-hook'

const InvReportsBySchool = () => {
    const [reports, setReports] = useState(null)
    const [load, setLoad] = useState(false)
    const [searchId, setSearchId] = useState('')
    const [locId, setLocId] = useState('')
    const [email, setEmail] = useState('')
    const [locations, setLocations] = useState([])
    // const loading = open && locations.length === 0
    const filterOptions = createFilterOptions({
        stringify: ({ lat, cyr }) => `${lat} ${cyr}`,
    })

    const { snackbar, severity, setSeverity, setSnackbar, handleSnackBarOpen } =
        useSnackbar()

    const getLocations = async () => {
        try {
            setLoad(true)
            const res = await axios.get('api/link/get/autocomplete/locations')
            setLocations(res.data.locations)
            setLoad(false)
        } catch (error) {
            console.log(error)
        }
    }

    const getReports = async (name) => {
        try {
            setLoad(true)
            const url = `/api/inventory-reports/popisna-lista?naziv=${name}`

            const response = await axios.get(url)
            const array = [
                ...response.data.materijali,
                ...response.data.radovi,
                ...response.data.oprema,
            ]

            const tableData = array.reduce((acc, currentEl) => {
                const date = currentEl?.datumPrimopredaje
                    ?.split('T')[0]
                    .split('-')

                const addElement = {
                    naziv: currentEl.invtype.naziv,
                    sifra: currentEl.invtype.sifra,
                    tip: latToCyr(currentEl.invtype.tip),
                    cena: currentEl.invtype.cena,
                    trajanjeGarancije: currentEl.invtype.trajanjeGarancije,
                    redniBroj: currentEl.invtype.redniBroj,
                    kolicina: currentEl.kolicina,
                    datumPrimopredaje:
                        date && `${date[2]}/${date[1]}/${date[0]}`,
                    ukupnaCena: currentEl['Ukupna Cena'],
                }

                acc.push(addElement)
                return acc
            }, [])
            setReports({
                materijali: tableData.filter(
                    (data) => data.tip === 'Материјал'
                ),
                oprema: tableData.filter((data) => data.tip === 'Опрема'),
                radovi: tableData.filter((data) => data.tip === 'Радови'),
            })
            setLocId(response.data.locId)
            setEmail(response.data.email)
            setLoad(false)
        } catch (error) {
            console.log(error)
        }
    }

    const notifySchool = async (id, schoolEmail, data) => {
        setLoad(true)
        try {
            const response = await axios.post(
                `/api/school-notification/${id}`,
                {
                    email: schoolEmail,
                    materijali: data.materijali,
                    oprema: data.oprema,
                    radovi: data.radovi,
                }
            )
            setSeverity('success')
            setSnackbar({
                open: true,
                message: latToCyr(response.data.message),
            })
            setLoad(false)
        } catch (error) {
            setLoad(false)
            console.log(error)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: latToCyr(error.response.data.message),
            })
        }
    }

    useEffect(() => {
        // if (searchId.length === 5) getReports(searchId)
        getLocations()
    }, [])

    const columns = [
        { field: 'naziv', header: 'Назив' },
        { field: 'redniBroj', header: 'Редни број' },
        { field: 'tip', header: 'Тип' },
        { field: 'ukupnaCena', header: 'Цена' },
        { field: 'trajanjeGarancije', header: 'Трајање' },
        { field: 'kolicina', header: 'Количина' },
        { field: 'datumPrimopredaje', header: 'Датум примопредаје' },
    ]

    return (
        <div>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {load && <SimpleModal />}
            <Grid container alignItems="center">
                <Grid item xs={2}>
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                        Упишите назив локације
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    {/* <TextField
                        id="outlined-basic"
                        // label="ИД локације"
                        placeholder="ИД локације (нпр. L0999 или Л0999)"
                        variant="outlined"
                        onChange={(event) => {
                            setSearchId(event.target.value)
                        }}
                        style={{ minWidth: 400, margin: 8 }}
                    /> */}
                    <Autocomplete
                        id="combo-box-demo"
                        options={locations}
                        filterOptions={filterOptions}
                        filterSelectedOptions
                        getOptionLabel={(option) => option.naziv}
                        getOptionSelected={(option, val) =>
                            option._id === val?._id
                        }
                        onChange={(e, val) => setSearchId(val?.naziv)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Назив локације"
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <Button
                        disabled={searchId === ''}
                        variant="contained"
                        color="primary"
                        onClick={() => getReports(searchId)}
                    >
                        Претрага
                    </Button>
                </Grid>
            </Grid>
            {reports && (
                <Grid container alignItems="center">
                    <Grid item xs={2}>
                        <Typography
                            variant="body1"
                            style={{ textAlign: 'left' }}
                        >
                            Обавестите школу
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            id="outlined-basic"
                            // label="ИД локације"
                            placeholder="Мејл адреса школе"
                            value={email}
                            variant="outlined"
                            onChange={(event) => {
                                setEmail(event.target.value)
                            }}
                            style={{ minWidth: 400, margin: 8 }}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => notifySchool(locId, email, reports)}
                        >
                            Пошаљи
                        </Button>
                    </Grid>
                </Grid>
            )}
            {reports && (
                <Grid container>
                    <Grid item>
                        <InventoryTable
                            values={reports?.materijali}
                            title="Материјали"
                            columns={columns}
                        />
                    </Grid>
                    <Grid item>
                        <InventoryTable
                            values={reports?.oprema}
                            title="Опрема"
                            columns={columns}
                        />
                    </Grid>
                    <Grid item>
                        <InventoryTable
                            values={reports?.radovi}
                            title="Радови"
                            columns={columns}
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

export default InvReportsBySchool
