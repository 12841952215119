import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
    palette: {
        primary: {
            main: '#417AB4',
        },
        secondary: {
            main: '#BE2528',
        },
        action: {
            active: '#333333',
        },
        success: {
            main: '#006442',
        },
        warning: {
            main: '#BE2528',
        },
        error: {
            main: '#BE2528',
        },
        info: {
            main: '#ffffff',
        },
    },

    typography: {
        fontFamily: 'Roboto,sans-serif',
        color: '#FFFFFF',

        button: {
            fontFamily: 'Roboto',
            fontSize: '0.75rem',
        },
        h1: {
            fontFamily: 'Roboto',
            fontSize: '3.5rem',
            fontWeight: 500,
        },
        h2: {
            fontFamily: 'Roboto',
            fontSize: '1.8rem',
            fontWeight: 900,
        },
        h3: {
            fontFamily: 'Roboto',
            fontSize: '2rem',
            fontWeight: 500,
        },
        h4: {
            fontFamily: 'Bitter',
            fontSize: '1.3rem',
            fontWeight: 500,
        },
        h5: {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            fontWeight: 300,
        },
        h6: {
            fontFamily: 'Roboto',
            fontSize: '0.9rem',
            fontWeight: 600,
        },

        body1: {
            color: '#4E5054',
            fontSize: '1rem',
            fontWeight: '700',
        },
        body2: {
            color: '#4E5054',
            fontSize: '1rem',
        },
        caption: {
            color: '#4E5054',
            fontSize: '1rem',
        },
        subtitle1: {
            fontFamily: 'Bitter',
            fontSize: '1.3rem',
            fontWeight: 500,
        },
        subtitle2: {
            fontFamily: 'Bitter',
            fontSize: '0.75rem',
        },
    },
    // shape: 0,
    overrides: {
        MuiContainer: {
            root: {
                minWidth: '99%',
            },
            maxWidthLg: {
                paddingLeft: 10,
            },
        },
        MuiSpeedDial: {
            fab: {
                borderRadius: 2,
                minWidth: '200px',
                height: '30px',
                variant: 'outlined',
                background: '#1E8637',
                '&:hover': {
                    background: '#059D2A',
                },
            },
        },
        MuiSpeedDialAction: {
            fab: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                minWidth: '70px',
                height: '50px',
                border: '1px solid #1E8637',
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '0.9rem',
                fontWeight: 500,
            },
        },
    },
})

export default theme
