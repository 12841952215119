import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Snackbar } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Alert } from '@material-ui/lab'
import TabPanel from '../../components/TabPanel/TabPanel'
import { axiosAuth as axios } from '../../util/axios-instance'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
// import SimpleModal from '../../components/Modal/Modal'
import BackToButton from '../../components/FormsUI/Button/BackToButton'
import LocationCard from '../../components/Cards/LocationCard'
import RemoveLocationCard from '../../components/Cards/RemoveLocationCard'
import SimpleModal from '../../components/Modal/Modal'

const useStyles = makeStyles(() => ({
    table: {
        margin: 20,
    },
    connectButton: {
        backgroundColor: 'rgb(45, 85, 125)',
        color: 'white',
        '&:hover': {
            backgroundColor: 'rgb(45, 85, 125)',
        },
        margin: '0 20px',
    },
    pdng: {
        padding: '10px 50px',
    },
    cards: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: 20,
    },
    titleDiv: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    title: {
        margin: '0px 20px',
    },
}))

const ContractsToLocations = () => {
    const [locations, setLocations] = useState([])
    const [selection, setSelection] = useState([])
    const [contract, setContract] = useState(false)
    const [linkedLocations, setLinkedLocations] = useState([])
    const classes = useStyles()
    // const [globalFilter, setGlobalFilter] = useState('')
    // const history = useHistory()
    const [value, setValue] = useState(0)
    const { naziv, id } = useParams()
    const [refresh, setRefresh] = useState(false)
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')
    const [load, setLoad] = useState(false)
    const historyLocation = useLocation()
    const route = historyLocation?.state.from

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const getUnlinkedLocations = async () => {
        try {
            let conType
            if (route === 'contractNad') {
                conType = 'documentsNad'
            } else if (route === 'contractIzv') {
                conType = 'documentsIzv'
            } else if (route === 'contractPro') {
                conType = 'documentsPro'
            } else {
                conType = 'documents'
            }
            const response = await axios.get(`/api/link/${conType}/location`)
            setLocations(response.data.documents)
        } catch (error) {
            console.log(error)
        }
    }

    const getContract = async () => {
        let newContract
        const response = await axios.get(`/api/${route}/${id}`)
        console.log(response)
        try {
            if (route === 'contractNad') {
                newContract = response.data.contractNad
            } else if (route === 'contractIzv') {
                newContract = response.data.contractIzv
            } else if (route === 'contractPro') {
                newContract = response.data.contractPro
            } else {
                newContract = response.data.contract
            }
            setContract(newContract)
            setLinkedLocations(newContract.lokacije)
            if (
                newContract.status === 'Završen' &&
                newContract.datumZavrsetka
            ) {
                setSeverity('warning')
                setSnackbar({
                    open: true,
                    message:
                        route === 'contract'
                            ? 'Није могуће повезати кластере са завршеним јавним позивом'
                            : 'Није могуће повезати кластере са завршеним уговором.',
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const connectLocations = async (state) => {
        try {
            setRefresh(true)
            setLoad(true)
            await axios.patch(`/api/link/location-to-${route}/${id}`, {
                lokacije: state,
            })
            // history.push('/app/ugovori')
            setSeverity('success')
            setSnackbar({
                open: true,
                message:
                    route === 'contract'
                        ? 'Успешно сте повезали кластер са јавним позивом.'
                        : 'Успешно сте повезали кластер са уговором.',
            })
            setRefresh(false)
            setLoad(false)
            setSelection([])
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error?.response?.data?.message
                    ? error.response.data.message
                    : 'Повезивање кластера неуспешно.',
            })
        }
    }

    const unlinkLocation = async (state) => {
        try {
            setRefresh(true)
            setLoad(true)
            await axios.patch(`/api/link/unlink${route.slice(8)}/${id}`, {
                lokacija: state,
            })
            setSeverity('success')
            setSnackbar({
                open: true,
                message:
                    route === 'contract'
                        ? 'Успешно сте уклонили кластер са јавног позива.'
                        : 'Успешно сте уклонили кластер са уговора.',
            })
            setRefresh(false)
            setLoad(false)
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error?.response?.data?.message
                    ? error.response.data.message
                    : 'Уклањање кластера неуспешно.',
            })
        }
    }

    useEffect(() => {
        if (!refresh) {
            getUnlinkedLocations()
            getContract()
        }
    }, [refresh])

    // const search = (
    //     <div className="table-header">
    //         {/* <Button
    //             type="button"
    //             label="Clear"
    //             className="p-button-outlined"
    //             icon="pi pi-filter-slash"
    //             // onClick={reset}
    //         /> */}
    //         <span className="p-input-icon-left">
    //             <i className="pi pi-search" />
    //             <InputText
    //                 type="search"
    //                 value={globalFilter}
    //                 onChange={(e) => setGlobalFilter(e.target.value)}
    //                 placeholder="Претрага"
    //                 className={classes.pdng}
    //             />
    //         </span>
    //     </div>
    // )

    const onCheckHandler = (e) => {
        if (!selection.includes(e.target.value)) {
            setSelection([...selection, e.target.value])
        } else {
            setSelection(selection.filter((sel) => sel !== e.target.value))
        }
    }
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        console.log(selection)
    }, [selection])

    return (
        <div>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {load && <SimpleModal />}
            <div className={classes.titleDiv}>
                <BackToButton
                    label={
                        route === 'contract'
                            ? 'НАЗАД НА ЛИСТУ СВИХ ЈАВНИХ ПОЗИВА'
                            : 'НАЗАД НА ЛИСТУ СВИХ УГОВОРА'
                    }
                />
                <div className={classes.title}>
                    <PageTitle
                        title={
                            route === 'contract'
                                ? `Повезивање кластера са јавним позивом - ${naziv}`
                                : `Повезивање кластера са уговором - ${naziv}`
                        }
                    />
                </div>
            </div>
            <div className={classes.appBar}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                >
                    <Tab label="Повезани кластери" />
                    <Tab label="Повежи кластер" />
                </Tabs>
            </div>
            <TabPanel value={value} index={0}>
                <div style={{ textAlign: 'left' }} className={classes.cards}>
                    {linkedLocations.length > 0 ? (
                        linkedLocations
                            .sort((a, b) => a.naziv > b.naziv)
                            .map((loc) => (
                                <RemoveLocationCard
                                    name={loc.naziv}
                                    key={loc._id}
                                    onClick={(state) => unlinkLocation(state)}
                                    id={loc._id}
                                />
                            ))
                    ) : (
                        <p>Нема повезаних кластера</p>
                    )}
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div style={{ textAlign: 'left' }}>
                    {selection.length > 0 ||
                    (contract.status === 'Završen' &&
                        contract.datumZavrsetka) ? (
                        <Button
                            className={classes.connectButton}
                            onClick={() => connectLocations(selection)}
                        >
                            Повежи
                        </Button>
                    ) : (
                        <Button disabled style={{ margin: '0 20px' }}>
                            Повежи
                        </Button>
                    )}
                </div>
                <div className={classes.cards}>
                    {locations.length > 0 ? (
                        locations
                            .sort((a, b) => a.naziv > b.naziv)
                            .map((loc) => (
                                <LocationCard
                                    name={loc.naziv}
                                    key={loc._id}
                                    onCheck={onCheckHandler}
                                    id={loc._id}
                                />
                            ))
                    ) : (
                        <p>Нема неповезаних кластера</p>
                    )}
                </div>
            </TabPanel>
        </div>
    )
}

export default ContractsToLocations
