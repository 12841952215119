import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { Grid, Paper } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'left',
        alignItems: 'flex-start',
        border: '1px solid #E0E0E0',
        marginTop: '0.5rem',
    },

    postTitle: {
        padding: '2rem 0',
        marginRight: '15px',

        textOverflow: 'ellipsis',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '60vw',
        },
    },
    labelBox: {
        backgroundColor: '#417AB4',
    },
    labelTypography: {
        color: 'white',
        padding: 12,
    },
    valueTypography: {
        padding: 12,
    },
}))

const DetailCard = ({ name, val }) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.root}>
            <Grid item xs={5} md={5}>
                <Paper elevation={0} square className={classes.labelBox}>
                    <Typography
                        variant="body1"
                        className={classes.labelTypography}
                    >
                        {name}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={7} md={7}>
                <Paper elevation={0} square className={classes.paperVal}>
                    <Typography
                        variant="body2"
                        className={classes.valueTypography}
                    >
                        {val}
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default DetailCard

DetailCard.propTypes = {
    name: PropTypes.string.isRequired,
    val: PropTypes.string,
}

DetailCard.defaultProps = {
    val: '',
}
