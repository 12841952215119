import React from 'react'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const CusButton = withStyles({
    root: {
        color: '#secondary',
        height: 48,
        padding: '25px 30px',
        width: '100%',
        border: '1px solid #4E5054',
        fontSize: '1rem',
        fontWeight: 700,
        fontFamily: 'Roboto',
        marginTop: 32,
        textDecoration: 'none',
    },
    // label: {
    //     textTransform: 'capitalize',
    // },
})((props) => <Button {...props} />)

function CustomButton({ children }) {
    return (
        <>
            <CusButton>{children}</CusButton>
        </>
    )
}

CustomButton.propTypes = {
    children: PropTypes.string.isRequired,
}

export default CustomButton
