import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import { DataTable } from 'primereact/datatable'
// import { Column } from 'primereact/column'
import { Container } from '@material-ui/core'
// import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'

import activities from './activitiesList'
import UserTable from '../../components/tables/UserTable'

const useStyles = makeStyles(() => ({
    container: {
        margin: '20px 0',
    },
}))

const Activities = () => {
    const classes = useStyles()
    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })

    return (
        <Container className={classes.container}>
            <UserTable
                sortField="kratakNaziv"
                model=""
                withExport={false}
                withPagination={false}
                data={activities}
                title="Активности"
                totalResults={0}
                page={0}
                setPage={() => {}}
                rowsPerPage={10}
                setRowsPerPage={() => {}}
                name="activitesView"
                onRowClick={() => {}}
                tableHeaders={[
                    { field: 'naziv', header: 'Назив' },
                    {
                        field: 'kratakNaziv',
                        header: 'Кратак назив',
                    },
                    {
                        field: 'prethodna',
                        header: 'Претходне активности',
                    },
                    {
                        field: 'odgovoran',
                        header: 'Одговоран тип',
                    },
                    {
                        field: 'rok',
                        header: 'Рок (у данима)',
                    },
                    {
                        field: 'dokumenta',
                        header: 'Документа',
                    },
                    // {
                    //     field: 'podaci',
                    //     header: 'Подаци',
                    // },
                ]}
                setSearch={setSearch}
                search={search}
            />
        </Container>
    )
}

export default Activities
