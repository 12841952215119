import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { axiosAuth } from '../util/axios-instance'

import PrivateRoutes from './PrivateRoutes'
// import Auth from './Auth'

function Routes() {
    return (
        <SWRConfig
            value={{ fetcher: (url) => axiosAuth(url).then((r) => r.data) }}
        >
            <Switch>
                <Route path="/app">
                    <PrivateRoutes />
                </Route>
                {/* <Route path="/">
                <Auth />
            </Route> */}
            </Switch>
        </SWRConfig>
    )
}

export default Routes
