import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import {
    Grid,
    Typography,
    Button,
    CircularProgress,
    Backdrop,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import { trigger } from 'swr'

import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
import CheckboxWrapper from '../../components/FormsUI/Checkbox/CheckboxWrapper'
import { axiosAuth } from '../../util/axios-instance'

const useStyles = makeStyles((theme) => ({
    checkboxWrapper: {
        textAlign: 'start',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        // position: 'absolute',
        // top: '10%',
        // left: '10%',
        // overflow: 'scroll',

        // display: 'block',
    },
    paper: {
        overflow: 'auto',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 600,
        [theme.breakpoints.down(610)]: {
            width: '90%',
        },
    },
}))

const INITIAL_FORM_STATE = {
    host: '',
    port: '',
    email: '',
    password: '',
    inUse: false,
}

const FORM_VALIDATION = Yup.object().shape({
    host: Yup.string().required('Обавезно поље'),
    port: Yup.number().typeError('Морате унети број').required('Обавезно поље'),
    email: Yup.string().email('Неисправна е-пошта').required('Обавезно поље'),
    password: Yup.string().required('Обавезно поље'),
})

const CreateSMTP = ({ open, close, setSeverity, setSnackbar }) => {
    const classes = useStyles()

    const handleCreateSmtp = async (emailConfiguration, { resetForm }) => {
        try {
            await axiosAuth.post(`/api/smtp/`, emailConfiguration)

            resetForm(INITIAL_FORM_STATE)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно подешен СМТП',
            })
            trigger('/api/smtp')
            close()
        } catch (err) {
            console.log(err.response)

            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Неуспешно подешавање СМТП!',
            })
        }
    }
    return (
        <Modal
            aria-labelledby="createSMTPModal"
            aria-describedby="createSMTPModal"
            className={classes.modal}
            open={open}
            onClose={close}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 1000,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Formik
                        initialValues={{
                            ...INITIAL_FORM_STATE,
                        }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={handleCreateSmtp}
                    >
                        {({ isSubmitting, values }) => (
                            <Form>
                                <Grid container spacing={1}>
                                    <Grid item xs={7}>
                                        <Typography variant="h2">
                                            Подеси СМТП
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="host"
                                            label="Хост"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="port"
                                            label="Порт"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="email"
                                            label="е-пошта"
                                            type="email"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="password"
                                            label="Лозинка"
                                            type="password"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.checkboxWrapper}
                                    >
                                        <CheckboxWrapper
                                            name="inUse"
                                            label="Користи"
                                            currentValue={values.inUse}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            spacing={3}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid item xs={6}>
                                                <Button
                                                    variant="outlined"
                                                    fullWidth
                                                    onClick={close}
                                                >
                                                    Одустани
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ButtonWrapper
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={
                                                        isSubmitting ? (
                                                            <CircularProgress size="0.9rem" />
                                                        ) : undefined
                                                    }
                                                >
                                                    Потврди
                                                </ButtonWrapper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Fade>
        </Modal>
    )
}

export default CreateSMTP

CreateSMTP.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setSeverity: PropTypes.func.isRequired,
    // setEditUserLoader: PropTypes.func.isRequired,
}
