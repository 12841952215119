import { intersection } from 'lodash'
import { axiosAuth } from './axios-instance'

export const isLoggedIn = async () => {
    try {
        const { data } = await axiosAuth('/api/user/my-profile')

        return !!data
    } catch (error) {
        console.log(error, error.response)
        return false
    }
}

export function isArrayWithLength(arr) {
    return Array.isArray(arr) && arr.length
}

export function getAllowedRoutes(routes, role) {
    const allowedRoutes = routes.filter((el) => {
        if (!el.permission) {
            return true
        }
        if (!isArrayWithLength(el.permission)) {
            return true
        }

        return intersection(el.permission, [role]).length
    })

    return allowedRoutes
}
