import React, { useState } from 'react'
import PropTypes, { objectOf } from 'prop-types'

import DescriptionIcon from '@material-ui/icons/Description'

import { makeStyles, Grid } from '@material-ui/core'
import moment from 'moment'
import BasicTable from '../../../components/tables/BasicTable'
import latToCyr from '../../../util/latToCyr'

const useStyles = makeStyles(() => ({
    root: {
        margin: 12,
    },
    table: {
        borderCollapse: 'collapse',
        margin: 8,
    },
    htmlTable: {
        border: '1px solid black',
        borderCollapse: 'collapse',
        marginBottom: 20,
    },
    thConnected: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        backgroundColor: '#417AB4',
        color: 'white',
        width: '100%',
    },
    th: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        backgroundColor: '#417AB4',
        color: 'white',
        width: 300,
    },
    docDiv: {
        border: '1px solid #ddd',
        display: 'flex',
        justifyContent: 'space-between',
        width: '500px',
        '@media (max-width:750px)': {
            width: '380px',
        },
        '@media (max-width:450px)': {
            width: '250px',
            flexDirection: 'column',
        },
    },

    td: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        width: 400,
    },
    contractTitle: {
        textAlign: 'left',
        margin: '20px 0px',
        fontSize: 25,
        color: '#417AB4',
        display: 'flex',
        alignItems: 'flex-end',
    },
    activityTitle: {
        textAlign: 'left',
        margin: '10px 8px',
        fontSize: 25,
        color: '#417AB4',
        display: 'flex',
        alignItems: 'flex-end',
    },
    icon: {
        fontSize: 40,
        color: '#417AB4',
        marginRight: 10,
    },
    tables: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    comment: {
        flex: '40%',
        marginBottom: '5rem',
    },
    downloadButton: {
        backgroundColor: '#1E8637',
        color: 'white',
        '&:hover': {
            backgroundColor: '#4a9e5e',
            color: '#fff',
        },
    },
    fileDescription: {
        margin: '0 8px',
    },
    message: {
        width: '100%',
        fontSize: '1rem',
    },
    additionalData: {
        marginTop: '1rem',
    },
}))

// eslint-disable-next-line no-unused-vars
const ActivityDetails = ({ activity }) => {
    // eslint-disable-next-line no-unused-vars
    const [open, setOpen] = useState(true)
    const classes = useStyles()

    const filteredActivityFields = (currentActivity) => {
        const responsible = currentActivity.odgovoran.join(', ')
        const startDate = currentActivity?.datumPocetka
            ? moment(currentActivity.datumPocetka).format('DD/MM/YYYY')
            : 'Активност није започета'
        const endDateFlex = currentActivity.rok === 'клизно' ? 'клизно' : false
        const endDate = currentActivity?.datumZavrsetka
            ? moment(currentActivity.datumZavrsetka).format('DD/MM/YYYY')
            : 'Активност није завршена'
        const rok = currentActivity?.datumPocetka
            ? moment(currentActivity?.datumPocetka)
                  .add(currentActivity.rok, 'd')
                  .format('DD/MM/YYYY')
            : 'Активност није започета'

        return {
            lokacija: currentActivity.lokacija.naziv,
            naziv: currentActivity.naziv,
            odgovoran: responsible,
            datumPocetka: startDate,
            datumZavrsetka: endDateFlex || endDate,
            rok: endDateFlex || rok,
            status: latToCyr(currentActivity.status),
        }
    }
    const activityHeaderLabel = () => {
        if (activity.kratakNaziv === 'A1') return 'Дужина трасе'
        if (activity.kratakNaziv === 'A3') return 'ЦЕОП'
        if (activity.kratakNaziv === 'A9')
            return 'Датум налога за извођење радова'
        return 'Вредност таксе'
    }

    const additionalDataHeaders = [
        { field: 'naziv', header: 'Назив' },
        { field: 'vrednost', header: activityHeaderLabel() },
    ]

    return (
        <Grid item xs={12} className={classes.contractDiv}>
            <div className={classes.contractTitle}>
                <DescriptionIcon className={classes.icon} />
                <span>Детаљи активности</span>
            </div>

            <BasicTable
                data={[filteredActivityFields(activity)]}
                name="detailsTable"
                tableHeaders={[
                    { field: 'naziv', header: 'Назив' },
                    {
                        field: 'lokacija',
                        header: 'Кластер',
                    },
                    { field: 'status', header: 'Статус' },
                    {
                        field: 'odgovoran',
                        header: 'Одговоран',
                    },
                    {
                        field: 'datumPocetka',
                        header: 'Датум почетка',
                    },
                    {
                        field: 'datumZavrsetka',
                        header: 'Датум завршетка',
                    },
                    {
                        field: 'rok',
                        header: 'Рок',
                    },
                ]}
            />
            {activity.podaci.length > 0 && (
                <Grid container className={classes.additionalData}>
                    <Grid item xs={6}>
                        <BasicTable
                            data={activity.podaci}
                            name="additionalDataTable"
                            tableHeaders={additionalDataHeaders}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default ActivityDetails

ActivityDetails.propTypes = {
    activity: objectOf(PropTypes.any).isRequired,
}
