import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, TablePagination, Button, Snackbar } from '@material-ui/core'
import { DataTable } from 'primereact/datatable'
import { useDebounce } from 'use-debounce'
import { Column } from 'primereact/column'
import { Alert } from '@material-ui/lab'
import { axiosAuth as axios } from '../../util/axios-instance'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import SimpleModal from '../../components/Modal/Modal'
import SearchBar from '../../components/SearchBar/SearchBar'

const useStyles = makeStyles({
    pageContainer: {
        padding: '50px 50px',
    },
    connectButton: {
        margin: '5px 0',
    },
})

const Comments = () => {
    const classes = useStyles()
    const history = useHistory()
    const [comments, setComments] = useState({ totalComments: '', comment: [] })
    const [page, setPage] = useState(0)
    const [load, setLoad] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [selection, setSelection] = useState([])
    const [deleted, setDeleted] = useState(false)
    const myRole = useSelector((state) => state.myrole)
    const [severity, setSeverity] = useState('info')
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    // console.log('Comments state:', comments)
    // console.log(selection)

    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    const [searchDebounced] = useDebounce(search, 1000)

    const selectedIds = selection ? selection.map((item) => item.id) : []

    useEffect(() => {
        const getComments = async () => {
            try {
                setLoad(true)
                const url =
                    searchDebounced.eng === ''
                        ? `/api/comment?page=${page + 1}&limit=${rowsPerPage}`
                        : `/api/search/comment?eng=${searchDebounced.eng}&srb=${
                              searchDebounced.srb
                          }&page=${page + 1}&limit=${rowsPerPage}`
                const { data } = await axios.get(url)
                // console.log(data.comments)

                const tableData = data.comments.reduce((acc, currentEl) => {
                    // const timeFormat = moment(currentEl.createdAt).format(
                    //     'HH:mm:ss DD-MM-YYYY'
                    // )
                    const addElement = {
                        ime:
                            currentEl.user && currentEl.user.ime
                                ? `${currentEl?.user?.ime} ${currentEl?.user?.prezime}`
                                : 'Обрисан корисник',
                        id: currentEl?._id,
                        activityId: currentEl?.aktivnost,
                        poruka: currentEl?.komentar,
                        email: currentEl?.user?.email
                            ? currentEl?.user?.email
                            : 'Обрисан корисник',
                        location: currentEl?.lokacija,
                        nazivAct: currentEl?.kratakNaziv ? (
                            <Button
                                fullWidth
                                name="Activity"
                                style={{
                                    textTransform: 'none',
                                    fontSize: '1rem',
                                    textAlign: 'left',
                                }}
                            >
                                {currentEl?.kratakNaziv}
                            </Button>
                        ) : (
                            ''
                        ),
                    }
                    acc.push(addElement)
                    return acc
                }, [])

                // console.log(tableData)

                setComments({
                    totalComments: data.totalResults,
                    comment: tableData,
                })
                setLoad(false)
            } catch (error) {
                setLoad(false)
            }
        }

        getComments()
    }, [page, deleted, rowsPerPage, searchDebounced.eng])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const footer = (
        <Grid item xs={12}>
            <Grid container justifyContent="space-between">
                <Grid item xs={6}>
                    <Grid container justifyContent="flex-end">
                        <TablePagination
                            component="div"
                            count={comments.totalComments * 1}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="редова по страници"
                            labelDisplayedRows={({ from, to, count }) =>
                                `приказ страница ${from}-${to} od ${count}`
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    const handleDeleteComments = async () => {
        try {
            // eslint-disable-next-line
            const res = await axios.delete(`/api/comment/`, {
                data: {
                    komentari: selectedIds,
                },
            })
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно обрисан/и коментари ',
            })
            setSelection([])
            setDeleted(!deleted)
        } catch (err) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: err.response.data.message,
            })
        }
    }

    const myRoleCheck =
        myRole && myRole.user && myRole.user.rola === 'Admin' ? 'multiple' : ''

    const handleSelection = (e) => {
        setSelection(e.value)
    }

    // Snackbar logic
    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const onRowClick = (rowData) => {
        //     if (rowData.originalEvent.target.nodeName === 'TD') {
        // //         history.push(`/app/korisnici/${rowData.data._id}`)
        // //     }
        //     }
        if (rowData.originalEvent.target.parentNode.name === 'Activity') {
            history.push(`/app/aktivnost/${rowData.data.activityId}`)
            // console.log(rowData.data)
        }
    }
    return (
        <Grid container className={classes.pageContainer}>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Grid container>
                <Grid container justifyContent="center">
                    <PageTitle title="Коментари" />
                </Grid>

                <Grid container justifyContent="space-between">
                    <Grid item>
                        {selection.length > 0 &&
                        myRole?.user?.rola === 'Admin' ? (
                            <Button
                                className={classes.connectButton}
                                // onClick={() => connectLocations(selection)}
                                variant="contained"
                                color="secondary"
                                onClick={
                                    myRole.user.rola === 'Admin'
                                        ? handleDeleteComments
                                        : null
                                }
                            >
                                Обриши селектоване коментаре
                            </Button>
                        ) : (
                            <Button disabled className={classes.connectButton}>
                                {myRole?.user?.rola === 'Admin'
                                    ? 'Обриши селектоване коментаре'
                                    : null}
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        <SearchBar
                            setSearch={setSearch}
                            search={search}
                            setPage={setPage}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {load ? (
                        <SimpleModal />
                    ) : (
                        <DataTable
                            className={`${classes.table} blackTable`}
                            value={comments?.comment}
                            selection={selection}
                            onSelectionChange={
                                myRole?.user?.rola === 'Admin'
                                    ? handleSelection
                                    : ''
                            }
                            dataKey="id"
                            selectionMode="single"
                            footer={footer}
                            onRowClick={onRowClick}
                            emptyMessage="Нема пронађених података."
                            setPage={() => setPage}
                        >
                            <Column
                                selectionMode={myRoleCheck}
                                headerStyle={{ width: '3em' }}
                            />
                            <Column field="ime" header="Име" />
                            <Column field="poruka" header="Коментар" />
                            <Column field="email" header="Имејл" />
                            <Column field="location" header="Кластер" />
                            <Column field="nazivAct" header="Активност" />
                        </DataTable>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Comments
