import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Snackbar } from '@material-ui/core'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import { axiosInstance } from '../../util/axios-instance'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'

const useStyles = makeStyles((theme) => ({
    formContainer: {
        minHeight: '70vh',
        alignItems: 'flex-start',
    },
    formWrapper: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(8),
    },
    alignAll: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
}))

const ResetPassword = () => {
    const classes = useStyles()
    const history = useHistory()
    const { email, code } = useParams()
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: 'default',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({ ...previous, open: false }))
    }

    const INITIAL_FORM_STATE = {
        password: '',
        confirmPassword: '',
        email: email,
        resetCode: code,
    }

    const FORM_VALIDATION = Yup.object().shape({
        password: Yup.string()
            .required('Није наведена лозинка.')
            .min(8, 'Лозинка мора имати минимално 8 карактера')
            .matches(/[a-zA-Z]/, 'Лозинка мора садржати латинична слова'),
        confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'лозинке морају да се подударају'
        ),
        email: Yup.string(),
        // resetCode:Yup.number().integer(),
    })

    const handleSubmit = async (data, { setErrors, resetForm }) => {
        try {
            await axiosInstance.post('/api/user/reset-password', data)

            resetForm(INITIAL_FORM_STATE)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешна промена лозинке',
            })

            setTimeout(() => {
                history.push(`/`)
            }, 1500)
        } catch (err) {
            console.log(err.response)
            setErrors({
                password:
                    err.response && err.response.data
                        ? err.response.data.error
                        : err.message,
            })
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Неуспела промена лозинке!',
            })
        }
    }

    return (
        <Grid container className={classes.formContainer}>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={4000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Grid item xs={12}>
                <Container maxWidth="xs">
                    <div className={classes.formWrapper}>
                        <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE,
                            }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={handleSubmit}
                        >
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <PageTitle>Промена лозинке</PageTitle>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="password"
                                            label="Нова лозинка"
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="confirmPassword"
                                            label="Потврди лозинку"
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <ButtonWrapper variant="contained">
                                            Промени
                                        </ButtonWrapper>
                                    </Grid>
                                </Grid>
                            </Form>
                        </Formik>
                    </div>
                </Container>
            </Grid>
        </Grid>
    )
}

export default ResetPassword
