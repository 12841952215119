import React from 'react'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Backdrop from '@material-ui/core/Backdrop'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

import {
    initialStateHandler,
    inputValidationHandler,
    contentHandler,
} from './helpers'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'scroll',
        maxHeight: '100%',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 500,
        [theme.breakpoints.down(610)]: {
            width: '90%',
        },
    },
    warning: {
        color: '#AE0F0F',
        fontSize: 50,
        margin: '0 auto',
    },
    deleteH3: {
        margin: '5px auto',
    },
}))

const DynamicModal = ({
    openModal,
    closeModal,
    selected,
    action,
    handleSubmit,
    handleDelete,
    message,
}) => {
    const classes = useStyles()

    const initialState = initialStateHandler(selected, action)

    const inputValidation = inputValidationHandler(selected, action)

    const content = contentHandler(
        selected,
        action,
        closeModal,
        handleDelete,
        message
    )

    return (
        <>
            <Modal
                aria-labelledby="sifarniciModal"
                aria-describedby="sifarniciModal"
                className={classes.modal}
                open={openModal}
                onClose={closeModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <div className={classes.paper}>
                        <Formik
                            initialValues={initialState}
                            validationSchema={inputValidation}
                            onSubmit={(values) => {
                                handleSubmit(values)
                            }}
                        >
                            <Form>{content}</Form>
                        </Formik>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default DynamicModal

DynamicModal.propTypes = {
    openModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    selected: PropTypes.objectOf(PropTypes.any).isRequired,
    action: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
}
