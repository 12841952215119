import * as Yup from 'yup'
import AddEditModal from './AddEditModal'
import DeleteModal from './DeleteModal'

export const initialStateHandler = (selected, action) => {
    let state
    if (Object.keys(selected).length === 0) {
        if (action === 'приоритет') {
            state = {
                naziv: selected && selected.naziv ? selected.naziv : '',
                broj: selected && selected.broj ? selected.broj : '',
            }
        } else {
            state = {
                naziv: selected && selected.naziv ? selected.naziv : '',
                kratakNaziv:
                    selected && selected.kratakNaziv
                        ? selected.kratakNaziv
                        : '',
            }
        }
    } else if (Object.keys(selected).includes('cirilica')) {
        state = {
            naziv: selected && selected.naziv ? selected.naziv : '',
            kratakNaziv:
                selected && selected.kratakNaziv ? selected.kratakNaziv : '',
        }
    } else if (!Object.keys(selected).includes('cirilica')) {
        state = {
            naziv: selected && selected.naziv ? selected.naziv : '',
            broj: selected && selected.broj ? selected.broj : '',
        }
    }
    return state
}

export const inputValidationHandler = (selected, action) => {
    let validation
    if (Object.keys(selected).length === 0) {
        if (action === 'приоритет') {
            validation = Yup.object().shape({
                naziv: Yup.string().required('Морате уписати Назив.'),
                broj: Yup.string().required('Морате уписати Број.'),
            })
        } else {
            validation = Yup.object().shape({
                naziv: Yup.string().required('Морате уписати Назив.'),
                kratakNaziv: Yup.string().required(
                    'Морате уписати кратак назив.'
                ),
            })
        }
    } else if (!Object.keys(selected).includes('broj')) {
        validation = Yup.object().shape({
            naziv: Yup.string().required('Морате уписати Назив.'),
            kratakNaziv: Yup.string().required('Морате уписати кратак назив.'),
        })
    } else if (!Object.keys(selected).includes('cirilica')) {
        validation = Yup.object().shape({
            naziv: Yup.string().required('Морате уписати Назив.'),
            broj: Yup.string().required('Морате уписати Број.'),
        })
    }
    return validation
}

export const contentHandler = (
    selected,
    action,
    closeModal,
    handleDelete,
    message,
    error
) => {
    let cont

    if (Object.keys(selected).length === 0) {
        if (action === 'приоритет') {
            cont = (
                <AddEditModal
                    type="prioritet"
                    title="Додај приоритет"
                    closeModal={closeModal}
                    message={message}
                    error={error}
                />
            )
        } else {
            cont = (
                <AddEditModal
                    type="podgrupa"
                    title="Додај тип групе"
                    closeModal={closeModal}
                    message={message}
                    error={error}
                />
            )
        }
    }

    if (!Object.keys(selected).includes('broj')) {
        if (action === 'izmeni') {
            cont = (
                <AddEditModal
                    type="podgrupa"
                    selected={selected}
                    title="Измена типа групе"
                    closeModal={closeModal}
                    message={message}
                    error={error}
                />
            )
        } else if (action === 'obrisi') {
            cont = (
                <DeleteModal
                    closeModal={closeModal}
                    type="типа групе"
                    deleteFunc={handleDelete}
                    message={message}
                    error={error}
                />
            )
        }
    } else if (!Object.keys(selected).includes('cirilica')) {
        if (action === 'izmeni') {
            cont = (
                <AddEditModal
                    type="prioritet"
                    selected={selected}
                    title="Измена приоритета"
                    closeModal={closeModal}
                    message={message}
                    error={error}
                />
            )
        } else if (action === 'obrisi') {
            cont = (
                <DeleteModal
                    closeModal={closeModal}
                    type="приоритета"
                    deleteFunc={handleDelete}
                    message={message}
                    error={error}
                />
            )
        }
    }
    return cont
}
