import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { FormControlLabel, Checkbox } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    card: {
        borderRadius: 5,
        border: '3px solid #417ab4',
        margin: 5,
        width: 250,
        textAlign: 'left',
        // padding: 5,
        display: 'flex',
        height: 90,
        userSelect: 'none',
        position: 'relative',
        '&:hover': {
            boxShadow:
                '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        },
    },
    control: {
        width: '100%',
        margin: 0,
    },
    asd: {
        border: '6px solid #417ab4',
    },
    check: {
        transition: 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)',
        position: 'absolute',
        clipPath: 'polygon(0 0, 100% 100%, 100% 0)',
        top: 0,
        right: 0,
        zIndex: 10,
        width: 30,
        height: 30,
        backgroundColor: '#417ab4',
        '::before': {
            transition: 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)',
            content: '',
            border: '20px solid',
            borderColor: 'transparent',
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: '-1',
        },
    },
    checkmark: {
        display: 'block',
        font: '20px sans-serif',
        top: '-5px',
        right: 0,
        textAlign: 'center',
        color: 'white',
        height: 20,
        position: 'absolute',
    },
}))

const LocationCard = ({ name, onCheck, id }) => {
    const classes = useStyles()
    const [state, setState] = useState(false)

    // const handleChange = (event) => {
    //     console.log(event.target.name)
    //     setState({ [event.target.name]: event.target.checked })
    //     setPushed([...pushed, event.target.name])
    //     niz.push(event.target.name)
    //     console.log(niz)
    // }

    // useEffect(() => {
    //     console.log(pushed)
    // }, [pushed])

    return (
        <div className={`${classes.card} ${state ? classes.asd : ''}`}>
            {state && (
                <div className={classes.check}>
                    <span className={classes.checkmark}>✔</span>
                </div>
            )}

            <FormControlLabel
                control={
                    <Checkbox
                        checked={state}
                        onChange={(event) => {
                            onCheck(event)
                            setState(!state)
                        }}
                        name={name}
                        color="primary"
                        value={id}
                    />
                }
                label={name}
                className={classes.control}
            />
        </div>
    )
}

LocationCard.propTypes = {
    name: PropTypes.string,
    onCheck: PropTypes.func,
    id: PropTypes.string,
}

LocationCard.defaultProps = {
    name: '',
    onCheck: () => {},
    id: '',
}

export default LocationCard
