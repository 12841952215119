import { Button, Grid, Tooltip } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import download from 'downloadjs'
import { makeStyles } from '@material-ui/core/styles'
import PublishSharpIcon from '@material-ui/icons/PublishSharp'
import { axiosAuth } from '../../../../util/axios-instance'

const useStyles = makeStyles(() => ({
    exportButtons: {
        background: '#1E8637',
        '&:hover': {
            background: '#059D2A',
        },
    },
    icon: {
        marginLeft: 5,
    },
    iconFile: {
        color: 'white',
    },
    importSchema: {
        background: '#1E8637',
        '&:hover': {
            background: '#059D2A',
        },
        marginLeft: 3,
    },
}))

const ButtonImportInventory = ({
    id,
    // setRefresh,
    // setSeverityHandler,
    // setSnackbarHandler,
    // setLoad,
    withUpload,
    withDownload,
    importInv,
}) => {
    const classes = useStyles()
    const configButton = {
        variant: 'contained',
        color: 'primary',
        fullWidth: true,
    }

    // eslint-disable-next-line
    const downloadInventoryTemplate = async () => {
        try {
            const response = await axiosAuth.get(
                '/api/inventory/download-template',
                {
                    responseType: 'blob',
                }
            )
            return download(response.data, 'inventar.xlsx')
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Grid container>
            {withUpload && (
                <Grid item xs={9}>
                    <label htmlFor="upload-CSV">
                        <input
                            style={{ display: 'none' }}
                            id="upload-CSV"
                            name="upload-CSV"
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={(event) => importInv(event, id)}
                        />
                        <Tooltip title="импортуј у ексел" placement="top">
                            <Button
                                {...configButton}
                                component="span"
                                className={classes.exportButtons}
                            >
                                <span>Увоз</span>
                                <PublishSharpIcon className={classes.icon} />
                            </Button>
                        </Tooltip>
                    </label>
                </Grid>
            )}
            {withDownload && (
                <Grid item xs={3}>
                    <Tooltip title="Преузми шаблон за увоз" placement="top">
                        <Button
                            variant="contained"
                            className={classes.importSchema}
                            onClick={() => downloadInventoryTemplate()}
                        >
                            <DescriptionOutlinedIcon
                                className={classes.iconFile}
                            />
                        </Button>
                    </Tooltip>
                </Grid>
            )}
        </Grid>
    )
}

export default ButtonImportInventory

ButtonImportInventory.propTypes = {
    id: PropTypes.string.isRequired,
    // setRefresh: PropTypes.func,
    // setSeverityHandler: PropTypes.func,
    // setSnackbarHandler: PropTypes.func,
    setLoad: PropTypes.func,
    withUpload: PropTypes.bool,
    withDownload: PropTypes.bool,
    importInv: PropTypes.func,
}

ButtonImportInventory.defaultProps = {
    // setRefresh: () => {},
    // setSeverityHandler: () => {},
    // setSnackbarHandler: () => {},
    setLoad: () => {},
    withUpload: true,
    withDownload: true,
    importInv: () => {},
}
