import React, { useState } from 'react'
import PropTypes, { objectOf } from 'prop-types'
import { trigger } from 'swr'
import { Button, Collapse, Grid, makeStyles } from '@material-ui/core'
import ActivityStepper from '../Stepper/ActivityStepper'
import ControllActivityStepper from '../ControllActivityStepper/ControllActivityStepper'

import { axiosAuth } from '../../../util/axios-instance'

const useStyles = makeStyles(() => ({
    cardMargin: {
        margin: '2rem auto',
    },
    btn: {
        backgroundColor: '#1E8637',
        color: '#FFF',
        '&:hover': {
            background: '#059D2A',
        },
        marginRight: '1rem',
        textAlign: 'center',
    },
    remarkButton: {
        marginRight: '1rem',
        textAlign: 'center',
    },
    editSection: {
        marginTop: '3rem',
    },
    remarkSection: {
        margin: '3rem 0',
    },
}))

const EditActivity = ({
    isControlActivity,
    activity,
    setSeverity,
    setSnackbar,
    setError,
    isA1,
    isInactive,

    connected,
}) => {
    const classes = useStyles()
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [noRemark, setNoRemark] = useState(true)

    const handleControlStatusChange = async () => {
        try {
            setIsSubmiting(true)
            await axiosAuth.post('/api/activity/control/', {
                faults: false,
                ctrlId: activity._id,
            })
            // console.log(data)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно промењен статус активности.',
            })
            setIsSubmiting(false)
            trigger(`/api/activity/${activity._id}`)
            setNoRemark(true)
        } catch (error) {
            console.log(error.response)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Мењање статуса активности није успешно!',
            })
        }
    }

    const handleControlStatusChangeBack = async () => {
        try {
            setIsSubmiting(true)
            await axiosAuth.post('/api/activity/control/', {
                faults: true,
                ctrlId: activity._id,
            })
            // console.log(data)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно промењен статус активности.',
            })
            setIsSubmiting(false)
            trigger(`/api/activity/${activity._id}`)
            setNoRemark(false)
        } catch (error) {
            console.log(error.response)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Мењање статуса активности није успешно!',
            })
        }
    }

    return (
        <>
            {!isControlActivity && (
                <>
                    <Grid item xs={6}>
                        <Collapse in={!((isA1 && isInactive) || isInactive)}>
                            <ActivityStepper
                                activity={activity}
                                setSeverity={setSeverity}
                                setSnackbar={setSnackbar}
                                setError={setError}
                            />
                        </Collapse>
                    </Grid>
                </>
            )}
            {isControlActivity && activity.status === 'U toku' && (
                <Grid item xs={6} className={classes.remarkSection}>
                    <Collapse in={!((isA1 && isInactive) || isInactive)}>
                        <ControllActivityStepper
                            connected={connected}
                            activity={activity}
                            setSeverity={setSeverity}
                            setSnackbar={setSnackbar}
                            setError={setError}
                        />
                    </Collapse>
                    <Grid container>
                        <Grid item xs={4} className={classes.remarkButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleControlStatusChange}
                                disabled={isSubmiting}
                            >
                                Без примедби
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={handleControlStatusChangeBack}
                                disabled={isSubmiting}
                            >
                                Са примедбама
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    )
}

export default EditActivity

EditActivity.propTypes = {
    isControlActivity: PropTypes.bool.isRequired,

    isA1: PropTypes.bool.isRequired,

    isInactive: PropTypes.bool.isRequired,
    activity: objectOf(PropTypes.any).isRequired,
    connected: PropTypes.arrayOf(PropTypes.any).isRequired,
    setSeverity: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
}
