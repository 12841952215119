import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import AddButton from '../../../../components/FormsUI/Button/AddButton'
import InventoryTable from '../../../../components/tables/InventoryTable'
import EditButton from '../../../../components/FormsUI/Button/EditBtn'
import DeleteButton from '../../../../components/FormsUI/Button/DeleteBtn'
import { axiosAuth as axios } from '../../../../util/axios-instance'
import CreateInventoryType from './CreateInventoryType'
import DeleteInventoryType from './DeleteInventoryType'
import EditInventoryType from './EditInventoryType'
import { useSnackbar } from '../../../../util/snackbar-hook'
import SimpleModal from '../../../../components/Modal/Modal'

const InventoryTypes = () => {
    const [load, setLoad] = useState(false)
    const [addOpen, setAddOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [allInv, setAllInv] = useState({
        typeRadovi: [],
        typeMaterijal: [],
        typeOprema: [],
    })
    const [invState, setInvState] = useState(false)
    const [invData, setInvData] = useState(null)
    const [refreshState, setRefreshState] = useState(false)
    const { setSeverity, setSnackbar } = useSnackbar()

    const getInventories = async () => {
        try {
            setLoad(true)
            const response = await axios.get('/api/inv-type')
            const radovi = response.data.filter((inv) => inv.tip === 'Radovi')
            const oprema = response.data.filter((inv) => inv.tip === 'Oprema')
            const materijal = response.data.filter(
                (inv) => inv.tip === 'Materijal'
            )
            setAllInv({
                typeRadovi: radovi,
                typeMaterijal: materijal,
                typeOprema: oprema,
            })
            setInvState(true)
            setLoad(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (!refreshState) getInventories()
    }, [refreshState])

    const columns = [
        { field: 'naziv', header: 'Назив', width: '60%' },
        { field: 'cena', header: 'Цена' },
        { field: 'valuta', header: 'Валута' },
    ]

    const onRowClick = (rowData) => {
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'izmeni'
        ) {
            setInvData(rowData.data)
            setEditOpen(true)
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'obrisi'
        ) {
            setInvData(rowData.data)
            setDeleteOpen(true)
        }
    }

    const actionBodyTemplate = () => (
        <Grid key="edit" container>
            <Grid item xs={6}>
                <EditButton name="izmeni">измени</EditButton>
            </Grid>
            <Grid item xs={6}>
                <DeleteButton name="obrisi">обриши</DeleteButton>
            </Grid>
        </Grid>
    )

    const openAddModal = () => {
        setAddOpen(true)
    }

    return (
        <div>
            {addOpen && (
                <CreateInventoryType
                    modalOpen={addOpen}
                    setModal={setAddOpen}
                    setRefresh={setRefreshState}
                    setSeverityHandler={setSeverity}
                    setSnackbarHandler={setSnackbar}
                />
            )}
            {deleteOpen && invData && (
                <DeleteInventoryType
                    id={invData._id}
                    modalOpen={deleteOpen}
                    setModal={setDeleteOpen}
                    setRefresh={setRefreshState}
                    setSeverityHandler={setSeverity}
                    setSnackbarHandler={setSnackbar}
                />
            )}
            {editOpen && invData && (
                <EditInventoryType
                    data={invData}
                    modalOpen={editOpen}
                    setModal={setEditOpen}
                    setRefresh={setRefreshState}
                    setSeverityHandler={setSeverity}
                    setSnackbarHandler={setSnackbar}
                />
            )}
            <div
                style={{
                    width: '85%',
                    margin: 'auto',
                    textAlign: 'left',
                }}
            >
                <AddButton label="додај" click={openAddModal} />
            </div>

            {invState && (
                <>
                    <InventoryTable
                        values={allInv.typeMaterijal}
                        actionBodyTemplate={actionBodyTemplate}
                        onRowClick={onRowClick}
                        title="Материјали"
                        columns={columns}
                        edit
                    />
                    <InventoryTable
                        values={allInv.typeOprema}
                        actionBodyTemplate={actionBodyTemplate}
                        onRowClick={onRowClick}
                        title="Опрема"
                        columns={columns}
                        edit
                    />
                    <InventoryTable
                        values={allInv.typeRadovi}
                        actionBodyTemplate={actionBodyTemplate}
                        onRowClick={onRowClick}
                        title="Радови"
                        columns={columns}
                        edit
                    />
                </>
            )}
            {load && <SimpleModal />}
        </div>
    )
}

export default InventoryTypes
