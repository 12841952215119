import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { Grid } from '@material-ui/core'
// import SearchBar from '../../../components/SearchBar/SearchBar'
// import SelectWrapper from '../../../components/FormsUI/Select/SelectWrapper'
import { axiosAuth as axios } from '../../../util/axios-instance'
import AdminTable from '../../../components/tables/AdminTable'
import SimpleModal from '../../../components/Modal/Modal'
import PageTitle from '../../../components/FormsUI/PageTitle/PageTitle'
import latToCyr from '../../../util/latToCyr'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    createbutton: {
        backgroundColor: '#d9534f',
        color: 'white',
        '&:hover': {
            backgroundColor: '#d9534f',
        },
        padding: 8,
        margin: 8,
        boxShadow: 'none',
    },
    root: {
        margin: 12,
        textAlign: 'left',
    },
    table: {
        margin: 8,
    },
    editButton: {
        backgroundColor: '#059D2A',
        color: 'white',
        '&:hover': {
            background: '#059D2A',
        },
    },
    deleteButton: {
        backgroundColor: '#D86565',
        color: 'white',
        '&:hover': {
            background: '#D86565',
        },
    },
}))

const AllLocations = () => {
    const history = useHistory()
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [locations, setLocations] = useState([])
    const [totalResults, setTotalResults] = useState(0)
    const [load, setLoad] = useState(false)
    // eslint-disable-next-line
    const [error, setError] = useState('')
    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    const [searchDebounced] = useDebounce(search, 1000)

    const getAllLocations = async () => {
        try {
            setLoad(true)
            const url =
                searchDebounced.eng === ''
                    ? `/api/location?page=${page + 1}&limit=${rowsPerPage}`
                    : `/api/search/location?eng=${search.eng}&srb=${
                          search.srb
                      }&page=${page + 1}&limit=${rowsPerPage}`
            const { data } = await axios.get(url)
            const svevidece = await axios.get('/api/group/sve-videci/true')
            const svevideceArr = []
            svevidece.data.groups.map((el) => svevideceArr.push(el))

            // console.log(data)

            const tableData =
                data &&
                data.locations.reduce((acc, currentEl) => {
                    const groupArray = [...currentEl.odgovoran, ...svevideceArr]
                        .filter((el) => el.tip.includes('Izvođač'))
                        .map((el) => el.naziv)
                        .join(', ')

                    const act = data.aktivne.filter(
                        (a) => currentEl._id === a.lokacija
                    )

                    const addElement = {
                        id: currentEl._id,
                        naziv: currentEl.naziv,
                        prioritet: currentEl?.prioritet?.naziv,
                        nazivUgovora: currentEl?.ugovor?.naziv,
                        ugovor: currentEl?.ugovor ? currentEl.ugovor : null,
                        odgovoran: groupArray,
                        status: act[0]?.kratakNaziv
                            ? `${latToCyr(currentEl.status)} - ${
                                  act[0].kratakNaziv
                              }`
                            : latToCyr(currentEl.status),
                    }
                    acc.push(addElement)
                    return acc
                    // console.log('lokacije su', data)
                    // // const dataKeys = ['_id', 'naziv', 'prioritet', 'odgovoran']

                    // const tableData = data.locations.reduce((acc, currentEl) => {
                    //     const groupArray = currentEl.odgovoran
                    //         .map((el) => el.tip)
                    //         .join(', ')
                    //     console.log(groupArray)
                    //     const addElement = {
                    //         id: currentEl._id,
                    //         idLok: currentEl.idLokacije,
                    //         nazivLokacije: currentEl.naziv,
                    //         prioritetBroj: currentEl.prioritet,
                    //         odgovoran: groupArray,
                    //         status: currentEl.status,
                    //         nazivOpstine: currentEl.nazivOpstine,
                    //     }
                    //     acc.push(addElement)
                    //     return acc
                }, [])

            setLocations(tableData)

            const dataLength = parseInt(data.totalResults, 10)
            setTotalResults(dataLength)
            setLoad(false)
        } catch (er) {
            setError(er.response)
            setLoad(false)
        }
    }

    useEffect(() => {
        getAllLocations()
    }, [page, rowsPerPage, searchDebounced.eng])

    const rowClass = (rowData) => {
        const start = new Date(rowData?.ugovor?.datumPocetka)
        const zavrsetak = start
        const rok = rowData?.ugovor?.rokTrajanja
        zavrsetak.setDate(new Date(start).getDate() + rok)
        const danas = new Date()
        const brojDanaDoRoka = moment
            .duration(moment(zavrsetak).diff(moment(danas)))
            .asDays()
        const x = brojDanaDoRoka / rok
        const statusUgovora = rowData?.ugovor?.status
        const datumZavrsetka = rowData?.ugovor?.datumZavrsetka
            ? rowData.ugovor.datumZavrsetka
            : null

        // console.log(start, zavrsetak, rok, x)

        if (brojDanaDoRoka > 0 && x > 0.1 && statusUgovora === 'U toku') {
            return { blueRow: true }
        }
        if (brojDanaDoRoka > 0 && x < 0.1 && statusUgovora === 'U toku') {
            return { orangeRow: true }
        }
        if (brojDanaDoRoka < 0 && statusUgovora === 'U toku') {
            return { redRow: true }
        }
        if (
            statusUgovora === 'Završen' &&
            new Date(zavrsetak) > new Date(datumZavrsetka)
        ) {
            return { greenRow: true }
        }
        if (
            statusUgovora === 'Završen' &&
            new Date(datumZavrsetka) > new Date(zavrsetak)
        ) {
            return { greenRedRow: true }
        }

        return { white: true }
    }

    const onRowClick = (rowData) => {
        if (
            rowData.originalEvent.target.nodeName === 'TD' ||
            rowData.originalEvent.target.nodeName === 'DIV'
        ) {
            history.push(`/app/klasteri-user/${rowData.data.id}`)
        }
        // const start = new Date(rowData?.data?.ugovor?.datumPocetka)
        // const zavrsetak = start
        // const rok = rowData?.data?.ugovor?.rokTrajanja
        // zavrsetak.setDate(new Date(start).getDate() + rok)
        // const danas = new Date()
        // const brojDanaDoRoka = moment
        //     .duration(moment(zavrsetak).diff(moment(danas)))
        //     .asDays()
        // const x = brojDanaDoRoka / rok
        // const statusUgovora = rowData?.data?.ugovor?.status
        // const datumZavrsetka = rowData?.data?.ugovor?.datumZavrsetka
        //     ? rowData.data.ugovor.datumZavrsetka
        //     : null

        // console.log('zavrsetak', zavrsetak)
        // console.log('rok', rok)
        // console.log('broj dana do roka', brojDanaDoRoka)
        // console.log('10%', x)
        // console.log('datum zavrsetka', datumZavrsetka)
    }

    if (load) return <SimpleModal />
    // if (error) return <span>{error.data.message}</span>
    return (
        <div className={classes.root}>
            <div className={classes.table}>
                <Grid container justifyContent="center">
                    <PageTitle>Кластери</PageTitle>
                </Grid>
                {locations.length >= 0 ? (
                    <AdminTable
                        model="location"
                        data={locations}
                        load={load}
                        title="Кластери"
                        totalResults={totalResults}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        name="userLocationTable"
                        onRowClick={onRowClick}
                        rowClass={rowClass}
                        tableHeaders={[
                            {
                                field: 'naziv',
                                header: 'Назив кластера',
                            },
                            { field: 'prioritet', header: 'Приоритет' },
                            { field: 'odgovoran', header: 'Извођач радова' },
                            { field: 'status', header: 'Статус' },
                            { field: 'nazivUgovora', header: 'Јавни позив' },
                        ]}
                        actionBodyTemplate=""
                        setSearch={setSearch}
                        search={search}
                        sortField=""
                        exportFileName="Кластери"
                    />
                ) : null}
            </div>
        </div>
    )
}

export default AllLocations
