import { Button, Grid, Snackbar, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import { Alert } from '@material-ui/lab'
import { trigger } from 'swr'
import download from 'downloadjs'
import { makeStyles } from '@material-ui/core/styles'
import PublishSharpIcon from '@material-ui/icons/PublishSharp'
import { axiosAuth } from '../../../../util/axios-instance'
import SimpleModal from '../../../Modal/Modal'

// import { axiosAuth } from '../../../../util/axios-instance'
// import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
    exportButtons: {
        // minWidth: '200px',
        // margin: '0 5px',
        background: '#1E8637',
        '&:hover': {
            background: '#059D2A',
        },
    },
    icon: {
        marginLeft: 5,
    },
    iconFile: {
        color: 'white',
    },
    importSchema: {
        background: '#1E8637',
        '&:hover': {
            background: '#059D2A',
        },
        marginLeft: 3,
    },
}))

const ButtonImportCSV = ({ model }) => {
    const importSchema = model === 'user' ? 'korisnici' : 'klasteri'
    const classes = useStyles()
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    // eslint-disable-next-line
    const [load, setLoad] = useState(false)
    const configButton = {
        variant: 'contained',
        color: 'primary',
        fullWidth: true,
    }

    /* eslint-disable */
    const downloadFile = async (fName) => {
        try {
            const response = await axiosAuth.get(
                `/api/files/${importSchema}/`,
                {
                    responseType: 'blob',
                }
            )

            return download(response.data, fName)
        } catch (error) {
            console.log(error.response)
        }
    }
    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const importCSV = async (event) => {
        try {
            const selectedFile = event.target.files[0]
            if (selectedFile.size > 10000000) {
                setSeverity('warning')
                setSnackbar({
                    open: true,
                    message: 'Документ не сме бити већи од 10 Мегабајта',
                })
            } else {
                const fileData = new FormData()
                fileData.append('file', selectedFile)

                setLoad(true)
                const { data } = await axiosAuth.post(
                    `/api/${model}/excel-import`,
                    fileData
                )

                setSeverity('success')
                const importFailData = data?.document
                    ? data.document.join(', ')
                    : ''

                setSnackbar({
                    open: true,
                    message: data?.message ? (
                        <>
                            <div>{data?.message}</div>
                            {importFailData !== '' && (
                                <div>
                                    <span>
                                        Грешка при увозу{' '}
                                        {model === 'user'
                                            ? 'корисника'
                                            : 'кластера'}
                                        :{' '}
                                    </span>
                                    {importFailData}
                                </div>
                            )}
                        </>
                    ) : (
                        'Успешно додат документ.'
                    ),
                })
                trigger(model === 'user' ? `/api/user/` : '/api/location')
                trigger(model === 'user' ? `/api/user/guests` : null)
                setLoad(false)
            }
        } catch (error) {
            setLoad(false)
            const failImportData = error?.response?.data?.document.join(',  ')
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error?.response?.data?.message ? (
                    <>
                        <div>{error?.response?.data?.message}</div>
                        <div>
                            <span>
                                Грешка при увозу{' '}
                                {model === 'user' ? 'корисника' : 'кластера'}:{' '}
                            </span>
                            {failImportData}
                        </div>
                    </>
                ) : (
                    'Неуспешно додавање документа!'
                ),
            })
        }
    }

    return (
        <>
            {load ? (
                <SimpleModal />
            ) : (
                <Grid item xs={6}>
                    <Snackbar
                        open={snackbar.open}
                        onClose={handleSnackBarOpen}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        key="key"
                    >
                        <Alert onClose={handleSnackBarOpen} severity={severity}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                    <Grid container>
                        <Grid item xs={9}>
                            <label htmlFor="upload-CSV">
                                <input
                                    style={{ display: 'none' }}
                                    id="upload-CSV"
                                    name="upload-CSV"
                                    type="file"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={importCSV}
                                />
                                <Tooltip
                                    title="импортуј у ексел"
                                    placement="top"
                                >
                                    <Button
                                        {...configButton}
                                        component="span"
                                        className={classes.exportButtons}
                                    >
                                        <span>Увоз</span>
                                        <PublishSharpIcon
                                            className={classes.icon}
                                        />
                                    </Button>
                                </Tooltip>
                            </label>
                        </Grid>
                        <Grid item xs={3}>
                            <Tooltip
                                title="Преузми шаблон за увоз"
                                placement="top"
                            >
                                <Button
                                    variant="contained"
                                    className={classes.importSchema}
                                    onClick={() => downloadFile(importSchema)}
                                >
                                    <DescriptionOutlinedIcon
                                        className={classes.iconFile}
                                    />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    )
}

export default ButtonImportCSV
ButtonImportCSV.propTypes = {
    model: PropTypes.string.isRequired,
}
