import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Arrow from '@material-ui/icons/ArrowDropDownOutlined'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
    btn: {
        margin: '20px 0',
    },
})

const AddButton = ({ label, click, show }) => {
    const classes = useStyles()
    return (
        <Button
            variant="contained"
            color="primary"
            onClick={click}
            endIcon={show ? <ArrowDropUpIcon /> : <Arrow />}
            className={classes.btn}
        >
            {label}
        </Button>
    )
}

AddButton.propTypes = {
    label: PropTypes.string.isRequired,
    click: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
}

export default AddButton
