import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@material-ui/lab'
import {
    Button,
    Paper,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import StepUploadFiles from '../Stepper/StepUploadFiles'

const useStyles = makeStyles(() => ({
    cardMargin: {
        margin: '2rem auto',
    },
    formWidth: {
        width: '100%',
    },
    containerFlex: {
        display: 'flex',
    },
    showLabel: {
        display: 'flex',
        width: 550,
    },
    hideLabel: {
        display: 'none',
    },
}))

const ControllActivityStepper = ({
    activity,
    connected,
    setSeverity,
    setSnackbar,
    setError,
}) => {
    const classes = useStyles()
    const [activeStep, setActiveStep] = useState(0)
    const [showStepLabel, setShowStepLabel] = useState({
        uploaded: false,
        statusChanged: false,
    })
    const [withChanges] = useState('false')

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const getSteps = () => ['Статус активности промењен']

    const steps = getSteps()

    const toggleClasses = (showSucess, index) => {
        if (showSucess.uploaded && index === 1) {
            const classUpload = classes.showLabel
            return classUpload
        }

        if (!showSucess.uploaded && index === 1) {
            const classUpload = classes.hideLabel
            return classUpload
        }

        if (showSucess.statusChanged && index === 2) {
            const classStatusChange = classes.showLabel
            return classStatusChange
        }

        if (!showSucess.statusChanged && index === 2) {
            const classStatusChange = classes.hideLabel
            return classStatusChange
        }

        return classes.hideLabel
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <StepUploadFiles
                        activity={activity}
                        setSeverity={setSeverity}
                        setSnackbar={setSnackbar}
                        handleNext={handleNext}
                        setError={setError}
                        setShowStepLabel={setShowStepLabel}
                        withChanges={withChanges}
                        connected={connected}
                    />
                )

            default:
                return 'Непостојећи корак'
        }
    }

    return (
        <>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>
                            {/* clsx(classes.appBar, classes.header)} */}
                            <Alert
                                className={toggleClasses(showStepLabel, index)}
                                severity="success"
                            >
                                {label}
                            </Alert>
                        </StepLabel>
                        <StepContent className={classes.containerFlex}>
                            {getStepContent(index)}

                            <div className={classes.actionsContainer}>
                                {activeStep !== 0 && (
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                    >
                                        Назад
                                    </Button>
                                )}
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0}>
                    <Typography>
                        Успешно сте променили статус активности
                    </Typography>
                </Paper>
            )}
        </>
    )
}

export default ControllActivityStepper

ControllActivityStepper.propTypes = {
    activity: PropTypes.objectOf(PropTypes.any).isRequired,
    connected: PropTypes.arrayOf(PropTypes.any).isRequired,
    setSeverity: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
}
