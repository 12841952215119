const latToCyr = (text) => {
    // prettier-ignore
    const lat = {
        "A": 'А', "B": 'Б', "V": 'В', "G": 'Г', "D": 'Д', "Đ": 'Ђ', "E": 'Е', "Ž": 'Ж', "Z": "З", "I": "И",
      "J": "Ј", "K":"К", "L":"Л", "Lj":"Љ", "M": "М", "N": "Н", "Nj": "Њ", "O": "О", "P": "П", "R": "Р", "S": "С", "T": "Т",
      "Ć": "Ћ", "U": "У", "F": "Ф", "H": "Х", "C": "Ц", "Č": "Ч", "Dž": "Џ", "Š": "Ш", "a": 'а', "b": 'б', "v": 'в', "g": 'г',
      "d": 'д', "đ": 'ђ', "e": 'е', "ž": 'ж', "z": "з", "i": "и", "j": "ј", "k": "к", "l": "л", "lj": "љ", "m": "м", "n": "н",
      "nj": "њ", "o": "о", "p": "п", "r": "р", "s": "с", "t": "т", "ć": "ћ", "u": "у", "f": "ф", "h": "х", "c": "ц", "č": "ч",
      "dž": "џ", "š": "ш"
      }
    const x = text
        ?.split('')
        .map((char) => lat[char] || char)
        .join('')

    if (
        x?.search('дж') !== -1 ||
        x?.search('нј') !== -1 ||
        x?.search('лј') !== -1
    ) {
        return x?.replace('лј', 'љ').replace('нј', 'њ').replace('дж', 'џ')
    }
    return x
}

export default latToCyr
