import React, { useEffect, useState } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
// import useSWR from 'swr'
import { useSelector } from 'react-redux'
import { Button, makeStyles, Snackbar } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Alert } from '@material-ui/lab'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import TabPanel from '../../components/TabPanel/TabPanel'
import { axiosAuth as axios } from '../../util/axios-instance'
import BackToButton from '../../components/FormsUI/Button/BackToButton'
import GroupRow from '../../components/Cards/GroupRow'
// import SimpleModal from '../../components/Modal/Modal'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import SimpleModal from '../../components/Modal/Modal'

const useStyles = makeStyles(() => ({
    appBar: {
        backgroundColor: 'white',
        color: 'black',
        margin: 8,
    },
    table: {
        maxWidth: 1080,
        margin: 8,
    },
    cell: {
        padding: '5px 9px',
    },
    button: {
        backgroundColor: '#C54F4F',
        color: 'white',
        '&:hover': {
            background: '#C54F4F',
        },
    },
    cellButton: {
        textAlign: 'right',
        padding: '5px 9px',
    },
    titleDiv: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    title: {
        margin: '0 20px',
        alignSelf: 'center',
    },
    root: {
        margin: 12,
    },
}))

const GroupsManagment = () => {
    const [value, setValue] = useState(0)
    const { naziv, groupId } = useParams()
    const classes = useStyles()
    const [users, setUsers] = useState([])
    const [guests, setGuests] = useState([])
    const [refreshState, setRefreshState] = useState(false)
    const [roles, setRoles] = useState([])
    const myInfo = useSelector((state) => state.myrole)
    const history = useHistory()
    const location = useLocation()
    // eslint-disable-next-line
    const [load, setLoad] = useState(false)
    const [guestsError, setGuestsError] = useState(false)
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        if (location?.state?.from !== 'UPRAVLJANJE KORISNICIMA')
            history.push('/app/grupe/')
    }, [])
    const getUsersFromGroup = async () => {
        try {
            // setLoad(true)
            // setRefreshState(true)
            // const u = await axios.get(`/api/user`)
            // const total = u.data.totalResults
            const response = await axios.get(
                `/api/user?groupId=${groupId}&limit=50000`
            )
            setUsers(
                response.data.users.filter((user) => user.grupa._id === groupId)
            )
            // setLoad(false)
            // setRefreshState(false)
        } catch (error) {
            // setRefreshState(true)
            // if (
            //     error.response.data.message ===
            //     'Грешка при проналажењу корисника.'
            // ) {
            //     setUsers([])
            // }
            // setRefreshState(false)
            console.log(error.response.data.message)

            // setTimeout(() => {
            //     setRefreshState(false)
            // }, 400)
        }
    }

    const getAllRoles = async () => {
        try {
            const response = await axios.get('/api/role')
            setRoles(response.data.roles)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllRoles()
    }, [])

    const getGuests = async () => {
        try {
            const response = await axios.get('/api/user/guests')
            setGuests(response.data.guests)
        } catch (error) {
            setGuestsError(error.response.data.message)
            setRefreshState(false)
        }
    }

    const removeUser = async (uId) => {
        try {
            setRefreshState(true)
            setLoad(true)
            await axios.patch(`/api/group/remove-user?userId=${uId}`)
            setRefreshState(false)
            setSeverity('success')
            setLoad(false)
            setSnackbar({
                open: true,
                message: 'Успешно сте избацили корисника из групе.',
            })
        } catch (error) {
            setRefreshState(true)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error?.response?.data?.message
                    ? error.response.data.message
                    : 'Неуспешно избацивање корисника из групе',
            })
            setRefreshState(false)
        }
    }

    const setRefresh = (state) => {
        setRefreshState(state)
    }

    useEffect(() => {
        if (!refreshState) {
            getUsersFromGroup()
            getGuests()
        }
    }, [refreshState])

    const setSeverityHandler = (state) => {
        setSeverity(state)
    }

    const setSnackbarHandler = (state) => {
        setSnackbar(state)
    }

    return (
        <div className={classes.root}>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <div className={classes.titleDiv}>
                <BackToButton label="НАЗАД НА ЛИСТУ СВИХ ГРУПА" />
                <div className={classes.title}>
                    <PageTitle
                        title={`Управљање везама корисника и групе - ${naziv}`}
                    />
                </div>
            </div>
            {/* {load && <SimpleModal />} */}
            {load && <SimpleModal />}
            {!load && (
                <>
                    <div className={classes.appBar}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs example"
                        >
                            <Tab label="Корисници из групе" />
                            <Tab label="Гости" />
                        </Tabs>
                    </div>

                    <TabPanel value={value} index={0}>
                        {users.length > 0 ? (
                            <>
                                {' '}
                                <TableContainer className={classes.container}>
                                    <Table
                                        stickyHeader
                                        aria-label="sticky table"
                                        className={classes.table}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.cell}
                                                >
                                                    Име
                                                </TableCell>
                                                <TableCell
                                                    className={classes.cell}
                                                >
                                                    Имејл адреса
                                                </TableCell>
                                                <TableCell
                                                    className={classes.cell}
                                                >
                                                    Рола
                                                </TableCell>
                                                <TableCell
                                                    className={classes.cell}
                                                />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users
                                                .filter(
                                                    (el) =>
                                                        el.grupa._id === groupId
                                                )
                                                .map((user) => (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={user._id}
                                                    >
                                                        <TableCell
                                                            className={
                                                                classes.cell
                                                            }
                                                        >
                                                            {user.ime}{' '}
                                                            {user.prezime}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                classes.cell
                                                            }
                                                        >
                                                            {user.email}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                classes.cell
                                                            }
                                                        >
                                                            {user.rola}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                classes.cellButton
                                                            }
                                                        >
                                                            <Button
                                                                className={
                                                                    classes.button
                                                                }
                                                                onClick={() =>
                                                                    removeUser(
                                                                        user._id
                                                                    )
                                                                }
                                                            >
                                                                Избаци
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        ) : (
                            <p>Тренутно нема корисника у овој групи</p>
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {guests.length > 0 && !guestsError ? (
                            <TableContainer className={classes.container}>
                                <Table
                                    stickyHeader
                                    aria-label="sticky table"
                                    className={classes.table}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.cell}>
                                                Име
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                Имејл адреса
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                Рола
                                            </TableCell>
                                            <TableCell
                                                className={classes.cell}
                                            />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {guests.map((guest) => (
                                            <GroupRow
                                                key={guest._id}
                                                name={`${guest.ime} ${guest.prezime}`}
                                                email={guest.email}
                                                groupId={groupId}
                                                userId={guest._id}
                                                roles={roles}
                                                setRefresh={setRefresh}
                                                myRole={myInfo.user.rola}
                                                naziv={naziv}
                                                setSnackbarHandler={
                                                    setSnackbarHandler
                                                }
                                                setSeverityHandler={
                                                    setSeverityHandler
                                                }
                                                setLoad={setLoad}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <p>Тренутно нема гостију</p>
                        )}
                    </TabPanel>
                </>
            )}
        </div>
    )
}

export default GroupsManagment
