import React, { useState } from 'react'
import uuid from 'react-uuid'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import { Alert } from '@material-ui/lab'
import PropTypes from 'prop-types'
import { Button, Snackbar, CircularProgress, Tooltip } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
// import moment from 'moment'
import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
// import SimpleModal from '../../components/Modal/Modal'
import { axiosAuth as axios } from '../../util/axios-instance'
import DateTextFieldWrapper from '../../components/FormsUI/Textfield/DateTextFieldWrapper'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: '90%',
        overflow: 'auto',
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
    },
    dateDiv: {
        width: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    uploadButton: {
        width: 'unset',
        padding: '4px 27px',
        margin: '10px',
        borderRadius: '5px',
        boxShadow: 'none',
        backgroundColor: '#1E8637',
        color: 'white',
        '&:hover': {
            backgroundColor: '#1E8637',
        },
    },
    newFiles: {
        height: 'auto',
        overflowY: 'auto',
        maxHeight: 300,
    },
    removeIcon: {
        cursor: 'pointer',
        color: 'red',
        fontSize: 20,
        margin: '0 0 0 10px',
    },
}))

const FORM_VALIDATION = Yup.object().shape({
    naziv: Yup.string().required('Поље је обавезно'),
    broj: Yup.string().required('Поље је обавезно'),
    datumPocetka: Yup.date().required('Поље је обавезно'),
    rokTrajanja: Yup.number()
        .typeError('Морате унети број')
        .required('Поље је обавезно'),
    // grupe: Yup.array().of(Yup.string()),
})

export default function CreateContract({
    modalOpen,
    setModal,
    setRefreshState,
    setSeverityHandler,
    setSnackbarHandler,
}) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)
    // const [files, setFiles] = useState([])
    // const [uploadState, setUploadState] = useState(false)
    // const [f, setF] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([])
    // const [groups, setGroups] = useState(false)
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const editContract = async (newFiles, id) => {
        try {
            // setUploadState(true)
            const fileData = new FormData()
            for (let i = 0; i < newFiles.length; i += 1) {
                fileData.append(`multi-files`, newFiles[i])
                fileData.append(`fajl${i}`, newFiles[i].name)
            }

            // setUploadState(false)

            await axios.post(`/api/contract/contract/${id}`, fileData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })

            setSelectedFiles([])
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error.response.data.message,
            })
        }
    }

    const createContract = async (values) => {
        try {
            setRefreshState(true)
            const response = await axios.post('/api/contract', {
                naziv: values.naziv,
                broj: values.broj,
                datumPocetka: values.datumPocetka,
                rokTrajanja: values.rokTrajanja,
            })

            // setNewId(response.data.document._id)
            if (selectedFiles.length > 0)
                editContract(selectedFiles, response.data.document)

            setSeverityHandler('success')
            setSnackbarHandler({
                open: true,
                message: 'Успешно креиран јавни позив.',
            })
            setModal(false)
            setRefreshState(false)
        } catch (error) {
            setSeverityHandler('error')
            setSnackbarHandler({
                open: true,
                message: 'Јавни позив није креиран.',
            })
            console.log(error)
        }
    }

    const uploadFiles = async (event) => {
        try {
            setSelectedFiles([
                ...selectedFiles,
                ...Array.from(event.target.files),
            ])
        } catch (error) {
            console.log(error)
        }
    }

    const deleteBlankContract = async () => {
        try {
            setModal(false)
            // await axios.delete(`/api/contract/blank/${id}`)
        } catch (error) {
            console.log(error)
        }
    }

    const removeNewAdded = (name) => {
        setSelectedFiles(
            Array.from(selectedFiles).filter((zx) => zx.name !== name)
        )
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={4000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <h2 className={classes.title}>Нови јавни позив</h2>

            <Formik
                initialValues={{
                    naziv: '',
                    broj: '',
                    rokTrajanja: '',
                    datumPocetka: '',
                    // grupe: [],
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => createContract(values)}
            >
                {({ isSubmitting, values }) => (
                    <Form>
                        <div className={classes.formDiv}>
                            <div className={classes.formField}>
                                <span className={classes.tableRowLabel}>
                                    Назив јавног позива
                                </span>
                                <TextfieldWrapper name="naziv" />
                            </div>
                            <div className={classes.formField}>
                                <span className={classes.tableRowLabel}>
                                    Број јавног позива
                                </span>
                                <TextfieldWrapper name="broj" />
                            </div>
                            <div className={classes.dateDiv}>
                                <span className={classes.tableRowLabel}>
                                    Датум почетка
                                </span>
                                <DateTextFieldWrapper
                                    id="date"
                                    name="datumPocetka"
                                    type="date"
                                    format="DD-MM-YYYY"
                                    // onChange={(event) => {
                                    //     if (event.target.valueAsDate) {
                                    //         setFieldValue(
                                    //             'datumPocetka',
                                    //             event.target.valueAsDate
                                    //         )
                                    //     }
                                    // }}
                                    // error={errors?.datumPocetka && true}
                                    // helperText={errors?.datumPocetka}
                                    value={values.datumPocetka}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>

                            <div className={classes.formField}>
                                <span className={classes.tableRowLabel}>
                                    Рок трајања (број дана)
                                </span>
                                <TextfieldWrapper name="rokTrajanja" />
                            </div>

                            {/* <div className={classes.formField}>
                                    <span className={classes.tableRowLabel}>
                                        Групе
                                    </span>

                                    <Autocomplete
                                        id="combo-box-demo"
                                        multiple
                                        disableClearable
                                        options={groups.filter(
                                            (group) => group.tip !== 'Admin'
                                        )}
                                        getOptionLabel={(option) =>
                                            option.naziv
                                        }
                                        getOptionSelected={(option, value) =>
                                            option._id === value._id
                                        }
                                        onChange={(event, gr) => {
                                            console.log(gr.map((g) => g._id))
                                            setFieldValue(
                                                'grupe',
                                                gr.map((g) => g._id)
                                            )
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </div> */}

                            {/* <div className={classes.dateDiv}>
                                <span className={classes.tableRowLabel}>
                                    Рок трајања
                                </span>
                                <TextField
                                    id="date"
                                    name="rokTrajanja"
                                    type="date"
                                    onChange={(event) => {
                                        if (event.target.valueAsDate) {
                                            setFieldValue(
                                                'rokTrajanja',
                                                event.target.valueAsDate
                                            )
                                        }
                                    }}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div> */}

                            {/* <div className={classes.formField}>
                                <span className={classes.tableRowLabel}>
                                    Рок трајања
                                </span>
                                <TextfieldWrapper name="rokTrajanja" />
                            </div> */}

                            <label htmlFor="multi-files">
                                <input
                                    style={{ display: 'none' }}
                                    id="multi-files"
                                    name="multi-files"
                                    type="file"
                                    accept=".doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    onChange={uploadFiles}
                                    multiple
                                />
                                <Button
                                    className={classes.uploadButton}
                                    component="span"
                                >
                                    Додајте документе
                                </Button>
                            </label>

                            {selectedFiles.length > 0 && <p>Нови документи</p>}
                            <div className={classes.newFiles}>
                                {selectedFiles.length > 0 &&
                                    Array.from(selectedFiles).map((file) => (
                                        <p
                                            key={uuid()}
                                            style={{
                                                display: 'flex',
                                                margin: '0',
                                            }}
                                        >
                                            <Tooltip title={file.name}>
                                                <span
                                                    style={{
                                                        width: '80%',
                                                    }}
                                                >
                                                    {file.name.length > 40
                                                        ? `${file.name.slice(
                                                              0,
                                                              40
                                                          )}...`
                                                        : file.name}
                                                </span>
                                            </Tooltip>
                                            {/* {file.name} */}
                                            <span>
                                                <ClearIcon
                                                    className={
                                                        classes.removeIcon
                                                    }
                                                    onClick={() => {
                                                        removeNewAdded(
                                                            file.name
                                                        )
                                                    }}
                                                />
                                            </span>
                                        </p>
                                    ))}
                            </div>

                            <ButtonWrapper
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={isSubmitting}
                                startIcon={
                                    isSubmitting ? (
                                        <CircularProgress size="0.9rem" />
                                    ) : undefined
                                }
                                style={{
                                    // backgroundColor: '#337ab7',
                                    // color: 'white',
                                    height: 'unset',
                                    fontSize: '0.75rem',
                                    // padding: '8px 12px',
                                    fontWeight: 'bold',
                                    margin: '10px 0',
                                }}
                            >
                                Направи јавни позив
                            </ButtonWrapper>
                            <Button
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                onClick={() => deleteBlankContract()}
                            >
                                Одустани
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )

    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={() => deleteBlankContract()}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}

CreateContract.propTypes = {
    modalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    setRefreshState: PropTypes.func,
    setSeverityHandler: PropTypes.func,
    setSnackbarHandler: PropTypes.func,
}

CreateContract.defaultProps = {
    modalOpen: false,
    setModal: () => {},
    setRefreshState: () => {},
    setSeverityHandler: () => {},
    setSnackbarHandler: () => {},
}
