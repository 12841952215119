import {
    GROUP_LIST_REQUEST,
    GROUP_LIST_SUCCESS,
    GROUP_LIST_FAIL,
    GROUP_SINGLE_REQUEST,
    GROUP_SINGLE_SUCCESS,
    GROUP_SINGLE_FAIL,
} from '../constants/groupConstants'

export const groupListReducer = (
    state = { loading: true, groupData: [] },
    action
) => {
    switch (action.type) {
        case GROUP_LIST_REQUEST:
            return { loading: true, ...state }
        case GROUP_LIST_SUCCESS:
            return { loading: false, groupData: action.payload }
        case GROUP_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const singleGroup = (state = { loading: true, group: [] }, action) => {
    switch (action.type) {
        case GROUP_SINGLE_REQUEST:
            return { loading: true, ...state }
        case GROUP_SINGLE_SUCCESS:
            return { loading: false, group: action.payload }
        case GROUP_SINGLE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export default 'bla'
