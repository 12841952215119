import { axiosAuth } from './axios-instance'
import latToCyr from './latToCyr'

const importExcel = async (
    event,
    id,
    setSeverityHandler,
    setSnackbarHandler,
    setRefresh,
    setLoad
) => {
    try {
        const selectedFile = event.target.files[0]
        if (selectedFile.size > 10000000) {
            setSeverityHandler('warning')
            setSnackbarHandler({
                open: true,
                message: 'Документ не сме бити већи од 10 Мегабајта',
            })
        } else {
            const fileData = new FormData()
            fileData.append('file', selectedFile)

            setLoad(true)

            setRefresh(true)
            await axiosAuth.post(
                `/api/inventory/import?lokacija=${id}`,
                fileData
            )
            setRefresh(false)

            setSeverityHandler('success')
            setSnackbarHandler({
                open: true,
                message: 'Успешан импорт инвентара.',
            })
            setLoad(false)
        }
    } catch (error) {
        setLoad(false)
        console.log(error)
        setSeverityHandler('warning')
        setSnackbarHandler({
            open: true,
            message: latToCyr(error.response.data.message),
        })
    }
}

export default importExcel
