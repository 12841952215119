import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import { Button, Grid } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import download from 'downloadjs'
import { axiosAuth as axios } from '../../../../util/axios-instance'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        maxWidth: 550,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center',
    },
    warning: {
        color: '#AE0F0F',
        fontSize: 50,
    },
    btnPreuzmi: {
        marginLeft: 10,
        padding: 8,
        background: '#1E8637',
        color: '#FFFFFF',
    },
    listWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        background: '#f2f2f2',
        marginBottom: 10,
        borderRadius: 5,
    },
}))

export default function FileModalContract({
    files,
    open,
    close,
    id,
    // locationId,
    title,
    type,
}) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)

    // const deleteFile= async () => {
    //     try {
    //         setRefresh(true)
    //         await axios.delete(`/api/group/${id}`)
    //         setModal(false)
    //         setRefresh(false)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const fileList = () => {
    //     files.map((item) => <Grid item>{item.fajl}</Grid>)
    // }

    /* eslint-disable */
    const downloadFile = async (fId, fName, mimetype) => {
        try {
            const response = await axios.get(
                `/api/files/${type}/${id}/${fId}`,
                {
                    responseType: 'blob',
                }
            )

            return download(response.data, fName, mimetype)
        } catch (error) {}
    }
    // console.log(files)
    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h3>{title}</h3>

            <Grid container spacing={2}>
                {files &&
                    files.map((item) => (
                        <Grid
                            item
                            xs={12}
                            className={classes.listWrap}
                            key={item._id}
                        >
                            {item.fajl}
                            <Button
                                className={classes.btnPreuzmi}
                                onClick={() => {
                                    downloadFile(
                                        item._id,
                                        item.originalnoIme,
                                        item.mimetype
                                    )
                                }}
                                endIcon={<GetAppIcon />}
                            >
                                Преузми
                            </Button>
                        </Grid>
                    ))}
            </Grid>
            <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={close}
                style={{ marginTop: 20 }}
            >
                Затвори
            </Button>
        </div>
    )

    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {files ? body : null}
        </Modal>
    )
}

FileModalContract.propTypes = {
    files: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    locationId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
}

FileModalContract.defaultProps = {
    files: [],
}
