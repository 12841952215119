/* eslint-disable */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
} from '@material-ui/core'
import { useField, useFormikContext } from 'formik'

const CheckboxWrapper = ({ name, label, currentValue, ...otherProps }) => {
    const { setFieldValue } = useFormikContext()
    const [field, meta] = useField(name)

    const handleChange = (evt) => {
        const { checked } = evt.target

        setFieldValue(name, checked)
    }

    const configCheckbox = {
        ...field,
        onChange: handleChange,
        ...otherProps,
    }

    const configFormControl = {}
    if (meta && meta.touched && meta.error) {
        configFormControl.error = true
    }

    useEffect(() => {
        if (currentValue !== undefined) setFieldValue(name, currentValue)
    }, [name, currentValue, setFieldValue])

    return (
        <FormControl {...configFormControl}>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={currentValue}
                            {...configCheckbox}
                            color="primary"
                        />
                    }
                    label={label}
                />
            </FormGroup>
        </FormControl>
    )
}

CheckboxWrapper.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    currentValue: PropTypes.bool.isRequired,
}

CheckboxWrapper.defaultProps = {
    name: '',
    label: '',
    // currentValue: false,
}

export default CheckboxWrapper
