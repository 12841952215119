import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { Grid, makeStyles, Button } from '@material-ui/core'
import { trigger } from 'swr'

// import { useParams } from 'react-router-dom'

import { axiosAuth } from '../../../util/axios-instance'

const useStyles = makeStyles(() => ({
    closeActionTitle: {
        margin: '1rem',
    },
    cardMargin: {
        margin: 20,
    },
    paper: {
        padding: 20,
        width: 400,
    },
}))

const StepChangeControlStatus = ({
    setSeverity,
    setSnackbar,

    setError,
    activity,
}) => {
    const [isSubmiting, setIsSubmiting] = useState(false)
    const classes = useStyles()

    const checkFiles = (files) => {
        const demandedFilesNumber = files.filter((file) =>
            file.originalnoIme.includes(`revizija=${activity.returned}`)
        ).length
        // console.log('zahtevani fajlovi BROJ', demandedFilesNumber)
        return demandedFilesNumber
    }

    const handleStatusChangeSubmit = async () => {
        try {
            setIsSubmiting(true)
            await axiosAuth.post(`/api/activity/control/`, {
                faults: true,
                ctrlId: activity._id,
            })
            // console.log(data)

            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно промењен статус активности.',
            })
            setIsSubmiting(false)

            trigger(`/api/activity/${activity._id}`)
        } catch (error) {
            setIsSubmiting(false)
            setError(error.response)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Мењање статуса активности није успешно!',
            })
        }
    }
    return <Grid container className={classes.cardMargin} />
}

export default StepChangeControlStatus

StepChangeControlStatus.propTypes = {
    setSeverity: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,

    setError: PropTypes.func.isRequired,
    activity: PropTypes.objectOf(PropTypes.any).isRequired,
}
