import React from 'react'
import PropTypes from 'prop-types'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { trigger } from 'swr'
// import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import TextfieldWrapper from '../../../components/FormsUI/Textfield/TextfieldWrapper'
import { axiosAuth } from '../../../util/axios-instance'
import SelectWrapper from '../../../components/FormsUI/Select/SelectWrapper'
import ButtonWrapper from '../../../components/FormsUI/Button/ButtonWrapper'
import AutoCompleteWrapper from '../../../components/FormsUI/AutoComplete/AutoCompleteWrapper'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        // position: 'absolute',
        // top: '10%',
        // left: '10%',
        // overflow: 'scroll',
        // maxHeight: '90%',
        // display: 'block',
    },
    paper: {
        overflow: 'auto',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 600,
        [theme.breakpoints.down(610)]: {
            width: '90%',
        },
    },
}))

const AddUserModal = ({
    open,
    close,
    groups,
    page,
    rowsPerPage,
    guestsPage,
    guestsRowsPerPage,
    setSeverity,
    setSnackbar,
}) => {
    const userInfo = useSelector((state) => state.myrole)
    const myRole = userInfo.user.rola
    const classes = useStyles()

    const roles = [
        { dataValue: 'Admin', dataLabel: 'Админ' },
        { dataValue: 'Rukovodilac', dataLabel: 'Руководилац' },
        { dataValue: 'Zaposleni', dataLabel: 'Запослени' },
        { dataValue: 'Gost', dataLabel: 'Гост' },
    ]
    const rolesManager = [
        { dataValue: 'Rukovodilac', dataLabel: 'Руководилац' },
        { dataValue: 'Zaposleni', dataLabel: 'Запослени' },
        { dataValue: 'Gost', dataLabel: 'Гост' },
    ]
    // const status = [
    //     { dataValue: 'aktivan', dataLabel: 'Активан' },
    //     { dataValue: 'neaktivan', dataLabel: 'Неактиван' },
    //     { dataValue: 'obrisan', dataLabel: 'Обрисан' },
    // ]
    const emailConfirmed = [
        { dataValue: 'true', dataLabel: 'Да' },
        { dataValue: 'false', dataLabel: 'Не' },
    ]
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down(600))

    const initialUserInfoState = {
        ime: '',
        prezime: '',
        email: '',
        emailPotvrdjen: false,
        grupa: '',
        rola: '',
        status: 'false',
        lozinka: '',
        potvrdaLozinke: '',
    }

    const userInfoValidation = Yup.object().shape({
        ime: Yup.string().required('Морате проследити име корисника'),
        prezime: Yup.string().required('Морате проследити презиме корисника'),
        email: Yup.string()
            .email('емаил није исправан')
            .required('Унесите емаил'),
        emailPotvrdjen: Yup.string().required('Изаберите једну од опција'),
        rola: Yup.string().required('Изаберите једну од опција'),
        grupa: Yup.string()
            .when('rola', {
                is: (rola) => rola !== 'Gost' && rola !== 'Admin',
                then: Yup.string().required('Изаберите једну од опција'),
                otherwise: Yup.string(),
            })
            .test((groupid) => {
                const isContained = groups.some((el) => el._id === groupid)
                if (!isContained && groupid !== '' && groupid !== undefined) {
                    return new Yup.ValidationError(
                        `Ниста изабрали валидну опцију `,
                        undefined,
                        'groupId'
                    )
                }

                return true
            }),
        status: Yup.string().required('Изаберите једну од опција'),
        lozinka: Yup.string()
            .required('Морате унети лозинку')
            .min(8, 'Лозинка мора имати минимум 8 карактера'),
        potvrdaLozinke: Yup.string()
            .oneOf([Yup.ref('lozinka'), null], 'Лозинке морају бити идентичне')
            .required('Морате потврдити лозинку'),
    })

    const handleCreateAccount = async (data, { resetForm, setErrors }) => {
        try {
            await axiosAuth.post('/api/user/', data)

            resetForm(initialUserInfoState)
            close()

            trigger(`/api/user/?page=${page + 1}&limit=${rowsPerPage}`)
            trigger(
                `/api/user/guests?page=${
                    guestsPage + 1
                }&limit=${guestsRowsPerPage}`
            )
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно сте направили кориснички налог.',
            })
        } catch (err) {
            console.log(err.response)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Кориснички налог неуспешно направљен!',
            })

            let error = 'email'
            if (err.response.data.message) {
                error =
                    err.response.data.message.includes(
                        'Емаил је већ у употреби'
                    ) && 'email'
                setErrors({
                    [error]: 'Унесена Е-пошта је заузета.',
                })
            }
        }
    }

    return (
        <Modal
            aria-labelledby="createUserModal"
            aria-describedby="createUserModal"
            className={classes.modal}
            open={open}
            onClose={close}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Formik
                        initialValues={{
                            ...initialUserInfoState,
                        }}
                        validationSchema={userInfoValidation}
                        onSubmit={handleCreateAccount}
                    >
                        {({ isSubmitting, values }) => (
                            <Form autoComplete="off">
                                <Grid container spacing={1}>
                                    <Grid item xs={7}>
                                        <Typography variant="h2">
                                            КРЕИРАЊЕ НАЛОГА
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="ime"
                                            label="Име"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="prezime"
                                            label="Презиме"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="email"
                                            label="е-пошта"
                                            type="email"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="lozinka"
                                            label="Лозинка"
                                            type="password"
                                            autoComplete="new-password"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="potvrdaLozinke"
                                            label="Потврда лозинке"
                                            type="password"
                                        />
                                    </Grid>
                                    {/* селект */}
                                    <Grid item xs={12}>
                                        <Box py={1}>
                                            <Grid container alignItems="center">
                                                {matches ? (
                                                    <></>
                                                ) : (
                                                    <Grid item xs={3}>
                                                        <Typography>
                                                            Потврђена е-пошта
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                <Grid item xs={12} sm={9}>
                                                    <SelectWrapper
                                                        options={emailConfirmed}
                                                        name="emailPotvrdjen"
                                                        label="Потврђена е-пошта"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container alignItems="center">
                                            {matches ? (
                                                <></>
                                            ) : (
                                                <Grid item xs={3}>
                                                    <Typography>
                                                        Рола
                                                    </Typography>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} sm={9}>
                                                <SelectWrapper
                                                    options={
                                                        myRole === 'Admin'
                                                            ? roles
                                                            : rolesManager
                                                    }
                                                    name="rola"
                                                    label="Рола"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container alignItems="center">
                                            {matches ? (
                                                <></>
                                            ) : (
                                                <Grid item xs={3}>
                                                    <Typography>
                                                        Група
                                                    </Typography>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} sm={9}>
                                                <AutoCompleteWrapper
                                                    name="grupa"
                                                    label="Група"
                                                    selectedRole={values.rola}
                                                    data={groups}
                                                    isDisabled={
                                                        values.rola ===
                                                            'Gost' ||
                                                        values.rola === 'Admin'
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* selekt */}
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            spacing={3}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid item xs={6}>
                                                <Button
                                                    variant="outlined"
                                                    fullWidth
                                                    onClick={close}
                                                >
                                                    Одустани
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ButtonWrapper
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={
                                                        isSubmitting ? (
                                                            <CircularProgress size="0.9rem" />
                                                        ) : undefined
                                                    }
                                                >
                                                    {matches
                                                        ? 'Потврди'
                                                        : 'Креирај налог'}
                                                </ButtonWrapper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Fade>
        </Modal>
    )
}

export default AddUserModal

AddUserModal.propTypes = {
    open: PropTypes.bool.isRequired,
    groups: PropTypes.arrayOf(PropTypes.any).isRequired,
    close: PropTypes.func.isRequired,

    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    guestsPage: PropTypes.number.isRequired,
    guestsRowsPerPage: PropTypes.number.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setSeverity: PropTypes.func.isRequired,
}
