import React from 'react'
import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import { TextField, CircularProgress } from '@material-ui/core'

const AutocompleteFilterCard = ({
    options,
    getOptionLabel,
    value,
    onChange,
    // loading,
    openTest,
    onOpen,
    onClose,
    label,
}) => {
    const loading = openTest && options.length === 0
    const filterOptions = createFilterOptions({
        stringify: ({ srb, eng }) => `${eng} ${srb}`,
    })

    return (
        <Autocomplete
            loadingText="Учитавање..."
            noOptionsText="Нема пронађених резултата"
            id="combo-box-demo"
            options={options}
            getOptionLabel={getOptionLabel}
            filterOptions={filterOptions}
            filterSelectedOptions
            getOptionSelected={(option, val) => option === val}
            value={value}
            onChange={onChange}
            loading={loading}
            open={openTest}
            onOpen={onOpen}
            onClose={onClose}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading && openTest ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    )
}

AutocompleteFilterCard.propTypes = {
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    value: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
    openTest: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
}

// AutocompleteFilterCard.defaultProps = {
// }

export default AutocompleteFilterCard
