import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import {
    Button,
    CircularProgress,
    NativeSelect,
    Snackbar,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import FormControl from '@material-ui/core/FormControl'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
import { axiosAuth as axios } from '../../util/axios-instance'
import SimpleModal from '../../components/Modal/Modal'
import CheckboxWrapper from '../../components/FormsUI/Checkbox/CheckboxWrapper'
import TypeSelectWrapper from '../../components/FormsUI/Select/TypeSelectWrapper'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
    removeIcon: {
        cursor: 'pointer',
        color: 'red',
        fontSize: 20,
        margin: '0 0 0 10px',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'column',
    },
}))

const FORM_VALIDATION = Yup.object().shape({
    nadredjena: Yup.string().nullable(true),
    naziv: Yup.string().required('Поље је обавезно'),
    kratakNaziv: Yup.string().required('Поље је обавезно'),
    tip: Yup.string().required('Поље је обавезно'),
    svi: Yup.bool(),
})

export default function EditGroup({
    id,
    modalOpen,
    setModal,
    setRefresh,
    setSeverityHandler,
    setSnackbarHandler,
}) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)
    const [group, setGroup] = useState(false)
    const [groups, setGroups] = useState([])
    const [selected, setSelected] = useState('')
    const [initVal, setInitVal] = useState(false)
    const [type, setType] = useState('')
    // const [tipNadredjene, setTipNadredjene] = useState('')
    const [types, setTypes] = useState([])
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const getGroup = async () => {
        try {
            const response = await axios.get(`/api/group/${id}`)
            setGroup(response.data.group)
            if (response.data.group.nadredjenaGrupa) {
                setSelected(response.data.group.nadredjenaGrupa._id)
            } else {
                setSelected('')
            }
            if (response.data.group.tip) {
                setType(response.data.group.tip)
            } else {
                setType('')
            }
            setInitVal({
                nadredjena: response.data.group.nadredjenaGrupa
                    ? response.data.group.nadredjenaGrupa._id
                    : '',
                naziv: response.data.group.naziv,
                kratakNaziv: response.data.group.kratakNaziv,
                tip: response.data.group.tip ? response.data.group.tip : '',
                svi: response.data.group.sveVideci,
            })
        } catch (error) {
            console.log(error)
        }
    }

    const editGroup = async (values) => {
        try {
            setRefresh(true)
            await axios.patch(`/api/group/${id}`, {
                nadredjenaGrupa: values.nadredjena,
                naziv: values.naziv,
                kratakNaziv: values.kratakNaziv,
                sveVideci: values.svi,
                tip: values.tip,
            })
            setModal(false)
            setSeverityHandler('success')
            setSnackbarHandler({
                open: true,
                message: 'Успешно сте изменили групу.',
            })
            setRefresh(false)
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error?.response?.data?.message
                    ? error.response.data.message
                    : 'Измена групе неуспешна.',
            })
        }
        // console.log({
        //     nadredjenaGrupa: values.nadredjena === '' && undefined,
        //     naziv: values.naziv,
        //     kratakNaziv: values.kratakNaziv,
        //     sveVideci: values.svi,
        //     tip: values.tip,
        // })
    }

    const getAllGroups = async () => {
        try {
            const response = await axios.get('/api/group?limit=5000')
            setGroups(response.data.groups)
        } catch (error) {
            console.log(error)
        }
    }

    const getAllTypes = async () => {
        try {
            const response = await axios.get('/api/types')
            setTypes(response.data.types)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getGroup()
        getAllGroups()
        getAllTypes()
    }, [id])

    const body = (
        <>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <div style={modalStyle} className={classes.paper}>
                <h2 className={classes.title}>Изменa групе</h2>
                {group && groups && initVal && types.length > 0 ? (
                    <Formik
                        initialValues={{
                            ...initVal,
                        }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values) => editGroup(values)}
                    >
                        {({ setFieldValue, values, isSubmitting }) => (
                            <Form>
                                <div className={classes.formDiv}>
                                    <div className={classes.tableRow}>
                                        <span className={classes.tableRowLabel}>
                                            Надређена група
                                        </span>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <NativeSelect
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        'nadredjena',
                                                        event.target.value
                                                    )
                                                    setSelected(
                                                        event.target.value
                                                    )
                                                    setFieldValue(
                                                        'tip',
                                                        event.target.selectedOptions[0].getAttribute(
                                                            'tip'
                                                        )
                                                    )
                                                    setType(
                                                        event.target.selectedOptions[0].getAttribute(
                                                            'tip'
                                                        )
                                                    )
                                                    setFieldValue('svi', false)
                                                }}
                                                value={selected}
                                                inputProps={{
                                                    name: 'name',
                                                    id: 'uncontrolled-native',
                                                }}
                                            >
                                                <option value="" tip="">
                                                    Изабери групу
                                                </option>
                                                {groups
                                                    .filter(
                                                        (el) =>
                                                            el.naziv !==
                                                            values.naziv
                                                    )
                                                    .filter(
                                                        (el) =>
                                                            el.naziv !==
                                                                'Админ' &&
                                                            el.naziv !== 'Admin'
                                                    )
                                                    .map((g) => (
                                                        <option
                                                            key={g._id}
                                                            value={g._id}
                                                            tip={g.tip}
                                                        >
                                                            {g.naziv}
                                                        </option>
                                                    ))}
                                            </NativeSelect>
                                        </FormControl>
                                    </div>
                                    <div className={classes.tableRow}>
                                        <span className={classes.tableRowLabel}>
                                            Назив групе
                                        </span>
                                        <TextfieldWrapper
                                            name="naziv"
                                            value={group.naziv}
                                        />
                                    </div>
                                    <div className={classes.tableRow}>
                                        <span className={classes.tableRowLabel}>
                                            Кратак назив групе
                                        </span>
                                        <TextfieldWrapper
                                            name="kratakNaziv"
                                            value={group.kratakNaziv}
                                        />
                                    </div>
                                    <div className={classes.tableRow}>
                                        <span className={classes.tableRowLabel}>
                                            Тип
                                        </span>
                                        <TypeSelectWrapper
                                            name="tip"
                                            // label="Изаберите тип"
                                            currentValue={type}
                                            options={types}
                                            onChange={(event) => {
                                                setFieldValue(
                                                    'tip',
                                                    event.target.value
                                                )
                                                setType(event.target.value)
                                            }}
                                            disabled={values.nadredjena !== ''}
                                        />
                                        {/* <FormControl
                                            className={classes.formControl}
                                            disabled={values.nadredjena !== ''}
                                            required
                                        >
                                            <Select
                                                native
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        'tip',
                                                        event.target.value
                                                    )
                                                    setType(event.target.value)
                                                }}
                                                value={type}
                                                inputProps={{
                                                    name: 'age',
                                                    id: 'age-native-simple',
                                                }}
                                            >
                                                <option
                                                    aria-label="None"
                                                    value=""
                                                >
                                                    Изаберите тип
                                                </option>
                                                {types
                                                    .filter(
                                                        (t) =>
                                                            t.naziv !== 'Admin'
                                                    )
                                                    .map((t) => (
                                                        <option
                                                            value={t.naziv}
                                                            key={t._id}
                                                        >
                                                            {t.cirilica}
                                                        </option>
                                                    ))}
                                            </Select>
                                        </FormControl> */}
                                    </div>
                                    <CheckboxWrapper
                                        name="svi"
                                        disabled={values.nadredjena !== ''}
                                        label="Види све кластере"
                                        currentValue={values.svi}
                                    />
                                    <ButtonWrapper
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        disabled={isSubmitting}
                                        startIcon={
                                            isSubmitting ? (
                                                <CircularProgress size="0.9rem" />
                                            ) : undefined
                                        }
                                        style={{
                                            // backgroundColor: '#337ab7',
                                            // color: 'white',
                                            height: 'unset',
                                            fontSize: '0.75rem',
                                            // padding: '8px 12px',
                                            fontWeight: 'bold',
                                            margin: '10px 0',
                                        }}
                                    >
                                        Сачувај измене
                                    </ButtonWrapper>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                        onClick={() => setModal(false)}
                                    >
                                        Одустани
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <SimpleModal />
                )}
            </div>
        </>
    )

    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={() => setModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}

EditGroup.propTypes = {
    id: PropTypes.string.isRequired,
    modalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    setRefresh: PropTypes.func,
    setSeverityHandler: PropTypes.func,
    setSnackbarHandler: PropTypes.func,
}

EditGroup.defaultProps = {
    modalOpen: false,
    setModal: () => {},
    setRefresh: () => {},
    setSeverityHandler: () => {},
    setSnackbarHandler: () => {},
}
