import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { Divider, Button, Grid, CircularProgress } from '@material-ui/core'
import useSWR from 'swr'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

import FileSaver from 'file-saver'
import xlsx from 'xlsx'
import './Roboto-Light-normal'
import {
    reduceUser,
    reduceLocations,
    reduceContracts,
    reduceGroups,
    // reduceOneGroup,
    reduceUserExcel,
    reduceLocationsExcel,
    reduceContractsExcel,
    reduceGroupsExcel,
    reduceNotifications,
    reduceNotificationsExcel,
    reduceReportsFinished,
    reduceReportsFinishedExcel,
    reduceReportsPzi,
    reduceReportsPziExcel,
    reduceReportsSummary,
    reduceReportsSummaryExcel,
    reduceAllInvReports,
    reduceAllInvReportsExcel,
    reduceSummaryInvReports,
    reduceSummaryInvReportsExcel,
    reduceInvByLocationReports,
    reduceInvByLocationReportsExcel,
    reduceSchoolNotifications,
    reduceSchoolNotificationsExcel,
    reduceInventoryCheck,
    reduceInventoryCheckExcel,
    reduceDetailedLocationsExcel,
    // reduceOneGroupExcel,
} from '../../util/reduceHelpers'

/* eslint-disable */
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'scroll',

        maxHeight: '100%',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 600,
        [theme.breakpoints.down(610)]: {
            width: '90%',
        },
    },
    typeLabel: {
        margin: '1rem 0',
    },
}))

const ExportModal = ({
    open,
    close,
    exportColumns,
    model,
    search,
    selectedActivity,
    selectedItem,
    selectedContract,
    name,
}) => {
    const classes = useStyles()
    const [exportType, setExportType] = useState('excel')
    const [exportData, setExportData] = useState([])
    const [exportExcelData, setExcelExportData] = useState([])
    const myProfile = useSelector((state) => state.myrole)
    const myRole = myProfile.user.rola

    const getUrl = (isReportData) => {
        let url = ''
        if (search.eng === '') {
            if (model === 'location') {
                url = `/api/${model}?limit=100000&page=1`
            } else if (
                model.includes('inventory-reports/sumarno/naziv') ||
                model.includes('inventory-reports/sumarno/datum')
            ) {
                url = `/api/${model.split('X')[0]}?limit=100000&page=1${
                    model.split('X')[1]
                }`
            } else {
                url = `/api/${model}?limit=100000&page=1`
            }
        } else {
            if (
                model === 'location' ||
                model === 'group' ||
                model === 'user' ||
                model === 'contract'
            ) {
                url = `/api/search/${model}?eng=${search.eng}&srb=${search.srb}&limit=100000&page=1`
            } else {
                url = `/api/${model}?eng=${search.eng}&srb=${search.srb}&limit=100000&page=1`
            }
        }
        if (isReportData && isReportData !== '') {
            console.log(isReportData)
            let reportUrl = ''
            if (selectedContract && selectedContract !== '') {
                reportUrl = `/api/${model}?broj=${selectedContract}`
            } else {
                reportUrl =
                    search.eng === ''
                        ? `/api/${model}/${isReportData}?page=1&limit=1000000`
                        : `/api/search/location?eng=${search.eng}&srb=${search.srb}&page=1&limit=100000&activity=${isReportData}`
            }

            return reportUrl
        }
        //         const url =
        //             search.eng === ''
        //                 ? `/api/${model}?limit=100000&page=1
        // `
        //                 : `/api/search/${model}?eng=${search.eng}&srb=${search.srb}&limit=100000&page=1`
        //         if (isReportData !== '') {
        //             const reportUrl =
        //                 search.eng === ''
        //                     ? `/api/${model}/${isReportData}?page=1&limit=1000000`
        //                     : `/api/search/location?eng=${search.eng}&srb=${search.srb}&page=1&limit=100000&activity=${isReportData}`
        //             return reportUrl
        //         }

        return url
    }

    const { data, error, isValidating } = useSWR(
        open
            ? getUrl(selectedActivity !== '' ? selectedActivity : selectedItem)
            : null
    )
    // console.log('Izvoz', data, 'url Izvoz', url)
    const {
        data: allMightyLocations,
        error: locationError,
        isValidating: locationIsValidating,
    } = useSWR(
        open &&
            (model === 'location' ||
                model === 'activity/notifications' ||
                model === 'report/summary')
            ? '/api/group/sve-videci/true'
            : null
    )

    // useEffect(() => {
    //     if (open)
    //         console.log(
    //             'Izvoz podaci',
    //             data,
    //             'svemoguce',
    //             allMightyLocations,
    //             'url Izvoz',
    //             getUrl(
    //                 selectedActivity !== '' ? selectedActivity : selectedItem
    //             )
    //         )
    // }, [data, allMightyLocations])

    const exportPdf = () => {
        const doc = new jsPDF('p', 'pt', 'a4')

        doc.setFont('Roboto-Light', 'normal')
        doc.setFontSize(4)

        doc.autoTable({
            columns: exportColumns,
            body: exportData,

            styles: {
                font: 'Roboto-Light',
                fontStyle: 'normal',
                fontSize: 5,
            },
            margin: { bottom: 60 },
        })

        return doc.save(`${name}.pdf`)
    }

    const saveAsExcelFile = (buffer, fileName) => {
        const EXCEL_TYPE =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        const EXCEL_EXTENSION = '.xlsx'
        const excelData = new Blob([buffer], {
            type: EXCEL_TYPE,
        })
        FileSaver.saveAs(
            excelData,
            `${fileName}-${new Date().getTime()}${EXCEL_EXTENSION}`
        )
    }

    const exportExcel = () => {
        const worksheet = xlsx.utils.json_to_sheet(exportExcelData)
        var wscols = [
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
            { wch: 30 },
        ]

        worksheet['!cols'] = wscols
        // console.log(worksheet)
        const workbook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        }
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        })
        saveAsExcelFile(excelBuffer, `${name}`)
    }

    useEffect(() => {
        if (data && model === 'inventory-reports/sve') {
            setExportData(reduceAllInvReports(data.allInventory))
            setExcelExportData(reduceAllInvReportsExcel(data.allInventory))
        }
        if (
            (data && model.includes('inventory-reports/sumarno/naziv')) ||
            model.includes('inventory-reports/sumarno/datum')
        ) {
            setExportData(reduceSummaryInvReports(data))
            setExcelExportData(reduceSummaryInvReportsExcel(data))
        }
        if (data && model === 'inventory-reports/inventar-po-lokaciji') {
            setExportData(reduceInvByLocationReports(data))
            setExcelExportData(reduceInvByLocationReportsExcel(data))
        }
        if (data && model === 'school-notification') {
            setExportData(reduceSchoolNotifications(data))
            setExcelExportData(reduceSchoolNotificationsExcel(data))
        }

        if (data && model === 'A13') {
            setExportData(reduceInventoryCheck(data))
            setExcelExportData(reduceInventoryCheckExcel(data))
        }
        if (data && model === 'user') {
            setExportData(reduceUser(data.users))
            setExcelExportData(reduceUserExcel(data.users))
        }
        if (data && model === 'contract') {
            setExportData(reduceContracts(data.contracts))
            setExcelExportData(reduceContractsExcel(data.contracts))
        }
        if (data && model === 'group') {
            setExportData(reduceGroups(data.groups))
            setExcelExportData(reduceGroupsExcel(data.groups))
        }
        if (data && model === 'report/finished') {
            setExportData(reduceReportsFinished(data.locations))
            setExcelExportData(reduceReportsFinishedExcel(data.locations))
        }
        if (data && selectedActivity !== '') {
            setExportData(reduceReportsPzi(data.locations, selectedActivity))
            setExcelExportData(
                reduceReportsPziExcel(data.locations, selectedActivity)
            )
        }
        if (allMightyLocations && data && selectedItem !== '') {
            const allLocationContractors = allMightyLocations.groups.filter(
                (el) => el.tip.includes('Izvođač')
            )
            setExportData(reduceReportsSummary(data, allLocationContractors))
            setExcelExportData(
                reduceReportsSummaryExcel(data, allLocationContractors)
            )
        }

        if (allMightyLocations && data && model === 'location') {
            setExportData(
                reduceLocations(data.locations, allMightyLocations.groups)
            )
            setExcelExportData(
                reduceLocationsExcel(data.locations, allMightyLocations.groups)
            )
            // setExcelExportData(
            //     reduceDetailedLocationsExcel(
            //         data.locations,
            //         allMightyLocations.groups
            //     )
            // )
        }
        if (allMightyLocations && data && model === 'activity/notifications') {
            setExportData(
                reduceNotifications(data.activities, allMightyLocations.groups)
            )
            setExcelExportData(
                reduceNotificationsExcel(
                    data.activities,
                    allMightyLocations.groups
                )
            )
        }
    }, [data])

    const handleChange = (event) => {
        setExportType(event.target.value)
    }

    const handleExportData = () => {
        if (exportType === 'excel') {
            exportExcel()
        }
        if (exportType === 'pdf') {
            exportPdf()
        }
    }

    return (
        <Modal
            aria-labelledby="exportModal"
            aria-describedby="exportModal"
            className={classes.modal}
            open={open}
            onClose={close}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 1000,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel
                                    component="legend"
                                    className={classes.typeLabel}
                                >
                                    Изаберите тип извоза
                                </FormLabel>
                                <Divider />
                                <RadioGroup
                                    aria-label="promenaTrase"
                                    name="promenaTrase"
                                    value={exportType}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel
                                        control={<Radio />}
                                        label="Извоз у PDF"
                                        value="pdf"
                                    />
                                    <FormControlLabel
                                        control={<Radio />}
                                        label="Извоз у Excel"
                                        value="excel"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={close}
                            >
                                Одустани
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                disabled={!!isValidating}
                                startIcon={
                                    isValidating ? (
                                        <CircularProgress size="0.9rem" />
                                    ) : undefined
                                }
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleExportData}
                            >
                                Извоз
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Fade>
        </Modal>
    )
}

export default ExportModal

ExportModal.propTypes = {
    exportColumns: PropTypes.arrayOf(PropTypes.any).isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    search: PropTypes.objectOf(PropTypes.any).isRequired,
    model: PropTypes.string.isRequired,
    selectedActivity: PropTypes.string,
    selectedItem: PropTypes.string,
    name: PropTypes.string,
}

ExportModal.defaultProps = {
    selectedActivity: '',
    selectedItem: '',
    name: 'data',
}
