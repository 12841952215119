import React from 'react'
import PropTypes from 'prop-types'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'

import { makeStyles } from '@material-ui/core/styles'
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'

import { Formik, Form } from 'formik'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { trigger } from 'swr'
import TextfieldWrapper from '../../../components/FormsUI/Textfield/TextfieldWrapper'
import { axiosAuth } from '../../../util/axios-instance'
import SelectWrapper from '../../../components/FormsUI/Select/SelectWrapper'
import ButtonWrapper from '../../../components/FormsUI/Button/ButtonWrapper'
import cyrToLat from '../../../util/cyrToLat'

import AutoCompleteWrapper from '../../../components/FormsUI/AutoComplete/AutoCompleteWrapper'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        overflow: 'auto',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 600,
        [theme.breakpoints.down(610)]: {
            width: '90%',
        },
    },
}))

const EditUserModal = ({
    open,
    close,
    user,
    groups,
    page,
    rowsPerPage,
    guestsPage,
    guestsRowsPerPage,
    setSeverity,
    setSnackbar,
    searchDebouncedUsers,
}) => {
    const userInfo = useSelector((state) => state.myrole)
    const myRole = userInfo.user.rola

    const classes = useStyles()

    const rolesAdmin = [
        { dataValue: 'Admin', dataLabel: 'Админ' },
        { dataValue: 'Rukovodilac', dataLabel: 'Руководилац' },
        { dataValue: 'Zaposleni', dataLabel: 'Запослени' },
        { dataValue: 'Gost', dataLabel: 'Гост' },
    ]
    const rolesRukovodilac = [
        { dataValue: 'Rukovodilac', dataLabel: 'Руководилац' },
        { dataValue: 'Zaposleni', dataLabel: 'Запослени' },
        { dataValue: 'Gost', dataLabel: 'Гост' },
    ]

    const emailConfirmed = [
        { dataValue: 'true', dataLabel: 'Да' },
        { dataValue: 'false', dataLabel: 'Не' },
    ]
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down(600))

    const initialUserInfoState = {
        ime: '',
        prezime: '',
        email: '',
        emailPotvrdjen: '',
        groupId: '',
        rola: '',
        status: user?.emailPotvrdjenBool === true ? 'aktivan' : 'neaktivan',
        lozinka: '',
        potvrdaLozinke: '',
    }

    const userInfoValidation = Yup.object().shape({
        ime: Yup.string().required('Морате проследити име корисника'),
        prezime: Yup.string().required('Морате проследити презиме корисника'),
        email: Yup.string()
            .email('емаил није исправан')
            .required('Унесите емаил'),
        emailPotvrdjen: Yup.string().required('Изаберите једну од опција'),
        rola: Yup.string().required('Изаберите једну од опција'),
        groupId: Yup.string()
            .when('rola', {
                is: (rola) => rola !== 'Gost' && rola !== 'Admin',
                then: Yup.string().required('Изаберите једну од опција'),
                otherwise: Yup.string(),
            })
            .test((groupid) => {
                const isContained = groups.some((el) => el._id === groupid)

                if (!isContained && groupid !== '' && groupid !== undefined) {
                    return new Yup.ValidationError(
                        `Ниста изабрали валидну опцију `,
                        undefined,
                        'groupId'
                    )
                }

                return true
            }),
        status: Yup.string(),
        lozinka: Yup.string().min(8, 'Лозинка мора имати минимум 8 карактера'),
        // .required('Морате унети лозинку')
        potvrdaLozinke: Yup.string().oneOf(
            [Yup.ref('lozinka'), null],
            'Лозинке морају бити идентичне'
        ),
    })

    const handleEditAccount = async (data, { setErrors, resetForm }) => {
        try {
            await axiosAuth.patch(`/api/user/single/${user._id}`, data)

            resetForm(initialUserInfoState)

            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно унесене измене.',
            })
            trigger(`/api/user?page=${page + 1}&limit=${rowsPerPage}`)
            trigger(
                `/api/user/guests?page=${
                    guestsPage + 1
                }&limit=${guestsRowsPerPage}`
            )
            trigger(
                searchDebouncedUsers.eng === ''
                    ? null
                    : `/api/search/user?eng=${searchDebouncedUsers.eng}&srb=${
                          searchDebouncedUsers.srb
                      }&page=${page + 1}&limit=${rowsPerPage}`
            )
            close()
        } catch (err) {
            console.log(err.response)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Измене нису успешне',
            })
            let error = 'currentPass'
            if (err.response.data?.message) {
                error = err.response.data?.message.includes(
                    'duplicate key error collection'
                )
                    ? 'email'
                    : 'email'
            }

            setErrors({
                [error]:
                    err.response && err.response.data.message
                        ? 'Е-пошта је заузета.'
                        : 'Грешка при креирању корисника.',
            })
        }
    }

    return (
        <Modal
            aria-labelledby="createUserModal"
            aria-describedby="createUserModal"
            className={classes.modal}
            open={open}
            onClose={close}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 1000,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Formik
                        initialValues={{
                            ...initialUserInfoState,
                        }}
                        validationSchema={userInfoValidation}
                        onSubmit={handleEditAccount}
                    >
                        {({ isSubmitting, values }) => (
                            <Form>
                                <Grid container spacing={1}>
                                    <Grid item xs={7}>
                                        <Typography variant="h2">
                                            ИЗМЕНА НАЛОГА
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="ime"
                                            label="Име"
                                            value={user.ime}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="prezime"
                                            label="Презиме"
                                            value={user.prezime}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="email"
                                            label="е-пошта"
                                            type="email"
                                            value={user.email}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="lozinka"
                                            label="Лозинка"
                                            type="password"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextfieldWrapper
                                            name="potvrdaLozinke"
                                            label="Потврда лозинке"
                                            type="password"
                                        />
                                    </Grid>
                                    {/* селект */}
                                    <Grid item xs={12}>
                                        <Box py={1}>
                                            <Grid container alignItems="center">
                                                {matches ? (
                                                    <></>
                                                ) : (
                                                    <Grid item xs={3}>
                                                        <Typography>
                                                            Потврђена е-пошта
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                <Grid item xs={12} sm={9}>
                                                    <SelectWrapper
                                                        options={emailConfirmed}
                                                        name="emailPotvrdjen"
                                                        label="Потврђена е-пошта"
                                                        currentValue={
                                                            user.emailPotvrdjenBool
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container alignItems="center">
                                            {matches ? (
                                                <></>
                                            ) : (
                                                <Grid item xs={3}>
                                                    <Typography>
                                                        Рола
                                                    </Typography>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} sm={9}>
                                                <SelectWrapper
                                                    options={
                                                        myRole === 'Admin'
                                                            ? rolesAdmin
                                                            : rolesRukovodilac
                                                    }
                                                    name="rola"
                                                    label="Рола"
                                                    currentValue={cyrToLat(
                                                        user.rola
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container alignItems="center">
                                            {matches ? (
                                                <></>
                                            ) : (
                                                <Grid item xs={3}>
                                                    <Typography>
                                                        Група
                                                    </Typography>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} sm={9}>
                                                <AutoCompleteWrapper
                                                    name="groupId"
                                                    label="Група"
                                                    data={groups}
                                                    isDisabled={
                                                        values.rola ===
                                                            'Gost' ||
                                                        values.rola === 'Admin'
                                                    }
                                                    selectedRole={values.rola}
                                                    currentValue={
                                                        user.grupaId
                                                            ? user.grupaId
                                                            : ''
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* selekt */}
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            spacing={3}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid item xs={6}>
                                                <Button
                                                    variant="outlined"
                                                    fullWidth
                                                    onClick={close}
                                                >
                                                    Одустани
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ButtonWrapper
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={
                                                        isSubmitting ? (
                                                            <CircularProgress size="0.9rem" />
                                                        ) : undefined
                                                    }
                                                >
                                                    {matches
                                                        ? 'Потврди'
                                                        : 'Потврди измене'}
                                                </ButtonWrapper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Fade>
        </Modal>
    )
}

export default EditUserModal

EditUserModal.propTypes = {
    user: PropTypes.objectOf(PropTypes.any),
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setSeverity: PropTypes.func.isRequired,
    groups: PropTypes.arrayOf(PropTypes.any).isRequired,

    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    guestsPage: PropTypes.number.isRequired,
    guestsRowsPerPage: PropTypes.number.isRequired,
    searchDebouncedUsers: PropTypes.objectOf(PropTypes.any).isRequired,
}

EditUserModal.defaultProps = {
    user: {
        id: '',
        ime: '',
        prezime: '',
        email: '',
        emailPotvrdjen: false,
        rola: '',
        status: '',
        grupa: '',
    },
}
