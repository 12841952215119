import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
    btn: {
        background: '#C54F4F',
        color: '#FFF',
        width: '90%',
        '&:hover': {
            background: '#D86565',
        },
    },
}))

const DeleteButton = ({ name, children }) => {
    const classes = useStyles()

    return (
        <Button variant="contained" className={classes.btn} name={name}>
            {children}
        </Button>
    )
}

DeleteButton.propTypes = {
    children: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

export default DeleteButton
