/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, FormControl, Grid, Typography } from '@material-ui/core'
import download from 'downloadjs'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import NativeSelect from '@material-ui/core/NativeSelect'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import GetAppIcon from '@material-ui/icons/GetApp'
import PropTypes from 'prop-types'
import { axiosAuth as axios } from '../../util/axios-instance'
import FileModal from '../../pages/Locations/SingleLocation/SingleLocationModals/FileModal'
import DataModal from '../../pages/Locations/SingleLocation/SingleLocationModals/DataModal'

const useStyles = makeStyles({
    tr: {
        border: '1px solid #dddddd',
    },
    td: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: '0 5px',
        width: 300,
    },
    th: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: '0 5px',
        backgroundColor: '#417AB4',
        color: 'white',
        width: 300,
    },
    btnPreuzmi: {
        marginLeft: 10,
        background: '#1E8637',
        color: '#FFFFFF',
    },
    listWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    fileTxt: {
        textDecoration: 'underline',
        textTransform: 'none',
    },
})

const ActivityRow = ({ activity, fromControl }) => {
    const classes = useStyles()
    const [fileOpen, setFileOpen] = useState(false)
    const [dataOpen, setDataOpen] = useState(false)

    const downloadFile = async (fId, fName, mimetype) => {
        try {
            const response = await axios.get(
                `/api/files/activity/${activity.lokacija._id}/${activity._id}/${fId}`,
                {
                    responseType: 'blob',
                }
            )

            return download(response.data, fName, mimetype)
        } catch (error) {}
    }

    const showFiles = () => {
        setFileOpen(true)
    }

    const showData = () => {
        setDataOpen(true)
    }

    // const showData = () => {
    // }

    const handleCloseFile = () => {
        setFileOpen(false)
    }
    const handleCloseData = () => {
        setDataOpen(false)
    }

    // useEffect(() => {
    //     showData()
    // }, [])

    // console.log(activity)

    return (
        <>
            {/* <FileModal
                open={fileOpen}
                close={handleCloseFile}
                files={activity.fajlovi}
                id={activity._id}
                locationId={activity.lokacija._id}
                title="Доступни фајлови активности"
                type="activity"
            /> */}
            {/* <DataModal
                podaci={activity.podaci}
                close={handleCloseData}
                open={dataOpen}
            /> */}

            <tr>
                {/* <td className={classes.td}>{activity.kratakNaziv}</td> */}
                <td className={classes.td}>
                    {activity.kratakNaziv} - {activity.naziv}
                </td>
                <td className={classes.td}>
                    <Grid container>
                        {activity.fajlovi &&
                            activity.fajlovi.map((item) => (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.listWrap}
                                    key={item._id}
                                >
                                    <Button
                                        onClick={() => {
                                            downloadFile(
                                                item._id,
                                                item.originalnoIme,
                                                item.mimetype
                                            )
                                        }}
                                        // endIcon={<GetAppIcon />}
                                    >
                                        <Typography
                                            variant="body2"
                                            className={classes.fileTxt}
                                        >
                                            {item.fajl}
                                        </Typography>
                                    </Button>
                                </Grid>
                            ))}
                    </Grid>
                    {/* <Button
                        disabled={activity.fajlovi.length === 0}
                        onClick={showFiles}
                        className={classes.btn}
                        variant="outlined"
                        color="primary"
                    >
                        Прикажи документа
                    </Button> */}
                </td>
                {!fromControl && (
                    <td className={classes.td}>
                        {activity.podaci.length > 0
                            ? activity.podaci.map((item, i) => (
                                  <Grid
                                      item
                                      xs={12}
                                      className={classes.listWrap}
                                      key={i}
                                  >
                                      {item.naziv}: {item.vrednost}
                                  </Grid>
                              ))
                            : null}
                        {/* {activity.podaci ? activity.podaci[0] : ''} */}
                        {/* <Button
                            disabled={activity.podaci.length === 0}
                            onClick={showData}
                            className={classes.btn}
                            variant="outlined"
                            color="primary"
                        >
                            Прикажи податке
                        </Button> */}
                        {/* <Button
                    disabled={activity.podaci.length === 0}
                    onClick={showData}
                >
                    Прикажи
                </Button> */}
                    </td>
                )}
            </tr>
        </>
    )
}

ActivityRow.propTypes = {
    activity: PropTypes.objectOf(PropTypes.any).isRequired,
    fromControl: PropTypes.bool,
}

ActivityRow.defaultProps = {
    fromControl: false,
}

export default ActivityRow
