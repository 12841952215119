/* eslint-disable */
import axios from 'axios'

export const axiosInstance = axios.create({
     baseURL: 'https://rbb.mit.gov.rs',
  //  baseURL: 'http://localhost:5002/',
})

export const axiosAuth = axios.create({
     baseURL: 'https://rbb.mit.gov.rs/',
    // baseURL: 'http://68.183.209.20:5000/',
   // baseURL: 'http://localhost:5002/',
})

axiosAuth.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
})
