import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    rectangleleDefault: {
        height: 15,
        width: 35,
        margin: '0 10px',
    },
    rectangleRed: {
        backgroundColor: 'rgb(248, 185, 185)',
    },
    rectangleBlue: {
        backgroundColor: '#d5eaff',
    },
    rectangleGreen: {
        backgroundColor: '#89FD80',
    },
    rectangleOrange: {
        backgroundColor: '#FFE09E',
    },
    rectangleRedGreen: {
        background:
            'repeating-linear-gradient(-45deg,rgb(199, 252, 191) 0 18px,#F8B9B9 20px 100px)',
    },

    wrapper: {
        display: 'flex',
        margin: 30,
    },
    innerWrapper: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 10px',
    },
}))

const InfoPanel = () => {
    const classes = useStyles()
    return (
        <div className={classes.wrapper}>
            <div className={classes.innerWrapper}>
                <div
                    className={clsx(
                        classes.rectangleRed,
                        classes.rectangleleDefault
                    )}
                >
                    {' '}
                </div>
                <Typography variant="body2">
                    Радови су у току и касне
                </Typography>
            </div>
            <div className={classes.innerWrapper}>
                <div
                    className={clsx(
                        classes.rectangleBlue,
                        classes.rectangleleDefault
                    )}
                >
                    {' '}
                </div>
                <Typography variant="body2">
                    Радови су у току и не касне
                </Typography>
            </div>
            <div className={classes.innerWrapper}>
                <div
                    className={clsx(
                        classes.rectangleGreen,
                        classes.rectangleleDefault
                    )}
                >
                    {' '}
                </div>
                <Typography variant="body2">Радови завршени у року</Typography>
            </div>
            <div className={classes.innerWrapper}>
                <div
                    className={clsx(
                        classes.rectangleOrange,
                        classes.rectangleleDefault
                    )}
                >
                    {' '}
                </div>
                <Typography variant="body2">
                    Радови у току, остало је мање од 10% времена за реализацију
                </Typography>
            </div>
            <div className={classes.innerWrapper}>
                <div
                    className={clsx(
                        classes.rectangleRedGreen,
                        classes.rectangleleDefault
                    )}
                >
                    {' '}
                </div>
                <Typography variant="body2">
                    Радови су завршени са кашњењем
                </Typography>
            </div>
        </div>
    )
}

export default InfoPanel
