import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
    Button,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Grid,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import StepUploadFiles from './StepUploadFiles'
import AdditionalField from '../AdditionalFieldsForm/AdditionalField'
import AdditionalFields from '../AdditionalFieldsForm/AdditionalFields'

const useStyles = makeStyles(() => ({
    cardMargin: {
        margin: '2rem auto',
    },
    formWidth: {
        width: '100%',
    },
    containerFlex: {
        display: 'flex',
    },
    showLabel: {
        display: 'flex',
        width: 550,
    },
    hideLabel: {
        display: 'none',
    },
    actionsContainer: {
        width: '100%',
        marginTop: 10,
    },
    backButton: {
        width: 400,
    },
}))

const ActivityStepper = ({
    activity,

    setSeverity,
    setSnackbar,
    setError,
}) => {
    const classes = useStyles()
    const [activeStep, setActiveStep] = useState(0)
    // eslint-disable-next-line
    const [showStepLabel, setShowStepLabel] = useState({
        uploaded: false,
        statusChanged: false,
    })
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const getSteps = () => ['Статус активности промењен']
    const getStepsWithExtraFields = () => [
        'Податак додат',
        'Статус активности промењен',
    ]
    const steps =
        activity.kratakNaziv === 'A1' ||
        activity.kratakNaziv === 'A5' ||
        activity.kratakNaziv === 'A3' ||
        activity.kratakNaziv === 'A7' ||
        activity.kratakNaziv === 'A9'
            ? getStepsWithExtraFields()
            : getSteps()

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <StepUploadFiles
                        activity={activity}
                        setSeverity={setSeverity}
                        setSnackbar={setSnackbar}
                        handleNext={handleNext}
                        setError={setError}
                        setShowStepLabel={setShowStepLabel}
                    />
                )

            default:
                return 'Непознат корак'
        }
    }

    const getStepContentWithExtraFields = (step) => {
        switch (step) {
            case 0:
                if (
                    activity.kratakNaziv === 'A1' ||
                    activity.kratakNaziv === 'A3' ||
                    activity.kratakNaziv === 'A9'
                ) {
                    return (
                        <AdditionalField
                            activity={activity}
                            setSeverity={setSeverity}
                            setSnackbar={setSnackbar}
                            setError={setError}
                            handleNext={handleNext}
                        />
                    )
                }

                return (
                    <AdditionalFields
                        activity={activity}
                        setSeverity={setSeverity}
                        setSnackbar={setSnackbar}
                        setError={setError}
                        handleNext={handleNext}
                    />
                )

            case 1:
                return activity.status !== 'Završena' ? (
                    <StepUploadFiles
                        activity={activity}
                        setSeverity={setSeverity}
                        setSnackbar={setSnackbar}
                        handleNext={handleNext}
                        setError={setError}
                        setShowStepLabel={setShowStepLabel}
                    />
                ) : (
                    <div className={classes.backButton} />
                )

            default:
                return 'Непознат корак'
        }
    }
    return (
        <>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel> </StepLabel>
                        <StepContent className={classes.containerFlex}>
                            {activity.kratakNaziv === 'A1' ||
                            activity.kratakNaziv === 'A5' ||
                            activity.kratakNaziv === 'A3' ||
                            activity.kratakNaziv === 'A9'
                                ? getStepContentWithExtraFields(index)
                                : getStepContent(index)}
                            <div className={classes.actionsContainer}>
                                {activeStep !== 0 && (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                variant="outlined"
                                                fullWidth
                                            >
                                                Назад
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {/* {activeStep === steps.length && (
                <Paper square elevation={0}>
                    <Typography>
                        Успешно сте променили статус активности
                    </Typography>
                </Paper>
            )} */}
        </>
    )
}

export default ActivityStepper

ActivityStepper.propTypes = {
    activity: PropTypes.objectOf(PropTypes.any).isRequired,

    setSeverity: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
}
