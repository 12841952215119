import React, { useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import PropTypes from 'prop-types'

const TextfieldWrapper = ({ name, value, ...otherProps }) => {
    const { setFieldValue } = useFormikContext()
    const [field, meta] = useField(name)
    // const classes = useStyles()
    const configTextfield = {
        ...field,
        ...otherProps,
        fullWidth: true,
    }

    if (meta && meta.touched && meta.error) {
        configTextfield.error = true
        configTextfield.helperText = meta.error
    }

    useEffect(() => {
        if ((name && value !== undefined) || value !== undefined)
            setFieldValue(name, value)
    }, [name, value, setFieldValue])

    return <TextField {...configTextfield} />
}

TextfieldWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // value: PropTypes.string,
}

TextfieldWrapper.defaultProps = {
    value: '',
}

export default TextfieldWrapper
