import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import WarningIcon from '@material-ui/icons/Warning'
import { trigger } from 'swr'
import { makeStyles } from '@material-ui/core/styles'
import { Button, CircularProgress } from '@material-ui/core'

import { axiosAuth } from '../../../util/axios-instance'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center',
    },
    warning: {
        color: '#AE0F0F',
        fontSize: 50,
    },
}))

const DeleteUserModal = ({
    open,
    close,
    user,
    page,
    rowsPerPage,
    guestsPage,
    guestsRowsPerPage,
    setSeverity,
    setSnackbar,
    searchDebouncedUsers,
    // reload,
}) => {
    const classes = useStyles()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [modalStyle] = React.useState(getModalStyle)

    const handleDeleteAccount = async () => {
        try {
            setIsSubmitting(true)
            await axiosAuth.delete(`/api/user/single/${user._id}`)

            trigger(`/api/user?page=${page + 1}&limit=${rowsPerPage}`)
            trigger(
                `/api/user/guests?page=${
                    guestsPage + 1
                }&limit=${guestsRowsPerPage}`
            )
            trigger(
                searchDebouncedUsers.eng === ''
                    ? null
                    : `/api/search/user?eng=${searchDebouncedUsers.eng}&srb=${
                          searchDebouncedUsers.srb
                      }&page=${page + 1}&limit=${rowsPerPage}`
            )
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно сте обрисали кориснички налог.',
            })
            close()
            setIsSubmitting(false)
        } catch (err) {
            console.log(err.response.data.message)

            setSeverity('warning')
            setSnackbar({
                open: true,
                message: `Кориснички налог неуспешно обрисан! ${err?.response?.data?.message}`,
            })
        }
        setIsSubmitting(false)
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <WarningIcon className={classes.warning} />
            <h3>Да ли сте сигурни?</h3>
            <h3>Брисање корисника мења статус</h3>
            <Button
                variant="contained"
                color="secondary"
                fullWidth
                style={{ margin: '10px 0' }}
                onClick={handleDeleteAccount}
                disabled={isSubmitting}
                startIcon={
                    isSubmitting ? (
                        <CircularProgress size="0.9rem" />
                    ) : undefined
                }
            >
                Обриши
            </Button>

            <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={close}
            >
                Одустани
            </Button>
        </div>
    )

    return (
        <Modal
            aria-labelledby="deleteUserModal"
            aria-describedby="deleteUserModal"
            className={classes.modal}
            open={open}
            onClose={close}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 1000,
            }}
        >
            <div>{body}</div>
        </Modal>
    )
}

export default DeleteUserModal

DeleteUserModal.propTypes = {
    user: PropTypes.objectOf(PropTypes.any),
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    guestsPage: PropTypes.number.isRequired,
    guestsRowsPerPage: PropTypes.number.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setSeverity: PropTypes.func.isRequired,
    searchDebouncedUsers: PropTypes.objectOf(PropTypes.any).isRequired,
    // reload: PropTypes.func.isRequired,
}

DeleteUserModal.defaultProps = {
    user: {
        id: '',
        ime: '',
        prezime: '',
        email: '',
        emailPotvrdjen: false,
        rola: '',
        status: '',
        grupa: '',
    },
}
