import { MenuItem, TextField } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useField, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import uuid from 'react-uuid'

const SelectWrapper = ({ name, options, currentValue, ...otherProps }) => {
    const { setFieldValue } = useFormikContext()
    const [field, meta] = useField(name)
    const handleChange = (evt) => {
        const { value } = evt.target
        setFieldValue(name, value)
        if (name === 'rola' && value === 'Gost') {
            setFieldValue('groupId', '')
            setFieldValue('grupa', '')
        }
        if (name === 'rola' && value === 'Admin') setFieldValue('groupId', '')
        if (name === 'emailPotvrdjen' && value === 'true')
            setFieldValue('status', 'aktivan')
        if (name === 'emailPotvrdjen' && value === 'false')
            setFieldValue('status', 'neaktivan')
        // if (currentValue === 'Admin' && name === 'rola')
        //     setFieldValue('groupId', '')
    }

    const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        variant: 'outlined',
        fullWidth: true,
        onChange: handleChange,
    }

    if (meta && meta.touched && meta.error) {
        configSelect.error = true
        configSelect.helperText = meta.error
    }
    useEffect(() => {
        if (currentValue !== undefined) setFieldValue(name, currentValue)
    }, [name, currentValue, setFieldValue])

    return (
        <TextField {...configSelect}>
            {options.map((item) => (
                <MenuItem
                    key={uuid()}
                    value={item.dataValue ? item.dataValue : item._id}
                >
                    {item.dataLabel ? item.dataLabel : item.kratakNaziv}
                </MenuItem>
            ))}
        </TextField>
    )
}

SelectWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.instanceOf(Array).isRequired,
    currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}
SelectWrapper.defaultProps = {
    currentValue: '',
}
export default SelectWrapper
