import {
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_GUESTS_LIST_REQUEST,
    USER_GUESTS_LIST_SUCCESS,
    USER_GUESTS_LIST_FAIL,
    MYINFO_REQUEST,
    MYINFO_SUCCESS,
    MYINFO_FAIL,
    USER_ROLE,
} from '../constants/userConstants'

export const userListReducer = (
    state = { loading: true, userData: [] },
    action
) => {
    switch (action.type) {
        case USER_LIST_REQUEST:
            return { loading: true, ...state }
        case USER_LIST_SUCCESS:
            return { loading: false, usersData: action.payload }
        case USER_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const guestsListReducer = (
    state = { loading: true, guestData: [] },
    action
) => {
    switch (action.type) {
        case USER_GUESTS_LIST_REQUEST:
            return { loading: true, ...state }
        case USER_GUESTS_LIST_SUCCESS:
            return { loading: false, guestData: action.payload }
        case USER_GUESTS_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const myInfoReducer = (
    state = { loading: false, myInfo: {} },
    action
) => {
    switch (action.type) {
        case MYINFO_REQUEST:
            return { loading: true, ...state }
        case MYINFO_SUCCESS:
            return { loading: false, myinfo: action.payload }
        case MYINFO_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const myRoleReducer = (state = { user: '' }, action) => {
    switch (action.type) {
        case USER_ROLE:
            return {
                ...action.payload,
            }
        default:
            return state
    }
}
export default 'bla'
