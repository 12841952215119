import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useSWR, { trigger } from 'swr'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    makeStyles,
    Grid,
    Typography,
    Button,
    Snackbar,
    CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
import { axiosAuth as axios } from '../../util/axios-instance'
import ChangePasswordForm from '../../components/ChangePassword/ChangePasswordForm'
import SimpleModal from '../../components/Modal/Modal'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import DeleteProfile from './DeleteProfile'
import latToCyr from '../../util/latToCyr'

const DATA_VALIDATION = Yup.object().shape({
    ime: Yup.string().required('Поље је обавезно'),
    prezime: Yup.string().required('Поље је обавезно'),
    email: Yup.string().required('Поље је обавезно'),
})

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center',
    },
    warning: {
        color: '#AE0F0F',
        fontSize: 50,
    },
    root: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        '@media (max-width:1050px)': {
            flexDirection: 'column',
        },
    },
    leftForm: {
        margin: '25px',
        maxWidth: 600,
        textAlign: 'left',
    },
    rightForm: {
        margin: '25px',
        maxWidth: 600,
        textAlign: 'left',
    },
    tableRow: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 10,
    },
    tableRowLabel: {
        width: 380,
    },
    link: {
        marginTop: 20,
    },
    htmlTable: {
        border: '1px solid black',
        borderCollapse: 'collapse',
        marginBottom: 20,
    },
    td: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        width: 300,
    },
    th: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        backgroundColor: '#417AB4',
        color: 'white',
        width: 300,
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '90%',
        marginLeft: 20,
    },
    myInfo: {
        margin: 25,
    },
    infoWrapper: {
        maxWidth: 600,
        marginTop: 20,
    },
}))

const MyProfile = () => {
    const classes = useStyles()
    const [deleteState, setDeleteState] = useState()
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')
    const myInfo = useSelector((state) => state)
    // const [refresh, setRefresh] = useState(false)
    // const [data, setData] = useState(false)

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const editUserData = async (values) => {
        try {
            await axios.post('/api/user/change-info', {
                ime: values.ime,
                prezime: values.prezime,
                email: values.email,
            })
            trigger(`/api/user/single/${myInfo.myrole.user._id}`)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно сте изменили податке.',
            })
        } catch (error) {
            console.log(error)
            if (
                error.response.data.message.includes(
                    'duplicate key error collection'
                )
            ) {
                setSeverity('warning')
                setSnackbar({
                    open: true,
                    message: 'Имејл адреса - погрешан унос',
                })
            } else {
                setSeverity('warning')
                setSnackbar({
                    open: true,
                    message: error?.response?.data?.message
                        ? error.response.data.message
                        : 'Грешка при измени података корисника.',
                })
            }
        }
    }

    // useEffect(() => {
    //     getData()
    // }, [])

    const deleteStateHandler = (state) => {
        setDeleteState(state)
    }

    const { data, error, isValidating } = useSWR(
        `/api/user/single/${myInfo.myrole.user._id}`,
        {
            revalidateOnFocus: false,
        }
    )

    return (
        <div className={classes.root}>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Grid container justifyContent="center">
                <PageTitle title="Мој налог" />
            </Grid>
            {isValidating && !error ? (
                <SimpleModal />
            ) : (
                <div className={classes.content}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={6} align="center">
                            <div className={classes.infoWrapper}>
                                <table className={classes.htmlTable}>
                                    <tbody>
                                        <tr>
                                            <th className={classes.th}>Име</th>
                                            <td className={classes.td}>
                                                {data?.user?.ime
                                                    ? data.user.ime
                                                    : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={classes.th}>
                                                Презиме
                                            </th>
                                            <td className={classes.td}>
                                                {data?.user?.prezime
                                                    ? data.user.prezime
                                                    : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={classes.th}>
                                                Имејл адреса
                                            </th>
                                            <td className={classes.td}>
                                                {data?.user?.email
                                                    ? data.user.email
                                                    : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={classes.th}>Рола</th>
                                            <td className={classes.td}>
                                                {data?.user?.rola
                                                    ? latToCyr(data.user.rola)
                                                    : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={classes.th}>
                                                Група
                                            </th>

                                            <td className={classes.td}>
                                                {data?.group?.naziv
                                                    ? data.group.naziv
                                                    : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={classes.th}>Тип</th>

                                            <td className={classes.td}>
                                                {data?.group?.tip
                                                    ? latToCyr(data.group.tip)
                                                    : ''}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <Grid container>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                            className={classes.link}
                                            onClick={() => setDeleteState(true)}
                                        >
                                            Обриши налог
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6} align="center">
                            <div>
                                <div className={classes.leftForm}>
                                    {myInfo.myrole.user ? (
                                        <Formik
                                            initialValues={{
                                                ime: '',
                                                prezime: '',
                                                email: '',
                                                lozinka: '',
                                            }}
                                            validationSchema={DATA_VALIDATION}
                                            onSubmit={(values) =>
                                                editUserData(values)
                                            }
                                        >
                                            {({ isSubmitting }) => (
                                                <Form>
                                                    <div
                                                        className={
                                                            classes.formDiv
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.tableRow
                                                            }
                                                        >
                                                            <Grid
                                                                container
                                                                alignItems="center"
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                >
                                                                    <Typography variant="body1">
                                                                        Име
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={8}
                                                                >
                                                                    <TextfieldWrapper
                                                                        name="ime"
                                                                        value={
                                                                            data
                                                                                ?.user
                                                                                ?.ime
                                                                        }
                                                                        variant="outlined"
                                                                        margin="dense"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.tableRow
                                                            }
                                                        >
                                                            <Grid
                                                                container
                                                                alignItems="center"
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                >
                                                                    <Typography variant="body1">
                                                                        Презиме
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={8}
                                                                >
                                                                    <TextfieldWrapper
                                                                        name="prezime"
                                                                        value={
                                                                            data
                                                                                ?.user
                                                                                ?.prezime
                                                                        }
                                                                        variant="outlined"
                                                                        margin="dense"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.tableRow
                                                            }
                                                        >
                                                            <Grid
                                                                container
                                                                alignItems="center"
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                >
                                                                    <Typography variant="body1">
                                                                        Имејл
                                                                        адреса
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={8}
                                                                >
                                                                    <TextfieldWrapper
                                                                        name="email"
                                                                        value={
                                                                            data
                                                                                ?.user
                                                                                ?.email
                                                                        }
                                                                        variant="outlined"
                                                                        margin="dense"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </div>

                                                        <ButtonWrapper
                                                            type="submit"
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                            startIcon={
                                                                isSubmitting ? (
                                                                    <CircularProgress size="0.9rem" />
                                                                ) : undefined
                                                            }
                                                            variant="contained"
                                                            style={{
                                                                // backgroundColor:
                                                                //     '#337ab7',
                                                                // color: 'white',
                                                                height: 'unset',
                                                                fontSize:
                                                                    '1rem',
                                                                // padding: '8px 12px',
                                                                fontWeight:
                                                                    'bold',
                                                                margin: '10px 0',
                                                            }}
                                                        >
                                                            Сачувај измене
                                                        </ButtonWrapper>
                                                    </div>

                                                    {deleteState && (
                                                        <DeleteProfile
                                                            id={
                                                                myInfo.myrole
                                                                    .user._id
                                                            }
                                                            modalOpen={
                                                                deleteState
                                                            }
                                                            setModal={
                                                                deleteStateHandler
                                                            }
                                                        />
                                                    )}
                                                </Form>
                                            )}
                                        </Formik>
                                    ) : (
                                        <SimpleModal />
                                    )}
                                </div>
                                <div className={classes.rightForm}>
                                    <ChangePasswordForm />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    )
}

export default MyProfile
