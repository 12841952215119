import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, FormControl, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import NativeSelect from '@material-ui/core/NativeSelect'
import PropTypes from 'prop-types'
import { axiosAuth as axios } from '../../util/axios-instance'
import latToCyr from '../../util/latToCyr'

const useStyles = makeStyles({
    cellButton: {
        textAlign: 'right',
        padding: '5px 9px',
    },
    cell: {
        padding: '5px 9px',
    },
    button: {
        backgroundColor: '#C54F4F',
        color: 'white',
        '&:hover': {
            background: '#C54F4F',
        },
    },
})

const GroupRow = ({
    name,
    email,
    groupId,
    userId,
    roles,
    setRefresh,
    naziv,
    setSeverityHandler,
    setSnackbarHandler,
    setLoad,
    // myRole,
}) => {
    const classes = useStyles()
    const [roleState, setRoleState] = useState('Gost')
    const [role, setRole] = useState(false)
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const addUserToGroup = async (group, newRole, user) => {
        try {
            setRefresh(true)
            setLoad(true)
            await axios.post(`/api/role/add-role`, {
                groupId: group,
                newRole: newRole,
                guestId: user,
            })
            setSeverityHandler('success')
            setSnackbarHandler({
                open: true,
                message: 'Успешно сте убацили корисника у групу.',
            })
            setRefresh(false)
            setLoad(false)
        } catch (error) {
            setRefresh(true)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error?.response?.data?.message
                    ? error.response.data.message
                    : 'Неуспешно додавање корисника у групу.',
            })
            setTimeout(() => {
                setRefresh(false)
            }, 400)
        }
    }

    useEffect(() => {
        if (naziv === 'Админ' || naziv === 'Admin') {
            setRole('Admin')
            setRoleState('Admin')
        }
    }, [naziv])

    return (
        <>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={4000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell className={classes.cell}>{name}</TableCell>
                <TableCell className={classes.cell}>{email}</TableCell>
                <TableCell className={classes.cellButtons}>
                    <div className={classes.divButtons}>
                        <FormControl className={classes.formControl}>
                            {/* <InputLabel id="label">Izaberite rolu</InputLabel> */}
                            <NativeSelect
                                defaultValue={
                                    naziv === 'Админ' || naziv === 'Admin'
                                        ? 'Admin'
                                        : 'Gost'
                                }
                                // labelId="label"
                                inputProps={{
                                    name: 'name',
                                    id: 'uncontrolled-native',
                                }}
                                disabled={
                                    naziv === 'Админ' || naziv === 'Admin'
                                }
                                onChange={(event) => {
                                    setRole(event.target.value)
                                    setRoleState(event.target.value)
                                }}
                            >
                                {naziv === 'Админ' ||
                                    (naziv === 'Admin' && (
                                        <option value="Admin">Админ</option>
                                    ))}
                                {roles
                                    .filter((rola) => rola.imeRole !== 'Admin')
                                    .map((r) => (
                                        <option value={r.imeRole} key={r._id}>
                                            {latToCyr(r.imeRole)}
                                        </option>
                                    ))}
                            </NativeSelect>
                        </FormControl>
                    </div>
                </TableCell>
                <TableCell className={classes.cellButton}>
                    {roleState !== 'Gost' ? (
                        <Button
                            className={classes.button}
                            onClick={() => {
                                addUserToGroup(groupId, role, userId)
                            }}
                        >
                            Убаци у групу
                        </Button>
                    ) : (
                        <Button disabled>Убаци у групу</Button>
                    )}
                </TableCell>
            </TableRow>
        </>
    )
}

GroupRow.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string,
    groupId: PropTypes.string,
    userId: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.any),
    setRefresh: PropTypes.func,
    // myRole: PropTypes.string,
    naziv: PropTypes.string,
    setSeverityHandler: PropTypes.func,
    setSnackbarHandler: PropTypes.func,
    setLoad: PropTypes.func,
}

GroupRow.defaultProps = {
    name: '',
    email: '',
    groupId: '',
    userId: '',
    roles: [],
    setRefresh: () => {},
    naziv: '',
    // myRole: '',
    setSeverityHandler: () => {},
    setSnackbarHandler: () => {},
    setLoad: () => {},
}

export default GroupRow
