import React, { useState, useEffect } from 'react'
import uuid from 'react-uuid'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import { Button, Snackbar, CircularProgress, Tooltip } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { Alert } from '@material-ui/lab'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import AddIcon from '@material-ui/icons/Add'
import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
import { axiosAuth as axios } from '../../util/axios-instance'
import SimpleModal from '../../components/Modal/Modal'
import DateTextFieldWrapper from '../../components/FormsUI/Textfield/DateTextFieldWrapper'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: '90%',
        overflow: 'auto',
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
    },
    dateDiv: {
        width: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    uploadButton: {
        width: 'unset',
        padding: '4px 27px',
        margin: '10px',
        borderRadius: '5px',
        boxShadow: 'none',
        backgroundColor: '#1E8637',
        color: 'white',
        '&:hover': {
            backgroundColor: '#1E8637',
        },
    },
    files: {
        // display: 'flex',
    },
    newFiles: {
        height: 'auto',
        overflowY: 'auto',
        maxHeight: 300,
    },
    oldFiles: {
        height: 'auto',
        overflowY: 'auto',
        maxHeight: 200,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    removeIcon: {
        cursor: 'pointer',
        color: 'red',
        fontSize: 20,
        marginLeft: 45,
    },
}))

const FORM_VALIDATION = Yup.object().shape({
    naziv: Yup.string().required('Поље је обавезно'),
    // broj: Yup.string().required('Поље је обавезно'),
    datumPocetka: Yup.date().required('Поље је обавезно'),
    rokTrajanja: Yup.number()
        .typeError('Морате унети број')
        .required('Поље је обавезно'),
    // grupe: Yup.array().of(Yup.string()),
})

export default function EditContract({
    id,
    modalOpen,
    setModal,
    label,
    setRefreshState,
    setSeverityHandler,
    setSnackbarHandler,
}) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)
    const [contract, setContract] = useState(false)
    const [date, setDate] = useState('')
    const [files, setFiles] = useState([])
    const [uploadState, setUploadState] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([])
    // const [groups, setGroups] = useState([])
    const [initValues, setInitValues] = useState(false)
    // const [responsible, setResponsible] = useState(false)
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const getContract = async () => {
        try {
            const response = await axios.get(`/api/contract/${id}`)
            setContract(response.data.contract)
            setDate(new Date(response.data.contract.datumPocetka).toISOString())
            setInitValues({
                naziv: response.data.contract.naziv,
                // broj: contract.broj,
                rokTrajanja: response.data.contract.rokTrajanja,
                // datumPocetka: new Date(response.data.contract.datumPocetka)
                //     .toISOString()
                //     .split('T')[0],
                datumPocetka: moment(
                    new Date(response.data.contract.datumPocetka)
                ).format('YYYY-MM-DD'),
                dokumenta: response.data.contract?.fajlovi
                    ? response.data.contract.fajlovi
                    : [],
                // grupe: response.data.contract.grupe
                //     ? response.data.contract.grupe.map((grupa) => grupa._id)
                //     : [],
            })
        } catch (error) {
            console.log(error)
        }
    }

    const editContract = async (values) => {
        try {
            setUploadState(true)
            const fileData = new FormData()
            for (let i = 0; i < selectedFiles.length; i += 1) {
                fileData.append(`multi-files`, selectedFiles[i])
                fileData.append(`fajl${i}`, selectedFiles[i].name)
            }

            fileData.append('naziv', values.naziv)
            fileData.append('datumPocetka', values.datumPocetka)
            fileData.append('rokTrajanja', values.rokTrajanja)
            // fileData.append('grupe', values.grupe)

            setUploadState(false)

            setRefreshState(true)
            await axios.post(`/api/contract/contract/${id}`, fileData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            setSeverityHandler('success')
            setSnackbarHandler({
                open: true,
                message: 'Успешно измењен јавни позив.',
            })
            setModal(false)
            setRefreshState(false)
            setSelectedFiles([])
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error?.response?.data?.message
                    ? error.response.data.message
                    : 'Јавни позив није измењен.',
            })
        }
    }

    const getFiles = async () => {
        try {
            const response = await axios.get(`/api/contract/${id}`)
            setFiles(response.data.contract.fajlovi)
        } catch (error) {
            console.log(error)
        }
    }

    const uploadFiles = (event) => {
        try {
            setSelectedFiles([
                ...selectedFiles,
                ...Array.from(event.target.files),
            ])
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error.response.data.message,
            })
        }
    }

    useEffect(() => {
        getContract()
        if (!uploadState) getFiles()
    }, [id, uploadState])

    const removeFiles = async (fId) => {
        try {
            await axios.delete(`/api/files/contract/${id}/${fId}`)
            getFiles()
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error.response.data.message,
            })
        }
    }

    const removeNewAdded = (name) => {
        setSelectedFiles(
            Array.from(selectedFiles).filter((zx) => zx.name !== name)
        )
    }

    const body = (
        <>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <div style={modalStyle} className={classes.paper}>
                {contract.status === 'Završen' && contract.datumZavrsetka && (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                        Није могуће мењати завршен јавни позив
                    </p>
                )}
                <h2 className={classes.title}>{label}</h2>
                {contract && date && initValues ? (
                    <Formik
                        initialValues={{
                            ...initValues,
                        }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values) => editContract(values)}
                    >
                        {({ values, isSubmitting }) => (
                            <Form>
                                <div className={classes.formDiv}>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Назив јавног позива
                                        </span>
                                        <TextfieldWrapper
                                            disabled={
                                                contract.status === 'Završen' &&
                                                contract.datumZavrsetka &&
                                                true
                                            }
                                            placeholder="Назив јавног позива"
                                            name="naziv"
                                            value={contract.naziv}
                                        />
                                    </div>
                                    {/* <div className={classes.formField}>
                                    <TextfieldWrapper
                                        placeholder="Број уговора"
                                        name="broj"
                                        value={contract.broj}
                                    />
                                </div> */}
                                    <div className={classes.dateDiv}>
                                        <span className={classes.tableRowLabel}>
                                            Датум почетка
                                        </span>
                                        <DateTextFieldWrapper
                                            disabled={
                                                contract.status === 'Završen' &&
                                                contract.datumZavrsetka &&
                                                true
                                            }
                                            id="date"
                                            required
                                            name="datumPocetka"
                                            type="date"
                                            value={values.datumPocetka}
                                            // onChange={(event) => {
                                            //     if (event.target.valueAsDate) {
                                            //         setFieldValue(
                                            //             'datumPocetka',
                                            //             event.target.valueAsDate
                                            //         )
                                            //     }
                                            // }}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>

                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Рок трајања (број дана)
                                        </span>
                                        <TextfieldWrapper
                                            name="rokTrajanja"
                                            disabled={
                                                contract.status === 'Završen' &&
                                                contract.datumZavrsetka &&
                                                true
                                            }
                                            value={values.rokTrajanja}
                                        />
                                    </div>

                                    {/* <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Групе
                                        </span>

                                        <Autocomplete
                                            id="combo-box-demo"
                                            multiple
                                            disableClearable
                                            options={groups.filter(
                                                (group) => group.tip !== 'Admin'
                                            )}
                                            getOptionLabel={(option) =>
                                                option.naziv
                                            }
                                            value={responsible}
                                            getOptionSelected={(
                                                option,
                                                value
                                            ) => option._id === value._id}
                                            onChange={(event, gr) => {
                                                setResponsible(gr)
                                                setFieldValue(
                                                    'grupe',
                                                    gr.map((g) => g._id)
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </div> */}

                                    {/* <div className={classes.dateDiv}>
                                    <span className={classes.tableRowLabel}>
                                        Рок трајања
                                    </span>
                                    <TextField
                                        id="date"
                                        name="rokTrajanja"
                                        type="date"
                                        defaultValue={endDate.split('T')[0]}
                                        onChange={(event) => {
                                            if (event.target.valueAsDate) {
                                                setFieldValue(
                                                    'rokTrajanja',
                                                    event.target.valueAsDate
                                                )
                                            }
                                        }}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div> */}

                                    <label htmlFor="multi-files">
                                        <input
                                            style={{ display: 'none' }}
                                            id="multi-files"
                                            name="multi-files"
                                            disabled={
                                                contract.status === 'Završen' &&
                                                contract.datumZavrsetka &&
                                                true
                                            }
                                            type="file"
                                            accept=".doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            onChange={uploadFiles}
                                            multiple
                                        />
                                        <Button
                                            className={classes.uploadButton}
                                            component="span"
                                            endIcon={<AddIcon />}
                                            disabled={
                                                contract.status === 'Završen' &&
                                                contract.datumZavrsetka &&
                                                true
                                            }
                                        >
                                            Додајте документ
                                        </Button>
                                    </label>
                                    <div className={classes.files}>
                                        {contract.fajlovi.length > 0 && (
                                            <p>Документи</p>
                                        )}
                                        <div className={classes.oldFiles}>
                                            {contract.fajlovi.length > 0 &&
                                                files.map((dok) => (
                                                    <p
                                                        key={uuid()}
                                                        style={{
                                                            display: 'flex',
                                                            margin: '0',
                                                            padding: '0',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            title={
                                                                dok.originalnoIme
                                                            }
                                                        >
                                                            <span
                                                                style={{
                                                                    width: '80%',
                                                                }}
                                                            >
                                                                {dok
                                                                    .originalnoIme
                                                                    .length > 50
                                                                    ? `${dok.originalnoIme.slice(
                                                                          0,
                                                                          40
                                                                      )}...`
                                                                    : dok.originalnoIme}
                                                            </span>
                                                        </Tooltip>
                                                        <span>
                                                            <ClearIcon
                                                                className={
                                                                    classes.removeIcon
                                                                }
                                                                onClick={() =>
                                                                    removeFiles(
                                                                        dok._id
                                                                    )
                                                                }
                                                            />
                                                        </span>
                                                    </p>
                                                ))}
                                        </div>
                                        {selectedFiles.length > 0 && (
                                            <p>Нови документи</p>
                                        )}
                                        <div className={classes.newFiles}>
                                            {selectedFiles.length > 0 &&
                                                Array.from(selectedFiles).map(
                                                    (file) => (
                                                        <p
                                                            key={uuid()}
                                                            style={{
                                                                display: 'flex',
                                                                margin: '0',
                                                            }}
                                                        >
                                                            <Tooltip
                                                                title={
                                                                    file.name
                                                                }
                                                            >
                                                                <span
                                                                    style={{
                                                                        width: '80%',
                                                                    }}
                                                                >
                                                                    {file.name
                                                                        .length >
                                                                    40
                                                                        ? `${file.name.slice(
                                                                              0,
                                                                              40
                                                                          )}...`
                                                                        : file.name}
                                                                </span>
                                                            </Tooltip>
                                                            <span>
                                                                <ClearIcon
                                                                    className={
                                                                        classes.removeIcon
                                                                    }
                                                                    onClick={() => {
                                                                        removeNewAdded(
                                                                            file.name
                                                                        )
                                                                    }}
                                                                />
                                                            </span>
                                                        </p>
                                                    )
                                                )}
                                        </div>
                                    </div>
                                    <ButtonWrapper
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        disabled={
                                            isSubmitting ||
                                            (contract.status === 'Završen' &&
                                                contract.datumZavrsetka &&
                                                true)
                                        }
                                        startIcon={
                                            isSubmitting ? (
                                                <CircularProgress size="0.9rem" />
                                            ) : undefined
                                        }
                                        style={{
                                            height: 'unset',
                                            fontSize: '0.75rem',
                                            fontWeight: 'bold',
                                            margin: '5px 0',
                                        }}
                                    >
                                        Сачувај измене
                                    </ButtonWrapper>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                        onClick={() => {
                                            setModal(false)
                                            setSelectedFiles([])
                                        }}
                                    >
                                        Одустани
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <SimpleModal />
                )}
            </div>
        </>
    )

    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={() => setModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {id ? body : null}
            </Modal>
        </div>
    )
}

EditContract.propTypes = {
    id: PropTypes.string.isRequired,
    modalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    label: PropTypes.string,
    setRefreshState: PropTypes.func,
    setSeverityHandler: PropTypes.func,
    setSnackbarHandler: PropTypes.func,
}

EditContract.defaultProps = {
    modalOpen: false,
    setModal: () => {},
    label: '',
    setRefreshState: () => {},
    setSeverityHandler: () => {},
    setSnackbarHandler: () => {},
}
