import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import {
    Button,
    TextField,
    Snackbar,
    CircularProgress,
    FormHelperText,
} from '@material-ui/core'
import { Autocomplete, Alert } from '@material-ui/lab'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextfieldWrapper from '../../../components/FormsUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../../components/FormsUI/Button/ButtonWrapper'
import { axiosAuth as axios } from '../../../util/axios-instance'
import SimpleModal from '../../../components/Modal/Modal'
import EditSelects from '../../../components/FormsUI/Select/EditSelects'
import latToCyr from '../../../util/latToCyr'
import AddArea from '../AddArea'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 'auto',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
}))

export default function CreateLocation({
    modalOpen,
    setModal,
    setRefresh,
    setSeverityHandler,
    setSnackbarHandler,
    subcontracts,
}) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)
    const [groups, setGroups] = useState(false)
    const [contracts, setContracts] = useState([])
    const [priorities, setPriorities] = useState([])
    // const [priorityError, setPriorityError] = useState(false)
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('')

    // const [field, meta] = useField('prioritet')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const FORM_VALIDATION = Yup.object().shape({
        // )
        // .test((odgovoranIds) => {
        //     if (groups && odgovoranIds) {
        //         const selectedGroups = groups.filter((group) =>
        //             odgovoranIds.some((el) => el === group._id)
        //         )
        //         const subGroups = selectedGroups.filter(
        //             (el) => el.nadredjenaGrupa
        //         )
        //         const isSuperGroup = subGroups
        //             .filter((el) =>
        //                 selectedGroups.every(
        //                     (group) => group._id !== el.nadredjenaGrupa._id
        //                 )
        //             )
        //             .filter(
        //                 (superGroup) =>
        //                     !superGroup?.nadredjenaGrupa?.sveVideci
        //             )
        //         console.log(
        //             'Selektovane grupe u YUP',
        //             selectedGroups,
        //             'Podredjene grupe',
        //             subGroups,
        //             'Nadredjene u nizu',
        //             isSuperGroup
        //         )
        //         if (isSuperGroup.length > 0) {
        //             const missingGroups = isSuperGroup
        //                 .map(
        //                     (missingGroup) =>
        //                         missingGroup.nadredjenaGrupa.naziv
        //                 )
        //                 .join(', ')
        //             return new Yup.ValidationError(
        //                 `Морате додати надређене групе: ${missingGroups}`,
        //                 undefined,
        //                 'odgovoran'
        //             )
        //         }

        //         return true
        //     }

        //     return true
        // }
        // ),
        naziv: Yup.string().required('Поље је обавезно'),
        prioritet: Yup.string(),
        odgovoran: Yup.array().of(Yup.string()),
        ugovor: Yup.string(),
        prosecnaDuzinaRova: Yup.number().typeError('Морате унети број'),
        opstina: Yup.string(),
        brojDomacinstava: Yup.number().typeError('Морате унети број'),
        // ukupnaDuzinaRova: Yup.number().typeError('Морате унети број'),
        duzinaIzPrijave: Yup.number().typeError('Морате унети број'),
        // javniPoziv: Yup.string(),
        brojIndirektnoPokrivenihDomacinstava:
            Yup.number().typeError('Морате унети број'),
        brojIndirektnoPokrivenihSkola:
            Yup.number().typeError('Морате унети број'),
        brojSkola: Yup.number().typeError('Морате унети број'),
        brojDodatnihSkola: Yup.number().typeError('Морате унети број'),
        // brojDodatnihDomacinstava: Yup.number().typeError('Морате унети број'),
        odobrenjeZaRad: Yup.string(),
        usputnaNaselja: Yup.array().of(
            Yup.object().shape({
                naziv: Yup.string().required('Обавезно поље'),
                maticniBroj: Yup.number()
                    .typeError('Морате унети број')
                    .required('Обавезно поље'),
            })
        ),
        napomena: Yup.string(),
        geokoordinateOperatora: Yup.string(),
        geokoordinateObjekta: Yup.string(),
        duzinaOptickihTrasa: Yup.number().typeError('Морате унети број'),
        naselja: Yup.array().of(
            Yup.object().shape({
                naziv: Yup.string().required('Обавезно поље'),
                maticniBroj: Yup.number()
                    .typeError('Морате унети број')
                    .required('Обавезно поље'),
            })
        ),
    })

    const createLocation = async (values) => {
        // console.log(values)
        try {
            setRefresh(true)
            await axios.post('/api/location', {
                naziv: values.naziv,
                prioritet: values.prioritet,
                odgovoran: values.odgovoran,
                ugovor: values.ugovor,
                ugovorIzvodjac: values.ugovorIzvodjac,
                ugovorNadzor: values.ugovorNadzor,
                ugovorProjektant: values.ugovorProjektant,
                prosecnaDuzinaRova: values.prosecnaDuzinaRova,
                opstina: values.opstina,
                brojDomacinstava: values.brojDomacinstava,
                // ukupnaDuzinaRova: values.ukupnaDuzinaRova,
                duzinaIzPrijave: values.duzinaIzPrijave,
                // javniPoziv: values.javniPoziv,
                brojIndirektnoPokrivenihDomacinstava:
                    values.brojIndirektnoPokrivenihDomacinstava,
                brojIndirektnoPokrivenihSkola:
                    values.brojIndirektnoPokrivenihSkola,
                brojSkola: values.brojSkola,
                brojDodatnihSkola: values.brojDodatnihSkola,
                // brojDodatnihDomacinstava: values.brojDodatnihDomacinstava,
                odobrenjeZaRad: values.odobrenjeZaRad,
                usputnaNaselja: values.usputnaNaselja,
                napomena: values.napomena,
                geokoordinateOperatora: values.geokoordinateOperatora,
                geokoordinateObjekta: values.geokoordinateObjekta,
                duzinaOptickihTrasa: values.duzinaOptickihTrasa,
                naselja: values.naselja,
            })
            setModal(false)
            setSeverityHandler('success')
            setSnackbarHandler({
                open: true,
                message: 'Успешно сте направили нови кластер.',
            })
            setRefresh(false)
        } catch (error) {
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: error?.response?.data?.message
                    ? error.response.data.message
                    : 'Креирање кластера неуспешно.',
            })
        }
    }

    const getPriorities = async () => {
        try {
            const response = await axios.get('/api/priority')
            setPriorities(response.data.priorities)
        } catch (error) {
            console.log(error)
        }
    }

    const getAllGroups = async () => {
        try {
            // const response = await axios.get('/api/group?limit=50000')
            const svevidece = await axios.get('/api/group/sve-videci/false')
            setGroups(
                svevidece.data.groups.filter(
                    (group) =>
                        group.naziv !== 'Админ' && group.naziv !== 'Admin'
                )
            )
        } catch (error) {
            console.log(error)
        }
    }

    const getAllContracts = async () => {
        try {
            const response = await axios.get('/api/contract')
            setContracts(response.data.contracts)
        } catch (error) {
            console.log(error)
            // setContractsError()
        }
    }

    useEffect(() => {
        getAllGroups()
        getPriorities()
        getAllContracts()
    }, [])

    const body = (
        <>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <div style={modalStyle} className={classes.paper}>
                <h2 className={classes.title}>Нови кластер</h2>
                {groups ? (
                    <Formik
                        validateOnChange={false}
                        initialValues={{
                            naziv: '',
                            prioritet: '',
                            odgovoran: '',
                            ugovor: '',
                            ugovorProjektant: '',
                            ugovorNadzor: '',
                            ugovorIzvodjac: '',
                            prosecnaDuzinaRova: '',
                            opstina: '',
                            brojDomacinstava: '',
                            // ukupnaDuzinaRova: '',
                            duzinaIzPrijave: '',
                            // javniPoziv: '',
                            brojIndirektnoPokrivenihDomacinstava: '',
                            brojIndirektnoPokrivenihSkola: '',
                            brojSkola: '',
                            brojDodatnihSkola: '',
                            // brojDodatnihDomacinstava: '',
                            odobrenjeZaRad: '',
                            usputnaNaselja: [],
                            napomena: '',
                            geokoordinateOperatora: '',
                            geokoordinateObjekta: '',
                            duzinaOptickihTrasa: '',
                            naselja: [],
                        }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values) => createLocation(values)}
                    >
                        {({ setFieldValue, isSubmitting, errors, values }) => (
                            <Form>
                                <div className={classes.formDiv}>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Назив кластера
                                        </span>
                                        <TextfieldWrapper name="naziv" />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Приоритет
                                        </span>

                                        <EditSelects
                                            name="prioritet"
                                            options={priorities}
                                            label="Изаберите приоритет"
                                        />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Јавни позив
                                        </span>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <Select
                                                name="ugovor"
                                                native
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        'ugovor',
                                                        event.target.value
                                                    )
                                                }}
                                            >
                                                <option
                                                    aria-label="None"
                                                    value=""
                                                >
                                                    Изаберите јавни позив
                                                </option>
                                                {contracts
                                                    .filter(
                                                        (con) =>
                                                            con.status !==
                                                            'Završen'
                                                    )
                                                    .map((priority) => (
                                                        <option
                                                            key={priority._id}
                                                            value={priority._id}
                                                        >
                                                            {priority.naziv}
                                                        </option>
                                                    ))}
                                            </Select>
                                            {errors.prioritet ? (
                                                <FormHelperText>
                                                    {errors.prioritet}
                                                </FormHelperText>
                                            ) : (
                                                ''
                                            )}
                                        </FormControl>
                                    </div>

                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Уговор - извођачи
                                        </span>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <Select
                                                name="ugovorIzvodjac"
                                                native
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        'ugovorIzvodjac',
                                                        event.target.value
                                                    )
                                                }}
                                            >
                                                <option
                                                    aria-label="None"
                                                    value=""
                                                >
                                                    Изаберите уговор - извођачи
                                                </option>
                                                {subcontracts?.contractsIzv?.map(
                                                    (contract) => (
                                                        <option
                                                            key={contract._id}
                                                            value={contract._id}
                                                        >
                                                            {contract.naziv}
                                                        </option>
                                                    )
                                                )}
                                            </Select>
                                            {errors.prioritet ? (
                                                <FormHelperText>
                                                    {errors.prioritet}
                                                </FormHelperText>
                                            ) : (
                                                ''
                                            )}
                                        </FormControl>
                                    </div>

                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Уговор - пројектанти
                                        </span>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <Select
                                                name="ugovorProjektant"
                                                native
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        'ugovorProjektant',
                                                        event.target.value
                                                    )
                                                }}
                                            >
                                                <option
                                                    aria-label="None"
                                                    value=""
                                                >
                                                    Изаберите уговор -
                                                    пројектанти
                                                </option>
                                                {subcontracts?.contractsPro?.map(
                                                    (contract) => (
                                                        <option
                                                            key={contract._id}
                                                            value={contract._id}
                                                        >
                                                            {contract.naziv}
                                                        </option>
                                                    )
                                                )}
                                            </Select>
                                            {errors.prioritet ? (
                                                <FormHelperText>
                                                    {errors.prioritet}
                                                </FormHelperText>
                                            ) : (
                                                ''
                                            )}
                                        </FormControl>
                                    </div>

                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Уговор - надзор
                                        </span>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <Select
                                                name="ugovorNadzor"
                                                native
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        'ugovorNadzor',
                                                        event.target.value
                                                    )
                                                }}
                                            >
                                                <option
                                                    aria-label="None"
                                                    value=""
                                                >
                                                    Изаберите уговор - надзор
                                                </option>

                                                {subcontracts?.contractsNad?.map(
                                                    (contract) => (
                                                        <option
                                                            key={contract._id}
                                                            value={contract._id}
                                                        >
                                                            {contract.naziv}
                                                        </option>
                                                    )
                                                )}
                                            </Select>
                                            {errors.prioritet ? (
                                                <FormHelperText>
                                                    {errors.prioritet}
                                                </FormHelperText>
                                            ) : (
                                                ''
                                            )}
                                        </FormControl>
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Групе везане за кластер
                                        </span>
                                        {/* <p> {JSON.stringify(errors)}</p> */}
                                        <Autocomplete
                                            id="combo-box-demo"
                                            multiple
                                            disableClearable
                                            options={groups}
                                            getOptionLabel={(option) =>
                                                `${option.naziv}  (${latToCyr(
                                                    option.tip
                                                )})`
                                            }
                                            getOptionSelected={(
                                                option,
                                                value
                                            ) => option._id === value._id}
                                            onChange={(event, gr) => {
                                                setFieldValue(
                                                    'odgovoran',
                                                    gr.map((g) => g._id)
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Општина
                                        </span>
                                        <TextfieldWrapper name="opstina" />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Насеља у кластеру
                                        </span>
                                        {/* <TextfieldWrapper name="naselja" /> */}
                                        <AddArea
                                            name="naselja"
                                            values={values.naselja}
                                            isSubmitting={isSubmitting}
                                        />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Успутна насеља
                                        </span>
                                        {/* <TextfieldWrapper name="usputnaNaselja" /> */}
                                        <AddArea
                                            name="usputnaNaselja"
                                            values={values.usputnaNaselja}
                                            isSubmitting={isSubmitting}
                                            usputna
                                        />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Просечна дужина рова по насељу
                                        </span>
                                        <TextfieldWrapper name="prosecnaDuzinaRova" />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Број домаћинстава
                                        </span>
                                        <TextfieldWrapper name="brojDomacinstava" />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Број индиректно покривених
                                            домаћинстава
                                        </span>
                                        <TextfieldWrapper name="brojIndirektnoPokrivenihDomacinstava" />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Број школа
                                        </span>
                                        <TextfieldWrapper name="brojSkola" />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Број индиректно покривених школа
                                        </span>
                                        <TextfieldWrapper name="brojIndirektnoPokrivenihSkola" />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Број додатних школа
                                        </span>
                                        <TextfieldWrapper name="brojDodatnihSkola" />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Дужина из пријаве
                                        </span>
                                        <TextfieldWrapper name="duzinaIzPrijave" />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Укупна дужина оптичких траса
                                        </span>
                                        <TextfieldWrapper name="duzinaOptickihTrasa" />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Одобрење за рад
                                        </span>
                                        <TextfieldWrapper name="odobrenjeZaRad" />
                                    </div>

                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Напомена
                                        </span>
                                        <TextfieldWrapper name="napomena" />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Геокоординате тачке присуства
                                            оператора
                                        </span>
                                        <TextfieldWrapper
                                            name="geokoordinateOperatora"
                                            placeholder="дужина, ширина"
                                        />
                                    </div>
                                    <div className={classes.formField}>
                                        <span className={classes.tableRowLabel}>
                                            Геокоординате објекта
                                        </span>
                                        <TextfieldWrapper
                                            name="geokoordinateObjekta"
                                            placeholder="дужина, ширина"
                                        />
                                    </div>

                                    <ButtonWrapper
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        disabled={isSubmitting}
                                        startIcon={
                                            isSubmitting ? (
                                                <CircularProgress size="0.9rem" />
                                            ) : undefined
                                        }
                                        style={{
                                            // backgroundColor: '#337ab7',
                                            // color: 'white',
                                            // height: 'unset',
                                            // fontSize: '0.75rem',
                                            // // padding: '8px 12px',
                                            // fontWeight: 'bold',
                                            margin: '10px 0',
                                        }}
                                    >
                                        Направи кластер
                                    </ButtonWrapper>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                        onClick={() => setModal(false)}
                                    >
                                        Одустани
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <SimpleModal />
                )}
            </div>
        </>
    )

    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={() => setModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}

CreateLocation.propTypes = {
    modalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    setRefresh: PropTypes.func,
    setSeverityHandler: PropTypes.func,
    setSnackbarHandler: PropTypes.func,
    subcontracts: PropTypes.objectOf(PropTypes.any),
}

CreateLocation.defaultProps = {
    modalOpen: false,
    setModal: () => {},
    setRefresh: () => {},
    setSeverityHandler: () => {},
    setSnackbarHandler: () => {},
    subcontracts: {},
}
