import roles from './roles'
import AllLocations from '../pages/Locations/AllLocations/AllLocations'
import NotificationPage from '../pages/NotificationPage/NotificationPage'
import SingleLocation from '../pages/Locations/SingleLocation/SingleLocationFix'
import EditLocation from '../pages/Locations/EditLocation/EditLocation'
import CreateLocation from '../pages/Locations/CreateLocation/CreateLocation'
import EditRole from '../pages/Roles/EditRole'
import CreateRole from '../pages/Roles/CreateRole'

import RolesUserManagment from '../pages/Roles/RolesUserManagment'
import AllRoles from '../pages/Roles/AllRoles'
import AllContracts from '../pages/Contracts/AllContracts'
// import CreateContract from '../pages/Contracts/CreateContract'
// import EditContract from '../pages/Contracts/EditContract'
import SingleContract from '../pages/Contracts/SingleContract'
import Users from '../pages/Users/Users'
// import AllActivity from '../pages/Activity/AllActivity'
// import SingleActivity from '../pages/Activity/SingleActivity'
import AllGroups from '../pages/Groups/AllGroups'
import SingleGroup from '../pages/Groups/SingleGroup'
import SingleUser from '../pages/Users/SingleUser'
import AllLocationsStaff from '../pages/LocationStaff/AllLocations/AllLocationsStaff'
import SingleLocationStaff from '../pages/LocationStaff/SingleLocation/SingleLocationStaff'
import GroupsManagment from '../pages/Groups/GroupsManagment'
import MyProfile from '../pages/MyProfile/MyProfile'
import ActivityPerLocation from '../pages/ActivityPerLocation/ActivityPerLocation'
import InProgress from '../pages/InProgress'
import Logs from '../pages/Logs/Logs'
import PrioritiesTypes from '../pages/PrioritiesTypes/PrioritiesTypes'
import ContractsToLocations from '../pages/Contracts/ContractsToLocations'
import LocationToContract from '../pages/Locations/LocationToContract'
import Reports from '../pages/Reports/Reports'
import Activities from '../pages/Activities/Activities'
import Comments from '../pages/Comments/Comments'
import EmailConfiguration from '../pages/email/EmailConfiguration'
import SingleEmailConfiguration from '../pages/email/SingleEmailConfiguration'
// import ReportInProgress from '../pages/Reports/reports_components/ReportInProgress'
import Inventory from '../pages/Inventory/Inventory'
import LocationInventory from '../pages/Inventory/Tabs/Contracts/LocationInventory'
import InventoryLocations from '../pages/Inventory/Tabs/Contracts/InventoryLocations'
import ContractsIzvodjaci from '../pages/Contracts/Subcontracts/ContractsIzvodjaci'
import ContractsProjektanti from '../pages/Contracts/Subcontracts/ContractsProjektanti'
import ContractsNadzor from '../pages/Contracts/Subcontracts/ContractsNadzor'

const privateRoutesConfig = [
    {
        component: NotificationPage,
        path: '/',
        title: 'Obavestenja',
        permission: [roles.RUKOVODILAC, roles.ZAPOSLENI, roles.GOST],
    },
    {
        component: AllLocations,
        path: '/klasteri',
        title: 'Lokacije',
        permission: [roles.ADMIN, roles.RUKOVODILAC],
    },
    {
        component: SingleLocation,
        path: '/klaster/:id',
        title: 'Lokacija',
        permission: [roles.ADMIN, roles.RUKOVODILAC],
    },
    {
        component: EditLocation,
        path: '/izmeni-klaster/:id',
        title: 'Izmeni Lokaciju',
        permission: [roles.ADMIN, roles.RUKOVODILAC],
    },

    {
        component: CreateLocation,
        path: '/novi-klaster',
        title: 'Kreiraj Lokaciju',
        permission: [roles.ADMIN],
    },
    {
        component: PrioritiesTypes,
        path: '/sifrarnici',
        title: 'Prioriteti i tipovi modul',
        permission: [roles.ADMIN],
    },
    {
        component: Activities,
        path: '/aktivnosti',
        title: 'Aktivnosti',
        permission: [roles.ADMIN],
    },
    {
        component: EmailConfiguration,
        path: '/emailconfig',
        title: 'Email Configuration',
        permission: [roles.ADMIN],
    },
    {
        component: SingleEmailConfiguration,
        path: '/emailconfig/:id',
        title: 'Single SMTP',
        permission: [roles.ADMIN],
    },

    {
        component: AllRoles,
        path: '/role',
        title: 'Role',
        permission: [roles.ADMIN, roles.RUKOVODILAC],
    },
    {
        component: EditRole,
        path: '/izmeni-rolu/:id',
        title: 'Izmeni Rolu',
        permission: [roles.ADMIN, roles.RUKOVODILAC],
    },
    {
        component: CreateRole,
        path: '/nova-rola',
        title: 'Kreiraj Rolu',
        permission: [roles.ADMIN],
    },

    {
        component: RolesUserManagment,
        path: '/upravljanje-korisnicima',
        title: 'Upravljanje korisnicima',
        permission: [roles.ADMIN, roles.RUKOVODILAC],
    },
    {
        component: AllContracts,
        path: '/javni-pozivi',
        title: 'Javni pozivi',
        permission: [roles.ADMIN],
    },
    {
        component: ContractsIzvodjaci,
        path: '/ugovori-izvodjaci',
        title: 'Ugovori',
        permission: [roles.ADMIN, roles.ZAPOSLENI],
    },
    {
        component: ContractsProjektanti,
        path: '/ugovori-projektanti',
        title: 'Ugovori',
        permission: [roles.ADMIN, roles.ZAPOSLENI],
    },
    {
        component: ContractsNadzor,
        path: '/ugovori-nadzor',
        title: 'Ugovori',
        permission: [roles.ADMIN, roles.ZAPOSLENI],
    },
    {
        component: SingleContract,
        path: '/ugovor/:id',
        title: 'Detalji ugovora',
        permission: [roles.ADMIN],
    },
    {
        component: Users,
        path: '/korisnici',
        title: 'korisnici',
        permission: [roles.ADMIN, roles.RUKOVODILAC],
    },
    {
        component: SingleUser,
        path: '/korisnici/:id',
        title: 'Korisnik',
        permission: [roles.ADMIN, roles.RUKOVODILAC],
    },

    // {
    //     component: SingleActivity,
    //     path: '/aktivnosti/:id',
    //     title: 'detalji aktivnosti',
    //     permission: [roles.ADMIN],
    // },
    {
        component: AllGroups,
        path: '/grupe',
        title: 'Grupe',
        permission: [roles.ADMIN, roles.RUKOVODILAC],
    },
    {
        component: SingleGroup,
        path: '/grupa/:id',
        title: 'Grupa',
        permission: [roles.ADMIN, roles.RUKOVODILAC],
    },
    {
        component: AllLocationsStaff,
        path: '/klasteri-user',
        title: 'Lokacije korisnika',
        permission: [roles.ZAPOSLENI, roles.RUKOVODILAC],
    },
    {
        component: SingleLocationStaff,
        path: '/klasteri-user/:id',
        title: 'Lokacije jednog korisnika',
        permission: [roles.ZAPOSLENI, roles.RUKOVODILAC],
    },
    {
        component: ActivityPerLocation,
        path: '/aktivnost/:id',
        title: 'Aktivnost na lokaciji',
        permission: [roles.ZAPOSLENI, roles.RUKOVODILAC, roles.ADMIN],
    },

    {
        component: GroupsManagment,
        path: '/grupe/upravljanje-korisnicima/:naziv/:groupId',
        title: 'Upravljanje vezama korisnika i grupe',
        permission: [roles.ADMIN, roles.RUKOVODILAC],
    },
    {
        component: MyProfile,
        path: '/moj-nalog',
        title: 'Moj nalog',
        permission: [roles.ZAPOSLENI, roles.ADMIN, roles.RUKOVODILAC],
    },
    {
        component: Reports,
        path: '/izvestaji',
        title: 'Izvestaji',
        permission: [roles.ADMIN, roles.ZAPOSLENI, roles.RUKOVODILAC],
    },
    {
        component: InProgress,
        path: '/izvestaji/:naziv',
        title: 'Izvestaji',
        permission: [roles.RUKOVODILAC, roles.ZAPOSLENI],
    },
    {
        component: Logs,
        path: '/logovi',
        title: 'ILogovi',
        permission: [roles.ADMIN, roles.RUKOVODILAC, roles.ZAPOSLENI],
    },

    {
        component: Comments,
        path: '/komentari',
        title: 'komentari',
        permission: [roles.ADMIN, roles.ZAPOSLENI, roles.RUKOVODILAC],
    },
    {
        component: InProgress,
        path: '/logovi/:naziv',
        title: 'Logovi',
        permission: [roles.ZAPOSLENI, roles.ADMIN, roles.RUKOVODILAC],
    },
    {
        component: InProgress,
        path: '/grupe',
        title: 'Izvestaji',
        permission: [roles.RUKOVODILAC],
    },
    {
        component: ContractsToLocations,
        path: '/ugovor/povezivanje-sa-klasterima/:naziv/:id',
        title: 'Povezivanje sa lokacijama',
        permission: [roles.ADMIN],
    },
    {
        component: LocationToContract,
        path: '/klaster/povezivanje-sa-ugovorom/:id',
        title: 'Povezivanje sa ugovorom',
        permission: [roles.ADMIN],
    },
    {
        component: NotificationPage,
        path: '/aktivnosti-u-toku',
        title: 'Aktivnosti u toku',
        permission: [roles.ADMIN],
    },
    {
        component: Inventory,
        path: '/inventar',
        title: 'Inventar',
        permission: [roles.ADMIN, roles.ZAPOSLENI],
    },
    {
        component: LocationInventory,
        path: '/inventar/lokacija/:id',
        title: 'Inventar',
        permission: [roles.ADMIN, roles.ZAPOSLENI],
    },
    {
        component: InventoryLocations,
        path: '/inventar/ugovor/lokacije/:id',
        title: 'Inventar',
        permission: [roles.ADMIN, roles.ZAPOSLENI],
    },
]

export default privateRoutesConfig
