import React from 'react'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

const useStyles = makeStyles(() => ({
    backbutton: {
        backgroundColor: 'rgb(201,83,83)',
        color: 'white',
        '&:hover': {
            backgroundColor: 'rgb(201,83,83,0.9)',
        },
        padding: 8,
        margin: 8,
        boxShadow: 'none',
        letterSpacing: 0.5,
    },
    div: {
        width: 'max-content',
    },
    icon: {
        fontSize: '0.5rem',
    },
}))

const BackToButtonNotification = ({ label }) => {
    const classes = useStyles()
    const history = useHistory()
    return (
        <div className={classes.div}>
            <Button
                className={classes.backbutton}
                variant="contained"
                color="secondary"
                onClick={() => history.push(`/app`)}
                startIcon={<ArrowBackIosIcon className={classes.icon} />}
            >
                {label}
            </Button>
        </div>
    )
}

BackToButtonNotification.propTypes = {
    label: PropTypes.string.isRequired,
}

export default BackToButtonNotification
