import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Grid,
    Typography,
    makeStyles,
    Snackbar,
    CircularProgress,
    TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import TextfieldWrapper from '../FormsUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../FormsUI/Button/ButtonWrapper'
import { axiosAuth as axios } from '../../util/axios-instance'

const useStyles = makeStyles(() => ({
    tableRow: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 5,
    },
    tableRowLabel: {
        width: 380,
        '@media (max-width:400px)': {
            width: 250,
        },
    },
}))

const INITIAL_VALUES = {
    password: '',
    newPassword: '',
    confirmNewPassword: '',
}

const PASSWORD_VALIDATION = Yup.object().shape({
    password: Yup.string().required('Поље је обавезно'),
    newPassword: Yup.string().required('Поље је обавезно'),
    confirmNewPassword: Yup.string().oneOf(
        [Yup.ref('newPassword'), null],
        'Лозинке морају бити идентичне'
    ),
})

const ChangePasswordForm = () => {
    const classes = useStyles()
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const changePassword = async (values, { resetForm }) => {
        try {
            await axios.post('/api/user/change-password', {
                password: values.password,
                newPassword: values.newPassword,
                confirmNewPassword: values.confirmNewPassword,
            })
            resetForm(INITIAL_VALUES)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно сте изменили лозинку.',
            })
        } catch (error) {
            if (
                error.response.data.message.includes(
                    'is shorter than the minimum allowed'
                )
            ) {
                setSeverity('warning')
                setSnackbar({
                    open: true,
                    message: 'Лозинка мора имати 8 или више карактера.',
                })
            } else {
                setSeverity('warning')
                setSnackbar({
                    open: true,
                    message: error.response.data.message,
                })
            }
        }
    }

    return (
        <div>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Formik
                initialValues={{
                    ...INITIAL_VALUES,
                }}
                validationSchema={PASSWORD_VALIDATION}
                onSubmit={changePassword}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        <div className={classes.formDiv}>
                            <div className={classes.tableRow}>
                                <Grid container alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body1">
                                            Стара лозинка
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {/* <TextfieldWrapper
                                            name="password"
                                            type="password"
                                            variant="outlined"
                                            margin="dense"
                                            autoComplete="new-password"
                                        /> */}
                                        <TextField
                                            name="password"
                                            type="password"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            autoComplete="new-password"
                                            onChange={(event) => {
                                                setFieldValue(
                                                    'password',
                                                    event.target.value
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={classes.tableRow}>
                                <Grid container alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body1">
                                            Нова лозинка
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextfieldWrapper
                                            name="newPassword"
                                            type="password"
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={classes.tableRow}>
                                <Grid container alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body1">
                                            Потврда нове лозинке
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextfieldWrapper
                                            name="confirmNewPassword"
                                            type="password"
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>
                            </div>

                            <ButtonWrapper
                                type="submit"
                                disabled={isSubmitting}
                                startIcon={
                                    isSubmitting ? (
                                        <CircularProgress size="0.9rem" />
                                    ) : undefined
                                }
                                variant="contained"
                                style={{
                                    // backgroundColor: '#337ab7',
                                    // color: 'white',
                                    height: 'unset',
                                    fontSize: '1rem',
                                    // padding: '8px 12px',
                                    fontWeight: 'bold',
                                    margin: '10px 0',
                                }}
                            >
                                Сачувај измене
                            </ButtonWrapper>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ChangePasswordForm
