import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
    Grid,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Typography,
} from '@material-ui/core'
import uuid from 'react-uuid'
import { axiosAuth as axios } from '../../util/axios-instance'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
// import ReportInProgress from './reports_components/ReportInProgress'
import ReportActivitySum from './reports_components/ReportActivitySum'
// import ReportUserAndGroups from './reports_components/ReportUserAndGroups'
// import ReportLocations from './reports_components/ReportLocations'
import ReportsFinished from './reports_components/ReportsFinished'
import ReportPzi from './reports_components/ReportPzi'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 400,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    pageContainer: {
        padding: 50,
    },
    selectBody: {
        marginLeft: 12,
    },
}))

const Reports = () => {
    const classes = useStyles()
    // eslint-disable-next-line
    const [load, setLoad] = useState(false)
    // const [reports, setReports] = useState([])
    // const [totalResults, setTotalResults] = useState(0)
    // const [page, setPage] = useState(0)
    // const [rowsPerPage, setRowsPerPage] = useState(10)
    const [selectedReport, setSelectedReport] = useState('')
    // eslint-disable-next-line
    const [error, setError] = useState('')
    const myGroups = useSelector((state) => state.myrole)
    // const [groups, setGroups] = useState([])
    const [priority, setPriority] = useState([])

    // console.log(myGroups)

    const getOneGroup = async () => {
        try {
            setLoad(true)
            await axios.get(`/api/group/${myGroups.user.grupa._id}`)

            // setGroups([
            //     {
            //         _id: response.data.group._id,
            //     },
            //     ...response.data.group.podredjeneGrupe,
            // ])
            setLoad(false)
        } catch (err) {
            console.log(err)
            setTimeout(() => {
                setLoad(false)
            }, 400)
        }
    }

    const getPriorities = async () => {
        try {
            const { data } = await axios.get('/api/priority/')
            setPriority(data.priorities)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getOneGroup()
        getPriorities()
    }, [])

    const adminSelect = [
        {
            id: '1',
            model: 'Activity',
            status: 'Završena',
            name: 'Извештај завршених активности',
        },
        {
            id: '2',
            model: 'Activity',
            status: '',
            sum: true,
            name: 'Сумарни подаци о статусима активности',
        },
        {
            id: '3',
            model: 'Location',
            locations: true,
            status: '',
            sum: '',
            name: 'Преузимање фајлова',
            groups: '',
        },
    ]

    // useEffect(() => {
    //     setReports({})
    // }, [selectedReport])

    const handleChange = (event) => {
        setSelectedReport(event.target.value)
    }

    return (
        <div>
            <Grid container className={classes.pageContainer}>
                <Grid container justifyContent="center">
                    <PageTitle title="Извештаји" />
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    className={classes.selectBody}
                >
                    <Grid item>
                        <Typography variant="body1">
                            Изаберите жељени извештај
                        </Typography>
                    </Grid>
                    <Grid item>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                                извештаји
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={selectedReport}
                                onChange={handleChange}
                                label="извештаји"
                            >
                                <MenuItem value="Izvestaj">
                                    <em>избор извештаја</em>
                                </MenuItem>
                                {/* {myGroups?.user?.rola === 'Admin' ||
                                myGroups?.user?.rola === 'Rukovodilac'
                                    ?  */}
                                {adminSelect.map((item) => (
                                    <MenuItem value={item.id} key={uuid()}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    {selectedReport === '1' ? (
                        // <ReportFinished2 table={reports} load={load} />
                        <ReportsFinished />
                    ) : null}
                    {selectedReport === '2' && priority ? (
                        <ReportActivitySum flag={priority} />
                    ) : null}
                    {selectedReport === '3' ? <ReportPzi /> : null}
                </Grid>
            </Grid>
        </div>
    )
}

export default Reports
