import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import { Button, Grid } from '@material-ui/core'
// import GetAppIcon from '@material-ui/icons/GetApp'
// import download from 'downloadjs'
// import { axiosAuth as axios } from '../../../../util/axios-instance'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center',
        overflow: 'auto',
        maxHeight: '90%',
    },
    warning: {
        color: '#AE0F0F',
        fontSize: 50,
    },
    btnPreuzmi: {
        padding: 8,
        background: '#1E8637',
        color: '#FFFFFF',
    },
    listWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        background: '#f2f2f2',
        marginBottom: 10,
        borderRadius: 5,
    },
}))

export default function DataModal({ podaci, open, close }) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)

    // const deleteFile= async () => {
    //     try {
    //         setRefresh(true)
    //         await axios.delete(`/api/group/${id}`)
    //         setModal(false)
    //         setRefresh(false)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const fileList = () => {
    //     files.map((item) => <Grid item>{item.fajl}</Grid>)
    // }

    /* eslint-disable */
    // const downloadFile = async (fId, fName, mimetype) => {
    //     try {
    //         const response = await axios.get(
    //             `/api/files/activity/${locationId}/${id}/${fId}`,
    //             {
    //                 responseType: 'blob',
    //             }
    //         )

    //         return download(response.data, fName, mimetype)
    //     } catch (error) {}
    // }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h3>Доступни подаци активности</h3>

            <Grid container>
                {podaci &&
                    podaci.map((item, i) => (
                        <Grid item xs={12} className={classes.listWrap} key={i}>
                            {item.naziv}: {item.vrednost}
                        </Grid>
                    ))}
            </Grid>
            <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={close}
            >
                Затвори
            </Button>
        </div>
    )

    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="simple-modal-podaci"
            aria-describedby="simple-modal-description"
        >
            {podaci ? body : null}
        </Modal>
    )
}

DataModal.propTypes = {
    podaci: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
}

DataModal.defaultProps = {
    podaci: [],
}
