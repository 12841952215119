import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import {
    Container,
    Grid,
    Typography,
    Snackbar,
    CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import { axiosInstance } from '../../util/axios-instance'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import BackToButton from '../../components/FormsUI/Button/BackToButton'

const useStyles = makeStyles((theme) => ({
    formContainer: {
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    formWrapper: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(8),
        maxWidth: 500,
        margin: '0 auto',
    },
    alignAll: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    infoText: {
        fontFamily: 'Roboto',
        fontSize: '0.9rem',
        color: '#737373',
        textAlign: 'left',
    },
}))

const INITIAL_FORM_STATE = {
    email: '',
}

const FORM_VALIDATION = Yup.object().shape({
    email: Yup.string().email('Имејл није валидан.'),
})

const ForgotPassword = () => {
    const classes = useStyles()
    const history = useHistory()
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')
    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const handleSubmit = async (data, { setErrors, resetForm }) => {
        try {
            const response = await axiosInstance.post(
                '/api/user/reset-password',
                data
            )
            setSeverity('success')
            setSnackbar({
                open: true,
                message: response.data.message,
            })

            resetForm(INITIAL_FORM_STATE)

            setTimeout(() => {
                history.push(`/`)
            }, 2000)
        } catch (err) {
            const error = 'email'

            setSeverity('warning')
            setSnackbar({
                open: true,
                message: err.response.data.message,
            })

            setErrors({
                [error]:
                    err.response && err.response.data.message
                        ? err.response.data.message
                        : 'Грешка при ресетовању лозинке',
            })
        }
    }

    return (
        <Grid container className={classes.formContainer}>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Grid container>
                <BackToButton label="назад на пријаву корисника" />
            </Grid>
            <PageTitle title="Заборављена лозинка" />
            <Grid item xs={12}>
                <Container maxWidth="xs">
                    <div className={classes.formWrapper}>
                        <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE,
                            }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <PageTitle>
                                                Заборављена лозинка
                                            </PageTitle>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextfieldWrapper
                                                name="email"
                                                label="Имејл"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                className={classes.infoText}
                                            >
                                                Молимо вас унесите имејл адресу
                                                и потврдите, а ми ћемо вам
                                                послати имејл линк за ресетовање
                                                лозинке
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <ButtonWrapper
                                                variant="contained"
                                                disabled={isSubmitting}
                                                startIcon={
                                                    isSubmitting ? (
                                                        <CircularProgress size="0.9rem" />
                                                    ) : undefined
                                                }
                                                height={48}
                                                fontSize="1rem"
                                                type="submit"
                                            >
                                                Потврди
                                            </ButtonWrapper>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Container>
            </Grid>
        </Grid>
    )
}

export default ForgotPassword
