import React from 'react'
import { Field, FieldArray } from 'formik'

import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import { Button, Grid, makeStyles, Paper } from '@material-ui/core'

import PropTypes from 'prop-types'
import { TextField } from 'formik-material-ui'
import AddIcon from '@material-ui/icons/Add'
import { useParams } from 'react-router-dom'

/* eslint-disable */
const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: 'white',
    },
    errorColor: {
        color: theme.palette.error.main,
    },
    noWrap: {
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'nowrap',
        },
    },
    fieldWrapper: {
        marginRight: '1rem',
        marginBottom: '1rem',
    },

    add: {
        padding: '20px 0',
        background: 'white',
        margin: 0,
    },
    btnAdd: {
        margin: '10px 0',
        background: '#1E8637',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#4a9e5e',
            color: '#fff',
        },
    },
    addBtnWrapper: {
        marginBottom: '1rem',
    },
    addAll: {
        marginTop: 30,
    },

    delbtn: {
        color: '#a52408',
        fontSize: '1.7rem',
        '&:hover': {
            color: '#ffff',
            backgroundColor: '#f36344',
            borderRadius: '50%',
            cursor: 'pointer',
        },
    },
}))

const emptyData = {
    naziv: '',
    maticniBroj: '',
    // duzinaRova: '',
}

const AddArea = ({ name, values, isSubmitting, usputna }) => {
    const classes = useStyles()
    const { id } = useParams()
    // console.log(
    //     'da li ima PODATAKa ',
    //     activity?.podaci.length > 0,
    //     'Ako nema onda',
    //     emptyData
    // )

    // console.log(initialDataValues)
    return (
        <Grid container className={classes.container}>
            <Paper className={classes.add} elevation={0}>
                <Grid container justifyContent="flex-start">
                    <Grid item>
                        <FieldArray name={name}>
                            {({ push, remove }) => (
                                <>
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                    ></Grid>
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        className={classes.addBtnWrapper}
                                    >
                                        <Button
                                            variant="contained"
                                            onClick={() => push(emptyData)}
                                            disabled={isSubmitting}
                                            className={classes.btnAdd}
                                            endIcon={<AddIcon />}
                                        >
                                            додај насеље
                                        </Button>
                                    </Grid>

                                    {values?.map((data, idx) => (
                                        <Grid
                                            container
                                            key={idx}
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                xs={5}
                                                // className={
                                                //     classes.gridFlex
                                                // }
                                            >
                                                <Field
                                                    name={
                                                        usputna
                                                            ? `usputnaNaselja[${idx}].naziv`
                                                            : `naselja[${idx}].naziv`
                                                    }
                                                    component={TextField}
                                                    label="Назив насеља"
                                                    // className={
                                                    //     classes.fieldWrapper
                                                    // }
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Field
                                                    name={
                                                        usputna
                                                            ? `usputnaNaselja.${idx}.maticniBroj`
                                                            : `naselja.${idx}.maticniBroj`
                                                    }
                                                    component={TextField}
                                                    label="Матични број"
                                                    // className={
                                                    //     classes.fieldWrapper
                                                    // }
                                                    variant="outlined"
                                                />
                                            </Grid>

                                            {/* <Grid item xs={3}>
                                                <Field
                                                    name={`naselja.${idx}.duzinaRova`}
                                                    component={TextField}
                                                    label="Дужина рова"
                                                    // className={
                                                    //     classes.fieldWrapper
                                                    // }
                                                    variant="outlined"
                                                />
                                            </Grid> */}

                                            <Grid
                                                item
                                                xs={1}
                                                className={
                                                    classes.delBtnWrapper
                                                }
                                            >
                                                <HighlightOffIcon
                                                    className={classes.delbtn}
                                                    onClick={() => remove(idx)}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </>
                            )}
                        </FieldArray>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default AddArea

AddArea.propTypes = {
    // setSeverity: PropTypes.func.isRequired,
    // setSnackbar: PropTypes.func.isRequired,
    // handleNext: PropTypes.func.isRequired,
    // activity: PropTypes.objectOf(PropTypes.any).isRequired,
    values: PropTypes.objectOf(PropTypes.any),
    isSubmitting: PropTypes.bool,
    usputna: PropTypes.bool,
    // setError: PropTypes.func.isRequired,
}
