import React, { useEffect, useState } from 'react'
import {
    // Redirect,
    useHistory,
    useLocation,
    // useRouteMatch,
} from 'react-router-dom'
import { useDispatch } from 'react-redux'
// import useSWR from 'swr'
import { getAllowedRoutes } from '../util/getAllowedRoutes'
import privateRoutesConfig from './privateRoutesConfig'
import MapAllowedRoutes from './MapAllowedRoutes'
import { axiosAuth } from '../util/axios-instance'
import { myRole } from '../Redux/actions/userActions'

const PrivateRoutes = () => {
    // eslint-disable-next-line

    // const match = useRouteMatch('/app')
    const dispatch = useDispatch()
    const [allowedRoutes, setAllowedRoutes] = useState('')
    const history = useHistory()
    const location = useLocation()

    const isLoggedIn = async () => {
        try {
            const { data } = await axiosAuth('/api/user/my-profile')
            const rola = data.user.rola.toUpperCase()
            dispatch(myRole(data))

            // if (data.user.tabela.length > 0) {
            //     data.user.tabela.forEach((el) => {
            //         const tableSetting = el.tabela
            //         tableSetting.headers = el.headers
            //         window.sessionStorage.setItem(
            //             el.imeTabele,
            //             JSON.stringify(tableSetting)
            //         )
            //     })
            // }

            if (rola === 'ADMIN' && location.pathname === '/app')
                history.push('/app/aktivnosti-u-toku')
            // console.log(data, rola, 'test from private')
            // if (rola === 'GOST' && location.pathname === '/app') {
            //     useSWR(`/api/user/my-profile`, {
            //         refreshInterval: 3000,
            //     })
            // }

            if (data) {
                setAllowedRoutes(getAllowedRoutes(privateRoutesConfig, rola))
            }
        } catch (error) {
            setAllowedRoutes('')
            console.log(error, error.response)
            localStorage.removeItem('token')
            history.push('/')
        }

        // return !!localStorage.getItem('roles')
    }

    useEffect(() => {
        // if (location.pathname.includes('/app')) {
        isLoggedIn()
        // }
    }, [location])

    return (
        <>
            {allowedRoutes && (
                <MapAllowedRoutes
                    routes={allowedRoutes}
                    basePath="/app"
                    isAddNotFound
                />
            )}
        </>
    )
}

export default PrivateRoutes
