import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDebounce } from 'use-debounce'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import { Grid, Box, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import EditLocation from '../EditLocation/EditLocation'
// import SearchBar from '../../../components/SearchBar/SearchBar'
// import SelectWrapper from '../../../components/FormsUI/Select/SelectWrapper'
import { axiosAuth as axios } from '../../../util/axios-instance'
import AdminTable from '../../../components/tables/AdminTable'
import SimpleModal from '../../../components/Modal/Modal'
import CreateLocation from '../CreateLocation/CreateLocation'
import DeleteLocation from '../DeleteLocation'
import AddButton from '../../../components/FormsUI/Button/AddButton'
import latToCyr from '../../../util/latToCyr'
import InfoPanel from '../../../components/FormsUI/Info/InfoPanel'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    createbutton: {
        padding: 8,
        margin: '20px 0px',
    },
    root: {
        margin: 12,
        textAlign: 'left',
    },
    table: {
        margin: 8,
    },
    editButton: {
        backgroundColor: '#059D2A',
        color: 'white',
        '&:hover': {
            background: '#059D2A',
        },
        border: '2px solid white',
    },
    deleteButton: {
        backgroundColor: '#D86565',
        color: 'white',
        '&:hover': {
            background: '#D86565',
        },
        border: '2px solid white',
    },
    buttonW: {
        margin: '1rem 0',
    },
}))

const AllLocations = () => {
    const history = useHistory()
    const classes = useStyles()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [locations, setLocations] = useState([])
    const [totalResults, setTotalResults] = useState(0)
    const [load, setLoad] = useState(false)
    const [createOpen, setCreateOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [editId, setEditId] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [refreshState, setRefreshState] = useState(false)
    const [subcontracts, setSubcontracts] = useState('')

    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    const [searchDebounced] = useDebounce(search, 1000)
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const getSubcontracts = async () => {
        try {
            const izv = await axios.get('/api/contractIzv?page=1&limit=1000')
            const nad = await axios.get('/api/contractNad?page=1&limit=1000')
            const pro = await axios.get('/api/contractPro?page=1&limit=1000')
            setSubcontracts({
                contractsIzv: izv.data.contractsIzv,
                contractsNad: nad.data.contractsNad,
                contractsPro: pro.data.contractsPro,
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getAllLocations = async () => {
        try {
            setLoad(true)
            const url =
                searchDebounced.eng === ''
                    ? `/api/location?page=${page + 1}&limit=${rowsPerPage}`
                    : `/api/search/location?eng=${searchDebounced.eng}&srb=${
                          searchDebounced.srb
                      }&page=${page + 1}&limit=${rowsPerPage}`
            // console.log('URL za sve lokacije', url)
            const { data } = await axios.get(url)
            // console.log(data)

            const svevidece = await axios.get('/api/group/sve-videci/true')
            const svevideceArr = []
            svevidece.data.groups.map((el) => svevideceArr.push(el))

            const tableData =
                data &&
                data.locations.reduce((acc, currentEl) => {
                    const groupArray = [...currentEl.odgovoran, ...svevideceArr]
                        .filter((el) => el.tip.includes('Izvođač'))
                        .map((el) => el.naziv)
                        .join(', ')

                    const act = data.aktivne.filter(
                        (a) => currentEl._id === a.lokacija
                    )

                    const addElement = {
                        id: currentEl._id,
                        naziv: currentEl.naziv,
                        prioritet: currentEl?.prioritet?.naziv,
                        nazivUgovora: currentEl?.ugovor?.naziv,
                        ugovor: currentEl?.ugovor ? currentEl.ugovor : null,
                        odgovoran: groupArray,
                        status: act[0]?.kratakNaziv
                            ? `${latToCyr(currentEl.status)} - ${
                                  act[0].kratakNaziv
                              }`
                            : latToCyr(currentEl.status),
                        // status: currentEl.status,
                    }
                    acc.push(addElement)
                    return acc
                }, [])

            setLoad(false)
            setLocations(tableData)

            const dataLength = parseInt(data.totalResults, 10)
            setTotalResults(dataLength)
        } catch (error) {
            console.log(error)
            setLoad(true)
            setTimeout(() => {
                setLoad(false)
            }, 400)
        }
    }

    const setRefresh = (state) => {
        setRefreshState(state)
    }

    useEffect(() => {
        getSubcontracts()
    }, [])

    useEffect(() => {
        if (!refreshState) getAllLocations()
    }, [page, rowsPerPage, refreshState, searchDebounced.eng])

    const actionBodyTemplate = () => (
        <Grid key="edit" container spacing={2}>
            <Grid item xs={6} className={classes.buttonWrapper}>
                <Button name="izmeni" fullWidth className={classes.editButton}>
                    {' '}
                    Измени
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button
                    name="obrisi"
                    color="secondary"
                    fullWidth
                    className={classes.deleteButton}
                >
                    {' '}
                    Обриши
                </Button>
            </Grid>
        </Grid>
    )

    const rowClass = (rowData) => {
        // rowData.data.ugovor (datumPocetka, rokTrajanja)
        // const startDate = new Date(rowData?.data?.ugovor?.datumPocetka).getDate()
        // const pocetak = new Date(rowData?.ugovor?.datumPocetka)
        const start = new Date(rowData?.ugovor?.datumPocetka)
        const zavrsetak = start
        const rok = rowData?.ugovor?.rokTrajanja
        zavrsetak.setDate(new Date(start).getDate() + rok)
        const danas = new Date()
        // const brojDanaDoRoka = zavrsetak.getDate() - danas.getDate()
        const brojDanaDoRoka = moment
            .duration(moment(zavrsetak).diff(moment(danas)))
            .asDays()
        const x = brojDanaDoRoka / rok
        const statusUgovora = rowData?.ugovor?.status
        const datumZavrsetka = rowData?.ugovor?.datumZavrsetka
            ? rowData.ugovor.datumZavrsetka
            : null

        if (brojDanaDoRoka > 0 && x > 0.1 && statusUgovora === 'U toku') {
            return { blueRow: true }
        }
        if (brojDanaDoRoka > 0 && x < 0.1 && statusUgovora === 'U toku') {
            return { orangeRow: true }
        }
        if (brojDanaDoRoka < 0 && statusUgovora === 'U toku') {
            return { redRow: true }
        }
        if (
            statusUgovora === 'Završen' &&
            new Date(zavrsetak) > new Date(datumZavrsetka)
        ) {
            return { greenRow: true }
        }
        if (
            statusUgovora === 'Završen' &&
            new Date(datumZavrsetka) > new Date(zavrsetak)
        ) {
            return { greenRedRow: true }
        }

        return { white: true }
    }

    const onRowClick = (rowData) => {
        if (
            rowData.originalEvent.target.nodeName === 'TD' ||
            rowData.originalEvent.target.nodeName === 'DIV'
        ) {
            history.push(`/app/klaster/${rowData.data.id}`, {
                from: 'LOKACIJE',
            })
            // console.log('single')
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'izmeni'
        ) {
            setEditOpen(true)
            setEditId(rowData.data.id)
            // console.log('edit')
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'obrisi'
        ) {
            setDeleteOpen(true)
            setDeleteId(rowData.data.id)
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'ugovori'
        ) {
            history.push(
                `/app/klaster/povezivanje-sa-ugovorom/${rowData.data.id}`
            )
        }
    }

    const setCreateModal = (state) => {
        setCreateOpen(state)
    }

    const setEditModal = (state) => {
        setEditOpen(state)
    }

    const setDeleteModal = (state) => {
        setDeleteOpen(state)
    }

    const handleAddLocation = () => {
        setCreateOpen(true)
    }

    const setSeverityHandler = (state) => {
        setSeverity(state)
    }

    const setSnackbarHandler = (state) => {
        setSnackbar(state)
    }

    return (
        <div className={classes.root}>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {createOpen ? (
                <CreateLocation
                    modalOpen={createOpen}
                    setModal={setCreateModal}
                    setRefresh={setRefresh}
                    setSeverityHandler={setSeverityHandler}
                    setSnackbarHandler={setSnackbarHandler}
                    subcontracts={subcontracts}
                />
            ) : null}
            {editOpen && editId.length > 0 ? (
                <EditLocation
                    id={editId}
                    modalOpen={editOpen}
                    setModal={setEditModal}
                    setRefresh={setRefresh}
                    setSeverityHandler={setSeverityHandler}
                    setSnackbarHandler={setSnackbarHandler}
                    subcontracts={subcontracts}
                />
            ) : null}
            {deleteOpen && deleteId.length > 0 ? (
                <DeleteLocation
                    id={deleteId}
                    modalOpen={deleteOpen}
                    setModal={setDeleteModal}
                    setRefresh={setRefresh}
                    setSeverityHandler={setSeverityHandler}
                    setSnackbarHandler={setSnackbarHandler}
                />
            ) : null}

            <div className={classes.table}>
                {/* <Grid container justifyContent="center">
                    <PageTitle>локације</PageTitle>
                </Grid> */}
                <Box className={classes.buttonW}>
                    <AddButton
                        label="НАПРАВИ НОВИ КЛАСТЕР"
                        click={handleAddLocation}
                    />
                </Box>
                {/* <Button
                    className={classes.createbutton}
                    variant="contained"
                    color="primary"
                    onClick={() => setCreateOpen(true)}
                    endIcon={<AddIcon />}
                >
                    НАПРАВИ НОВУ ЛОКАЦИЈУ
                </Button> */}
                {load ? (
                    <SimpleModal />
                ) : (
                    <AdminTable
                        model="location"
                        rowClass={rowClass}
                        data={locations}
                        title="Кластери"
                        totalResults={totalResults}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        name="locationTable"
                        onRowClick={onRowClick}
                        tableHeaders={[
                            {
                                field: 'naziv',
                                header: 'Назив кластера',
                            },
                            { field: 'prioritet', header: 'Приоритет' },
                            { field: 'odgovoran', header: 'Одговоран Извођач' },
                            { field: 'status', header: 'Статус' },
                            { field: 'nazivUgovora', header: 'Јавни позив' },
                            { field: 'edit', header: '' },
                        ]}
                        setSearch={setSearch}
                        search={search}
                        actionBodyTemplate={actionBodyTemplate}
                        editColumnwidth={300}
                        withImport
                        sortField=""
                        exportFileName="Кластери"
                    />
                )}
            </div>
            <InfoPanel />
        </div>
    )
}

export default AllLocations
