import React from 'react'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'

const AddButton = ({ label, click }) => (
    <Button
        variant="contained"
        onClick={click}
        color="primary"
        endIcon={<AddIcon />}
        style={{ padding: '8px 16px' }}
    >
        {label}
    </Button>
)

AddButton.propTypes = {
    label: PropTypes.string.isRequired,
    click: PropTypes.func.isRequired,
}

export default AddButton
