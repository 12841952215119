import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Snackbar, Button } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import useSWR from 'swr'

import { Alert } from '@material-ui/lab'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'

// import { axiosInstance } from '../../util/axios-instance'
// import AutoCompleteWrapper from "../../components/FormsUI/Select/AutoCompleteWrapper";

import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
import CheckboxWrapper from '../../components/FormsUI/Checkbox/CheckboxWrapper'
import DeleteEmailServer from './DeleteEmailServer'
import removeEmptyKeys from '../../util/removeEmptyStringKeys'
import { axiosAuth } from '../../util/axios-instance'
import BackToButton from '../../components/FormsUI/Button/BackToButton'

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(8),
        maxWidth: 600,
        justifyContent: 'center',
    },
    gridAlign: {
        minHeight: '70vh',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    checkboxWrapper: {
        textAlign: 'start',
    },
    deleteButton: {
        margin: '16px 0',
    },
    deleteButtonColor: {
        backgroundColor: '#D86565',
        color: 'white',
        '&:hover': {
            backgroundColor: '#9c3333',
        },
    },
    backButton: {
        justifySelf: 'start',
    },
}))

const SingleEmailConfiguration = () => {
    const { id } = useParams()
    const history = useHistory()
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: 'default',
    })
    // eslint-disable-next-line
    const [severity, setSeverity] = useState('info')

    const [openDeleteEmailServerModal, setDeleteEmailServerModal] =
        useState(false)
    const handleOpenDeleteEmailServerModal = () => {
        setDeleteEmailServerModal(true)
    }
    const handleClose = () => {
        setDeleteEmailServerModal(false)
    }

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }
    const classes = useStyles()

    const { data, isValidating } = useSWR(`/api/smtp/${id}`)

    const INITIAL_FORM_STATE = {
        host: '',
        port: '',
        email: '',
        password: '',
        inUse: !data ? false : data?.smtp?.inUse,
    }

    const FORM_VALIDATION = Yup.object().shape({
        host: Yup.string().required('Обавезно поље'),
        port: Yup.number().required('Обавезно поље'),
        email: Yup.string()
            .email('Неисправна е-пошта')
            .required('Обавезно поље'),
        password: Yup.string(),
    })

    const handleEmailConfig = async (emailConfiguration, { resetForm }) => {
        const filteredEmailConfiguration = removeEmptyKeys(emailConfiguration)

        try {
            await axiosAuth.patch(`/api/smtp/${id}`, filteredEmailConfiguration)

            resetForm(INITIAL_FORM_STATE)
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно промењен СМТП',
            })
            setTimeout(() => {
                history.push(`/app/emailconfig`)
            }, 1000)
        } catch (err) {
            console.log(err.response)

            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Неуспешно промењен СМТП!',
            })
        }
    }

    return (
        <Container>
            <Grid container>
                <Grid item className={classes.backButton}>
                    <BackToButton label="Назад на преглед свих протокола" />
                </Grid>
                <Snackbar
                    open={snackbar.open}
                    onClose={handleSnackBarOpen}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={2000}
                    key="key"
                >
                    <Alert onClose={handleSnackBarOpen} severity={severity}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
                <Grid container justifyContent="center">
                    {data && (
                        <Grid item xs={6}>
                            <div className={classes.formWrapper}>
                                <Formik
                                    initialValues={{
                                        ...INITIAL_FORM_STATE,
                                    }}
                                    validationSchema={FORM_VALIDATION}
                                    onSubmit={handleEmailConfig}
                                >
                                    {({ isSubmitting, values }) => (
                                        <Form>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <PageTitle title="Конфигурација Е-поште" />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextfieldWrapper
                                                        name="host"
                                                        label="Хост"
                                                        variant="outlined"
                                                        value={
                                                            isValidating
                                                                ? ''
                                                                : data?.smtp
                                                                      .host
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextfieldWrapper
                                                        name="port"
                                                        label="Порт"
                                                        type="number"
                                                        variant="outlined"
                                                        value={
                                                            isValidating
                                                                ? ''
                                                                : data?.smtp
                                                                      .port
                                                        }
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextfieldWrapper
                                                        name="email"
                                                        label="Е-пошта"
                                                        variant="outlined"
                                                        value={
                                                            isValidating
                                                                ? ''
                                                                : data?.smtp
                                                                      .email
                                                        }
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextfieldWrapper
                                                        name="password"
                                                        label="Лозинка"
                                                        type="password"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <CheckboxWrapper
                                                        name="inUse"
                                                        label="Користи"
                                                        currentValue={
                                                            values.inUse
                                                        }
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <ButtonWrapper
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                        variant="contained"
                                                    >
                                                        Потврди
                                                    </ButtonWrapper>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.deleteButton}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            color="secondary"
                                            onClick={
                                                handleOpenDeleteEmailServerModal
                                            }
                                        >
                                            Обриши
                                        </Button>
                                    </Grid>
                                    <DeleteEmailServer
                                        open={openDeleteEmailServerModal}
                                        close={handleClose}
                                        setSeverity={setSeverity}
                                        setSnackbar={setSnackbar}
                                    />
                                </Grid>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Container>
    )
}

export default SingleEmailConfiguration
