import React, { useEffect, useState } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { axiosAuth as axios } from '../../util/axios-instance'
import SimpleModal from '../../components/Modal/Modal'
import BackToButton from '../../components/FormsUI/Button/BackToButton'
import latToCyr from '../../util/latToCyr'

const useStyles = makeStyles(() => ({
    root: {
        margin: 12,
    },
    table: {
        borderCollapse: 'collapse',
        margin: 8,
    },
    th: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        backgroundColor: '#417AB4',
        color: 'white',
        width: 300,
    },
    td: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        width: 300,
    },
    contractTitle: {
        textAlign: 'left',
        margin: '20px 8px',
        fontSize: 25,
        color: '#417AB4',
        display: 'flex',
        alignItems: 'flex-end',
    },
}))

const SingleGroup = () => {
    const classes = useStyles()
    const [group, setGroup] = useState(false)
    const [nadredjena, setNadredjena] = useState(false)
    const [podredjene, setPodredjene] = useState(false)
    const { id } = useParams()
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        if (location?.state?.from !== 'GRUPE') history.push('/app/grupe/')
    }, [])

    const getGroup = async () => {
        try {
            const response = await axios.get(`/api/group/${id}`)
            if (response.status === 200) {
                setGroup(response.data.group)

                if (response.data.group.nadredjenaGrupa) {
                    setNadredjena(response.data.group.nadredjenaGrupa)
                }
                if (response.data.group.podredjeneGrupe) {
                    setPodredjene(response.data.group.podredjeneGrupe)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getGroup()
    }, [])

    return (
        <div className={classes.root}>
            <BackToButton label="Назад на листу свих група" />

            <div className={classes.contractTitle}>
                <span>Група {group.naziv}</span>
            </div>
            {group && group.naziv ? (
                <>
                    <table className={classes.table}>
                        <tbody>
                            <tr>
                                <th className={classes.th}>Назив</th>
                                <td className={classes.td}>{group.naziv}</td>
                            </tr>
                            <tr>
                                <th className={classes.th}>Кратак назив</th>
                                <td className={classes.td}>
                                    {group.kratakNaziv}
                                </td>
                            </tr>
                            <tr>
                                <th className={classes.th}>Тип</th>
                                <td className={classes.td}>
                                    {latToCyr(group.tip)}
                                </td>
                            </tr>
                            {nadredjena && nadredjena.naziv ? (
                                <tr>
                                    <th className={classes.th}>
                                        Надређена група
                                    </th>
                                    <td className={classes.td}>
                                        {nadredjena.naziv}
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </table>

                    <div className={classes.contractTitle}>
                        <span>Подређене групе</span>
                    </div>

                    <table className={classes.table}>
                        <tbody>
                            <tr>
                                <th className={classes.th}>Назив</th>
                                <th className={classes.th}>Кратак назив</th>
                                <th className={classes.th}>Тип</th>
                            </tr>
                            {podredjene.length > 0 ? (
                                podredjene.map((pod) => (
                                    <tr key={pod._id}>
                                        <td className={classes.td}>
                                            {pod.naziv}
                                        </td>
                                        <td className={classes.td}>
                                            {pod.kratakNaziv}
                                        </td>
                                        <td className={classes.td}>
                                            {latToCyr(pod.tip)}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr className={classes.td}>
                                    <td>Нема подређених група</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>
            ) : (
                <SimpleModal />
            )}

            {/* <Example
                title="Choose brands (3 max)"
                cardContents={genres}
                multiple
                maxSelectable={3}
            /> */}
        </div>
    )
}

export default SingleGroup
