import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import WarningIcon from '@material-ui/icons/Warning'

import { makeStyles } from '@material-ui/core/styles'
import { useParams, useHistory } from 'react-router-dom'
import {
    // Box,
    Button,
    CircularProgress,
    // Grid,
    // Typography,
    // useMediaQuery,
    // useTheme,
} from '@material-ui/core'
import { axiosAuth } from '../../util/axios-instance'
// import { axiosAuth } from '../../util/axios-instance'
// import { useHistory } from 'react-router-dom'
// import { Formik, Form } from 'formik'
// import * as Yup from 'yup'

// import TextfieldWrapper from '../../../components/FormsUI/Textfield/TextfieldWrapper'

// import SelectWrapper from '../../../components/FormsUI/Select/SelectWrapper'
// import ButtonWrapper from '../../../components/FormsUI/Button/ButtonWrapper'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center',
    },
    warning: {
        color: '#AE0F0F',
        fontSize: 50,
    },
}))

const DeleteEmailServer = ({
    open,
    close,

    setSeverity,
    setSnackbar,

    // reload,
}) => {
    const classes = useStyles()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const history = useHistory()

    const { id } = useParams()
    // const matches = useMediaQuery(theme.breakpoints.down(600))
    const [modalStyle] = useState(getModalStyle)

    const handleDeleteEmailServer = async () => {
        try {
            setIsSubmitting(true)
            await axiosAuth.delete(`/api/smtp/${id}`)

            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно сте обрисали СМТП сервер.',
            })
            close()

            setIsSubmitting(false)
            history.push(`/app/emailconfig`)
        } catch (err) {
            console.log(err.response.data.message)

            setSeverity('warning')
            setSnackbar({
                open: true,
                message: err.response?.data?.message
                    ? err.response.data.message
                    : 'СМТП сервер неуспешно обрисан!',
            })
        }
        setIsSubmitting(false)
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <WarningIcon className={classes.warning} />
            <h3>Да ли сте сигурни да желите да обришете СМТП сервер?</h3>

            <Button
                variant="contained"
                color="secondary"
                fullWidth
                style={{ margin: '10px 0' }}
                onClick={handleDeleteEmailServer}
                disabled={isSubmitting}
                startIcon={
                    isSubmitting ? (
                        <CircularProgress size="0.9rem" />
                    ) : undefined
                }
            >
                Обриши
            </Button>

            <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={close}
            >
                Одустани
            </Button>
        </div>
    )

    return (
        <Modal
            aria-labelledby="DeleteEmailServer"
            aria-describedby="DeleteEmailServer"
            className={classes.modal}
            open={open}
            onClose={close}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 1000,
            }}
        >
            <div>{body}</div>
        </Modal>
    )
}

export default DeleteEmailServer

DeleteEmailServer.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setSeverity: PropTypes.func.isRequired,

    // reload: PropTypes.func.isRequired,
}
