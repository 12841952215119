import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { Button, Collapse, Grid, Snackbar, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MultiSelect } from 'primereact/multiselect'
import TablePagination from '@material-ui/core/TablePagination'
import DownloadSharpIcon from '@material-ui/icons/DownloadSharp'
import SaveIcon from '@material-ui/icons/Save'
import RestoreIcon from '@material-ui/icons/Restore'
import SettingsIcon from '@material-ui/icons/Settings'
import { Alert } from '@material-ui/lab'
import { trigger } from 'swr'
import uuid from 'react-uuid'

import ButtonImportXMLS from '../FormsUI/Button/Buttons-ImportExports/ButtonImportCSV-XMLS'

import ExportModal from '../Modal/ExportModal'
import './table.css'
import SearchBar from '../SearchBar/SearchBar'
import PageTitle from '../FormsUI/PageTitle/PageTitle'
import { axiosAuth } from '../../util/axios-instance'

const useStyles = makeStyles(() => ({
    tableHeader: {
        fontSize: '1rem',
        overflowX: 'hidden',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    buttonColor: {
        color: '#1E8637',
    },
    exportButtons: {
        minWidth: '200px',
        margin: '0 5px',
    },
    icon: {
        marginLeft: 5,
    },
    speedDial: {
        minWidth: '200px',
        height: '50px',
        borderRadius: '0px',
    },
    multiSelect: {
        marginTop: 'auto',
        position: 'relative',
    },
    settingsWrapper: {
        minWidth: 160,
    },
    settings: {
        margin: 5,
        color: '#4e5054',
        opacity: 0.9,
    },

    tableCell: {
        width: '100%',

        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        wordWrap: 'break-word',
    },
    saveTable: {
        margin: 5,
        color: '#4e5054',
        fontSize: '2rem',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.7,
        },
    },
    importButton: {
        minWidth: '230px',

        background: '#1E8637',
        '&:hover': {
            background: '#059D2A',
        },
    },
}))

const UserTable = ({
    data,
    noData,
    totalResults,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    name,
    onRowClick,
    tableHeaders,
    // editColumnwidth,
    rowClass,
    model,
    setSearch,
    search,
    withSearch,
    withExport,
    withImport,
    title,
    withPagination,
    sortField,
    selectedActivity,
    selectedItem,
    exportFileName,
    resetTable,
}) => {
    const myProfile = useSelector((state) => state.myrole)
    const myTables = myProfile.user.tabela
    const tableSetting = myTables.find((el) => el.imeTabele === name)
    const classes = useStyles()
    const dt = useRef(null)
    const [selectedColumns, setSelectedColumns] = useState(
        tableSetting && name !== 'reportsum1'
            ? tableSetting.headers.map(({ _id, ...keepAttrs }) => keepAttrs)
            : tableHeaders
    )
    // eslint-disable-next-line
    const [cols, setCols] = useState(tableHeaders)

    const [save, setSave] = useState(false)

    const [openExportModal, setOpenExportModal] = useState(false)
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: 'default',
    })

    const [severity, setSeverity] = useState('info')
    const configButton = {
        variant: 'contained',
        color: 'primary',
        fullWidth: true,
    }

    const handleOpenExportModal = () => {
        setOpenExportModal(true)
    }

    const handleCloseModals = () => {
        setOpenExportModal(false)
    }
    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const onColumnToggle = (event) => {
        const columnsSelected = event.value

        if (columnsSelected.length > selectedColumns.length) {
            setSelectedColumns([])
            setTimeout(() => {
                setSelectedColumns(tableHeaders)
            }, 10)
            return
        }

        const orderedSelectedColumns = cols.filter((col) =>
            columnsSelected.some((sCol) => sCol.field === col.field)
        )
        setSelectedColumns(orderedSelectedColumns)
    }

    const handleSaveTableSettings = async () => {
        const tableSettings = {
            imeTabele: name,
            tabela: JSON.parse(localStorage.getItem(name)),
            headers: selectedColumns,
        }

        try {
            await axiosAuth.post(`/api/user/edit-columns`, tableSettings)

            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно сте сачували табелу.',
            })
            trigger('/api/user/my-profile')
            setSave(false)
        } catch (error) {
            console.log(error.response)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Табела неуспешно сачувана!',
            })
        }
    }

    useEffect(() => {
        if (name === 'reportsum1') {
            setSelectedColumns([])
            setTimeout(() => {
                setSelectedColumns(tableHeaders)
            }, 10)
        }
    }, [resetTable])

    const onCustomSaveState = (state) => {
        // console.log('Admin table state je ', state)

        setSave(true)

        return localStorage.setItem(name, JSON.stringify(state))
    }

    const onCustomRestoreState = () => {
        if (myTables.length > 0 && tableSetting) {
            return tableSetting.tabela
        }
        return localStorage.getItem(name)
    }

    useEffect(() => {
        onCustomRestoreState()
    }, [dt.current])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    // const toCapitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1)

    const restoreTable = () => {
        setSelectedColumns([])
        setTimeout(() => {
            setSelectedColumns(tableHeaders)
        }, 10)
    }

    const header = (
        <Grid container justifyContent="space-between">
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>

            <Grid item className={classes.multiSelect}>
                <Grid
                    container
                    alignItems="center"
                    className={classes.settingsWrapper}
                >
                    <Grid item>
                        <MultiSelect
                            value={selectedColumns}
                            options={cols}
                            optionLabel="header"
                            onChange={onColumnToggle}
                            style={{
                                width: '3rem',
                                fontSize: '0.9rem',
                                height: '3rem',
                                opacity: 0,
                                position: 'absolute',
                                left: 1,
                                top: 0,
                                zIndex: 999,
                            }}
                            placeholder="Прикажи/склони колону"
                            fixedPlaceholder="true"
                        />

                        <SettingsIcon
                            fontSize="large"
                            className={classes.settings}
                        />
                    </Grid>
                    <Grid item>
                        <Tooltip title="Сачувајте табелу" placement="right">
                            <SaveIcon
                                style={{ display: !save && 'none' }}
                                onClick={handleSaveTableSettings}
                                className={classes.saveTable}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Ресетуј табелу" placement="right">
                            <RestoreIcon
                                className={classes.saveTable}
                                onClick={restoreTable}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <PageTitle title={title} />
            <Collapse in={withSearch}>
                <SearchBar
                    searchColumns={tableHeaders}
                    setSearch={setSearch}
                    search={search}
                    setPage={setPage}
                />
            </Collapse>
        </Grid>
    )
    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }))

    const footer = (
        <Grid item xs={12}>
            <Grid container justifyContent="space-between">
                <Grid item xs={12} lg={7}>
                    <Grid container justifyContent="flex-end">
                        {withPagination && (
                            <TablePagination
                                component="div"
                                count={totalResults}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[
                                    10,
                                    20,
                                    50,
                                    100,
                                    {
                                        value: totalResults,
                                        label: 'Све',
                                    },
                                ]}
                                labelRowsPerPage="редова по страници"
                                labelDisplayedRows={({ from, to, count }) =>
                                    `приказ страница ${from}-${to} од ${count}`
                                }
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Grid container alignItems="center">
                        {withExport && (
                            <Grid item xs={6}>
                                <Button
                                    {...configButton}
                                    component="span"
                                    onClick={handleOpenExportModal}
                                    className={classes.importButton}
                                >
                                    <span>Извоз</span>
                                    <DownloadSharpIcon
                                        className={classes.icon}
                                    />
                                </Button>
                            </Grid>
                        )}

                        {withImport && <ButtonImportXMLS />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
    const cellData = (rowData, cellTooltip) => (
        <Tooltip title={rowData[cellTooltip] ? rowData[cellTooltip] : ''}>
            <div className={classes.tableCell}> {rowData[cellTooltip]}</div>
        </Tooltip>
    )

    const columnComponents = selectedColumns.map((col) => (
        <Column
            key={uuid()}
            field={col.field}
            header={col.header}
            body={(rowData) => cellData(rowData, col.field)}
            sortable
        />
    ))

    const stateCheck = () => {
        // console.log(`customstate je : ${state}`)
    }

    return (
        <Grid container>
            <ExportModal
                open={openExportModal}
                close={handleCloseModals}
                exportColumns={exportColumns}
                model={model}
                search={search}
                selectedActivity={selectedActivity}
                selectedItem={selectedItem}
                name={exportFileName}
            />
            <Grid item xs={12}>
                <DataTable
                    // globalFilter={globalFilter}
                    sortField={sortField}
                    sortOrder={+1}
                    showGridlines
                    ref={dt}
                    onColumnResizerClick={onRowClick}
                    className={`${classes.header} p-datatable-sm blackTable`}
                    header={header}
                    footer={
                        withExport === false &&
                        withImport === false &&
                        withPagination === false
                            ? null
                            : footer
                    }
                    value={data}
                    resizableColumns
                    columnResizeMode="fit"
                    reorderableColumns
                    rowClassName={rowClass}
                    onRowClick={onRowClick}
                    stateStorage="custom"
                    customSaveState={onCustomSaveState}
                    customRestoreState={onCustomRestoreState}
                    stateKey="table1"
                    emptyMessage={noData}
                    onColReorder={stateCheck}
                >
                    {columnComponents}
                </DataTable>
            </Grid>
        </Grid>
    )
}

UserTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    tableHeaders: PropTypes.arrayOf(PropTypes.any).isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    setRowsPerPage: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onRowClick: PropTypes.func.isRequired,
    setSearch: PropTypes.func.isRequired,
    search: PropTypes.objectOf(PropTypes.any).isRequired,
    title: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    noData: PropTypes.string,
    totalResults: PropTypes.number,
    // editColumnwidth: PropTypes.number,
    rowClass: PropTypes.func,
    // searchColumns: PropTypes.arrayOf(PropTypes.any),
    withExport: PropTypes.bool,
    withSearch: PropTypes.bool,
    withImport: PropTypes.bool,
    withPagination: PropTypes.bool,
    sortField: PropTypes.string,
    selectedActivity: PropTypes.string,
    selectedItem: PropTypes.string,
    exportFileName: PropTypes.string,
    resetTable: PropTypes.bool,
}
UserTable.defaultProps = {
    // editColumnwidth: 300,
    rowClass: () => ({ white: true }),
    // searchColumns: [],
    withSearch: false,
    withExport: true,
    withImport: false,
    withPagination: true,
    totalResults: 1,
    noData: 'Нема пронађених података.',
    sortField: 'naziv',
    selectedActivity: '',
    selectedItem: '',
    exportFileName: 'data',
    resetTable: false,
}

export default UserTable
