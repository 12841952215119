import React from 'react'
import { makeStyles, Grid, Button } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import Notification from './Notification'

const useStyles = makeStyles(() => ({
    warning: {
        color: '#AE0F0F',
        fontSize: 50,
        margin: '0 auto',
    },
    deleteH3: {
        margin: '5px auto',
    },
}))

const DeleteModal = ({ closeModal, type, deleteFunc, message }) => {
    const classes = useStyles()
    const content = (
        <Grid
            container
            spacing={2}
            style={{ flexDirection: 'column', textAlign: 'center' }}
        >
            <WarningIcon className={classes.warning} />
            <h3 className={classes.deleteH3}>Да ли сте сигурни?</h3>
            <h3 className={classes.deleteH3}>Брисање {type} је трајно.</h3>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                    deleteFunc()
                }}
                style={{ margin: '10px auto', width: '60%' }}
            >
                Обриши
            </Button>
            <Button
                variant="outlined"
                color="secondary"
                onClick={() => closeModal()}
                style={{ margin: '10px auto', width: '60%' }}
            >
                Одустани
            </Button>
            <Notification message={message} />
        </Grid>
    )
    return content
}

export default DeleteModal
