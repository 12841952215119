import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import CircularProgress from '@material-ui/core/CircularProgress'
/* eslint-disable */
const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

export default function SimpleModal() {
    const classes = useStyles()

    const body = (
        <CircularProgress style={{ color: 'white', outline: 'none' }} />
    )

    return (
        <div>
            <Modal
                open={true}
                className={classes.paper}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}
