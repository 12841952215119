import React from 'react'
import PropTypes from 'prop-types'
import { alpha, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search'

import TextField from '@material-ui/core/TextField'

/* eslint-disable */
const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
        border: '1px solid black',
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 260,
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textfield: {
        marginBottom: '7px',
    },
    searchIcon: {
        marginBottom: '3px',
    },
}))

const SearchBar = ({ setSearch, search, setPage }) => {
    const classes = useStyles()

    // prettier-ignore
    const cyr = { "А": 'A', "Б": 'B', "В": 'V', "Г": 'G', "Д": 'D', "Ђ": 'Đ', "Е": 'E', "Ж": 'Ž', "З": "Z", "И": "I",
    "Ј": "J", "К":"K", "Л":"L", "Љ":"Lj", "М": "M", "Н": "N", "Њ": "Nj", "О": "O", "П": "P", "Р": "R", "С": "S", "Т": "T",
    "Ћ": "Ć", "У": "U", "Ф": "F", "Х": "H", "Ц": "C", "Ч": "Č", "Џ": "Dz", "Ш": "Š", "а": 'a', "б": 'b', "в": 'v', "г": 'g',
    "д": 'd', "ђ": 'đ', "е": 'e', "ж": 'ž', "з": "z", "и": "i", "ј": "j", "к":"k", "л":"l", "љ":"lj", "м": "m", "н": "n",
    "њ": "nj", "о": "o", "п": "p", "р": "r", "с": "s", "т": "t", "ћ": "ć", "у": "u", "ф": "f", "х": "h", "ц": "c", "ч": "č",
    "џ": "dž", "ш": "š"}

    // prettier-ignore
    const lat = {
      "A": 'А', "B": 'Б', "V": 'В', "G": 'Г', "D": 'Д', "Đ": 'Ђ', "E": 'Е', "Ž": 'Ж', "Z": "З", "I": "И",
    "J": "Ј", "K":"К", "L":"Л", "Lj":"Љ", "M": "М", "N": "Н", "Nj": "Њ", "O": "О", "P": "П", "R": "Р", "S": "С", "T": "Т",
    "Ć": "Ћ", "U": "У", "F": "Ф", "H": "Х", "C": "Ц", "Č": "Ч", "Dž": "Џ", "Š": "Ш", "a": 'а', "b": 'б', "v": 'в', "g": 'г',
    "d": 'д', "đ": 'ђ', "e": 'е', "ž": 'ж', "z": "з", "i": "и", "j": "ј", "k": "к", "l": "л", "lj": "љ", "m": "м", "n": "н",
    "nj": "њ", "o": "о", "p": "п", "r": "р", "s": "с", "t": "т", "ć": "ћ", "u": "у", "f": "ф", "h": "х", "c": "ц", "č": "ч",
    "dž": "џ", "š": "ш"
    }

    const cyrToLat = (text) => {
        const x = text
            .split('')
            .map((char) => cyr[char] || char)
            .join('')

        return x
    }

    const latToCyr = (text) => {
        const x = text
            .split('')
            .map((char) => lat[char] || char)
            .join('')

        if (
            x.search('дж') !== -1 ||
            x.search('нј') !== -1 ||
            x.search('лј') !== -1
        ) {
            return x.replace('лј', 'љ').replace('нј', 'њ').replace('дж', 'џ')
        }
        return x
    }
    // test for cyrilic
    const cyrillicPattern = /^\p{Script=Cyrillic}+$/u

    const handleChange = (e) => {
        const { value, name } = e.target

        const counter = 0
        if (counter === 0) setPage(0)
        counter + 1
        let srb = ''
        if (name === 'eng') {
            const translate = cyrillicPattern.test(value)
                ? cyrToLat(value)
                : latToCyr(value)
            srb = translate
        }

        setSearch((previous) => ({
            ...previous,
            [name]: value,
            srb,
        }))
    }

    return (
        <Grid item>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                    <SearchIcon className={classes.searchIcon} />
                </Grid>
                <Grid item className={classes.textfield}>
                    <TextField
                        id="search"
                        label="Претрага..."
                        name="eng"
                        type="text"
                        value={search.eng}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SearchBar

SearchBar.propTypes = {
    setSearch: PropTypes.func.isRequired,
    search: PropTypes.objectOf(PropTypes.any).isRequired,
    setPage: PropTypes.func.isRequired,
}
