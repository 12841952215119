import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ResponsiveDrawer from './components/drawer/ResponsiveDrawer'
import Auth from './routing/Auth'

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/app">
                    <ResponsiveDrawer />
                </Route>
                <Route path="/">
                    <Auth />
                </Route>
            </Switch>
        </Router>
        // <Router>
        //     <ResponsiveDrawer />
        // </Router>
    )
}

export default App
