import { Box } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'

const TabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box pt={3}>{children}</Box>}
        </div>
    )
}

export default TabPanel

TabPanel.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}

TabPanel.defaultProps = {
    children: <></>,
}
