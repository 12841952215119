import React, { memo, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { axiosAuth } from '../util/axios-instance'
// import { isLoggedIn } from '../util/getAllowedRoutes'
import PublicRoutes from './PublicRoutes'

const Auth = () => {
    const [userInfo, setUserInfo] = useState('')
    // const location = useLocation()
    const isLoggedIn = async () => {
        try {
            const { data } = await axiosAuth('/api/user/my-profile')

            setUserInfo(data)
        } catch (error) {
            console.log(error, error.response)

            setUserInfo('')
        }
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            isLoggedIn()
        }
    }, [])

    return <>{userInfo ? <Redirect to="/app" /> : <PublicRoutes />}</>
}

export default memo(Auth)
