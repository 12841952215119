import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { axiosAuth as axios } from '../../util/axios-instance'
import TabPanel from '../../components/TabPanel/TabPanel'
import SimpleModal from '../../components/Modal/Modal'
import RoleRow from '../../components/Cards/RoleRow'

const useStyles = makeStyles({
    root: {
        margin: 12,
        textAlign: 'left',
    },
    table: {
        minWidth: 650,
    },
    cont: {
        margin: '20px 20px 50px 8px',
        width: 'unset',
    },
    titleDiv: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    title: {
        margin: '0 20px',
    },
    appBar: {
        backgroundColor: 'white',
        color: 'black',
        margin: 8,
    },
})

const RoleTab = ({ id, index, value }) => {
    const classes = useStyles()
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [totalResults, setTotalResults] = useState(0)
    const [users, setUsers] = useState([])
    const [refreshState, setRefreshState] = useState(false)
    const [groups, setGroups] = useState([])
    const myInfo = useSelector((state) => state.myrole)
    // eslint-disable-next-line
    const [load, setLoad] = useState(false)
    const [roles, setRoles] = useState([])

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const setSeverityHandler = (state) => {
        setSeverity(state)
    }

    const setSnackbarHandler = (state) => {
        setSnackbar(state)
    }

    const getUsers = async () => {
        try {
            // setLoad(true)
            const response = await axios.get(
                `/api/user/users-per-role?roleId=${id}&page=${
                    page + 1
                }&limit=${rowsPerPage}`
            )
            const allRoles = await axios.get('/api/role')
            setTotalResults(response.data.totalResults)
            // setRole(clickedRole.data.role.imeRole)
            setUsers(response.data.users)
            setRoles(allRoles.data.roles)
            // setLoad(false)
        } catch (error) {
            setLoad(true)
            console.log(error)
            setTimeout(() => {
                setLoad(false)
            }, 400)
        }
    }

    const getAllGroups = async () => {
        try {
            // setLoad(true)
            const response = await axios.get('/api/group')
            setGroups([
                { naziv: 'Изабери групу', value: '' },
                ...response.data.groups,
            ])
            // console.log([
            //     { naziv: 'Изабери групу', value: '' },
            //     ...response.data.groups,
            // ])
            // setLoad(false)
        } catch (error) {
            console.log(error)
            setTimeout(() => {
                setLoad(false)
            }, 400)
        }
    }

    const setRefresh = (state) => {
        setRefreshState(state)
    }

    useEffect(() => {
        getAllGroups()
    }, [page, rowsPerPage, refreshState])

    useEffect(() => {
        if (!refreshState) getUsers()
    }, [page, rowsPerPage, refreshState, value])

    return (
        <>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {load && <SimpleModal />}
            <TabPanel value={value} index={index}>
                {/* {load ? (
                <SimpleModal />
            ) : ( */}
                <div>
                    <TableContainer className={classes.cont}>
                        <Table
                            stickyHeader
                            className={classes.table}
                            aria-label="sticky table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Име</TableCell>
                                    <TableCell>Имејл адреса</TableCell>
                                    <TableCell>Рола</TableCell>
                                    <TableCell>Група</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {roles && groups ? (
                                    users.map((user) => (
                                        <RoleRow
                                            name={`${user.ime} ${user.prezime}`}
                                            userId={user._id}
                                            email={user.email}
                                            // groups={
                                            //     myInfo.user.rola === 'Admin'
                                            //         ? groups
                                            //         : rukGroups
                                            // }
                                            groups={groups}
                                            roles={
                                                myInfo.user.rola ===
                                                'Rukovodilac'
                                                    ? roles.filter(
                                                          (role) =>
                                                              role.imeRole !==
                                                              'Admin'
                                                      )
                                                    : roles
                                            }
                                            myRole={myInfo.user.rola}
                                            usersRole={user.rola}
                                            key={user._id}
                                            setRefresh={setRefresh}
                                            userGroup={
                                                user?.grupa
                                                    ? user.grupa
                                                    : {
                                                          naziv: 'Изабери групу',
                                                          value: '',
                                                      }
                                            }
                                            setSeverityHandler={
                                                setSeverityHandler
                                            }
                                            setSnackbarHandler={
                                                setSnackbarHandler
                                            }
                                            setLoad={setLoad}
                                        />
                                    ))
                                ) : (
                                    <SimpleModal />
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={totalResults}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(event.target.value)
                            setPage(0)
                        }}
                    />
                </div>
                {/* )} */}
            </TabPanel>
        </>
    )
}

RoleTab.propTypes = {
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}
// RoleTab.defaultProps = {
// }

export default RoleTab
