import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, FormControl } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import NativeSelect from '@material-ui/core/NativeSelect'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import { axiosAuth as axios } from '../../util/axios-instance'
import latToCyr from '../../util/latToCyr'

const useStyles = makeStyles({
    root: {
        margin: 12,
        textAlign: 'left',
    },
    table: {
        minWidth: 650,
    },
    cont: {
        margin: '20px 20px 50px 8px',
        width: 'unset',
    },
    cellButtons: {
        padding: 4,
    },
    btn: {
        background: '#1E8637',
        color: '#fff',
    },
})

const RoleRow = ({
    name,
    userId,
    email,
    usersRole,
    groups,
    roles,
    // myRole,
    setRefresh,
    userGroup,
    setSeverityHandler,
    setSnackbarHandler,
    setLoad,
}) => {
    const classes = useStyles()
    const [roleState, setRoleState] = useState(false)
    const [role, setRole] = useState(usersRole)
    const [adminGroup, setAdminGroup] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState(false)
    // const [group, setGroup] = useState({
    //     naziv: 'Изабери',
    //     value: '',
    // })
    const [grupa, setGrupa] = useState(userGroup)
    // const [snackbar, setSnackbar] = useState({
    //     open: false,
    //     message: '',
    // })
    // const [severity, setSeverity] = useState('info')

    // const handleSnackBarOpen = () => {
    //     setSnackbar((previous) => ({
    //         ...previous,
    //         open: false,
    //     }))
    // }

    const addRoleToUser = async (groupId, newRole, guestId) => {
        try {
            setRefresh(true)
            setLoad(true)
            await axios.post('/api/role/add-role', {
                groupId: groupId,
                newRole: newRole,
                guestId: guestId,
            })
            setSeverityHandler('success')
            setSnackbarHandler({
                open: true,
                message: 'Успешно сте променили ролу кориснику.',
            })
            setRefresh(false)
            setLoad(false)
        } catch (error) {
            setSeverityHandler('warning')
            setSnackbarHandler({
                open: true,
                message: error?.response?.data?.message
                    ? error.response.data.message
                    : 'Неуспешно промењена рола.',
            })
        }
    }

    return (
        <TableRow>
            <TableCell
                className={classes.cellButtons}
                component="th"
                scope="row"
            >
                {name}
            </TableCell>
            <TableCell
                className={classes.cellButtons}
                component="th"
                scope="row"
            >
                {email}
            </TableCell>
            <TableCell
                className={classes.cellButtons}
                component="th"
                scope="row"
            >
                <div className={classes.divButtons}>
                    <FormControl className={classes.formControl}>
                        <NativeSelect
                            value={role}
                            inputProps={{
                                name: 'name',
                                id: 'uncontrolled-native',
                            }}
                            onChange={(event) => {
                                setRole(event.target.value)
                                setRoleState(event.target.value)
                                if (event.target.value === 'Admin') {
                                    setAdminGroup(true)
                                    // setGrupa('Admin')
                                    setGrupa(
                                        groups.filter(
                                            (group) =>
                                                group.tip === event.target.value
                                        )[0]
                                    )
                                    setSelectedGroup(
                                        groups.filter(
                                            (group) =>
                                                group.tip === event.target.value
                                        )[0]
                                    )
                                } else {
                                    setAdminGroup(false)
                                    // setGrupa(grupa)
                                    setGrupa(
                                        groups.filter(
                                            (group) => group.value === ''
                                        )[0]
                                    )
                                    setSelectedGroup(false)
                                }
                            }}
                        >
                            {usersRole === 'Gost' && (
                                <option aria-label="None" value="">
                                    Изаберите ролу
                                </option>
                            )}
                            {roles
                                .filter((r) => r.imeRole !== 'Gost')
                                .map((r) => (
                                    <option value={r.imeRole} key={r._id}>
                                        {latToCyr(r.imeRole)}
                                    </option>
                                ))}
                        </NativeSelect>
                    </FormControl>
                </div>
            </TableCell>
            <TableCell className={classes.cellButtons}>
                <Autocomplete
                    id="combo-box-demo"
                    disableClearable
                    disabled={
                        roleState === 'Gost' || adminGroup || role === 'Admin'
                    }
                    value={grupa}
                    options={groups.filter(
                        (group) =>
                            group.naziv !== 'Админ' && group.naziv !== 'Admin'
                    )}
                    getOptionSelected={(option, value) =>
                        option.value === value.value
                    }
                    getOptionLabel={(option) => option.naziv}
                    onChange={(event, gr) => {
                        setGrupa(gr)
                        setSelectedGroup(gr)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            // label="Изаберите групу"
                            // variant="outlined"
                        />
                    )}
                />
            </TableCell>
            <TableCell className={classes.cellButtons}>
                <Button
                    disabled={!roleState || !selectedGroup || !grupa._id}
                    onClick={() => {
                        addRoleToUser(grupa._id, roleState, userId)
                    }}
                    variant="outlined"
                    color="primary"
                >
                    Сачувај
                </Button>
            </TableCell>
        </TableRow>
    )
}

RoleRow.propTypes = {
    name: PropTypes.string,
    userId: PropTypes.string,
    email: PropTypes.string,
    usersRole: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.any),
    roles: PropTypes.arrayOf(PropTypes.any),
    myRole: PropTypes.string,
    setRefresh: PropTypes.func,
    userGroup: PropTypes.objectOf(PropTypes.any),
    setSnackbarHandler: PropTypes.func,
    setSeverityHandler: PropTypes.func,
    setLoad: PropTypes.func,
}

RoleRow.defaultProps = {
    name: '',
    userId: '',
    email: '',
    usersRole: '',
    groups: [],
    roles: [],
    myRole: '',
    setRefresh: () => {},
    userGroup: {},
    setSnackbarHandler: () => {},
    setSeverityHandler: () => {},
    setLoad: () => {},
}

export default RoleRow
