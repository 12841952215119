import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    btnbox: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 0,
        margin: 0,
    },
    text: {
        color: 'secondary',
        fontSize: '1rem',
        textTransform: 'lowercase',
        textDecoration: 'underline',
    },
}))

const CategoryBtn = () => {
    const classes = useStyles()
    return (
        <Box className={classes.btnbox}>
            <Button component={Link} to="/zaboravljena-lozinka">
                <Typography className={classes.text}>
                    заборављена лозинка?
                </Typography>
            </Button>
        </Box>
    )
}

export default CategoryBtn
