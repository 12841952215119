import React, { useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, Box, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { axiosAuth as axios } from '../../../util/axios-instance'
import AdminTable from '../../../components/tables/AdminTable'
import SimpleModal from '../../../components/Modal/Modal'
import latToCyr from '../../../util/latToCyr'

import AddButton from '../../../components/FormsUI/Button/AddButton'
import CreateSubcontract from './CreateSubcontract'
import EditSubcontract from './EditSubcontract'
import DeleteSubcontract from './DeleteSubcontract'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    root: {
        margin: '20px',
        width: 'unset',
    },
    cellButtons: {
        maxWidth: 'min-content',
        cursor: 'default',
    },
    divButtons: {
        '& > *': {
            width: '33%',
        },
    },
    createButton: {
        backgroundColor: '#d9534f',
        color: 'white',
        '&:hover': {
            backgroundColor: '#d9534f',
        },
        padding: 8,
        margin: 20,
        boxShadow: 'none',
    },
    roleBtn: {
        '&:hover': {
            backgroundColor: 'unset',
            boxShadow: 'none',
        },
    },
    divRoot: {
        textAlign: 'left',
    },
    editButton: {
        backgroundColor: '#059D2A',
        color: 'white',
        '&:hover': {
            background: '#059D2A',
        },
    },
    deleteButton: {
        backgroundColor: '#D86565',
        color: 'white',
        '&:hover': {
            background: '#D86565',
        },
    },
    locationsButton: {
        backgroundColor: 'white',
        color: '#D86565',
        '&:hover': {
            background: 'white',
        },
    },
    icon: {
        marginLeft: 5,
    },
    buttonW: {
        margin: '1rem 0',
    },
})

const ContractsNadzor = () => {
    const history = useHistory()

    const classes = useStyles()
    const [contracts, setContracts] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [totalResults, setTotalResults] = useState(0)
    const [load, setLoad] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [editId, setEditId] = useState('')
    const [deleteId, setDeleteId] = useState('')
    // const [blankId, setBlankId] = useState(false)
    const [blankCreateOpen, setBlankCreateOpen] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [groups, setGroups] = useState([])

    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    const [searchDebounced] = useDebounce(search, 1000)

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    })
    const [severity, setSeverity] = useState('info')

    const handleSnackBarOpen = () => {
        setSnackbar((previous) => ({
            ...previous,
            open: false,
        }))
    }

    const getGroups = async () => {
        try {
            setLoad(true)
            const response = await axios.get('/api/group/sve-videci/false')
            setGroups(response.data.groups.filter((g) => g.tip === 'Nadzor'))
            setLoad(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getGroups()
    }, [])

    const getContracts = async () => {
        try {
            setLoad(true)
            const url =
                searchDebounced.eng === ''
                    ? `/api/contractNad?page=${page + 1}&limit=${rowsPerPage}`
                    : `/api/search/contractNad?eng=${searchDebounced.eng}&srb=${
                          searchDebounced.srb
                      }&page=${page + 1}&limit=${rowsPerPage}`

            const { data } = await axios.get(url)
            const tableData = data.contractsNad.reduce((acc, currentEl) => {
                const addElement = {
                    naziv: currentEl.naziv || '',
                    broj: currentEl.broj || '',
                    status: currentEl.status ? latToCyr(currentEl.status) : '',
                    statusLat: currentEl.status ? currentEl.status : '',
                    datumPocetka: currentEl.datumPocetka
                        ? moment(currentEl.datumPocetka).format('DD/MM/YYYY')
                        : '',
                    rokTrajanja: currentEl.rokTrajanja
                        ? currentEl.rokTrajanja
                        : '',
                    id: currentEl._id || '',
                    datumZavrsetka: currentEl.datumZavrsetka
                        ? currentEl.datumZavrsetka
                        : null,
                }
                acc.push(addElement)
                return acc
            }, [])

            setContracts(tableData)
            setTotalResults(data.totalResults)
            setLoad(false)
        } catch (error) {
            console.log(error)
            setLoad(true)
            setTimeout(() => {
                setLoad(false)
            }, 400)
        }
    }

    const actionBodyTemplate = () => (
        <Grid key="edit" container spacing={2}>
            <Grid item xs={3} className={classes.buttonWrapper}>
                <Button name="izmeni" fullWidth className={classes.editButton}>
                    {' '}
                    Измени
                </Button>
            </Grid>
            <Grid item xs={3} className={classes.buttonWrapper}>
                <Button
                    name="obrisi"
                    color="secondary"
                    fullWidth
                    className={classes.deleteButton}
                >
                    {' '}
                    Обриши
                </Button>
            </Grid>
            <Grid item xs={5} className={classes.buttonWrapper}>
                <Button
                    name="lokacije"
                    fullWidth
                    className={classes.locationsButton}
                    variant="outlined"
                    color="secondary"
                >
                    {' '}
                    Повежи кластере
                </Button>
            </Grid>
        </Grid>
    )
    const onRowClick = (rowData) => {
        if (
            rowData.originalEvent.target.nodeName === 'TD' ||
            rowData.originalEvent.target.nodeName === 'DIV'
        ) {
            history.push(`/app/ugovor/${rowData.data.id}`, {
                from: 'CONTRACTS-NAD',
            })
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'lokacije'
        ) {
            if (
                rowData.data.statusLat === 'Završen' &&
                rowData.data.datumZavrsetka
            ) {
                setSeverity('warning')
                setSnackbar({
                    open: true,
                    message:
                        'Није могуће повезати кластере са завршеним уговором',
                })
            } else {
                const filteredName = rowData.data.naziv.replace(/\\|\//g, '-')
                console.log(filteredName)
                history.push(
                    `/app/ugovor/povezivanje-sa-klasterima/${filteredName}/${rowData.data.id}`,
                    {
                        from: 'contractNad',
                    }
                )
            }
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'izmeni'
        ) {
            setEditOpen(true)
            setEditId(rowData.data.id)
        }
        if (
            rowData.originalEvent.target.nodeName === 'SPAN' &&
            rowData.originalEvent.target.parentNode.name === 'obrisi'
        ) {
            setDeleteOpen(true)
            setDeleteId(rowData.data.id)
        }
    }

    const setEditModal = (state) => {
        setEditOpen(state)
    }

    const setDeleteModal = (state) => {
        setDeleteOpen(state)
    }

    const setCreateBlankModal = (state) => {
        setBlankCreateOpen(state)
    }

    const setRefreshState = (state) => {
        setRefresh(state)
    }

    const setSeverityHandler = (state) => {
        setSeverity(state)
    }

    const setSnackbarHandler = (state) => {
        setSnackbar(state)
    }

    useEffect(() => {
        if (!refresh) getContracts()
    }, [page, rowsPerPage, refresh, searchDebounced.eng])

    return (
        <div className={classes.divRoot}>
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {editOpen && editId.length > 0 ? (
                <EditSubcontract
                    id={editId}
                    groups={groups}
                    modalOpen={editOpen}
                    setModal={setEditModal}
                    label="Измена уговора"
                    setRefreshState={setRefreshState}
                    setSeverityHandler={setSeverityHandler}
                    setSnackbarHandler={setSnackbarHandler}
                    route="contractNad"
                />
            ) : null}

            {blankCreateOpen ? (
                <CreateSubcontract
                    // id={blankId}
                    groups={groups}
                    modalOpen={blankCreateOpen}
                    setModal={setCreateBlankModal}
                    setRefreshState={setRefreshState}
                    setSeverityHandler={setSeverityHandler}
                    setSnackbarHandler={setSnackbarHandler}
                    route="contractNad"
                />
            ) : null}

            {deleteOpen && deleteId.length > 0 ? (
                <DeleteSubcontract
                    id={deleteId}
                    modalOpen={deleteOpen}
                    setModal={setDeleteModal}
                    setRefreshState={setRefreshState}
                    setSeverityHandler={setSeverityHandler}
                    setSnackbarHandler={setSnackbarHandler}
                    route="contractNad"
                />
            ) : null}

            <div className={classes.root}>
                {/* <Grid container justifyContent="center">
                    <PageTitle>Уговори</PageTitle>
                </Grid> */}
                <Box className={classes.buttonW}>
                    <AddButton
                        label="НАПРАВИ НОВИ УГОВОР"
                        click={() => {
                            setBlankCreateOpen(true)
                        }}
                    />
                </Box>
                {load ? (
                    <SimpleModal />
                ) : (
                    <AdminTable
                        model="contract"
                        data={contracts}
                        load={load}
                        title="Уговори - надзор"
                        totalResults={totalResults}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        name="contractTable"
                        onRowClick={onRowClick}
                        tableHeaders={[
                            { field: 'naziv', header: 'Назив' },
                            { field: 'broj', header: 'Број' },
                            { field: 'status', header: 'Статус' },
                            { field: 'datumPocetka', header: 'Датум почетка' },
                            {
                                field: 'rokTrajanja',
                                header: 'Рок трајања (број дана)',
                            },
                            { field: 'edit', header: '' },
                        ]}
                        actionBodyTemplate={actionBodyTemplate}
                        editColumnwidth={400}
                        searchColumns={[
                            { dataValue: 'naziv', label: 'Назив' },
                            { dataValue: 'broj', label: 'Број' },
                            { dataValue: 'status', label: 'Статус' },
                            {
                                dataValue: 'datumPocetka',
                                label: 'Датум почетка',
                            },
                            { dataValue: 'rokTrajanja', label: 'Рок трајања' },
                        ]}
                        setSearch={setSearch}
                        search={search}
                        sortField=""
                        exportFileName="Уговори"
                    />
                )}
            </div>
        </div>
    )
}

export default ContractsNadzor
