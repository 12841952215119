import React, { useState } from 'react'
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AllReports from './AllReports'
import SummaryInvReports from './SummaryInvReports'
import InvReportsByLocations from './InvReportsByLocations'
import InvReportsBySchool from './InvReportsBySchool'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 400,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    pageContainer: {
        padding: 50,
    },
    selectBody: {
        marginLeft: 12,
    },
}))

const InventoryReports = () => {
    const classes = useStyles()
    // const [load, setLoad] = useState(false)
    const [selectedReport, setSelectedReport] = useState('')

    return (
        <Grid container className={classes.pageContainer}>
            {/* {load && <SimpleModal />} */}
            <Grid container alignItems="center" className={classes.selectBody}>
                <Grid item>
                    <Typography variant="body1">
                        Изаберите жељени извештај
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControl
                        variant="outlined"
                        className={classes.formControl}
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Извештаји
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={selectedReport}
                            onChange={(event) =>
                                setSelectedReport(event.target.value)
                            }
                            label="извештаји"
                        >
                            <MenuItem value="">
                                <em>избор извештаја</em>
                            </MenuItem>
                            <MenuItem value="Spisak svih stavki">
                                Списак свих ставки
                            </MenuItem>
                            <MenuItem value="Sumarni podaci po stavkama">
                                Сумарни подаци по ставкама
                            </MenuItem>
                            <MenuItem value="Spisak stanja inventara po lokacijama">
                                Списак стања инвентара по локацијама
                            </MenuItem>
                            <MenuItem value="Popisna lista po skoli">
                                Пописна листа (инвентар) по школи
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    {selectedReport === 'Spisak svih stavki' && <AllReports />}
                    {selectedReport === 'Sumarni podaci po stavkama' && (
                        <SummaryInvReports />
                    )}
                    {selectedReport ===
                        'Spisak stanja inventara po lokacijama' && (
                        <InvReportsByLocations />
                    )}
                    {selectedReport === 'Popisna lista po skoli' && (
                        <InvReportsBySchool />
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default InventoryReports
