import React, { useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'
import { axiosAuth as axios } from '../../../../util/axios-instance'
import AdminTable from '../../../../components/tables/AdminTable'
import SimpleModal from '../../../../components/Modal/Modal'

const AllReports = () => {
    const [reports, setReports] = useState([])
    const [load, setLoad] = useState(false)
    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    const [searchDebounced] = useDebounce(search, 2000)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [totalResults, setTotalResults] = useState(0)

    const getReports = async () => {
        try {
            setLoad(true)

            const url =
                // prettier-ignore
                searchDebounced.eng === ''
                    ? `/api/inventory-reports/sve?page=${page + 1}&limit=${rowsPerPage}`
                    : `/api/inventory-reports/sve?eng=${searchDebounced.eng}&srb=${searchDebounced.srb
                    }&page=${page + 1}&limit=${rowsPerPage}`

            // ? `/api/inventory-reports/sve?page=${page + 1}&limit=${rowsPerPage}`

            const response = await axios.get(url)

            const tableData = response?.data?.allInventory?.reduce(
                (acc, currentEl) => {
                    const date = currentEl?.datumPrimopredaje
                        ?.split('T')[0]
                        .split('-')

                    const addElement = {
                        _id: currentEl._id,
                        datumPrimopredaje:
                            date && `${date[2]}/${date[1]}/${date[0]}`,
                        cena: currentEl.invtype.cena,
                        kolicina: currentEl.kolicina,
                        nazivInv: currentEl.invtype.naziv,
                        nazivLokacije: currentEl.lokacija?.naziv,
                    }
                    acc.push(addElement)
                    return acc
                },
                []
            )
            setTotalResults(response.data.totalResults)
            setReports(tableData)
            setLoad(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getReports()
    }, [
        page,
        rowsPerPage,
        // refreshState,
        searchDebounced.eng,
        searchDebounced.srb,
    ])

    return (
        <div>
            {load && <SimpleModal />}
            <AdminTable
                data={reports}
                name="allInventoryReports"
                tableHeaders={[
                    { field: 'nazivInv', header: 'Назив инвентара' },
                    { field: 'kolicina', header: 'Количина' },
                    { field: 'cena', header: 'Цена' },
                    {
                        field: 'datumPrimopredaje',
                        header: 'Датум примопредаје',
                    },
                    {
                        field: 'nazivLokacije',
                        header: 'Назив локације',
                    },
                    // { field: 'tlocrt', header: 'Тлоцрт' },
                    // { field: 'edit', header: '' },
                ]}
                setSearch={setSearch}
                search={search}
                totalResults={totalResults}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                onRowClick={() => {}}
                actionBodyTemplate={() => {}}
                model="inventory-reports/sve"
                title="Списак свих ставки"
                exportFileName="Све ставке"
            />
        </div>
    )
}

export default AllReports
