import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import moment from 'moment'
import download from 'downloadjs'
import {
    Button,
    makeStyles,
    Grid,
    Tooltip,
    Typography,
} from '@material-ui/core'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import uuid from 'react-uuid'
import { axiosAuth as axios } from '../../util/axios-instance'
import BackToButton from '../../components/FormsUI/Button/BackToButton'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import latToCyr from '../../util/latToCyr'

const useStyles = makeStyles(() => ({
    root: {
        margin: 12,
    },
    table: {
        borderCollapse: 'collapse',
        margin: 8,
    },
    docDiv: {
        border: '1px solid #ddd',
        display: 'flex',
        justifyContent: 'space-between',
        width: '500px',
        '@media (max-width:750px)': {
            width: '380px',
        },
        '@media (max-width:450px)': {
            width: '250px',
            flexDirection: 'column',
        },
    },
    th: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        backgroundColor: '#417AB4',
        color: 'white',
        width: 300,
    },
    td: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        width: 300,
    },
    tr: {
        border: '1px solid #dddddd',
    },
    contractTitle: {
        textAlign: 'left',
        margin: '20px 8px',
        fontSize: 25,
        color: '#417AB4',
        display: 'flex',
        alignItems: 'flex-end',
    },
    icon: {
        fontSize: 40,
        color: '#417AB4',
    },
    tables: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    contractDiv: {
        flex: '40%',
    },
    downloadButton: {
        backgroundColor: '#1E8637',
        color: 'white',
        '&:hover': {
            backgroundColor: '#1E8637',
        },
    },
    noDocuments: {
        textAlign: 'left',
    },
}))

const SingleContract = () => {
    const { id } = useParams()
    const [contract, setContract] = useState(false)
    const historyLocation = useLocation()
    const classes = useStyles()
    // useEffect(() => {
    //     if (
    //         historyLocation?.state?.from !== 'CONTRACTS' ||
    //         historyLocation?.state?.from !== 'CONTRACTS-NAD' ||
    //         historyLocation?.state?.from !== 'CONTRACTS-IZV' ||
    //         historyLocation?.state?.from !== 'CONTRACTS-PRO'
    //     )
    //         history.push('/app/ugovori')
    // }, [])

    const getContract = async () => {
        console.log(historyLocation?.state?.from)
        try {
            if (historyLocation?.state?.from === 'CONTRACTS-IZV') {
                const response = await axios.get(`/api/contractIzv/${id}`)
                setContract(response.data.contractIzv)
            } else if (historyLocation?.state?.from === 'CONTRACTS-PRO') {
                const response = await axios.get(`/api/contractPro/${id}`)
                setContract(response.data.contractPro)
            } else if (historyLocation?.state?.from === 'CONTRACTS-NAD') {
                const response = await axios.get(`/api/contractNad/${id}`)
                setContract(response.data.contractNad)
            } else {
                const response = await axios.get(`/api/contract/${id}`)
                setContract(response.data.contract)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getContract()
    }, [])

    /* eslint-disable */
    const downloadFile = async (fId, fName, mimetype) => {
        try {
            const response = await axios.get(
                `/api/files/contract/${id}/${fId}`,
                {
                    responseType: 'blob',
                }
            )
            return download(response.data, fName, mimetype)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className={classes.root}>
            <BackToButton label="Назад на листу свих јавних позива" />

            <Grid container justifyContent="center">
                <PageTitle
                    title={
                        historyLocation?.state?.from === 'CONTRACTS-IZV' ||
                        historyLocation?.state?.from === 'CONTRACTS-NAD' ||
                        historyLocation?.state?.from === 'CONTRACTS-PRO'
                            ? 'Детаљи уговора'
                            : 'детаљи јавног позива'
                    }
                />
            </Grid>
            {contract ? (
                <div className={classes.tables}>
                    <div className={classes.contractDiv}>
                        <div className={classes.contractTitle}>
                            <FolderOpenIcon className={classes.icon} />
                            <span>
                                {historyLocation?.state?.from ===
                                    'CONTRACTS-IZV' ||
                                historyLocation?.state?.from ===
                                    'CONTRACTS-NAD' ||
                                historyLocation?.state?.from === 'CONTRACTS-PRO'
                                    ? 'Уговор'
                                    : 'Јавни позив'}
                            </span>
                        </div>
                        <table className={classes.table}>
                            <tbody>
                                <tr>
                                    <th className={classes.th}>Назив</th>
                                    <td className={classes.td}>
                                        {contract.naziv ? contract.naziv : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.th}>Број</th>
                                    <td className={classes.td}>
                                        {contract.broj ? contract.broj : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.th}>Статус</th>
                                    <td className={classes.td}>
                                        {contract.status
                                            ? latToCyr(contract.status)
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.th}>
                                        Датум почетка
                                    </th>
                                    <td className={classes.td}>
                                        {contract.datumPocetka
                                            ? moment(
                                                  contract.datumPocetka
                                              ).format('DD/MM/YYYY')
                                            : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.th}>Рок трајања</th>
                                    <td className={classes.td}>
                                        {contract.rokTrajanja
                                            ? contract.rokTrajanja
                                            : ''}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={classes.contractDiv}>
                        <div className={classes.contractTitle}>
                            <FolderOpenIcon className={classes.icon} />
                            <span>Документи</span>
                        </div>

                        {contract.fajlovi.length > 0 ? (
                            <div style={{ margin: '8px' }}>
                                {contract.fajlovi.map((dok) =>
                                    dok.originalnoIme.length > 40 ? (
                                        <Tooltip
                                            key={dok._id}
                                            title={dok.originalnoIme}
                                        >
                                            <div className={classes.docDiv}>
                                                <p
                                                    style={{
                                                        textAlign: 'left',
                                                        padding: '0 15px',
                                                    }}
                                                >
                                                    {dok.originalnoIme.length >
                                                    40
                                                        ? `${dok.originalnoIme.slice(
                                                              0,
                                                              40
                                                          )}...`
                                                        : dok.originalnoIme}
                                                </p>
                                                <Button
                                                    className={
                                                        classes.downloadButton
                                                    }
                                                    onClick={() => {
                                                        download(
                                                            dok._id,
                                                            dok.originalnoIme,
                                                            dok.mimetype
                                                        )
                                                    }}
                                                >
                                                    Преузми
                                                </Button>
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <div
                                            key={dok._id}
                                            className={classes.docDiv}
                                        >
                                            <p
                                                style={{
                                                    textAlign: 'left',
                                                    padding: '0 15px',
                                                }}
                                            >
                                                {dok.originalnoIme.length > 40
                                                    ? `${dok.originalnoIme.slice(
                                                          0,
                                                          40
                                                      )}...`
                                                    : dok.originalnoIme}
                                            </p>
                                            <Button
                                                className={
                                                    classes.downloadButton
                                                }
                                                onClick={() => {
                                                    downloadFile(
                                                        dok._id,
                                                        dok.originalnoIme,
                                                        dok.mimetype
                                                    )
                                                }}
                                            >
                                                Преузми
                                            </Button>
                                        </div>
                                    )
                                )}
                            </div>
                        ) : (
                            <Typography
                                className={classes.noDocuments}
                                variant="subtitle1"
                                color="secondary"
                            >
                                Нема докумената
                            </Typography>
                        )}
                    </div>
                    <div className={classes.contractDiv}>
                        <div className={classes.contractTitle}>
                            <LocationOnIcon className={classes.icon} />
                            <span>Повезани кластери</span>
                        </div>
                        <table className={classes.table}>
                            <tbody>
                                <tr>
                                    <th className={classes.th}>
                                        Назив кластера
                                    </th>
                                    <th className={classes.th}>Статус</th>
                                    <th className={classes.th}>Одговоран</th>
                                </tr>
                                {contract.lokacije.length > 0 ? (
                                    contract.lokacije.map((lok) => (
                                        <tr key={uuid()}>
                                            <td className={classes.td}>
                                                {lok.naziv}
                                            </td>
                                            <td className={classes.td}>
                                                {latToCyr(lok.status)}
                                            </td>
                                            <td className={classes.td}>
                                                {lok?.odgovoran
                                                    ? lok.odgovoran
                                                          .map(
                                                              (odg) => odg.naziv
                                                          )
                                                          .join(', ')
                                                    : ''}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className={classes.td}>
                                        <td>Нема повезаних кластера</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default SingleContract
