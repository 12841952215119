import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import BackToButton from '../../components/FormsUI/Button/BackToButton'
import { axiosAuth as axios } from '../../util/axios-instance'
// import SimpleModal from '../../components/Modal/Modal'
// import RoleRow from '../../components/Cards/RoleRow'
import latToCyr from '../../util/latToCyr'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import RoleTab from './RoleTab'

const useStyles = makeStyles({
    root: {
        margin: 12,
        textAlign: 'left',
    },
    table: {
        minWidth: 650,
    },
    cont: {
        margin: '20px 20px 50px 8px',
        width: 'unset',
    },
    titleDiv: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    title: {
        margin: '0 20px',
    },
    appBar: {
        backgroundColor: 'white',
        color: 'black',
        margin: 8,
    },
})

const UserManagment = () => {
    const classes = useStyles()
    const [roles, setRoles] = useState([])
    // const [myGroup, setMyGroup] = useState(false)
    // const [rukGroups, setRukGroups] = useState([])
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const getAllRoles = async () => {
        try {
            const response = await axios.get('/api/role')
            setRoles(response.data.roles)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllRoles()
    }, [])

    return (
        <div className={classes.root}>
            <div className={classes.titleDiv}>
                <BackToButton label="НАЗАД НА ЛИСТУ СВИХ РОЛА" />
                <div className={classes.title}>
                    <PageTitle title="Списак свих корисника" />
                </div>
            </div>

            <div className={classes.appBar}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                >
                    {roles.map((rola) => (
                        <Tab key={rola._id} label={latToCyr(rola.imeRole)} />
                    ))}
                    {/* <Tab label="Админ" />
                    <Tab label="Руководилац" />
                    <Tab label="Запослени" />
                    <Tab label="Гост" /> */}
                </Tabs>
            </div>
            {roles.map((rola, index) => (
                <RoleTab
                    key={rola._id}
                    id={rola._id}
                    index={index}
                    value={value}
                />
            ))}
            {/* <TabPanel value={value} index={0}>
                Админ
            </TabPanel>
            <TabPanel value={value} index={1}>
                Руководилац
            </TabPanel>
            <TabPanel value={value} index={2}>
                Запослени
            </TabPanel>
            <TabPanel value={value} index={3}>
                Гост
            </TabPanel> */}
            {/* {users.length > 0 && role.length > 0 && roles.length > 0 ? ( */}
            {/* {load ? (
                <SimpleModal />
            ) : (
                <div>
                    
                </div>
            )} */}
        </div>
    )
}

export default UserManagment
