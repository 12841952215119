import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import { Button, CircularProgress, TextField } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextfieldWrapper from '../../../../components/FormsUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../../../components/FormsUI/Button/ButtonWrapper'
import { axiosAuth as axios } from '../../../../util/axios-instance'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
    removeIcon: {
        cursor: 'pointer',
        color: 'red',
        fontSize: 20,
        margin: '0 0 0 10px',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10,
    },
}))

const FORM_VALIDATION = Yup.object().shape({
    naziv: Yup.string().required('Поље је обавезно'),
    cena: Yup.string().required('Поље је обавезно'),
    cenaRsd: Yup.string().required('Поље је обавезно'),
    // kratakNaziv: Yup.string().required('Поље је обавезно'),
})

export default function CreateInventoryType({
    modalOpen,
    setModal,
    setRefresh,
    setSeverityHandler,
    setSnackbarHandler,
}) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)

    const createInvType = async (values) => {
        try {
            setRefresh(true)
            const response = await axios.post('/api/inv-type', values)
            setModal(false)
            setSeverityHandler('success')
            setSnackbarHandler({
                open: true,
                message: response.data.message,
            })
            setRefresh(false)
        } catch (error) {
            console.log(error)
            setSeverityHandler('warning')
            setSnackbarHandler({
                open: true,
                message: error.response.data.message,
            })
        }
    }

    const body = (
        <>
            <div style={modalStyle} className={classes.paper}>
                <h2 className={classes.title}>Додавање инвентара</h2>
                {/* {groups.length > 0 ? ( */}
                <Formik
                    initialValues={{
                        naziv: '',
                        tip: 'Oprema',
                        kratakNaziv: '',
                        trajanjeGarancije: '',
                        cena: '',
                        valuta: 'RSD',
                        cenaRsd: '',
                    }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values) => createInvType(values)}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                        <Form>
                            <div className={classes.formDiv}>
                                <div className={classes.tableRow}>
                                    <span className={classes.tableRowLabel}>
                                        Назив
                                    </span>
                                    <TextfieldWrapper name="naziv" />
                                </div>
                                <div className={classes.tableRow}>
                                    <span className={classes.tableRowLabel}>
                                        Кратак назив
                                    </span>
                                    <TextfieldWrapper name="kratakNaziv" />
                                </div>
                                <div className={classes.tableRow}>
                                    <span className={classes.tableRowLabel}>
                                        Трајање гаранције
                                    </span>
                                    <TextfieldWrapper name="trajanjeGarancije" />
                                </div>
                                <div className={classes.tableRow}>
                                    <span className={classes.tableRowLabel}>
                                        Валута
                                    </span>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Select
                                            native
                                            onChange={(event) => {
                                                setFieldValue(
                                                    'valuta',
                                                    event.target.value
                                                )
                                                if (
                                                    event.target.value === 'RSD'
                                                ) {
                                                    setFieldValue(
                                                        'cenaRsd',
                                                        values.cena
                                                    )
                                                }
                                            }}
                                        >
                                            <option
                                                aria-label="None"
                                                value="RSD"
                                            >
                                                Динар
                                            </option>
                                            <option
                                                aria-label="None"
                                                value="EUR"
                                            >
                                                Евро
                                            </option>
                                            <option
                                                aria-label="None"
                                                value="USD"
                                            >
                                                Амерички долар
                                            </option>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className={classes.tableRow}>
                                    <span className={classes.tableRowLabel}>
                                        Цена
                                    </span>
                                    <TextField
                                        name="cena"
                                        value={values.cena}
                                        onChange={(event) => {
                                            setFieldValue(
                                                'cena',
                                                event.target.value
                                            )
                                            if (values.valuta === 'RSD') {
                                                setFieldValue(
                                                    'cenaRsd',
                                                    event.target.value
                                                )
                                            }
                                        }}
                                    />
                                </div>
                                {values.valuta !== 'RSD' && (
                                    <div className={classes.tableRow}>
                                        <span className={classes.tableRowLabel}>
                                            Цена у динарима
                                        </span>
                                        <TextfieldWrapper name="cenaRsd" />
                                    </div>
                                )}
                                <div className={classes.tableRow}>
                                    <span className={classes.tableRowLabel}>
                                        Тип
                                    </span>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Select
                                            native
                                            onChange={(event) => {
                                                setFieldValue(
                                                    'tip',
                                                    event.target.value
                                                )
                                            }}
                                        >
                                            {/* <option aria-label="None" value="">
                                                Изаберите надређену групу
                                            </option> */}
                                            <option
                                                aria-label="None"
                                                value="Oprema"
                                            >
                                                Опрема
                                            </option>
                                            <option
                                                aria-label="None"
                                                value="Materijal"
                                            >
                                                Материјал
                                            </option>
                                            <option
                                                aria-label="None"
                                                value="Radovi"
                                            >
                                                Радови
                                            </option>
                                        </Select>
                                    </FormControl>
                                </div>

                                <ButtonWrapper
                                    type="submit"
                                    fullWidth
                                    disabled={isSubmitting}
                                    startIcon={
                                        isSubmitting ? (
                                            <CircularProgress size="0.9rem" />
                                        ) : undefined
                                    }
                                    variant="contained"
                                    style={{
                                        // backgroundColor: '#337ab7',
                                        // color: 'white',
                                        // height: 'unset',
                                        // fontSize: '0.75rem',
                                        // // padding: '8px 12px',
                                        // fontWeight: 'bold',
                                        margin: '10px 0',
                                    }}
                                >
                                    Направи
                                </ButtonWrapper>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    onClick={() => setModal(false)}
                                >
                                    Одустани
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
                {/* ) : (
                    <SimpleModal />
                )} */}
            </div>
        </>
    )

    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={() => setModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}

CreateInventoryType.propTypes = {
    modalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    setRefresh: PropTypes.func,
    setSeverityHandler: PropTypes.func,
    setSnackbarHandler: PropTypes.func,
}

CreateInventoryType.defaultProps = {
    modalOpen: false,
    setModal: () => {},
    setRefresh: () => {},
    setSeverityHandler: () => {},
    setSnackbarHandler: () => {},
}
