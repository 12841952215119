import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: 600,
        marginTop: 100,
    },
    btn: {
        marginTop: 20,
    },
    text: {
        marginBottom: theme.spacing(1),
        color: '#737373',
        fontWeight: 900,
        marginTop: 20,
        fontSize: '1.4rem',
    },
}))

const NotFound = () => {
    const classes = useStyles()
    const history = useHistory()

    const handleClick = () => {
        history.push('/app')
    }

    return (
        <Grid container justifyContent="center">
            <Grid item className={classes.wrapper}>
                <Typography className={classes.text} align="left">
                    Страница коју тражите не постоји Кликните на дугме испод да
                    бисте се вратили на почетну страницу.
                </Typography>
                <Button
                    variant="contained"
                    onClick={handleClick}
                    className={classes.btn}
                    color="primary"
                >
                    Почетна страница
                </Button>
            </Grid>
        </Grid>
    )
}

export default NotFound
