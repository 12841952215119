import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
    btn: {
        background: '#C54F4F',
        marginLeft: 'auto',
        color: '#FFF',
        '&:hover': {
            background: '#D86565',
        },
    },
}))

const LogoutBtn = () => {
    const classes = useStyles()
    const history = useHistory()

    const signoutHandler = () => {
        window.localStorage.removeItem('token')
        history.push('/')
    }

    return (
        <Button
            variant="contained"
            className={classes.btn}
            onClick={signoutHandler}
        >
            Крај рада
        </Button>
    )
}

export default LogoutBtn
