import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
import { axiosAuth as axios } from '../../util/axios-instance'
import SimpleModal from '../../components/Modal/Modal'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
    removeIcon: {
        cursor: 'pointer',
        color: 'red',
        fontSize: 20,
        margin: '0 0 0 10px',
    },
}))

const FORM_VALIDATION = Yup.object().shape({
    naziv: Yup.string().required('Поље је обавезно'),
    broj: Yup.number().required('Поље је обавезно'),
    datumPocetka: Yup.date().required('Поље је обавезно'),
    rokTrajanja: Yup.number().required('Поље је обавезно'),
})

export default function EditRole({ id, modalOpen, setModal }) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)
    const [role, setRole] = useState({})

    const getRole = async () => {
        try {
            const response = await axios.get(`/api/role/${id}`)
            setRole(response.data.role)
        } catch (error) {
            console.log(error)
        }
    }

    const editRole = async (values) => {
        try {
            await axios.patch(`/api/role/${id}`, {
                imeRole: values.imeRole,
                kratakNaziv: values.kratakNaziv,
            })
            setModal(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getRole()
    }, [id])

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 className={classes.title}>Изменa ролe</h2>
            {role ? (
                <Formik
                    initialValues={{
                        imeRole: role.imeRole ? role.imeRole : '',
                        kratakNaziv: role.kratakNaziv ? role.kratakNaziv : '',
                    }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values) => editRole(values)}
                >
                    <Form>
                        <div className={classes.formDiv}>
                            <div className={classes.tableRow}>
                                <span className={classes.tableRowLabel}>
                                    Назив роле
                                </span>
                                <TextfieldWrapper
                                    name="imeRole"
                                    value={role.imeRole}
                                />
                            </div>
                            <div className={classes.tableRow}>
                                <span className={classes.tableRowLabel}>
                                    Кратак назив роле
                                </span>
                                <TextfieldWrapper
                                    name="kratakNaziv"
                                    value={role.kratakNaziv}
                                />
                            </div>

                            <ButtonWrapper
                                type="submit"
                                fullWidth
                                style={{
                                    backgroundColor: '#337ab7',
                                    color: 'white',
                                    height: 'unset',
                                    fontSize: '0.75rem',
                                    // padding: '8px 12px',
                                    fontWeight: 'bold',
                                    margin: '10px 0',
                                }}
                            >
                                Сачувај измене
                            </ButtonWrapper>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={() => setModal(false)}
                            >
                                Одустани
                            </Button>
                        </div>
                    </Form>
                </Formik>
            ) : (
                <SimpleModal />
            )}
        </div>
    )

    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={() => setModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}

EditRole.propTypes = {
    id: PropTypes.string,
    modalOpen: PropTypes.bool,
    setModal: PropTypes.func,
}

EditRole.defaultProps = {
    id: '',
    modalOpen: false,
    setModal: () => {},
}
