import React from 'react'
import {
    createStyles,
    LinearProgress,
    Typography,
    withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import FileHeader from './FileHeader'

const ErrorLinearProgress = withStyles((theme) =>
    createStyles({
        bar: {
            backgroundColor: theme.palette.error.main,
        },
    })
)(LinearProgress)

const UploadError = ({ file, onDelete, errors }) => (
    <>
        <FileHeader file={file} onDelete={onDelete} />
        <ErrorLinearProgress variant="determinate" value={100} />
        {errors.map((error) => (
            <div key={error.code}>
                <Typography color="error">
                    {error.message.includes('type')
                        ? 'Погрешан тип фајла'
                        : 'Фајл је превелик'}
                </Typography>
            </div>
        ))}
    </>
)

export default UploadError

UploadError.propTypes = {
    file: PropTypes.instanceOf(File).isRequired,
    onDelete: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.any),
}

UploadError.defaultProps = {
    errors: [],
}
