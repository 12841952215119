import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import { Button, CircularProgress, TextField } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextfieldWrapper from '../../../../components/FormsUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../../../components/FormsUI/Button/ButtonWrapper'
import { axiosAuth as axios } from '../../../../util/axios-instance'
import latToCyr from '../../../../util/latToCyr'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
    removeIcon: {
        cursor: 'pointer',
        color: 'red',
        fontSize: 20,
        margin: '0 0 0 10px',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10,
    },
}))

const FORM_VALIDATION = Yup.object().shape({
    kolicina: Yup.number().required('Поље је обавезно'),
    cenaRsd: Yup.string().required('Поље је обавезно'),
    // sifra: Yup.string().required('Поље је обавезно'),
})

export default function EditLocationInventory({
    data,
    modalOpen,
    setModal,
    setRefresh,
    setSeverityHandler,
    setSnackbarHandler,
}) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)
    const [invType, setInvType] = useState(data.invType.tip)
    const [materials, setMaterials] = useState([])
    const [currency, setCurrency] = useState(data.invType.valuta)
    const [price, setPrice] = useState(data.invType.cena)

    const editInventory = async (values) => {
        try {
            setRefresh(true)
            const response = await axios.patch(
                `/api/inventory/${data._id}`,
                values
            )
            setModal(false)
            setSeverityHandler('success')
            setSnackbarHandler({
                open: true,
                message: latToCyr(response.data.message),
            })
            setRefresh(false)
        } catch (error) {
            console.log(error)
            setSeverityHandler('warning')
            setSnackbarHandler({
                open: true,
                message: latToCyr(error.response.data.message),
            })
        }
    }

    const getMaterials = async () => {
        try {
            const response = await axios.get(`/api/inv-type?tip=${invType}`)
            setMaterials(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (invType !== '') getMaterials()
    }, [invType])

    const body = (
        <>
            <div style={modalStyle} className={classes.paper}>
                <h2 className={classes.title}>Измена инвентара</h2>
                {/* {groups.length > 0 ? ( */}
                <Formik
                    initialValues={{
                        invType: data.invType._id,
                        lokacija: data.lokacija._id,
                        kolicina: data.kolicina,
                        cenaRsd: data.cenaRsd,
                        datumPrimopredaje: data.datumPrimopredaje
                            .split('/')
                            .reverse()
                            .join('-'),
                    }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values) => editInventory(values)}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                        <Form>
                            <div className={classes.formDiv}>
                                <div className={classes.tableRow}>
                                    <span className={classes.tableRowLabel}>
                                        Количина
                                    </span>
                                    <TextfieldWrapper
                                        value={values.kolicina}
                                        name="kolicina"
                                    />
                                </div>
                                <div className={classes.tableRow}>
                                    <span className={classes.tableRowLabel}>
                                        Датум примопредаје
                                    </span>
                                    <TextField
                                        id="date"
                                        required
                                        type="date"
                                        // defaultValue="2017-05-24"
                                        value={values.datumPrimopredaje}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(event) => {
                                            setFieldValue(
                                                'datumPrimopredaje',
                                                event.target.value
                                            )
                                        }}
                                    />
                                </div>
                                <div className={classes.tableRow}>
                                    <span className={classes.tableRowLabel}>
                                        Тип
                                    </span>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <Select
                                            required
                                            native
                                            onChange={(event) => {
                                                setInvType(event.target.value)
                                            }}
                                            value={invType}
                                        >
                                            <option aria-label="None" value="">
                                                Изаберите тип
                                            </option>
                                            <option
                                                aria-label="None"
                                                value="Materijal"
                                            >
                                                Материјал
                                            </option>
                                            <option
                                                aria-label="None"
                                                value="Oprema"
                                            >
                                                Опрема
                                            </option>
                                            <option
                                                aria-label="None"
                                                value="Radovi"
                                            >
                                                Радови
                                            </option>
                                        </Select>
                                    </FormControl>
                                </div>

                                {invType !== '' && (
                                    <div className={classes.tableRow}>
                                        <span className={classes.tableRowLabel}>
                                            Врста
                                        </span>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <Select
                                                required
                                                native
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        'invType',
                                                        event.target.value
                                                    )
                                                    setCurrency(
                                                        event.target.selectedOptions[0].getAttribute(
                                                            'valuta'
                                                        )
                                                    )
                                                    setPrice(
                                                        event.target.selectedOptions[0].getAttribute(
                                                            'cena'
                                                        )
                                                    )
                                                    if (
                                                        event.target.selectedOptions[0].getAttribute(
                                                            'valuta'
                                                        ) === 'RSD'
                                                    ) {
                                                        setFieldValue(
                                                            'cenaRsd',
                                                            event.target.selectedOptions[0].getAttribute(
                                                                'cena'
                                                            )
                                                        )
                                                    } else {
                                                        setFieldValue(
                                                            'cenaRsd',
                                                            ''
                                                        )
                                                    }
                                                }}
                                                value={values.invType}
                                            >
                                                <option
                                                    aria-label="None"
                                                    value=""
                                                >
                                                    Изаберите врсту
                                                </option>
                                                {materials.map((material) => (
                                                    <option
                                                        aria-label="None"
                                                        value={material._id}
                                                        key={material._id}
                                                        valuta={material.valuta}
                                                        cena={material.cena}
                                                    >
                                                        {material.naziv}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}

                                {currency !== '' && (
                                    <div className={classes.tableRow}>
                                        <span className={classes.tableRowLabel}>
                                            Цена
                                        </span>
                                        <TextField
                                            disabled
                                            value={`${price} ${currency}`}
                                        />
                                    </div>
                                )}

                                {currency !== 'RSD' && currency !== '' && (
                                    <div className={classes.tableRow}>
                                        <span className={classes.tableRowLabel}>
                                            Цена у динарима
                                        </span>
                                        <TextfieldWrapper
                                            name="cenaRsd"
                                            value={values.cenaRsd}
                                        />
                                    </div>
                                )}

                                <ButtonWrapper
                                    type="submit"
                                    fullWidth
                                    disabled={isSubmitting}
                                    startIcon={
                                        isSubmitting ? (
                                            <CircularProgress size="0.9rem" />
                                        ) : undefined
                                    }
                                    variant="contained"
                                    style={{
                                        margin: '10px 0',
                                    }}
                                >
                                    Измени
                                </ButtonWrapper>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    onClick={() => setModal(false)}
                                >
                                    Одустани
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
                {/* ) : (
                    <SimpleModal />
                )} */}
            </div>
        </>
    )

    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={() => setModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}

EditLocationInventory.propTypes = {
    modalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    setRefresh: PropTypes.func,
    setSeverityHandler: PropTypes.func,
    setSnackbarHandler: PropTypes.func,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
}

EditLocationInventory.defaultProps = {
    /* eslint-disable */
    modalOpen: false,
    setModal: () => {},
    setRefresh: () => {},
    setSeverityHandler: () => {},
    setSnackbarHandler: () => {},
}
