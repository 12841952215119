/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, FormControl, Grid, Typography } from '@material-ui/core'
import download from 'downloadjs'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import NativeSelect from '@material-ui/core/NativeSelect'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import { axiosAuth as axios } from '../../util/axios-instance'
import GetAppIcon from '@material-ui/icons/GetApp'
import FileModal from '../../pages/Locations/SingleLocation/SingleLocationModals/FileModal'
import DataModal from '../../pages/Locations/SingleLocation/SingleLocationModals/DataModal'
import FileModalContract from '../../pages/Locations/SingleLocation/SingleLocationModals/FileModalContract'

const useStyles = makeStyles({
    tr: {
        border: '1px solid #dddddd',
    },
    td: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: '0 5px',
        width: 300,
    },
    th: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: '0 5px',
        backgroundColor: '#417AB4',
        color: 'white',
        width: 300,
    },
    btnPreuzmi: {
        marginLeft: 10,
        background: '#1E8637',
        color: '#FFFFFF',
        minWidth: 100,
    },
    listWrap: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    fileTxt: {
        textDecoration: 'underline',
        textTransform: 'none',
        textAlign: 'left',
    },
})

const ContractFiles = ({ files }) => {
    const classes = useStyles()
    const [fileOpen, setFileOpen] = useState(false)
    const [dataOpen, setDataOpen] = useState(false)
    // console.log(files.ugovor._id)

    const downloadFile = async (fId, fName, mimetype) => {
        try {
            const response = await axios.get(
                `/api/files/contract/${files?.ugovor?._id}/${fId}`,
                {
                    responseType: 'blob',
                }
            )

            return download(response.data, fName, mimetype)
        } catch (error) {
            console.log(error)
        }
    }

    console.log(files)
    const showFiles = () => {
        setFileOpen(true)
    }

    const handleCloseFile = () => {
        setFileOpen(false)
    }

    return (
        <>
            <Grid container>
                {files?.ugovor?.fajlovi?.map((item) => (
                    <Grid item xs={12} key={item._id}>
                        <Button
                            onClick={() => {
                                downloadFile(
                                    item._id,
                                    item.originalnoIme,
                                    item.mimetype
                                )
                            }}
                        >
                            <Typography
                                variant="body2"
                                className={classes.fileTxt}
                            >
                                {item.fajl}
                            </Typography>
                        </Button>
                    </Grid>
                ))}
            </Grid>
            {/* <FileModalContract
                open={fileOpen}
                close={handleCloseFile}
                files={files.ugovor.fajlovi}
                id={files.ugovor._id}
                locationId={files._id}
                title="Доступни фајлови уговора"
                type="contract"
            />

            <Button
                disabled={files.ugovor.fajlovi <= 0}
                onClick={showFiles}
                className={classes.btn}
                variant="outlined"
                color="primary"
            >
                Прикажи документа
            </Button> */}
        </>
    )
}

ContractFiles.propTypes = {
    files: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default ContractFiles
