const activities = [
    {
        kratakNaziv: 'A1',
        naziv: 'Урађен предлог трасе',
        prethodna: '',
        naredna: 'A2',
        odgovoran: ['Пројектант'],
        rok: '30',
        podaci: [],
        dokumenta:
            'Закачити документ са сликом кластера или цртежом (ГИС) трасе (формат ТВД)',
        brojFajlova: 1,
        id: 1,
    },
    {
        kratakNaziv: 'A2',
        naziv: 'Одобрен предлог трасе',
        prethodna: 'A1',
        naredna: 'A3',
        odgovoran: ['Инвеститор'],
        rok: '7',
        podaci: [],
        dokumenta:
            'Закачити документ са пројектним задатком потписан од стране Пројектанта, MTT и Оператора',
        brojFajlova: 1,
        id: 2,
    },
    {
        id: 3,
        kratakNaziv: 'A3',
        naziv: 'Предато идејно решење за добијање локацијских услова',
        prethodna: 'A2',
        naredna: 'A3.0',
        odgovoran: ['Пројектант'],
        rok: '15',
        podaci: [
            {
                naziv: 'Број ЦЕОП предмента',
                vrednost: '',
            },
        ],
        dokumenta:
            'Идејно решење, Потврда општине о предатом захтеву, Потврда о уплати',
        brojFajlova: 3,
    },
    {
        id: 4,
        kratakNaziv: 'A3.0',
        naziv: 'Контролна активност за А3',
        kontrolna: true,
        podAktivnosti: ['A3', 'A3.1', 'A3.2', 'A3.3'],
        prethodna: 'A3',
        naredna: 'A4',
        odgovoran: ['Пројектант'],
        rok: 'клизно',
        podaci: [],
        dokumenta: 'Решење о одбијеном захтеву',
        brojFajlova: 0,
    },
    {
        id: 5,
        kratakNaziv: 'A3.1',
        sub: true,
        naziv: 'Урађен ревидиран предлог трасе усклађен са примедбама',
        prethodna: 'A3.0',
        podAktivnosti: ['A3', 'A3.0', 'A3.2', 'A3.3'],
        naredna: 'A3.2',
        odgovoran: ['Пројектант'],
        rok: '15',
        dokumenta:
            'Закачити документ са ревидираном сликом кластера или цртежом (ГИС) трасе (формат ТВД)',
        brojFajlova: 1,
    },
    {
        id: 6,
        kratakNaziv: 'A3.2',
        sub: true,
        kontrolna: true,
        podAktivnosti: ['A3', 'A3.1', 'A3.0', 'A3.3'],
        naziv: 'Одобрен предлог ревидиране трасе',
        prethodna: 'A3.1',
        naredna: 'A3.3',
        odgovoran: ['Инвеститор'],
        rok: 'клизно',
        dokumenta:
            'Закачити документ са ревидираним пројектним задатком потписан од стране Пројектанта, MTT и Оператора',
        brojFajlova: 1,
    },
    {
        id: 7,
        kratakNaziv: 'A3.3',
        sub: true,
        naziv: 'Предат ревидиран идејни пројекат (или допуна захтева) за добијање локацијских услова',
        prethodna: 'A3.2',
        naredna: 'A3.0',
        podAktivnosti: ['A3', 'A3.1', 'A3.2', 'A3.0'],
        odgovoran: ['Пројектант'],
        rok: '15',
        dokumenta:
            'Идејно решење, Потврда општине о предатом захтеву, Потврда о допуни захтева',
        brojFajlova: 3,
    },
    {
        id: 8,
        kratakNaziv: 'A4',
        naziv: 'Добијени локацијски услови',
        prethodna: 'A3.0',
        naredna: 'A6',
        odgovoran: ['Пројектант'],
        rok: 'клизно',
        dokumenta: 'Решење о добијању локацијских услова',
        brojFajlova: 1,
    },
    {
        id: 9,
        kratakNaziv: 'A5',
        naziv: 'Плаћене таксе имаоцима јавних овлашћења',
        prethodna: 'A2',
        naredna: 'A6',
        odgovoran: ['Пројектант'],
        rok: '45',
        podaci: [],
        brojFajlova: 1,
    },
    {
        id: 10,
        kratakNaziv: 'A6',
        naziv: 'Предат ПГД/ИДП за добијање ГД',
        prethodna: 'A5',
        naredna: 'A6.0',
        odgovoran: ['Пројектант'],
        rok: 'клизно',
        dokumenta: 'ПГД/ИДП, Потврда о пријему ПГД/ИДП',
        brojFajlova: 2,
    },
    {
        id: 11,
        kontrolna: true,
        naziv: 'Контролна активност за А6',
        kratakNaziv: 'A6.0',
        podAktivnosti: ['A6', 'A6.1'],
        prethodna: 'A6',
        naredna: 'A7',
        odgovoran: ['Пројектант'],
        rok: 'клизно',
        dokumenta: 'Решење од одбијању захтева',
        brojFajlova: 0,
    },
    {
        id: 12,
        kratakNaziv: 'A6.1',
        sub: true,
        naziv: 'Израђен и предат ревидиран Пројекат за грађевинску дозволу',
        prethodna: 'A6.0',
        naredna: 'A6.0',
        podAktivnosti: ['A6', 'A6.0'],
        odgovoran: ['Пројектант'],
        rok: '15',
        dokumenta:
            'Потврда о пријему ревидераног ПДГ (или допуне) и ревидиран ПДГ',
        brojFajlova: 2,
    },

    {
        id: 13,
        kratakNaziv: 'A7',
        naziv: 'Добијена ГД или решење о одобрењу за изградњу',
        prethodna: 'A6.0',
        naredna: 'A8',
        odgovoran: ['Пројектант'],
        rok: '30',
        dokumenta: 'Грађевинска дозвола или решење о одобрењу за изградњу',
        brojFajlova: 1,
    },

    {
        id: 14,
        kratakNaziv: 'A8',
        naziv: 'Потписивање Уговора са имаоцима јавних овлашћења',
        prethodna: 'A7',
        naredna: 'A9',
        odgovoran: ['Инвеститор'],
        rok: '30',
        dokumenta: 'Закачити уговоре или сагласности за сваког имаоца',
        brojFajlova: 0,
    },
    {
        id: 15,
        kratakNaziv: 'A9',
        naziv: 'Налог за извођење радова',
        prethodna: 'A8',
        naredna: 'A10',
        odgovoran: ['Инвеститор'],
        rok: '30',
        podaci: [
            {
                naziv: 'Датум налога за извођење радова',
                vrednost: '',
            },
        ],
        dokumenta: 'Налог за извођење радова',
        brojFajlova: 1,
    },
    {
        id: 16,
        kratakNaziv: 'A10',
        naziv: 'Дозвола за раскопавање',
        prethodna: 'A9',
        naredna: 'A11',
        odgovoran: ['Извођач'],
        rok: '30',
        dokumenta: 'Дозвола за раскопавање',
        brojFajlova: 1,
    },
    {
        id: 17,
        kratakNaziv: 'A11',
        naziv: 'Израда ПЗИ',
        prethodna: 'A10',
        naredna: 'A11.0',
        odgovoran: ['Извођач'],
        rok: '30',
        dokumenta: 'ПЗИ Пројекат за Извођење',
        brojFajlova: 1,
    },

    {
        id: 18,
        kontrolna: true,
        kratakNaziv: 'A11.0',
        podAktivnosti: ['A11', 'A11.1'],
        naziv: 'Овера ПЗИ од стране надзора',
        prethodna: 'A11',
        naredna: 'A12',
        odgovoran: ['Надзор'],
        rok: '15',
        dokumenta: 'Оверен ПЗИ',
        brojFajlova: 1,
    },
    {
        id: 19,
        kratakNaziv: 'A11.1',
        sub: true,
        naziv: 'Израда ревидираног ПЗИ',
        prethodna: 'A11.0',
        naredna: 'A11.0',
        podAktivnosti: ['A11', 'A11.0'],
        odgovoran: ['Извођач'],
        rok: '15',
        dokumenta: 'Ревидиран ПЗИ',
        brojFajlova: 1,
    },

    {
        id: 20,
        kontrolna: true,
        kratakNaziv: 'A12',
        podAktivnosti: ['A11.0'],
        naziv: 'Одобрење ПЗИ од стране  инвеститора и давање сагласности за почетак радова',
        prethodna: 'A11.0',
        naredna: 'A13',
        odgovoran: ['Инвеститор'],
        rok: '15',
        brojFajlova: 1,
    },
    {
        id: 21,
        kratakNaziv: 'A13',
        naziv: 'Израда ИСХСХ плана',
        prethodna: 'A12',
        naredna: 'A14',
        odgovoran: ['Извођач'],
        rok: '30',
        dokumenta: 'ИСХСХ план',
        brojFajlova: 1,
    },
    {
        id: 22,
        kratakNaziv: 'A14',
        sub: true,
        naziv: 'Пријава радова',
        prethodna: 'A13',
        naredna: 'A15',
        odgovoran: ['Извођач'],
        rok: 'клизно',
        dokumenta: 'Разна документација за пријаву радова',
        brojFajlova: 1,
    },
    {
        id: 23,
        kontrolna: true,
        kratakNaziv: 'A15',
        naziv: 'Увођење у посао',
        podAktivnosti: ['A14'],
        prethodna: 'A14',
        naredna: 'A16',
        odgovoran: ['Надзор'],
        rok: 'клизно',
        dokumenta: 'Налог за отпочињање радова',
        brojFajlova: 1,
    },
    {
        id: 24,
        kratakNaziv: 'A16',
        naziv: 'Унос документације о изградњи',
        prethodna: 'A15',
        naredna: 'A17',
        odgovoran: ['Извођач'],
        rok: 'клизно',
        dokumenta: 'Грађевински дневник, Грађевинска књига, Књига инспекције',
        brojFajlova: 3,
    },
    {
        id: 25,
        kratakNaziv: 'A17',
        naziv: 'Изграђена траса према пројекту',
        prethodna: 'A16',
        naredna: 'A18',
        odgovoran: ['Извођач'],
        rok: 'клизно',
        brojFajlova: 0,
    },
    {
        id: 26,
        kratakNaziv: 'A18',
        naziv: 'Достављен ПИО и пратећа документација',
        prethodna: 'A17',
        naredna: 'A18.0',
        odgovoran: ['Извођач'],
        rok: 'клизно',
        dokumenta: 'Пројекат Изведног Објекта ',
        brojFajlova: 1,
    },

    {
        id: 27,
        kontrolna: true,
        naziv: 'Овера ПИО',
        kratakNaziv: 'A18.0',
        podAktivnosti: ['A18', 'A18.1'],
        prethodna: 'A18',
        naredna: 'A19',
        odgovoran: ['Надзор'],
        rok: 'клизно',
        dokumenta: 'Оверен ПИО или примедбе',
        brojFajlova: 1,
    },
    {
        id: 28,
        kratakNaziv: 'A18.1',
        sub: true,
        naziv: 'Достављен ревидиран ПИО и пратећа документација',
        prethodna: 'A18.0',
        naredna: 'A18.0',
        podAktivnosti: ['A18', 'A18.0'],
        odgovoran: ['Извођач'],
        rok: '15',
        dokumenta: 'Ревидиран ПИО',
        brojFajlova: 1,
    },
    {
        id: 29,
        kratakNaziv: 'A19',
        naziv: 'Потписивање записника о примопредаји',
        prethodna: 'A18.0',
        naredna: 'A20',
        odgovoran: ['Извођач'],
        rok: '15',
        dokumenta: 'Записник о примопредаји',
        brojFajlova: 1,
    },
    {
        id: 30,
        kratakNaziv: 'A20',
        naziv: 'Упис кластера у катастар',
        prethodna: 'A19',
        naredna: 'A21',
        odgovoran: ['Извођач'],
        rok: '15',
        brojFajlova: 0,
    },
    {
        id: 31,
        kratakNaziv: 'A21',
        naziv: 'Добијено решење о упису у катастар',
        prethodna: 'A20',
        naredna: 'A22',
        odgovoran: ['Извођач'],
        rok: '15',
        dokumenta: 'Решење о упису у катастар',
        brojFajlova: 1,
    },
    {
        id: 32,
        kratakNaziv: 'A22',
        naziv: 'Предато за употребну дозволу',
        prethodna: 'A21',
        naredna: 'A23',
        odgovoran: ['Извођач'],
        rok: '15',
        brojFajlova: 0,
    },
    {
        id: 33,
        kratakNaziv: 'A23',
        naziv: 'Прибављена употребна дозвола',
        prethodna: 'A22',
        naredna: '',
        odgovoran: ['Извођач'],
        rok: '15',
        dokumenta: 'Употребна дозвола',
        brojFajlova: 1,
    },
]

export default activities
