import moment from 'moment'
import latToCyr from './latToCyr'

export const reduceUser = (dataArr) =>
    dataArr.reduce((acc, currentEl) => {
        const addElement = {
            ime: currentEl.ime,
            prezime: currentEl.prezime,
            email: currentEl.email,
            emailPotvrdjen: currentEl.emailPotvrdjen === false ? 'Не' : 'Да',
            rola: currentEl.rola,
            status: currentEl.status,
            grupa: currentEl.grupa ? currentEl.grupa?.naziv : 'Нема групу',
            tip: currentEl.grupa?.tip,
            nadredjenaGrupa:
                currentEl.grupa && currentEl.grupa?.nadredjenaGrupa
                    ? currentEl.grupa?.nadredjenaGrupa?.naziv
                    : '/',
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceUserExcel = (dataArr) =>
    dataArr.reduce((acc, currentEl) => {
        const addElement = {
            Име: currentEl.ime,
            Презиме: currentEl.prezime,
            'Е-пошта': currentEl.email,
            'Е-пошта потврђена':
                currentEl.emailPotvrdjen === false ? 'Не' : 'Да',
            Рола: currentEl.rola,
            Статус: currentEl.status,
            Група: currentEl.grupa ? currentEl.grupa?.naziv : 'Нема групу',
            Тип: currentEl.grupa?.tip,
            'Надређена група':
                currentEl.grupa && currentEl.grupa?.nadredjenaGrupa
                    ? currentEl.grupa?.nadredjenaGrupa?.naziv
                    : '/',
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceLocations = (dataArr, allMightyLocations) =>
    dataArr.reduce((acc, currentEl) => {
        const groupArray = [...currentEl.odgovoran, ...allMightyLocations]
            .filter((el) => el.tip.includes('Izvođač'))
            .map((el) => el.naziv)
            .join(', ')
        const addElement = {
            naziv: currentEl.naziv,
            prioritet: currentEl?.prioritet?.naziv,
            nazivUgovora: currentEl?.ugovor?.naziv,

            odgovoran: groupArray,
            status: latToCyr(currentEl.status),
        }
        acc.push(addElement)
        return acc
    }, [])
export const reduceLocationsExcel = (dataArr, allMightyLocations) =>
    dataArr.reduce((acc, currentEl) => {
        const groupArray = [...currentEl.odgovoran, ...allMightyLocations]
            .filter((el) => el.tip.includes('Izvođač'))
            .map((el) => el.naziv)
            .join(', ')
        const addElement = {
            Назив: currentEl.naziv,
            Приоритет: currentEl?.prioritet?.naziv,
            'Јавни позив': currentEl?.ugovor?.naziv,

            Одговоран: groupArray,
            Статус: latToCyr(currentEl.status),
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceDetailedLocationsExcel = (dataArr, allMightyLocations) =>
    dataArr.reduce((acc, currentEl) => {
        const groupArray = [...currentEl.odgovoran, ...allMightyLocations]
            .filter((el) => el.tip.includes('Izvođač'))
            .map((el) => el.naziv)
            .join(', ')

        const activities = currentEl.aktivnosti.map((akt) => ({
            kratakNaziv: akt.kratakNaziv,
            naziv: akt.naziv,
            status: akt.status,
            podaci:
                akt?.podaci?.length > 0
                    ? akt.podaci
                          .map(
                              (podatak) =>
                                  `${podatak.naziv}: ${podatak.vrednost}`
                          )
                          .join(', ')
                    : '',
            dokumenta: akt?.dokumenta ? akt.dokumenta : '',
        }))

        const reducedActivities = activities.reduce(
            (obj, item) =>
                Object.assign(obj, { [item.kratakNaziv]: { ...item } }),
            {}
        )

        /* eslint-disable */
        const addElement = {
            Назив: currentEl.naziv,
            Приоритет: currentEl?.prioritet?.naziv,
            'Јавни позив': currentEl?.ugovor?.naziv,

            Одговоран: groupArray,
            Статус: latToCyr(currentEl.status),
            'Просечна дужина рова': currentEl.prosecnaDuzinaRova
                ? currentEl.prosecnaDuzinaRova
                : '',
            Општина: currentEl.opstina ? currentEl.opstina : '',
            'Број домаћинстава': currentEl.brojDomacinstava
                ? currentEl.brojDomacinstava
                : '',
            'Дужина из пријаве': currentEl.duzinaIzPrijave
                ? currentEl.duzinaIzPrijave
                : '',
            'Број индиректно покривених домаћинстава':
                currentEl.brojIndirektnoPokrivenihDomacinstava
                    ? currentEl.brojIndirektnoPokrivenihDomacinstava
                    : '',
            'Број индиректно покривених школа':
                currentEl.brojIndirektnoPokrivenihSkola
                    ? currentEl.brojIndirektnoPokrivenihSkola
                    : '',
            'Број школа': currentEl.brojSkola ? currentEl.brojSkola : '',
            'Број додатних школа': currentEl.brojDodatnihSkola
                ? currentEl.brojDodatnihSkola
                : '',
            Напомена: currentEl.napomena ? currentEl.napomena : '',
            'Геокоординате оператора': currentEl.geokoordinateOperatora
                ? currentEl.geokoordinateOperatora
                : '',
            'Геокоординате објекта': currentEl.geokoordinateObjekta
                ? currentEl.geokoordinateObjekta
                : '',
            'Дужина оптичких траса': currentEl.duzinaOptickihTrasa
                ? currentEl.duzinaOptickihTrasa
                : '',
            'Број насеља': currentEl.brojNaselja ? currentEl.brojNaselja : '',
            Насеља:
                currentEl.naselja?.length > 0
                    ? currentEl.naselja
                          .map((naselje) => naselje.naziv)
                          .join(', ')
                    : '',
            'Успутна насеља':
                currentEl.usputnaNaselja?.length > 0
                    ? currentEl.usputnaNaselja
                          .map((naselje) => naselje.naziv)
                          .join(', ')
                    : '',
            'Јавни позив': currentEl.javniPoziv ? currentEl.javniPoziv : '',
            'Одобрење за рад': currentEl.odobrenjeZaRad
                ? currentEl.odobrenjeZaRad
                : '',
            'А1 назив': reducedActivities['A1']?.naziv,
            'А1 статус': reducedActivities['A1']?.status,
            'А1 документа': reducedActivities['A1']?.dokumenta,
            'А1 подаци': reducedActivities['A1']?.podaci,
            'А2 назив': reducedActivities['A2']?.naziv,
            'А2 статус': reducedActivities['A2']?.status,
            'А2 документа': reducedActivities['A2']?.dokumenta,
            'А2 подаци': reducedActivities['A2']?.podaci,
            'А3 назив': reducedActivities['A3']?.naziv,
            'А3 статус': reducedActivities['A3']?.status,
            'А3 документа': reducedActivities['A3']?.dokumenta,
            'А3 подаци': reducedActivities['A3']?.podaci,
            'А3.0 назив': reducedActivities['A3.0']?.naziv,
            'А3.0 статус': reducedActivities['A3.0']?.status,
            'А3.0 документа': reducedActivities['A3.0']?.dokumenta,
            'А3.0 подаци': reducedActivities['A3.0']?.podaci,
            'А3.1 назив': reducedActivities['A3.1']?.naziv,
            'А3.1 статус': reducedActivities['A3.1']?.status,
            'А3.1 документа': reducedActivities['A3.1']?.dokumenta,
            'А3.1 подаци': reducedActivities['A3.1']?.podaci,
            'А3.2 назив': reducedActivities['A3.2']?.naziv,
            'А3.2 статус': reducedActivities['A3.2']?.status,
            'А3.2 документа': reducedActivities['A3.2']?.dokumenta,
            'А3.2 подаци': reducedActivities['A3.2']?.podaci,
            'А3.3 назив': reducedActivities['A3.3']?.naziv,
            'А3.3 статус': reducedActivities['A3.3']?.status,
            'А3.3 документа': reducedActivities['A3.3']?.dokumenta,
            'А3.3 подаци': reducedActivities['A3.3']?.podaci,
            'А4 назив': reducedActivities['A4']?.naziv,
            'А4 статус': reducedActivities['A4']?.status,
            'А4 документа': reducedActivities['A4']?.dokumenta,
            'А4 подаци': reducedActivities['A4']?.podaci,
            'А5 назив': reducedActivities['A5']?.naziv,
            'А5 статус': reducedActivities['A5']?.status,
            'А5 документа': reducedActivities['A5']?.dokumenta,
            'А5 подаци': reducedActivities['A5']?.podaci,
            'А6 назив': reducedActivities['A6']?.naziv,
            'А6 статус': reducedActivities['A6']?.status,
            'А6 документа': reducedActivities['A6']?.dokumenta,
            'А6 подаци': reducedActivities['A6']?.podaci,
            'А7 назив': reducedActivities['A7']?.naziv,
            'А7 статус': reducedActivities['A7']?.status,
            'А7 документа': reducedActivities['A7']?.dokumenta,
            'А7 подаци': reducedActivities['A7']?.podaci,
            'А7.0 назив': reducedActivities['A7.0']?.naziv,
            'А7.0 статус': reducedActivities['A7.0']?.status,
            'А7.0 документа': reducedActivities['A7.0']?.dokumenta,
            'А7.0 подаци': reducedActivities['A7.0']?.podaci,
            'А7.1 назив': reducedActivities['A7.1']?.naziv,
            'А7.1 статус': reducedActivities['A7.1']?.status,
            'А7.1 документа': reducedActivities['A7.1']?.dokumenta,
            'А7.1 подаци': reducedActivities['A7.1']?.podaci,
            'А8 назив': reducedActivities['A8']?.naziv,
            'А8 статус': reducedActivities['A8']?.status,
            'А8 документа': reducedActivities['A8']?.dokumenta,
            'А8 подаци': reducedActivities['A8']?.podaci,
            'А9 назив': reducedActivities['A9']?.naziv,
            'А9 статус': reducedActivities['A9']?.status,
            'А9 документа': reducedActivities['A9']?.dokumenta,
            'А9 подаци': reducedActivities['A9']?.podaci,
            'А10 назив': reducedActivities['A10']?.naziv,
            'А10 статус': reducedActivities['A10']?.status,
            'А10 документа': reducedActivities['A10']?.dokumenta,
            'А10 подаци': reducedActivities['A10']?.podaci,
            'А11 назив': reducedActivities['A11']?.naziv,
            'А11 статус': reducedActivities['A11']?.status,
            'А11 документа': reducedActivities['A11']?.dokumenta,
            'А11 подаци': reducedActivities['A11']?.podaci,
            'А12 назив': reducedActivities['A12']?.naziv,
            'А12 статус': reducedActivities['A12']?.status,
            'А12 документа': reducedActivities['A12']?.dokumenta,
            'А12 подаци': reducedActivities['A12']?.podaci,
            'А13 назив': reducedActivities['A13']?.naziv,
            'А13 статус': reducedActivities['A13']?.status,
            'А13 документа': reducedActivities['A13']?.dokumenta,
            'А13 подаци': reducedActivities['A13']?.podaci,
            'А14 назив': reducedActivities['A14']?.naziv,
            'А14 статус': reducedActivities['A14']?.status,
            'А14 документа': reducedActivities['A14']?.dokumenta,
            'А14 подаци': reducedActivities['A14']?.podaci,
            'А15 назив': reducedActivities['A15']?.naziv,
            'А15 статус': reducedActivities['A15']?.status,
            'А15 документа': reducedActivities['A15']?.dokumenta,
            'А15 подаци': reducedActivities['A15']?.podaci,
            'А16 назив': reducedActivities['A16']?.naziv,
            'А16 статус': reducedActivities['A16']?.status,
            'А16 документа': reducedActivities['A16']?.dokumenta,
            'А16 подаци': reducedActivities['A16']?.podaci,
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceContracts = (dataArr) =>
    dataArr.reduce((acc, currentEl) => {
        const addElement = {
            naziv: currentEl.naziv || '',
            broj: currentEl.broj || '',
            status: currentEl.status || '',
            datumPocetka: currentEl.datumPocetka
                ? moment(currentEl.datumPocetka).format('DD/MM/YYYY')
                : '',
            rokTrajanja: currentEl.rokTrajanja ? currentEl.rokTrajanja : '',
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceContractsExcel = (dataArr) =>
    dataArr.reduce((acc, currentEl) => {
        const addElement = {
            Назив: currentEl.naziv || '',
            Број: currentEl.broj || '',
            Статус: currentEl.status || '',
            'Датум почетка': currentEl.datumPocetka
                ? moment(currentEl.datumPocetka).format('DD/MM/YYYY')
                : '',
            'Рок трајања': currentEl.rokTrajanja ? currentEl.rokTrajanja : '',
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceGroups = (dataArr) =>
    dataArr.reduce((acc, currentEl) => {
        const addElement = {
            naziv: currentEl.naziv,
            kratakNaziv: currentEl.kratakNaziv,
            tip: currentEl.cirilica,
            nadredjenaGrupa: currentEl.nadredjenaGrupa?.naziv,
            sveLokacije: currentEl.sveVideci ? 'Да' : 'Не',
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceGroupsExcel = (dataArr) =>
    dataArr.reduce((acc, currentEl) => {
        const addElement = {
            Назив: currentEl.naziv,
            'Кратак назив': currentEl.kratakNaziv,
            Тип: currentEl.cirilica,
            'Надређена група': currentEl.nadredjenaGrupa?.naziv,
            'Види све кластере': currentEl.sveVideci ? 'Да' : 'Не',
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceOneGroup = (response) => {
    const withSubGroups = [
        {
            kratakNaziv: response.group.kratakNaziv,
            naziv: response.group.naziv,
            tip: response.group.tip,
        },
        ...response.group.podredjeneGrupe,
    ]

    return withSubGroups.reduce((acc, currentEl) => {
        const addElement = {
            naziv: currentEl.naziv,
            kratakNaziv: currentEl.kratakNaziv,
            tip: currentEl.cirilica,
            nadredjenaGrupa: currentEl.nadredjenaGrupa?.naziv,
        }
        acc.push(addElement)
        return acc
    }, [])
}

export const reduceOneGroupExcel = (response) => {
    const withSubGroups = [
        {
            kratakNaziv: response.group.kratakNaziv,
            naziv: response.group.naziv,
            tip: response.group.tip,
        },
        ...response.group.podredjeneGrupe,
    ]

    return withSubGroups.reduce((acc, currentEl) => {
        const addElement = {
            Назив: currentEl.naziv,
            'Кратак назив': currentEl.kratakNaziv,
            Тип: currentEl.cirilica,
            'Надређена Група': currentEl.nadredjenaGrupa?.naziv,
        }
        acc.push(addElement)
        return acc
    }, [])
}

export const reduceNotifications = (dataArr, allMightyLocations) =>
    dataArr.reduce((acc, currentEl) => {
        const startDate = moment(currentEl.datumPocetka).format('DD/MM/YYYY')
        const groupsArr = currentEl?.lokacija
            ? [...currentEl.lokacija.odgovoran, ...allMightyLocations]
            : [...allMightyLocations]
        const groupReliable = groupsArr
            .filter((el) => el.tip.includes('Izvođač'))
            .map((el) => el.naziv)
            .join(', ')
        const rokZavr =
            currentEl.rok === 'клизно'
                ? 'клизно'
                : moment(currentEl.rokZavrsetka).format('DD/MM/YYYY')

        const addElement = {
            lokacija:
                currentEl.lokacija === null ? '/' : currentEl.lokacija.naziv,
            aktivnost: currentEl.kratakNaziv,
            odgovoran: groupReliable,
            datumPocetka: startDate,
            datumZavrsetka:
                currentEl.status === 'Nije započeta'
                    ? 'Незапочета активност'
                    : rokZavr,
            status: latToCyr(currentEl.status),

            rok: currentEl.rok,
        }
        acc.push(addElement)
        return acc
    }, [])
export const reduceNotificationsExcel = (dataArr, allMightyLocations) =>
    dataArr.reduce((acc, currentEl) => {
        const startDate = moment(currentEl.datumPocetka).format('DD/MM/YYYY')
        const groupsArr = currentEl?.lokacija
            ? [...currentEl.lokacija.odgovoran, ...allMightyLocations]
            : [...allMightyLocations]
        const groupReliable = groupsArr
            .filter((el) => el.tip.includes('Izvođač'))
            .map((el) => el.naziv)
            .join(', ')
        const rokZavr =
            currentEl.rok === 'клизно'
                ? 'клизно'
                : moment(currentEl.rokZavrsetka).format('DD/MM/YYYY')

        const addElement = {
            Кластер:
                currentEl.lokacija === null ? '/' : currentEl.lokacija.naziv,
            Активност: currentEl.kratakNaziv,
            ' Извођач радова': groupReliable,
            'Датум почетка': startDate,
            'Рок за завршетак':
                currentEl.status === 'Nije započeta'
                    ? 'Незапочета активност'
                    : rokZavr,
            Статус: latToCyr(currentEl.status),

            Рок: currentEl.rok,
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceReportsFinished = (dataArr) =>
    dataArr.reduce((acc, currentEl) => {
        // const groupArray = currentEl.odgovoran
        //     ?.map((el) => el.naziv)
        //     .join(', ')

        const formatDate = moment(currentEl?.completed?.datumZavrsetka).format(
            'DD/MM/YYYY'
        )
        const addElement = {
            nazivAktiv: currentEl.completed?.kratakNaziv,
            lokacija: currentEl.naziv,
            ugovor: currentEl?.ugovor?.naziv,
            datumZav: formatDate,
            status: currentEl?.completed?.status,
            // status: currentEl.status,
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceReportsFinishedExcel = (dataArr) =>
    dataArr.reduce((acc, currentEl) => {
        // const groupArray = currentEl.odgovoran
        //     ?.map((el) => el.naziv)
        //     .join(', ')

        const formatDate = moment(currentEl?.completed?.datumZavrsetka).format(
            'DD/MM/YYYY'
        )
        const addElement = {
            Активност: currentEl.completed?.kratakNaziv,
            Кластер: currentEl.naziv,
            Уговор: currentEl?.ugovor?.naziv,
            'Датум завршетка': formatDate,
            Статус: currentEl?.completed?.status,
            // status: currentEl.status,
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceReportsPzi = (dataArr, selectedActivity) =>
    dataArr.reduce((acc, currentEl) => {
        const formatDate = moment(currentEl?.datumZavrsetka).isValid()
        let hasFiles = ''
        if (selectedActivity === 'A9')
            hasFiles =
                currentEl.fajlovi === 'Нема фајлова.'
                    ? 'Није послат ПЗИ'
                    : 'ПЗИ послат'

        if (selectedActivity === 'A12')
            hasFiles =
                currentEl.fajlovi === 'Нема фајлова.'
                    ? 'Није послат ПИО'
                    : 'ПИО послат'
        if (selectedActivity !== 'A12' && selectedActivity !== 'A9')
            hasFiles =
                currentEl.fajlovi === 'Нема фајлова.'
                    ? 'Нема фајлова'
                    : 'Фајлови послати'
        const groupArray = currentEl.odgovoran
            ?.map((el) => latToCyr(el.naziv))
            .join(', ')

        // const formatDate = moment(
        //     currentEl?.completed?.datumZavrsetka
        // ).format('DD/MM/YYYY')
        // const checkFile = currentEl.fajlovi === "Нема фајлова"
        const addElement = {
            lokacija: currentEl?.lokacija,
            status: currentEl?.status_aktivnosti,
            prioritet: currentEl?.prioritet?.naziv,
            odgovoran: groupArray,
            datumZavr: formatDate
                ? moment(currentEl?.datumZavrsetka).format('DD/MM/YYYY')
                : 'Нема датум завршетка',
            pzi: hasFiles,
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceReportsPziExcel = (dataArr, selectedActivity) =>
    dataArr.reduce((acc, currentEl) => {
        const formatDate = moment(currentEl?.datumZavrsetka).isValid()
        let hasFiles = ''
        if (selectedActivity === 'A9')
            hasFiles =
                currentEl.fajlovi === 'Нема фајлова.'
                    ? 'Није послат ПЗИ'
                    : 'ПЗИ послат'

        if (selectedActivity === 'A12')
            hasFiles =
                currentEl.fajlovi === 'Нема фајлова.'
                    ? 'Није послат ПИО'
                    : 'ПИО послат'
        if (selectedActivity !== 'A12' && selectedActivity !== 'A9')
            hasFiles =
                currentEl.fajlovi === 'Нема фајлова.'
                    ? 'Нема фајлова'
                    : 'Фајлови послати'
        const groupArray = currentEl.odgovoran
            ?.map((el) => latToCyr(el.naziv))
            .join(', ')

        // const formatDate = moment(
        //     currentEl?.completed?.datumZavrsetka
        // ).format('DD/MM/YYYY')
        // const checkFile = currentEl.fajlovi === "Нема фајлова"
        const addElement = {
            'Назив кластера': currentEl?.lokacija,
            Статус: currentEl?.status_aktivnosti,
            Приоритет: currentEl?.prioritet?.naziv,
            Одговоран: groupArray,
            'Датум завршетка': formatDate
                ? moment(currentEl?.datumZavrsetka).format('DD/MM/YYYY')
                : 'Нема датум завршетка',
            Фајлови: hasFiles,
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceReportsSummary = (data, allLocationContractors) => {
    const tableData = data.completedActivitiesCount.reduce((acc, currentEl) => {
        const duzinaTrase = () => {
            if (currentEl.kratakNaziv === 'A1') {
                return ` (${data.trasa} м)`
            }
            if (currentEl.kratakNaziv === 'A5') {
                return ` (${data.tax} рсд)`
            }
            return ''
        }

        const addElement = {
            naziv: `${currentEl.kratakNaziv} - ${currentEl.naziv}`,
            count: `${currentEl.count}  ${duzinaTrase()}`,
        }
        const singleContractorCount = currentEl?.izvCounter.map((item) => ({
            [item.izvodjac.naziv]: item?.count,
        }))

        const singleContractorCountToObject = Object.assign(
            {},
            ...singleContractorCount
        )

        const allLocationContractorCount = allLocationContractors.map(
            (item) => ({
                [item.naziv]: currentEl.count,
            })
        )
        const allLocationContractorCountToObject = Object.assign(
            {},
            ...allLocationContractorCount
        )

        const contractorsCount = {
            ...addElement,
            ...singleContractorCountToObject,
            ...allLocationContractorCountToObject,
        }
        acc.push(contractorsCount)
        return acc
    }, [])

    const total = {
        naziv: 'Укупно кластера',
        count: data.totalLocations,
    }

    const contractorDataTotal = data.izvodjaci?.map((item) => ({
        [item.izvodjac.naziv]: item.count,
    }))

    const contractorDataTotaltoObject = Object.assign(
        {},
        ...contractorDataTotal
    )

    const allLocationContractorTotal = allLocationContractors.map((item) => ({
        [item.naziv]: data.totalLocations,
    }))
    const allLocationContractorToObject = Object.assign(
        {},
        ...allLocationContractorTotal
    )

    const dynamicTotalContractorsData = {
        ...total,
        ...contractorDataTotaltoObject,
        ...allLocationContractorToObject,
    }
    const fullData = [dynamicTotalContractorsData, ...tableData]

    return fullData
}

export const reduceReportsSummaryExcel = (data, allLocationContractors) => {
    const tableData = data.completedActivitiesCount.reduce((acc, currentEl) => {
        const duzinaTrase = () => {
            if (currentEl.kratakNaziv === 'A1') {
                return ` - укупна дужина трасе: ${data.trasa} м`
            }
            if (currentEl.kratakNaziv === 'A5') {
                return ` - укупно такси: ${data.tax} рсд`
            }
            return ''
        }
        const addElement = {
            Назив: `${currentEl.kratakNaziv} - ${currentEl.naziv}`,
            Укупно: `${currentEl.count}  ${duzinaTrase()}`,
        }
        const singleContractorCount = currentEl?.izvCounter.map((item) => ({
            [item.izvodjac.naziv]: item?.count,
        }))
        const singleContractorCountToObject = Object.assign(
            {},
            ...singleContractorCount
        )

        const allLocationContractorCount = allLocationContractors.map(
            (item) => ({
                [item.naziv]: currentEl.count,
            })
        )
        const allLocationContractorCountToObject = Object.assign(
            {},
            ...allLocationContractorCount
        )
        const contractorsCount = {
            ...addElement,
            ...singleContractorCountToObject,
            ...allLocationContractorCountToObject,
        }
        acc.push(contractorsCount)

        return acc
    }, [])

    const total = {
        Назив: 'Укупно кластера',
        Укупно: data.totalLocations,
    }

    const contractorDataTotal = data.izvodjaci?.map((item) => ({
        [item.izvodjac.naziv]: item.count,
    }))

    const contractorDataTotaltoObject = Object.assign(
        {},
        ...contractorDataTotal
    )

    const allLocationContractorTotal = allLocationContractors.map((item) => ({
        [item.naziv]: data.totalLocations,
    }))
    const allLocationContractorToObject = Object.assign(
        {},
        ...allLocationContractorTotal
    )

    const dynamicTotalContractorsData = {
        ...total,
        ...contractorDataTotaltoObject,
        ...allLocationContractorToObject,
    }
    const fullData = [dynamicTotalContractorsData, ...tableData]

    return fullData
}

export const reduceAllInvReports = (dataArr) =>
    dataArr.reduce((acc, currentEl) => {
        const date = currentEl?.datumPrimopredaje?.split('T')[0].split('-')

        const addElement = {
            _id: currentEl._id,
            datumPrimopredaje: date && `${date[2]}/${date[1]}/${date[0]}`,
            cena: currentEl.invtype.cena,
            kolicina: currentEl.kolicina,
            nazivInv: currentEl.invtype.naziv,
            nazivLokacije: currentEl.lokacija.naziv,
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceAllInvReportsExcel = (dataArr) =>
    dataArr.reduce((acc, currentEl) => {
        const date = currentEl?.datumPrimopredaje?.split('T')[0].split('-')

        const addElement = {
            Назив: currentEl.invtype.naziv,
            Количина: currentEl.kolicina,
            Цена: currentEl.invtype.cena,
            'Датум примопредаје': `${date[2]}/${date[1]}/${date[0]}`,
            'Назив локације': currentEl.lokacija.naziv,
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceSummaryInvReports = (dataArr) =>
    dataArr?.sumPerTypeInventory?.reduce((acc, currentEl) => {
        const date = currentEl.datum?.split('T')[0].split('-')

        const addElement = {
            _id: currentEl._id,
            kolicina: currentEl.Kolicina,
            ukupnaCena: currentEl['Ukupna cena'],
            ukupnaKolicina: currentEl.UkupnaKolicina,
            cena: currentEl.cena,
            naziv: currentEl.naziv,
            kratakNaziv: currentEl.kratakNaziv,
            tip: latToCyr(currentEl.tip),
            trajanjeGarancije: currentEl.trajanjeGarancije,
            datum: currentEl.datum
                ? `${date[2]}/${date[1]}/${date[0]}`
                : 'Више од једног',
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceSummaryInvReportsExcel = (dataArr) =>
    dataArr?.sumPerTypeInventory?.reduce((acc, currentEl) => {
        const date = currentEl.datum?.split('T')[0].split('-')
        const addElement = {
            Назив: currentEl.naziv,
            'Редни број': currentEl.redniBroj,
            'Укупна количина': currentEl.UkupnaKolicina,
            'Укупна цена': currentEl['Ukupna cena'],
            Тип: latToCyr(currentEl.tip),
            'Трајање гаранције': currentEl.trajanjeGarancije,
            Датум: currentEl.datum
                ? `${date[2]}/${date[1]}/${date[0]}`
                : 'Више од једног',
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceInvByLocationReports = (dataArr) =>
    dataArr.inventoryPerLocation.reduce((acc, currentEl) => {
        const addElement = {
            naziv: currentEl.naziv,
            stanje: latToCyr(currentEl.stanje),
        }

        acc.push(addElement)
        return acc
    }, [])

export const reduceInvByLocationReportsExcel = (dataArr) =>
    dataArr.inventoryPerLocation.reduce((acc, currentEl) => {
        const addElement = {
            Назив: currentEl.naziv,
            Стање: latToCyr(currentEl.stanje),
        }

        acc.push(addElement)
        return acc
    }, [])

export const reduceSchoolNotifications = (dataArr) =>
    dataArr.notifications.reduce((acc, currentEl) => {
        const date = currentEl?.mailPoslat?.split('T')[0].split('-')

        const addElement = {
            naziv: currentEl.lokacija.naziv,
            mailPoslat: `${date[2]}/${date[1]}/${date[0]}`,
            potvrdaSkole: currentEl.potvrdaSkole ? 'ДА' : 'НЕ',
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceSchoolNotificationsExcel = (dataArr) =>
    dataArr.notifications.reduce((acc, currentEl) => {
        const date = currentEl?.mailPoslat?.split('T')[0].split('-')

        const addElement = {
            Назив: currentEl.lokacija.naziv,
            'Школа обавештена': `${date[2]}/${date[1]}/${date[0]}`,
            'Школа потврдила': currentEl.potvrdaSkole ? 'ДА' : 'НЕ',
        }
        acc.push(addElement)
        return acc
    }, [])

export const reduceInventoryCheck = (dataArr) =>
    dataArr.locations.reduce((acc, currentEl) => {
        // const invStatus = statuses.data.filter(
        //     (status) => status.lokacija === currentEl._id
        // )

        const addElement = {
            _id: currentEl._id,
            naziv: currentEl.naziv,
            statusA13: latToCyr(currentEl.activity.status),
            // potvrdjeno: invStatus[0].status,
            // statusId: invStatus[0]._id,
            statusInventara: currentEl.inventar
                ? 'Постоје унете ставке'
                : 'Нема унетих ставки',
        }

        acc.push(addElement)
        return acc
    }, [])

export const reduceInventoryCheckExcel = (dataArr) =>
    dataArr.locations.reduce((acc, currentEl) => {
        // const invStatus = statuses.data.filter(
        //     (status) => status.lokacija === currentEl._id
        // )

        const addElement = {
            _id: currentEl._id,
            Назив: currentEl.naziv,
            'Статус А13': latToCyr(currentEl.activity.status),
            // potvrdjeno: invStatus[0].status,
            // statusId: invStatus[0]._id,
            Инвентар: currentEl.inventar
                ? 'Постоје унете ставке'
                : 'Нема унетих ставки',
        }

        acc.push(addElement)
        return acc
    }, [])
