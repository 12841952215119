import React, { useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'
import { Grid, Button, Snackbar, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'
import EditIcon from '@material-ui/icons/Edit'
import PublishIcon from '@material-ui/icons/Publish'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import DownloadIcon from '@material-ui/icons/Download'
import Tooltip from '@material-ui/core/Tooltip'
import fileDownload from 'js-file-download'
import { axiosAuth as axios } from '../../../../util/axios-instance'
import AdminTable from '../../../../components/tables/AdminTable'
import SimpleModal from '../../../../components/Modal/Modal'
import latToCyr from '../../../../util/latToCyr'
import { useSnackbar } from '../../../../util/snackbar-hook'
import EditInventoryModal from './EditInventoryModal'
import importExcel from '../../../../util/import'
import { setImportId } from '../../../../Redux/actions/importActions'
import ButtonImportInventory from '../../../../components/FormsUI/Button/Buttons-ImportExports/ButtonImportInventory'

const InventoryCheck = () => {
    const [reports, setReports] = useState([])
    const reduxInfo = useSelector((state) => state.importInfo)
    const dispatch = useDispatch()
    const [refreshState, setRefreshState] = useState(false)
    const [load, setLoad] = useState(false)
    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    const [searchDebounced] = useDebounce(search, 2000)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [totalResults, setTotalResults] = useState(0)
    const [editOpen, setEditOpen] = useState(false)
    const [editData, setEditData] = useState(null)
    const [stats, setStats] = useState(null)
    const { snackbar, severity, setSeverity, setSnackbar, handleSnackBarOpen } =
        useSnackbar()

    const getStats = async () => {
        setLoad(true)
        const response = await axios.get('/api/A13/get/stats')
        setStats(response.data)
        setLoad(false)
    }

    const getReports = async () => {
        try {
            setLoad(true)

            const url =
                // prettier-ignore
                searchDebounced.eng === ''
                ? `/api/A13?page=${page+1}&limit=${rowsPerPage}`
                : `/api/A13?eng=${searchDebounced.eng}&srb=${searchDebounced.srb
                }&page=${page+1}&limit=${rowsPerPage}`

            const response = await axios.get(url)

            console.log('res', response)

            const statuses = await axios.get('/api/a13/get-all')

            getStats()

            const tableData = response?.data?.locations?.reduce(
                (acc, currentEl) => {
                    const invStatus = statuses.data.filter(
                        (status) => status.lokacija === currentEl._id
                    )

                    const addElement = {
                        _id: currentEl._id,
                        activity: currentEl.activity,
                        ugovor: currentEl.ugovor,
                        naziv: currentEl.naziv,
                        naselje: currentEl.naselje,
                        opstina: currentEl.opstina,
                        statusA13: latToCyr(currentEl.activity.status),
                        potvrdjeno: invStatus[0]?.status,
                        statusId: invStatus[0]?._id,
                        statusInventara: currentEl.inventar
                            ? 'Постоје унете ставке'
                            : 'Нема унетих ставки',
                    }

                    acc.push(addElement)
                    return acc
                },
                []
            )
            setTotalResults(response.data.totalResults)
            setReports(tableData)
            setLoad(false)
        } catch (error) {
            console.log(error)
        }
    }

    const downloadA13 = async (data) => {
        try {
            const response = await axios.get(
                `/api/a13/download-files?aktId=${data.activity._id}&lokId=${data._id}`,
                {
                    responseType: 'arraybuffer',
                }
            )

            fileDownload(response.data, `${data.naziv}-a13.zip`)
        } catch (error) {
            console.log(error)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Нема докумената',
            })
        }
    }

    const confirmStatus = async (data) => {
        try {
            setRefreshState(true)
            const newState = !data.potvrdjeno
            await axios.patch(`/api/A13/${data.statusId}`, {
                status: newState,
            })
            setSeverity('success')
            setSnackbar({
                open: true,
                message: 'Успешно промењен статус',
            })
            setRefreshState(false)
        } catch (error) {
            console.log(error)
            setSeverity('warning')
            setSnackbar({
                open: true,
                message: 'Промена статуса неуспешна',
            })
        }
    }

    useEffect(() => {
        if (!refreshState) getReports()
    }, [
        page,
        rowsPerPage,
        refreshState,
        searchDebounced.eng,
        searchDebounced.srb,
    ])

    const importInv = (event) => {
        importExcel(
            event,
            reduxInfo.id,
            setSeverity,
            setSnackbar,
            setRefreshState,
            setLoad
        )
    }

    const actionBodyTemplate = (rowData) => (
        <Grid key="edit" container spacing={2}>
            <Grid item xs={3}>
                <Tooltip title="Измена инвентара">
                    <Button
                        name="izmeni"
                        onClick={() => {
                            setEditData(rowData)
                            setEditOpen(true)
                            dispatch(setImportId(rowData._id))
                        }}
                    >
                        <EditIcon />
                    </Button>
                </Tooltip>
            </Grid>
            <Grid item xs={3}>
                <Tooltip title="Преузми А13">
                    <Button name="preuzmi" onClick={() => downloadA13(rowData)}>
                        <DownloadIcon />
                    </Button>
                </Tooltip>
            </Grid>
            <Grid item xs={3}>
                <label htmlFor="upload-CSV">
                    <input
                        style={{ display: 'none' }}
                        id="upload-CSV"
                        name="upload-CSV"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(event) => importInv(event)}
                    />
                    <Tooltip title="Увоз инвентара">
                        <Button
                            name="preview"
                            component="span"
                            onClick={() => {
                                dispatch(setImportId(rowData._id))
                            }}
                        >
                            <PublishIcon />
                        </Button>
                    </Tooltip>
                </label>
            </Grid>
            <Grid item xs={3}>
                <Tooltip
                    title={rowData.potvrdjeno ? 'Поништи' : 'Потврди промене'}
                >
                    <Button
                        name="obrisi"
                        onClick={() => confirmStatus(rowData)}
                    >
                        {rowData.potvrdjeno ? <CloseIcon /> : <DoneIcon />}
                    </Button>
                </Tooltip>
            </Grid>
        </Grid>
    )

    const rowClass = (rowData) => {
        if (rowData.potvrdjeno) {
            return { greenRow: true }
        }
        return { whiteRow: true }
    }

    return (
        <div style={{ padding: 30 }}>
            {load && <SimpleModal />}
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                key="key"
            >
                <Alert onClose={handleSnackBarOpen} severity={severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>

            {editOpen && editData && (
                <EditInventoryModal
                    // importId={importId}
                    data={editData}
                    modalOpen={editOpen}
                    setModal={setEditOpen}
                    refresh={refreshState}
                    setRefresh={setRefreshState}
                    setSeverityHandler={setSeverity}
                    setSnackbarHandler={setSnackbar}
                />
            )}
            {stats && (
                <Grid
                    container
                    spacing={4}
                    alignItems="center"
                    style={{ padding: '10px 0' }}
                >
                    <Grid item>
                        <ButtonImportInventory
                            setRefresh={setRefreshState}
                            setSeverityHandler={setSeverity}
                            setSnackbarHandler={setSnackbar}
                            setLoad={setLoad}
                            id=""
                            withUpload={false}
                        />
                    </Grid>
                    <Grid item>
                        <Typography align="left">{stats.status}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography align="left">{stats.inventory}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography align="left">{stats.finishA13}</Typography>
                    </Grid>
                </Grid>
            )}

            <AdminTable
                data={reports}
                name="invCheck"
                tableHeaders={[
                    { field: 'naziv', header: 'Назив' },
                    { field: 'statusA13', header: 'Статус А13' },
                    { field: 'statusInventara', header: 'Инвентар' },
                    { field: 'edit', header: '' },
                ]}
                setSearch={setSearch}
                search={search}
                totalResults={totalResults}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                onRowClick={() => {}}
                rowClass={rowClass}
                actionBodyTemplate={actionBodyTemplate}
                editColumnwidth={250}
                model="A13"
                title="Провера инвентара"
                exportFileName="Провера инвентара"
            />
        </div>
    )
}

export default InventoryCheck
