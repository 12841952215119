import { axiosAuth } from '../../util/axios-instance'
import {
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_GUESTS_LIST_REQUEST,
    USER_GUESTS_LIST_SUCCESS,
    USER_GUESTS_LIST_FAIL,
    MYINFO_REQUEST,
    MYINFO_SUCCESS,
    MYINFO_FAIL,
    USER_ROLE,
} from '../constants/userConstants'

export const getAllUsers = (page, rowsPerPage) => async (dispatch) => {
    try {
        dispatch({ type: USER_LIST_REQUEST })

        const { data } = await axiosAuth(
            `api/user?page=${page + 1}&limit=${rowsPerPage}`
        )

        dispatch({
            type: USER_LIST_SUCCESS,
            payload: { totalResults: data.totalResults, users: data.users },
        })
    } catch (error) {
        dispatch({
            type: USER_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getGuests = (page, rowsPerPage) => async (dispatch) => {
    try {
        dispatch({ type: USER_GUESTS_LIST_REQUEST })

        const { data } = await axiosAuth(
            `api/user/guests?page=${page + 1}&limit=${rowsPerPage}`
        )

        dispatch({
            type: USER_GUESTS_LIST_SUCCESS,
            payload: { totalResults: data.totalResults, users: data.guests },
        })
    } catch (error) {
        dispatch({
            type: USER_GUESTS_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const myProfile = () => async (dispatch) => {
    try {
        dispatch({ type: MYINFO_REQUEST })

        const { data } = await axiosAuth(`api/user/my-profile`)
        console.log(data, 'data iz reduxa myprofile')
        dispatch({
            type: MYINFO_SUCCESS,
            payload: { ...data },
        })
    } catch (error) {
        dispatch({
            type: MYINFO_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const myRole = (role) => ({ type: USER_ROLE, payload: role })

export default 'aaa'
