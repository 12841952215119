import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

import { useMediaQuery, useTheme } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import { useSelector } from 'react-redux'
import { capitalize } from 'lodash'
import Logo from '../../assets/logo.png'
import Routes from '../../routing/Routes'

import LogoutBtn from '../FormsUI/Button/LogoutBtn'

import SideBarConfig from './SideBarConfig'
/* eslint-disable*/
const styles = (theme) => ({
    root: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        color: '#000133',
    },
    appBar: {
        background: '#00022E',
        color: '#FC86AA',
    },
    icon: {
        padding: '10px',
        color: '#ffff',
    },
    title: {
        margin: 'auto',
        flexGrow: 1,
        color: '#FFF',
        textAlign: 'left',
    },
    container: {
        display: 'flex',
        flex: 1,
    },
    drawer: {
        position: 'static',
        transition: 'width .7s',
    },
    closed: {
        width: '0px',
    },
    opened: {
        width: '220px',
    },
    main: {
        flex: 1,
        background: '#ffffff',
        color: 'black',
    },
    footer: {
        height: '50px',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },

    logoSize: {
        width: 30,
        marginRight: '0.5rem',
    },
    header: {
        background: '#4E5054',
    },
    logo: {
        display: 'flex',
        marginLeft: '5%',
    },
    navlinks: {
        width: '200px',
        color: '#000',
    },
    toolbar: {
        marginTop: 30,
    },
    logout: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
    divider: {
        margin: '5px 0',
    },
    roleWelcome: {
        color: '#fff',
        marginRight: 15,
    },
})

export const ResponsiveDrawer = () => {
    const useStyles = makeStyles(styles)
    const classes = useStyles()
    const [isOpened, setIsOpened] = useState(true)

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down(550))

    let myGroup = ''

    const myRole = useSelector((state) => state.myrole)
    // console.log(myRole, 'redux role iz drawera')
    const isLoading = myRole && myRole.user === ''

    const isGuest = myRole && myRole?.user !== '' && myRole.user.rola === 'Gost'

    const usePageViews = () => {
        const location = useLocation()
    }
    usePageViews()

    if (
        myRole &&
        myRole.user !== '' &&
        myRole.user.rola !== 'Gost' &&
        myRole.user.rola !== 'Admin' &&
        myRole?.user.grupa
    ) {
        myGroup = myRole?.user.grupa.kratakNaziv
    }

    useEffect(() => {
        if (matches) setIsOpened(false)
    }, [matches])

    return (
        <div className={classes.root}>
            <AppBar className={clsx(classes.appBar, classes.header)}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        onClick={() => setIsOpened(!isOpened)}
                        className={classes.icon}
                    >
                        {isOpened ? <MenuOpenIcon /> : <MenuIcon />}
                    </IconButton>
                    <div className={classes.logo}>
                        <img
                            src={Logo}
                            alt="logo"
                            className={classes.logoSize}
                        />
                        <Typography variant="h6" className={classes.title}>
                            Министарство информисања и телекомуникација
                        </Typography>
                    </div>
                    <div className={classes.logout}>
                        <Typography
                            variant="body2"
                            className={classes.roleWelcome}
                        >
                            Добро дошли, {capitalize(myRole.user.ime)}{' '}
                            {capitalize(myRole.user.prezime)}
                        </Typography>

                        <LogoutBtn />
                    </div>
                </Toolbar>
            </AppBar>

            <Toolbar />

            <div className={classes.container}>
                {isGuest || isLoading ? (
                    <></>
                ) : (
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: clsx(classes.drawer, {
                                [classes.closed]: !isOpened,
                                [classes.opened]: isOpened,
                            }),
                        }}
                    >
                        {myRole && myRole.user !== '' && myRole.user.rola ? (
                            <SideBarConfig
                                role={myRole.user.rola}
                                group={myGroup}
                            />
                        ) : (
                            false
                        )}
                    </Drawer>
                )}

                <main className={classes.main}>
                    <Routes />
                </main>
            </div>
        </div>
    )
}
export default ResponsiveDrawer
