import React, { memo } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import PropTypes from 'prop-types'
import NotFound from '../pages/NotFound/NotFound'
/*
 * This is the route utility component used for generating
 * routes and child routes it only requires routes array and basePath
 */
const MapAllowedRoutes = ({ routes, isAddNotFound, basePath }) => {
    const match = useRouteMatch(basePath)
    return (
        <Switch>
            {routes.map((route) => {
                const {
                    path,
                    component: Component,
                    children,
                    title,
                    permission,
                    ...rest
                } = route
                return (
                    <Route
                        {...rest}
                        key={path}
                        path={`${match.path}${path}`}
                        exact
                    >
                        <Component>{children}</Component>
                    </Route>
                )
            })}
            {isAddNotFound && (
                <Route>
                    <NotFound />
                </Route>
            )}
        </Switch>
    )
}

export default memo(MapAllowedRoutes)

MapAllowedRoutes.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.any).isRequired,
    basePath: PropTypes.string.isRequired,
    isAddNotFound: PropTypes.bool.isRequired,
}
