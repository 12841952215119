import React from 'react'
import { Button } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const ButtonWrapper = ({ children, buttonStyles, ...otherProps }) => {
    const useStyles = makeStyles(() => ({
        btn: {
            height: (otherStyles) => otherStyles.height,
            color: (otherStyles) => otherStyles.color,
            fontSize: (otherStyles) => otherStyles.fontSize,
            fontWeight: (otherStyles) => otherStyles.fontWeight,
            backgroundColor: (otherStyles) => otherStyles.backgroundColor,

            '&:hover': {
                backgroundColor: (otherStyles) => otherStyles.hoverColor,
            },
        },
    }))
    const { submitForm } = useFormikContext()
    const classes = useStyles(buttonStyles)

    const handleSubmit = () => {
        submitForm()
    }

    const configButton = {
        color: 'primary',
        fullWidth: true,
        onSubmit: handleSubmit,
        ...otherProps,
    }

    return (
        <Button {...configButton} className={classes.btn}>
            {children}
        </Button>
    )
}

ButtonWrapper.propTypes = {
    children: PropTypes.string.isRequired,
    buttonStyles: PropTypes.objectOf(PropTypes.any),
}
ButtonWrapper.defaultProps = {
    buttonStyles: {},
}

export default ButtonWrapper
