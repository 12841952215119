import React from 'react'
import ReactDOM from 'react-dom'
// import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import { Provider } from 'react-redux'
// import thunk from 'redux-thunk'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme/theme'
import 'primereact/resources/themes/md-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import App from './App'
import store from './store'

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline>
            <Provider store={store}>
                <App />
            </Provider>
        </CssBaseline>
    </ThemeProvider>,
    document.getElementById('root')
)
