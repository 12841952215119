import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    cardMargin: {
        margin: '0.5rem 0',
    },
}))

const FileHeader = ({ file }) => {
    const classes = useStyles()
    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.cardMargin}
        >
            <Grid item>{file.name}</Grid>
        </Grid>
    )
}

export default FileHeader

FileHeader.propTypes = {
    file: PropTypes.instanceOf(File).isRequired,
    // onDelete: PropTypes.func.isRequired,
}
