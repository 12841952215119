import React, { useCallback, useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useField } from 'formik'
import PropTypes, { objectOf } from 'prop-types'
import uuid from 'react-uuid'
import { useDropzone } from 'react-dropzone'
import SingleFileUploadWithProgress from './SingleFileUploadWithProgress'
import UploadError from './UploadError'

const useStyles = makeStyles((theme) => ({
    dropzone: {
        border: `2px dashed ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.background.default,
        height: '10rem',
        outline: 'none',
    },
}))
const MultipleFileUploadField = ({
    name,
    activity,
    setSeverity,
    setSnackbar,
    setError,
}) => {
    // eslint-disable-next-line
    const [_, __, helpers] = useField(name)
    const classes = useStyles()
    const [files, setFiles] = useState([])

    const onDrop = useCallback((accFiles, rejFiles) => {
        const mappedAcc = accFiles.map((file) => ({
            file,
            errors: [],
            id: uuid(),
        }))
        const mappedRej = rejFiles.map((r) => ({ ...r, id: uuid() }))
        setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej])
    }, [])
    useEffect(() => {
        helpers.setValue(files)
    }, [files])

    function onDelete(file) {
        setFiles((curr) => curr.filter((fw) => fw.file !== file))
    }
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    })
    return (
        <>
            <Grid item xs={12}>
                <div {...getRootProps({ className: classes.dropzone })}>
                    <input {...getInputProps()} />

                    <p>Превуците фајл или кликните да бисте увезли фајл</p>
                </div>
            </Grid>

            {files.map((fileWrapper) => (
                <Grid item key={fileWrapper.id}>
                    {fileWrapper.errors.length ? (
                        <UploadError
                            file={fileWrapper.file}
                            errors={fileWrapper.errors}
                            onDelete={onDelete}
                        />
                    ) : (
                        <SingleFileUploadWithProgress
                            onDelete={onDelete}
                            file={fileWrapper}
                            activity={activity}
                            setSeverity={setSeverity}
                            setSnackbar={setSnackbar}
                            setError={setError}
                            setFiles={setFiles}
                            // progress={progress}
                        />
                    )}
                </Grid>
            ))}
        </>
    )
}

export default MultipleFileUploadField

MultipleFileUploadField.propTypes = {
    name: PropTypes.string.isRequired,
    activity: objectOf(PropTypes.any).isRequired,
    setSeverity: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
}
