import { MenuItem, TextField } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useField, useFormikContext } from 'formik'
import PropTypes from 'prop-types'

const EditSelects = ({ name, options, currentValue, ...otherProps }) => {
    const { setFieldValue } = useFormikContext()
    const [field, meta] = useField(name)
    const handleChange = (evt) => {
        const { value } = evt.target
        setFieldValue(name, value)
    }

    const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        fullWidth: true,
        onChange: handleChange,
    }

    if (meta && meta.touched && meta.error) {
        configSelect.error = true
        configSelect.helperText = meta.error
    }
    useEffect(() => {
        if (currentValue !== undefined) setFieldValue(name, currentValue)
    }, [name, currentValue, setFieldValue])

    return (
        <TextField {...configSelect}>
            {/* <MenuItem value="">Изаберите приоритет</MenuItem> */}
            {options.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                    {item.naziv}
                </MenuItem>
            ))}
        </TextField>
    )
}

EditSelects.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.instanceOf(Array).isRequired,
    currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}
EditSelects.defaultProps = {
    currentValue: '',
}
export default EditSelects
