import React, { useEffect, useState } from 'react'
// import { useDebounce } from 'use-debounce'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import { Container, Grid } from '@material-ui/core/'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import FolderIcon from '@material-ui/icons/Folder'
import Tooltip from '@material-ui/core/Tooltip'
import AdminTable from '../../../components/tables/AdminTable'
import { axiosAuth as axios } from '../../../util/axios-instance'
import BackToButton from '../../../components/FormsUI/Button/BackToButton'
import SimpleModal from '../../../components/Modal/Modal'
import PageTitle from '../../../components/FormsUI/PageTitle/PageTitle'
import LocationInfoBtn from '../../../components/FormsUI/Button/LocationInfoBtn'
import LocationInfoFix from '../../../components/Cards/LocationInfoFix'
import latToCyr from '../../../util/latToCyr'
import CommentsModal from './SingleLocationModals/CommentsModal'
import FileModal from './SingleLocationModals/FileModal'
import DataModal from './SingleLocationModals/DataModal'

// const useStyles = makeStyles({
//     icon: {
//         marginLeft: 5,
//     },
//     flex: {
//         display: 'flex',
//         justifyContent: 'flex-start',
//     },

//     createButton: {
//         backgroundColor: '#ba000d',
//         color: 'white',
//         '&:hover': {
//             backgroundColor: '#d9534f',
//         },
//         width: '180px',
//         fontWeight: 700,
//         marginBottom: '40px',
//     },
//     pageContainer: {
//         margin: '0px 0',
//     },
//     relative: {
//         position: 'relative',
//     },
//     loader: {
//         position: 'fixed',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         // transform: '-webkit-translate(-50%, -50%)',
//         // transform: '-moz-translate(-50%, -50%)',
//         // transform: '-ms-translate(-50%, -50%)',
//     },
// })

const SingleLocation = () => {
    // const classes = useStyles()
    const { id } = useParams()
    const [location, setLocation] = useState(false)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    // const [load, setLoad] = useState(false)
    // const [expanded, setExpanded] = React.useState(false)
    const [showLocationInfo, setShowLocationInfo] = useState(false)
    const [act, setAct] = useState([])

    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    // const [searchDebounced] = useDebounce(search, 1000)
    const history = useHistory()
    const historyLocation = useLocation()
    const [commentModal, setCommentsModal] = useState(false)
    const [comments, setComments] = useState()
    const [documentsModal, setDocumentsModal] = useState(false)
    const [documents, setDocuments] = useState([])
    const [dataModal, setDataModal] = useState(false)
    const [data, setData] = useState([])
    const [aktId, setAktId] = useState('')

    // const handleChange = (panel) => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panel : false)
    // }

    useEffect(() => {
        if (historyLocation?.state?.from !== 'LOKACIJE')
            history.push('/app/klasteri')
    }, [])

    const getLocation = async () => {
        try {
            const response = await axios.get(`/api/location/${id}`)
            setLocation(response.data.location)

            const svevidece = await axios.get('/api/group/sve-videci/true')
            const svevideceArr = []
            svevidece.data.groups.map((el) => svevideceArr.push(el))

            const tableData =
                response.data &&
                response.data.location.aktivnosti.reduce((acc, currentEl) => {
                    // console.log('current el', currentEl)
                    // console.log('svevidece lok', svevideceArr)
                    // console.log('odg + curr', [
                    //     ...currentEl.lokacija.odgovoran,
                    //     ...svevideceArr,
                    // ])

                    const groupArray = currentEl.odgovoran
                        .map((el) => el)
                        .join(', ')

                    const odgNazivi = [
                        ...currentEl.lokacija.odgovoran,
                        ...svevideceArr,
                    ]
                        .filter((el) => groupArray.includes(el.cirilica))
                        .map(
                            (el) =>
                                `${el.naziv} ${
                                    el.nadredjenaGrupa
                                        ? // prettier-ignore
                                          `(${el.nadredjenaGrupa.naziv})`
                                        : ''
                                }`
                        )
                        .join(', ')

                    const addElement = {
                        id: currentEl._id,
                        naziv: `${currentEl?.kratakNaziv} - ${currentEl.naziv}`,
                        status: latToCyr(currentEl.status),
                        statusLat: currentEl.status,
                        tip: groupArray,
                        nazivOdgGrupe: odgNazivi,
                        rokBrDana: currentEl.rok,
                        pocetak: currentEl.datumPocetka
                            ? moment(currentEl.datumPocetka).format(
                                  'DD/MM/YYYY'
                              )
                            : '',
                        pocetakMD: currentEl.datumPocetka
                            ? moment(currentEl.datumPocetka).format(
                                  'MM/DD/YYYY'
                              )
                            : '',
                        zavrsetak: currentEl.datumZavrsetka
                            ? moment(currentEl.datumZavrsetka).format(
                                  'DD/MM/YYYY'
                              )
                            : '',
                        zavrsetakMD: currentEl.datumZavrsetka
                            ? moment(currentEl.datumZavrsetka).format(
                                  'MM/DD/YYYY'
                              )
                            : '',
                        rok: currentEl.rokZavrsetka,
                        rokZaZavrsetak:
                            currentEl.rok !== 'клизно'
                                ? moment(currentEl.rokZavrsetka).format(
                                      'DD/MM/YYYY'
                                  )
                                : 'клизно',
                        rokZaZavrsetakMD:
                            currentEl.rok !== 'клизно'
                                ? moment(currentEl.rokZavrsetka).format(
                                      'MM/DD/YYYY'
                                  )
                                : 'клизно',
                        poslednjiKomentar:
                            currentEl.komentari.length > 0 ? (
                                <span
                                    data-name="comments"
                                    name="comments"
                                    style={{
                                        width: '100%',
                                        display: 'inline-block',
                                    }}
                                >
                                    {
                                        currentEl.komentari[
                                            currentEl.komentari.length - 1
                                        ].komentar
                                    }
                                </span>
                            ) : (
                                ''
                            ),
                        podaci: currentEl.podaci,
                        fajlovi: currentEl.fajlovi,
                        sviKomentari: currentEl.komentari,
                    }
                    acc.push(addElement)
                    return acc
                }, [])

            setAct(tableData)
        } catch (error) {
            console.log(error)
        }
    }

    const actionBodyTemplate = (rowData) => (
        <Grid key="edit" container spacing={2} justifyContent="center">
            <Grid item xs={4}>
                {rowData.fajlovi.length > 0 && (
                    <Tooltip title="Документа">
                        {/* <Button endIcon={<FolderIcon />} name="dokumenta" /> */}
                        <FolderIcon data-name="dokumenta" />
                    </Tooltip>
                )}
            </Grid>
            <Grid item xs={4}>
                {rowData.podaci.length > 0 &&
                    rowData.podaci[0].vrednost !== '' && (
                        <Tooltip title="Подаци">
                            {/* <Button endIcon={<InsertDriveFileIcon />} name="podaci" /> */}
                            <InsertDriveFileIcon data-name="podaci" />
                        </Tooltip>
                    )}
            </Grid>
        </Grid>
    )
    const handleOpenCommentsModal = () => {
        setCommentsModal(true)
    }
    const onRowClick = (rowData) => {
        // console.log(rowData.originalEvent.target.dataset.name === 'comments')
        if (
            rowData.originalEvent.target.nodeName === 'TD' ||
            rowData.originalEvent.target.nodeName === 'DIV'
        ) {
            history.push(`/app/aktivnost/${rowData.data.id}`)
        }
        if (
            rowData.originalEvent.target.nodeName === 'path' &&
            rowData.originalEvent.target.parentNode.dataset.name === 'dokumenta'
        ) {
            setDocumentsModal(true)
            setDocuments(rowData.data.fajlovi)
            setAktId(rowData.data.id)
        }
        if (
            rowData.originalEvent.target.nodeName === 'path' &&
            rowData.originalEvent.target.parentNode.dataset.name === 'podaci'
        ) {
            setData(rowData.data.podaci)
            setDataModal(true)
        }
        if (rowData.originalEvent.target.dataset.name === 'comments') {
            setComments(rowData.data)
            handleOpenCommentsModal()
        }
    }

    const rowClass = (rowData) => {
        const danas = new Date()
        // const pocetakD = new Date(rowData.pocetakMD)
        const zavrseno = new Date(rowData.zavrsetakMD)
        const planiraniRokZavrsetka = new Date(rowData.rokZaZavrsetakMD)
        const brojDanaDoRoka = moment
            .duration(moment(planiraniRokZavrsetka).diff(moment(danas)))
            .asDays()
        const rokBrojDana = rowData.rokBrDana
        const tenPercentToDeadLine = brojDanaDoRoka / rokBrojDana

        if (
            rowData.statusLat === 'Završena' &&
            planiraniRokZavrsetka > zavrseno
        ) {
            return { greenRow: true }
        }
        if (
            rowData.statusLat === 'Završena' &&
            planiraniRokZavrsetka < zavrseno
        ) {
            return { greenRedRow: true }
        }
        if (
            brojDanaDoRoka > 0 &&
            tenPercentToDeadLine > 0.1 &&
            rowData.statusLat === 'U toku'
        ) {
            return { blueRow: true }
        }
        if (
            brojDanaDoRoka > 0 &&
            tenPercentToDeadLine < 0.1 &&
            rowData.statusLat === 'U toku'
        ) {
            return { orangeRow: true }
        }
        if (brojDanaDoRoka < 0 && rowData.statusLat === 'U toku') {
            return { redRow: true }
        }
        if (
            rowData.rokZaZavrsetak === 'клизно' &&
            rowData.statusLat === 'U toku'
        ) {
            return { blueRow: true }
        }
        if (
            rowData.statusLat === 'Završena' &&
            rowData.rokZaZavrsetak === 'клизно'
        ) {
            return { greenRow: true }
        }

        return { white: true }
    }

    useEffect(() => {
        getLocation()
    }, [id])

    const handleCloseModal = () => {
        setCommentsModal(false)
    }

    const showLocationHandler = () => {
        setShowLocationInfo(!showLocationInfo)
    }

    // const onRowClick = (rowData) => {
    //     // history.push(`/app/aktivnost/${rowData.data.id}`)
    //     if (rowData.originalEvent.target.nodeName === 'TD') {
    //         history.push(`/app/aktivnost/${rowData.data.id}`)
    //     }
    // }

    // const actionBodyTemplate = () => (
    //     <Grid key="edit" container>
    //         <Grid item xs={6} className={classes.buttonWrapper}>
    //             <Button name="comments">bla bla</Button>
    //         </Grid>
    //     </Grid>
    // )
    // console.log(location)

    return (
        <Container>
            <BackToButton label="НАЗАД НА ЛИСТУ СВИХ КЛАСТЕРА" />
            <Grid container justifyContent="center">
                <PageTitle>Детаљи кластера {location?.naziv}</PageTitle>
            </Grid>
            <Grid container alignContent="flex-start">
                <LocationInfoBtn
                    label="Лична карта кластера"
                    show={showLocationInfo}
                    click={showLocationHandler}
                />
            </Grid>
            {showLocationInfo ? <LocationInfoFix location={location} /> : null}
            {commentModal ? (
                <CommentsModal
                    open={commentModal}
                    close={handleCloseModal}
                    podaci={comments}
                />
            ) : null}
            {dataModal ? (
                <DataModal
                    open={dataModal}
                    close={() => setDataModal(false)}
                    podaci={data}
                />
            ) : null}
            {documentsModal ? (
                <FileModal
                    open={documentsModal}
                    close={() => setDocumentsModal(false)}
                    files={documents}
                    id={aktId}
                    locationId={id}
                    title="Доступни документи"
                    type="activity"
                />
            ) : null}
            {location ? (
                <>
                    <div style={{ marginBottom: '20px' }}>
                        {location &&
                            location.aktivnosti &&
                            location.aktivnosti.length !== 0 && (
                                <AdminTable
                                    data={act}
                                    model="location"
                                    // load={load}
                                    totalResults={parseInt(
                                        location.aktivnosti.length,
                                        10
                                    )}
                                    title={`Листа активности са статусима - ${location?.naziv}`}
                                    page={page}
                                    setPage={setPage}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                    name="activitiesTableSL"
                                    onRowClick={onRowClick}
                                    rowClass={rowClass}
                                    tableHeaders={[
                                        { field: 'naziv', header: 'Назив' },
                                        { field: 'status', header: 'Статус' },
                                        { field: 'pocetak', header: 'Почетак' },
                                        {
                                            field: 'zavrsetak',
                                            header: 'Завршетак',
                                        },
                                        {
                                            field: 'rokZaZavrsetak',
                                            header: 'Рок за завршетак',
                                        },
                                        {
                                            field: 'tip',
                                            header: 'Тип групе',
                                        },
                                        {
                                            field: 'nazivOdgGrupe',
                                            header: 'Назив одговорне групе',
                                        },
                                        {
                                            field: 'poslednjiKomentar',
                                            header: 'Последњи коментар',
                                        },
                                        {
                                            field: 'edit',
                                            header: '',
                                        },
                                    ]}
                                    // actionBodyTemplate={actionBodyTemplate}
                                    setSearch={setSearch}
                                    search={search}
                                    editColumnwidth={150}
                                    actionBodyTemplate={actionBodyTemplate}
                                    withSearch={false}
                                    withPagination={false}
                                    withExport={false}
                                />
                            )}
                    </div>
                </>
            ) : (
                <SimpleModal />
            )}
        </Container>
    )
}

export default SingleLocation
