import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    card: {
        borderRadius: 5,
        border: '3px solid #c95353',
        margin: 5,
        width: 350,
        textAlign: 'left',
        // padding: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 90,
        userSelect: 'none',
        position: 'relative',
        '&:hover': {
            boxShadow:
                '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        },
    },
    button: {
        backgroundColor: '#C54F4F',
        color: 'white',
        '&:hover': {
            background: '#C54F4F',
        },
        height: '100%',
        borderRadius: 0,
    },
    locationName: {
        padding: '0 0 0 15px',
    },
}))

const RemoveLocationCard = ({ name, onClick, id }) => {
    const classes = useStyles()
    // const [state, setState] = useState(false)

    // const handleChange = (event) => {
    //     console.log(event.target.name)
    //     setState({ [event.target.name]: event.target.checked })
    //     setPushed([...pushed, event.target.name])
    //     niz.push(event.target.name)
    //     console.log(niz)
    // }

    // useEffect(() => {
    //     console.log(pushed)
    // }, [pushed])

    return (
        <div className={classes.card}>
            <p className={classes.locationName}>{name}</p>
            <Button className={classes.button} onClick={() => onClick(id)}>
                Избаци
            </Button>
        </div>
    )
}

RemoveLocationCard.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
}

RemoveLocationCard.defaultProps = {
    name: '',
    onClick: () => {},
    id: '',
}

export default RemoveLocationCard
