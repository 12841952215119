import React, { useState, useEffect } from 'react'
// import { useDebounce } from 'use-debounce'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { axiosAuth as axios } from '../../util/axios-instance'
import AdminTable from '../../components/tables/AdminTable'
import SimpleModal from '../../components/Modal/Modal'
import PageTitle from '../../components/FormsUI/PageTitle/PageTitle'
import latToCyr from '../../util/latToCyr'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    root: {
        margin: '20px 20px 50px 20px',
        width: 'unset',
    },
    cellButtons: {
        maxWidth: 'min-content',
        cursor: 'default',
    },
    divButtons: {
        '& > *': {
            width: '33%',
        },
    },
    buttonW: {
        margin: '1rem 0',
    },
    createButton: {
        margin: '20px 0',
    },
    roleBtn: {
        '&:hover': {
            backgroundColor: 'unset',
            boxShadow: 'none',
        },
    },
    grWr: {
        flexWrap: 'unset',
        alignItems: 'center',
    },
    editButton: {
        backgroundColor: '#059D2A',
        color: 'white',
        '&:hover': {
            background: '#059D2A',
        },
    },
    deleteButton: {
        backgroundColor: '#D86565',
        color: 'white',
        '&:hover': {
            background: '#D86565',
        },
    },
    icon: {
        marginLeft: 5,
    },
    btnOut: {
        // margin: '0 10px',
    },
})

const AllRoles = () => {
    const [roles, setRoles] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [totalResults, setTotalResults] = useState(0)
    const [load, setLoad] = useState(false)
    const classes = useStyles()
    const history = useHistory()
    const myInfo = useSelector((state) => state.myrole)

    const [search, setSearch] = useState({ eng: '', key: '', srb: '' })
    // const [searchDebounced] = useDebounce(search, 1000)

    const getRoles = async () => {
        try {
            setLoad(true)
            const { data } = await axios.get(
                `/api/role?page=${page + 1}&limit=${rowsPerPage}`
            )

            const tableData = data.roles.reduce((acc, currentEl) => {
                const addElement = {
                    imeRole: latToCyr(currentEl.imeRole),
                    kratakNaziv: latToCyr(currentEl.kratakNaziv),
                    id: currentEl._id,
                }
                acc.push(addElement)
                return acc
            }, [])

            // setRoles(tableData)
            if (myInfo.user.rola === 'Rukovodilac') {
                setRoles(tableData.filter((one) => one.imeRole !== 'Admin'))
            } else {
                setRoles(tableData)
            }

            const dataLength = parseInt(data.roles.length, 10)
            setTotalResults(dataLength)
            setLoad(false)
        } catch (error) {
            console.log(error)
            setLoad(true)
            setTimeout(() => {
                setLoad(false)
            }, 400)
        }
    }

    // const deleteRole = async (id) => {
    //     try {
    //         const response = await axios.delete(`/api/role/${id}`)
    //         console.log(response)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    useEffect(() => {
        getRoles()
    }, [page, rowsPerPage])

    const actionBodyTemplate = () => (
        <Grid key="edit" container className={classes.grWr} spacing={2}>
            <Button
                name="upravljanjeKorisnicima"
                color="secondary"
                variant="outlined"
                fullWidth
                className={classes.btnOut}
            >
                {' '}
                Управљање корисницима
            </Button>
            {/* </Grid> */}
        </Grid>
    )

    return (
        <div style={{ textAlign: 'left' }}>
            <div className={classes.root}>
                <Button
                    name="upravljanjeKorisnicima"
                    color="secondary"
                    variant="outlined"
                    // fullWidth
                    onClick={() => history.push('/app/upravljanje-korisnicima')}
                    className={classes.btnOut}
                >
                    {' '}
                    Управљање корисницима
                </Button>
                <Grid container justifyContent="center">
                    <PageTitle>Роле</PageTitle>
                </Grid>

                {/* <Box className={classes.buttonW}>
                    <AddButton
                        label="НАПРАВИ НОВУ РОЛУ"
                        click={handleAddClick}
                    /> */}
                {/* <Button
                    className={classes.createButton}
                    variant="contained"
                    color="primary"
                    onClick={() => setCreateOpen(true)}
                >
                    <span>НАПРАВИ НОВУ РОЛУ</span>
                    <AddIcon className={classes.icon} />
                </Button> */}
                {/* </Box> */}
                {load ? (
                    <SimpleModal />
                ) : (
                    <AdminTable
                        withExport={false}
                        withImport={false}
                        model=""
                        data={roles}
                        load={load}
                        title="Роле"
                        totalResults={totalResults}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        name="rolesTable"
                        onRowClick={() => {}}
                        tableHeaders={[
                            {
                                field: 'imeRole',
                                header: 'Име роле',
                            },
                            { field: 'kratakNaziv', header: 'Кратак назив' },
                            // { field: 'edit', header: '' },
                        ]}
                        actionBodyTemplate={actionBodyTemplate}
                        // editColumnwidth={300}
                        setSearch={setSearch}
                        search={search}
                        withSearch={false}
                        withPagination={false}
                    />
                )}
            </div>
        </div>
    )
}

export default AllRoles
