import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
    title: {
        background: '#417AB4',
        color: '#FFF',
        padding: '0.8rem 1rem',
    },
    nested: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    BoardWrap: {
        border: '1px solid #E0E0E0',
        marginTop: '0.5rem',
    },
}))

const GrupaCard = ({ naziv, ime }) => {
    const classes = useStyles()

    return (
        <Box className={classes.BoardWrap}>
            <Typography
                variant="body1"
                color="primary"
                className={classes.title}
            >
                {ime}
            </Typography>

            <Box>
                <List className={classes.root}>
                    <div>
                        <List component="div" disablePadding>
                            <ListItem className={classes.nested}>
                                <Typography variant="body1">Назив:</Typography>
                                <Typography variant="body2">{naziv}</Typography>
                            </ListItem>
                            {/* <ListItem className={classes.nested}>
                                <Typography variant="body1">Тип:</Typography>
                                <Typography variant="body2">{tip}</Typography>
                            </ListItem> */}
                        </List>
                    </div>
                </List>
            </Box>
        </Box>
    )
}

export default GrupaCard

GrupaCard.propTypes = {
    // kn: PropTypes.string,
    naziv: PropTypes.string,
    // tip: PropTypes.string,
    ime: PropTypes.string.isRequired,
}

GrupaCard.defaultProps = {
    // kn: '',
    naziv: '',
    // tip: '',
}
