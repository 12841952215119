import React, { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    header: {
        fontSize: '1rem',
        color: '#417AB4',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    buttonColor: {
        color: '#1E8637',
    },
    exportButtons: {
        minWidth: '200px',
        margin: '0 5px',
    },
    icon: {
        marginLeft: 5,
    },
    speedDial: {
        minWidth: '200px',
        height: '50px',
        borderRadius: '0px',
    },
    multiSelect: {
        marginTop: 'auto',
    },
}))

const BasicTable = ({ data, name, tableHeaders, commentColumWidth }) => {
    const classes = useStyles()
    /* const dt = useRef(null) */
    // eslint-disable-next-line
    const [cols, setCols] = useState(tableHeaders)

    const columnComponents = cols.map((col) =>
        col.field !== 'komentar' ? (
            <Column key={col.field} field={col.field} header={col.header} />
        ) : (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                style={{ width: commentColumWidth }}
            />
        )
    )
    const onCustomSaveState = (state) =>
        window.sessionStorage.setItem(name, JSON.stringify(state))

    const onCustomRestoreState = () =>
        JSON.parse(window.sessionStorage.getItem(name))

    const stateCheck = () => {
        // console.log(`customstate je : ${state}`)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataTable
                    showGridlines
                    className={`${classes.header} p-datatable-sm blueTable`}
                    value={data}
                    resizableColumns
                    columnResizeMode="fit"
                    reorderableColumns
                    stateStorage="custom"
                    customSaveState={onCustomSaveState}
                    customRestoreState={onCustomRestoreState}
                    emptyMessage="Активност нема коментаре."
                    onColReorder={stateCheck}
                >
                    {columnComponents}
                </DataTable>
            </Grid>
        </Grid>
    )
}

BasicTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    tableHeaders: PropTypes.arrayOf(PropTypes.any).isRequired,
    name: PropTypes.string.isRequired,
    commentColumWidth: PropTypes.string,
}
BasicTable.defaultProps = {
    commentColumWidth: '50%',
}

export default BasicTable
