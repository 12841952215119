import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import './table.css'

const InventoryTable = ({
    values,
    actionBodyTemplate,
    onRowClick,
    title,
    columns,
    edit,
}) => {
    const cellData = (rowData, cellTooltip) => (
        <Tooltip title={rowData[cellTooltip] ? rowData[cellTooltip] : ''}>
            <div className="tableCell"> {rowData[cellTooltip]}</div>
        </Tooltip>
    )

    return (
        <div className="tableDiv" style={{ width: '85%', margin: '1rem auto' }}>
            <h3
                style={{
                    textAlign: 'left',
                }}
            >
                {title}
            </h3>
            <DataTable
                value={values}
                onRowClick={onRowClick}
                className="blackTable"
                showGridlines
                emptyMessage="Нема пронађених података"
            >
                {columns.map((col) => (
                    <Column
                        key={Math.floor(Math.random() * 99998789)}
                        field={col.field}
                        header={col.header}
                        style={{
                            width: col.width && col.width,
                        }}
                        body={(rowData) => cellData(rowData, col.field)}
                    />
                ))}
                {edit && (
                    <Column
                        field={actionBodyTemplate}
                        style={{
                            width: '200px',
                        }}
                    />
                )}
            </DataTable>
        </div>
    )
}

export default InventoryTable

InventoryTable.propTypes = {
    values: PropTypes.arrayOf(PropTypes.any).isRequired,
    actionBodyTemplate: PropTypes.func,
    onRowClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    // style: PropTypes.string.isRequired,
    edit: PropTypes.bool,
}

InventoryTable.defaultProps = {
    actionBodyTemplate: () => {},
    onRowClick: () => {},
    edit: false,
}
