import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import uuid from 'react-uuid'
import ActivityRow from './ActivityRow'
import ContractFiles from './ContractFiles'
import latToCyr from '../../util/latToCyr'

const useStyles = makeStyles((theme) => ({
    title: {
        background: '#417AB4',
        color: '#FFF',
        padding: '0.8rem 1rem',
    },
    nested: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    BoardWrap: {
        border: '1px solid #E0E0E0',
        marginTop: '0.5rem',
    },
    titlebody: {
        marginRight: 20,
    },
    contentBody: {
        textAlign: 'right',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    table: {
        border: '1px solid #dddddd',
        borderCollapse: 'collapse',
        margin: 20,
    },
    tr: {
        border: '1px solid #dddddd',
    },
    td: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        width: 300,
    },
    th: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
        backgroundColor: '#417AB4',
        color: 'white',
        width: 300,
    },
    editbutton: {
        backgroundColor: '#d9534f',
        color: 'white',
        '&:hover': {
            backgroundColor: '#d9534f',
        },
        padding: 8,
        margin: 8,
        boxShadow: 'none',
    },
    tables: {
        display: 'flex',
        flexDirection: 'column',
    },
    contractTitle: {
        textAlign: 'left',
        margin: '20px',
        fontSize: 25,
        color: '#417AB4',
        display: 'flex',
        alignItems: 'flex-end',
    },
    icon: {
        fontSize: 40,
        color: '#417AB4',
    },
    htmlTable: {
        width: '100%',
        border: '1px solid black',
        borderCollapse: 'collapse',
        marginBottom: 20,
    },
    btn: {
        margin: 5,
    },
}))

const LocationInfo = ({ location }) => {
    const classes = useStyles()

    return (
        <Grid container>
            <Grid item xs={12}>
                <div className={classes.tables}>
                    <Grid container justifyContent="flex-start">
                        <Box py={1}>
                            <Typography variant="body1">
                                ИНФОРМАЦИЈЕ О КЛАСТЕРУ
                            </Typography>
                        </Box>
                    </Grid>
                    <table className={classes.htmlTable}>
                        <tbody>
                            <tr>
                                <th className={classes.th}>Назив кластера</th>
                                <th className={classes.th}>Општина</th>
                                <th className={classes.th}>Приоритет</th>
                                <th className={classes.th}>Одговоран</th>
                                <th className={classes.th}>Статус</th>
                            </tr>
                            <tr>
                                <td className={classes.td}>
                                    {location?.naziv
                                        ? location.naziv
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location.opstina
                                        ? location.opstina
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location.prioritet
                                        ? location.prioritet.naziv
                                        : 'Нема информација'}
                                    {/* Нема информација */}
                                </td>
                                <td className={classes.td}>
                                    {location?.odgovoran?.length > 0
                                        ? location.odgovoran
                                              .filter(
                                                  (el) => el.tip === 'Izvođač'
                                              )
                                              .map((el) => el.naziv)
                                              .join(', ')
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location.status
                                        ? latToCyr(location.status)
                                        : 'Нема информација'}
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th className={classes.th}>
                                    Број насеља у кластеру
                                </th>
                                <th className={classes.th}>
                                    Просечна дужина рова
                                </th>
                                <th className={classes.th}>Укупна дужина</th>
                                <th className={classes.th}>
                                    Дужина из пријаве
                                </th>
                                <th className={classes.th}>Јавни позив</th>
                            </tr>
                            <tr>
                                <td className={classes.td}>
                                    {location.brojNaselja
                                        ? location.brojNaselja
                                        : 'Нема информација'}
                                </td>

                                <td className={classes.td}>
                                    {location.prosecnaDuzinaRova
                                        ? location.prosecnaDuzinaRova
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location.ukupnaDuzinaRova
                                        ? location.ukupnaDuzinaRova
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location.duzinaIzPrijave
                                        ? location.duzinaIzPrijave
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location.javniPoziv
                                        ? location.javniPoziv
                                        : 'Нема информација'}
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th className={classes.th}>
                                    Број домаћинстава
                                </th>
                                <th className={classes.th}>
                                    Број индиректно покривених домаћинстава
                                </th>
                                <th className={classes.th}>Број школа</th>
                                <th className={classes.th}>
                                    Број индиректно покривених школа
                                </th>
                                <th className={classes.th}>
                                    Број додатних школа
                                </th>
                            </tr>
                            <tr>
                                <td className={classes.td}>
                                    {location.brojDomacinstava
                                        ? location.brojDomacinstava
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location.brojIndirektnoPokrivenihDomacinstava
                                        ? location.brojIndirektnoPokrivenihDomacinstava
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location.brojSkola
                                        ? location.brojSkola
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location.brojIndirektnoPokrivenihSkola
                                        ? location.brojIndirektnoPokrivenihSkola
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location.brojDodatnihSkola
                                        ? location.brojDodatnihSkola
                                        : 'Нема информација'}
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th className={classes.th}>Одобрење за рад</th>
                                <th className={classes.th}>Напомена</th>
                                <th className={classes.th}>
                                    Дужина оптичких траса
                                </th>
                                <th className={classes.th}>
                                    Геокоординате објекта
                                </th>
                                <th className={classes.th}>
                                    Геокоординате тачке присуства оператора
                                </th>
                            </tr>
                            <tr>
                                <td className={classes.td}>
                                    {location.odobrenjeZaRad
                                        ? location.odobrenjeZaRad
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location.napomena
                                        ? location.napomena
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location.duzinaOptickihTrasa
                                        ? location.duzinaOptickihTrasa
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location.geokoordinateObjekta
                                        ? location.geokoordinateObjekta
                                        : 'Нема информација'}
                                </td>
                                <td className={classes.td}>
                                    {location.geokoordinateOperatora
                                        ? location.geokoordinateOperatora
                                        : 'Нема информација'}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Grid container justifyContent="flex-start">
                                <Box py={1}>
                                    <Typography variant="body1">
                                        НАСЕЉА У КЛАСТЕРУ
                                    </Typography>
                                </Box>
                            </Grid>
                            <table className={classes.htmlTable}>
                                <tbody>
                                    <tr>
                                        <th className={classes.th}>Назив</th>
                                        <th className={classes.th}>
                                            Матични број
                                        </th>
                                    </tr>
                                    {location?.naselja?.length > 0 ? (
                                        location.naselja.map((naselje) => (
                                            <tr key={uuid()}>
                                                <td className={classes.td}>
                                                    {naselje.naziv}
                                                </td>
                                                <td className={classes.td}>
                                                    {naselje.maticniBroj}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td className={classes.td}>
                                                Нема додатих насеља
                                            </td>
                                            <td className={classes.td}> </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent="flex-start">
                                <Box py={1}>
                                    <Typography variant="body1">
                                        УСПУТНА НАСЕЉА
                                    </Typography>
                                </Box>
                            </Grid>
                            <table className={classes.htmlTable}>
                                <tbody>
                                    <tr>
                                        <th className={classes.th}>Назив</th>
                                        <th className={classes.th}>
                                            Матични број
                                        </th>
                                    </tr>
                                    {location?.usputnaNaselja?.length > 0 ? (
                                        location.usputnaNaselja.map(
                                            (naselje) => (
                                                <tr key={uuid()}>
                                                    <td className={classes.td}>
                                                        {naselje.naziv}
                                                    </td>
                                                    <td className={classes.td}>
                                                        {naselje.maticniBroj}
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    ) : (
                                        <tr>
                                            <td className={classes.td}>
                                                Нема успутних насеља
                                            </td>
                                            <td className={classes.td}> </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-start">
                        <Box py={1}>
                            <Typography variant="body1">
                                ИНФОРМАЦИЈЕ О ЈАВНОМ ПОЗИВУ
                            </Typography>
                        </Box>
                    </Grid>
                    <table className={classes.htmlTable}>
                        <tbody>
                            <tr>
                                <th className={classes.th}>Јавни позив</th>
                                <th className={classes.th}>
                                    Број јавног позива
                                </th>
                                <th className={classes.th}>Рок трајања</th>
                                <th className={classes.th}>Статус</th>
                                <th className={classes.th}>Документи</th>
                            </tr>
                            <tr>
                                <td className={classes.td}>
                                    {location?.ugovor?.broj
                                        ? location.ugovor.naziv
                                        : 'Није повезан јавни позив'}
                                </td>
                                <td className={classes.td}>
                                    {location?.ugovor?.broj
                                        ? location.ugovor.broj
                                        : ''}
                                </td>
                                <td className={classes.td}>
                                    {location?.ugovor?.broj
                                        ? location.ugovor.rokTrajanja
                                        : ''}
                                </td>
                                <td className={classes.td}>
                                    {location?.ugovor?.broj
                                        ? latToCyr(location.ugovor.status)
                                        : ''}
                                </td>
                                <td className={classes.td}>
                                    {/* <Button
                                        disabled={
                                            location.ugovor.fajlovi.length === 0
                                        }
                                        // onClick={showFiles}
                                        className={classes.btn}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Прикажи уговоре
                                    </Button> */}
                                    <ContractFiles files={location} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Grid container justifyContent="flex-start">
                        <Box py={1}>
                            <Typography variant="body1">
                                ИНФОРМАЦИЈЕ О АКТИВНОСТИМА
                            </Typography>
                        </Box>
                    </Grid>
                    <table className={classes.htmlTable}>
                        <tbody>
                            <tr>
                                <th className={classes.th}>Назив</th>
                                <th className={classes.th}>Документи</th>
                                <th className={classes.th}>Подаци</th>
                            </tr>
                            {location.aktivnosti
                                .filter(
                                    (aktivnost) =>
                                        (aktivnost?.podaci?.length > 0 &&
                                            aktivnost.podaci[0].vrednost !==
                                                '') ||
                                        aktivnost.fajlovi.length > 0
                                )
                                .map((el) => (
                                    <ActivityRow key={el._id} activity={el} />
                                ))}
                        </tbody>
                    </table>
                </div>
            </Grid>
        </Grid>
    )
}

LocationInfo.propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default LocationInfo
