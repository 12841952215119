import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextfieldWrapper from '../../components/FormsUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../components/FormsUI/Button/ButtonWrapper'
import { axiosAuth as axios } from '../../util/axios-instance'
// import SimpleModal from '../../components/Modal/Modal'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    title: {
        textAlign: 'center',
    },
    formField: {
        padding: 10,
    },
    dateDiv: {
        width: '100%',
        padding: 10,
    },
    removeIcon: {
        cursor: 'pointer',
        color: 'red',
        fontSize: 20,
        margin: '0 0 0 10px',
    },
}))

const FORM_VALIDATION = Yup.object().shape({
    imeRole: Yup.string().required('Поље је обавезно'),
    kratakNaziv: Yup.string().required('Поље је обавезно'),
})

export default function CreateRole({ modalOpen, setModal }) {
    const classes = useStyles()
    const [modalStyle] = React.useState(getModalStyle)

    const createRole = async (values) => {
        try {
            await axios.post(`/api/role`, {
                imeRole: values.imeRole,
                kratakNaziv: values.kratakNaziv,
            })

            setModal(false)
        } catch (error) {
            console.log(error)
        }
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 className={classes.title}>Изменa ролe</h2>

            <Formik
                initialValues={{
                    imeRole: '',
                    kratakNaziv: '',
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => createRole(values)}
            >
                <Form>
                    <div className={classes.formDiv}>
                        <div className={classes.tableRow}>
                            <TextfieldWrapper
                                placeholder="Назив роле"
                                name="imeRole"
                            />
                        </div>
                        <div className={classes.tableRow}>
                            <TextfieldWrapper
                                placeholder="Кратак назив роле"
                                name="kratakNaziv"
                            />
                        </div>

                        <ButtonWrapper
                            type="submit"
                            fullWidth
                            style={{
                                backgroundColor: '#337ab7',
                                color: 'white',
                                height: 'unset',
                                fontSize: '0.75rem',
                                // padding: '8px 12px',
                                fontWeight: 'bold',
                                margin: '10px 0',
                            }}
                        >
                            Сачувај измене
                        </ButtonWrapper>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={() => setModal(false)}
                        >
                            Одустани
                        </Button>
                    </div>
                </Form>
            </Formik>
        </div>
    )

    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={() => setModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}

CreateRole.propTypes = {
    modalOpen: PropTypes.bool,
    setModal: PropTypes.func,
}

CreateRole.defaultProps = {
    modalOpen: false,
    setModal: () => {},
}
