import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(1),
        color: '#737373',
        fontWeight: 900,
        textTransform: 'uppercase',
        marginTop: 20,
        fontSize: '1.5rem',
    },
}))

const PageTitle = ({ title }) => {
    const classes = useStyles()
    return (
        <Typography align="center" className={classes.title}>
            {title}
        </Typography>
    )
}

PageTitle.propTypes = {
    title: PropTypes.string,
}

PageTitle.defaultProps = {
    title: ' ',
}

export default PageTitle
